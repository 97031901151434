<template>
    <div class="block text-sm text-gray-600 text-center mb-1 md:hidden">
        {{ $tx(text) }}
    </div>
</template>

<script>
import {defineComponent, inject} from 'vue';

export default defineComponent({
    name: 'ChartLabelHint',
    props: {
        text: {
            type: String,
            default: 'Select a datapoint to view the data or view the data table.',
        }
    },

    setup() {
        return {}
    },
});
</script>
