<template>
    <div class="introduction-section sm:mb-20 bg-gray-100 px-10 pt-0 pb-16 border-b-2 border-gray-800">
        <p class="introduction-content py-10 text-xl leading-8" v-html="introduction"></p>

        <div class="introduction-boxes-container p-2 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-10 max-w-xl xl:max-w-6xl m-auto">
            <div v-for="(topic, index) in topics" :key="index" class="m-auto">
                <button @click="moveTo(topic.link)" class="intro-box-container m-auto w-[250px] aspect-square group focus:ring-0">
                    <div class="intro-box-border border-2 border-gray-400 shadow-lg rounded-2xl overflow-hidden flex flex-col group-focus:ring-2 group-focus:ring-gray-400 group-focus:ring-offset-1">
                        <div class="intro-box-image-container overflow-hidden max-h-[185px] bg-white border-b-8" :class="`border-qol${index+1}-topicBorder`">
                            <img :src="topic.icon" class="flex flex-1" alt="" role="img">
                        </div>
                        <div class="intro-box-label-container text-center text-xl" :class="topic.classes">
                            <p class="flex h-[80px] justify-center flex-col px-4">{{ topic.title }}</p>
                        </div>
                    </div>
                </button>
            </div>
        </div>

        <div class="flex justify-center">
            <div class="introduction-covid-disclaimer flex flex-shrink flex-col align-middle justify-center px-6 mt-12 border-2 border-gray-300 shadow-xl">
                <div class="flex text-left md:text-center">
                    <div class="flex overflow-hidden mr-3 md:mr-6">
                        <img class="aspect-square self-center w-10 h-16 md:w-16 md:h-16" src="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/covid-icon.svg" alt="COVID" role="img" />
                    </div>
                    <div class="flex-1 px-3 py-6 text-sm mt-auto mb-auto h-full md:text-xl">
                        {{ covid }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Introduction',
    props: {
        topics: {
            type: Array,
            required: true,
        },
        introduction: {
            type: String,
            required: true,
        },
        covid: {
            type: String,
            required: true
        },
    },
    setup() {

        function moveTo(topicId) {
            const el = document.getElementById(topicId);

            window.parent.scrollTo({
                top: el.offsetTop,
                behavior: 'smooth',
            })
        }

        return {
            moveTo,
        }
    },
})
</script>
