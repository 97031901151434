<template>
    <div class="webform">
        <div v-if="!showForm && submission?.success" class="text-black p-3 bg-white rounded-lg">
            <div v-html="submission.success"></div>
            <div class="mt-6">
                <button @click="showForm = true" type="button" class="appearance-none text-base text-secondary-500 hover:underline">
                    Go back to the form
                </button>
            </div>
        </div>

        <template v-else>
            <div class="space-y-10">
                <div v-if="content?.length" class="bg-white rounded-lg p-4 sm:p-6 lg:p-8">
                    <SetsRenderer :sets="content" />
                </div>

                <div v-show="submission?.errors?.length || (captcha.enabled && captcha?.errors?.length)" class="bg-white rounded-lg p-4 sm:p-6 lg:p-8">
                    <div v-if="submission?.errors?.length" class="bg-red-300 text-white p-2">
                        <span v-html="submission.errors.join('<br/>')"></span>
                    </div>

                    <div v-if="captcha.enabled && captcha?.errors?.length" class="bg-red-300 text-white p-2">
                        <span v-html="captcha.errors.join('<br/>')"></span>
                    </div>
                </div>

                <div class="bg-white rounded-lg p-0 sm:p-4 lg:p-8">
                    <form v-if="showForm" :action="action" method="POST" enctype="multipart/form-data" class="max-w-3xl mx-auto p-2 md:p-10 lg:p-16" @keydown.enter.prevent @keyup.enter.prevent>
                        <input type="hidden" name="_token" :value="csrfToken">
                        <div class="flex flex-wrap">
                            <template v-for="field in fields">
                                <div class="p-2" :class="[getWidthClass(field)]">
                                    <div>
                                        <component :is="getComponent(field)" :field="field" :id="`form_field_${field.handle}`" />
                                    </div>
                                    <!-- Show error here -->
                                    <p v-if="field.errors?.length" class="text-sm text-red-500 mt-1">
                                        <span v-html="field.errors.join('<br/>')"></span>
                                    </p>
                                </div>
                            </template>
                        </div>

                        <input v-if="honeypot" :name="honeypot" type="text" class="hidden">

                        <div class="flex justify-end px-3 py-2">
                            <button class="py-2 px-4 bg-primary text-white rounded" type="submit">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import {ref, defineComponent} from 'vue';
import BusStopAmenities from "../../pages/QOL/Page2/TransitStopFeatures";
import SetsRenderer from "../SetsRenderer";
import Assets from "./Fields/Assets";
import Default from "./Fields/Default";
import Checkboxes from "./Fields/Checkboxes";
import Radio from "./Fields/Radio";
import Date from "./Fields/Date";
import Time from "./Fields/Time";
import Section from "./Fields/Section";
import Select from "./Fields/Select";
import Textarea from "./Fields/Textarea";
import MaterialOrdering from "./Fields/MaterialOrdering";
import {useScriptTag} from '@vueuse/core';

export default defineComponent({
    name: 'Webform',
    components: {BusStopAmenities, SetsRenderer},
    props: {
        action: {
            required: true,
            type: String,
        },
        handle: {
            required: true,
            type: String,
        },
        title: {
            type: [String, null],
            default: null,
        },
        content: {
            type: [Array, null],
            default: null,
        },
        fields: {
            required: true,
            type: Array,
        },
        honeypot: {
            type: [String, null],
            default: null,
        },
        captcha: {
            type: Object,
            required: true,
        },
        csrfToken: {
            type: String,
            required: true,
        },
        submission: {
            type: [Object, null],
            required: true,
        },
    },

    setup(props) {
        const showForm = ref(! props.submission?.success);

        if (props.captcha.enabled) {
            window.recaptchaV3 = {};
            window.recaptchaV3.siteKey = props.captcha.siteKey;
            window.recaptchaV3.action = props.handle.replace('-', '_');
            window.recaptchaV3.verifyOnPageLoad = true;

            useScriptTag('/vendor/statamic-recaptcha/js/recaptcha-v3.js');
        }

        const getComponent = (field) => {
            switch (field.type) {
                case 'assets': return Assets;
                case 'checkboxes': return Checkboxes;
                case 'date': return Date;
                case 'radio': return Radio;
                case 'section': return Section;
                case 'select': return Select;
                case 'textarea': return Textarea;
                case 'time': return Time;
                case 'material_ordering': return MaterialOrdering;
                default: return Default;
            }
        };

        const getWidthClass = ((field) => {
            if (field?.width || parseInt(field.width) === 100) {
                return 'w-full';
            }

            const width = (field.width / 100) * 12;
            return `w-${width}/12`;
        });

        return {
            showForm,
            getComponent,
            getWidthClass,
        }
    },
});
</script>
