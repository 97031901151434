<template>
    <div>
        <FieldLabel :field="field"/>

        <div class="flex flex-col items-center space-y-2 md:flex-row md:space-y-0 md:space-x-4">
            <div v-for="select in timeSelects" :key="field.label" class="w-full md:w-32">
                <select
                    :id="`${field.id}_${select.name}`"
                    :name="`__${field.handle}-${select.name}`"
                    :required="field.isRequired"
                    class="w-full"
                    @change="e => setTime(select, e.target.value)"
                    :value="select.value"
                    :title="field.label || field.placeholder"
                    :aria-label="`${field.label} - ${select.name}`"
                >
                    <option value>{{ select.name }}</option>
                    <option v-for="option in select.options" :value="option.value">{{ option.label }}</option>
                </select>
            </div>

            <div class="flex items-center space-x-2">
                <span class="inline-flex items-center space-x-2">
                    <input :id="`field_${field.handle}_am`" :aria-label="`${field.label} - Select A.M.`" :title="field.label || field.placeholder"  type="radio" :name="`${field.handle}-ampm`" value="am" @input="() => setAmpm('am')" :checked="ampm === 'am'"> am
                </span>
                <span class="inline-flex items-center space-x-2">
                    <input :id="`field_${field.handle}_pm`" :aria-label="`${field.label} - Select P.M.`" :title="field.label || field.placeholder"  type="radio" :name="`${field.handle}-ampm`" value="pm" @input="() => setAmpm('pm')" :checked="ampm === 'pm'"> pm
                </span>
            </div>
        </div>
        <input
            ref="timeInput"
            type="hidden"
            :name="field.handle"
            :value="time"
            :required="field.isRequired"
            :title="field.label || field.placeholder"
        />
    </div>
</template>

<script setup>
import {ref} from "vue";
import FieldLabel from "../FieldLabel";
import {defineField} from "./BaseField.js";

const field = defineField();
const timeInput = ref();
const time = ref(field.old);
const ampm = ref('am');

const timeSelects = ref({
    hour: {
        name: 'Hour',
        value: null,
        setValue: (value) => timeSelects.value.hour.value = value,
        options: Array.from({length: 12}, (_, h) => ({
            value: (h + 1).toString().padStart(2, '0'),
            label: (h + 1).toString().padStart(2, '0'),
        })),
    },
    minute: {
        name: 'Minute',
        value: null,
        setValue: (value) => timeSelects.value.minutes.value = value,
        options: Array.from({length: 60}, (_, m) => ({
            value: m.toString().padStart(2, '0'),
            label: m.toString().padStart(2, '0'),
        })),
    },
});

function setTime(select, value) {
    select.value = value;
    updateTimeInput();
}

function setAmpm(value) {
    ampm.value = value;
    updateTimeInput();
}

function updateTimeInput() {
    let minute = timeSelects.value.minute.value.toString().padStart(2, '0');
    let hour = parseInt(timeSelects.value.hour.value);

    if (ampm.value === 'pm') {
        hour = (hour !== 12) ? hour + 12 : 0;
    }

    hour = hour.toString().padStart(2, '0');

    time.value = `${hour}:${minute}`;
}
</script>
