<template>
    <div class="relative w-full bg-white xl:overflow-hidden">
        <SectionTitle :title="content.title" link="safety-of-transit" color="blue" />
        <Section>
            <template #graphic>
                <Image class="graphic w-full p-4" alt="" src="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/isolated/static-safety-riding-transit-v2.svg" />
            </template>
            <template #info>
                <div class="flex flex-wrap gap-8 justify-center lg:pb-24 mt-16 lg:mt-8">
                    <InfoBox v-for="(infoBox, index) in content.infoBoxes" :key="index" halign="left" color="qol3-full" width-class="mb-8 w-[290px] md:w-[420px]">
                        <template #header>
                            <InfoBoxIcon :icon="infoBox.icon" class="-mt-10 -mb-2 p-2"/>
                        </template>
                        <template #content>
                            <div v-html="infoBox.text" class="text-center"></div>
                        </template>
                    </InfoBox>
                </div>
            </template>
        </Section>
    </div>
</template>

<script>
import {defineComponent, computed} from 'vue';
import Image from "../Components/Image";
import Section from "../Components/Section";
import SectionTitle from "../Components/SectionTitle";
import InfoBox from "../Components/InfoBox";
import InfoBoxIcon from "../Components/InfoBoxIcon";
import useTranslations from "../translations";

export default defineComponent({
    name: 'SafetyOfTransit',
    components: {
        Image,
        Section,
        SectionTitle,
        InfoBox,
        InfoBoxIcon,
    },
    setup() {
        const { translate } = useTranslations();

        const content = computed(() => ({
            title: translate.value('Safety of Riding Transit', 'Seguridad Viajando en Transporte Público'),
            infoBoxes: [
                {
                    icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/safety_of_riding_transit_1.svg',
                    text: translate.value(
                        '<strong style="font-size: 1.4em; line-height: 1;">Signalized intersections</strong> for pedestrians and auto traffic',
                        '<strong style="font-size: 1.4em; line-height: 1;">Intersecciones con semáforos</strong> para el tráfico peatonal y vehicular',
                    )
                },
                {
                    icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/safety_of_riding_transit_2.svg',
                    text: translate.value(
                        '<strong style="font-size: 1.4em; line-height: 1;">Signs</strong> to warn motorists and pedestrians of the light rail tracks and approaching trains',
                        '<strong style="font-size: 1.4em; line-height: 1;">Señales</strong> para advertir a los automovilistas y peatones sobre las vías del tren ligero y los trenes aproximándose',
                    )
                },
                {
                    icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/safety_of_riding_transit_3.svg',
                    text: translate.value(
                        '<strong style="font-size: 1.4em; line-height: 1;">Bells</strong> to alert passengers, pedestrians and motorists to trains arriving and departing, or moving through crowded pedestrian environments',
                        '<strong style="font-size: 1.4em; line-height: 1;">Campanas</strong> para alertar a los pasajeros, peatones y automovilistas sobre los trenes llegando y saliendo, o movilizándose a través de entornos peatonales abarrotados',
                    )
                },
                {
                    icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/safety_of_riding_transit_4.svg',
                    text: translate.value(
                        '<strong style="font-size: 1.4em; line-height: 1;">Headlights and turn signals</strong> will flash when the audible warnings are initiated as a <strong style="font-size: 1.4em; line-height: 1;">visual warning</strong> for the hearing impaired',
                        '<strong style="font-size: 1.4em; line-height: 1;">Los faros y las señales para dar vuelta</strong> parpadearán cuando se inicien las advertencias audibles como una advertencia visual para las personas con discapacidades auditivas',
                    )
                }
            ]
        }))

        return {
            content
        };
    }
});
</script>
