<template>
    <div v-if="manageMode && url" class="fixed top-1/2 right-0 z-[100]">
        <a :href="url" class="z-[100] py-2 px-4 bg-blue-700 font-bold text-md text-white uppercase hover:bg-blue-900">EDIT</a>
    </div>
</template>

<script>
import {usePage} from '@inertiajs/vue3';
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'EditButton',
    props: {
        url: {type: [String, null], required: true},
    },

    setup() {
        const page = usePage();

        return {
            manageMode: page.props.global.manage_mode,
        }
    },
});
</script>
