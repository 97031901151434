<template>
    <div class="relative w-full max-w-7xl mx-auto xl:mb-10">
        <AppHead title=""></AppHead>

        <EditButton :url="edit_url" />

        <Tabs :items="tabs" />
    </div>
</template>

<script>
import {defineComponent} from "vue";
import AppHead from "../components/AppHead";
import EditButton from "../components/EditButton";
import Tabs from "../components/Tabs";

export default defineComponent({
    name: "Home",
    components: {EditButton, Tabs, AppHead},
    props: {
        tabs: {type: Array, required: true},
        edit_url: { type: [String, null], default: null },
    }
});
</script>
