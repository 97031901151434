import {
    addMinutes,
    differenceInMinutes,
    endOfDay,
    format,
    getISODay,
    isAfter,
    isBefore,
    isSameDay,
    isSaturday,
    isSunday,
    isWeekend,
    parse,
    startOfDay
} from "date-fns";
import Fuse from "fuse.js";
import {cloneDeep, each, find, findIndex, kebabCase, map, sortBy} from "lodash";
import { createNanoEvents } from "nanoevents";
import { computed, inject, provide, readonly, ref, watch } from "vue";
import { betweenDates, getNow, getToday, parseFromISO } from "../../utils/date.js";
import {slugify, sortAndPrepareRouteGroups} from "../../utils/helpers";

const emitter = createNanoEvents();
let api = null;
let store = null;
let unwatchHandlers = [];
const now = getNow();

const state = ref({
    currentPage: null,
    isLoading: false,
    isProcessingSchedule: false,
    dataFetched: false,

    routes: {
        data: [],
        groups: [],
        schedules: {},
        current: null,
        fetched: false,
        scroll: {},
    },

    routeGroups: [],
    selectedSchedule: null,
    selectedStops: [],

    alerts: {
        data: [],
        stops: {},
        routes: {},
        groups: {},
        fetched: false,
    },

    filters: {
        directionOfTravel: {
            options: [],
            selected: null,
            selectedLabel: null,
            headsignLabel: null,
        },

        timeOfTravel: {
            selected: '0',
        },

        dayOfTravel: {
            options: [],
            selected: format(now, 'MMM d, yyyy'),
            selectedLabel: null,
            selectedDate: now,
            selectedDayIndex: getISODay(now) - 1,
            isToday: true,
            isWeekend: isWeekend(now),
            isSaturday: isSaturday(now),
            isSunday: isSunday(now),
            isWeekday: !isWeekend(now),
        },
    },

    routeSearch: null,
});

function getRunsOn() {
    const dayOfTravel = state.value.filters.dayOfTravel;

    // When a not specific date is selected, we try to use
    // the selected label (weekdays, saturdays...)
    if (! dayOfTravel.selectedDate) {
        if (! dayOfTravel.selectedLabel) {
            return '';
        }

        return `on <strong>${dayOfTravel.selectedLabel}</strong>`;
    }

    // A specific dated has been selected.
    if (dayOfTravel.isWeekday) {
        return  'on <strong>Weekdays</strong>';
    }
    if (dayOfTravel.isSaturday) {
        return 'on <strong>Saturdays</strong>';
    }
    if (dayOfTravel.isSunday) {
        return 'on <strong>Sundays</strong>';
    }
    if (dayOfTravel.isWeekend) {
        return 'on <strong>Weekends</strong>';
    }

    return '';
}

function setDirectionOfTravelValue(value) {
    state.value.filters.directionOfTravel.selected = value;
    const label = state.value.filters.directionOfTravel.options.find((opt) => opt.value === value)?.label;

    state.value.filters.directionOfTravel.selectedLabel = label ? label : 'null';
    state.value.filters.directionOfTravel.headsignLabel = label ? label.replace('bound', '') : null;

    setTimeOfTravelValue(-1);
}

function setDirectionOfTravelOptions(options) {
    state.value.filters.directionOfTravel.options = options;
}

function setDayOfTravelValue(value) {
    state.value.filters.dayOfTravel.selected = value.value;
    state.value.filters.dayOfTravel.selectedLabel = value.label;
    state.value.filters.dayOfTravel.selectedDate = null;
    state.value.filters.dayOfTravel.selectedDayIndex = null;

    const today = getToday();

    const dateIsWeekday = state.value.filters.dayOfTravel.selectedLabel === 'Weekdays' && !isWeekend(today);
    // saturdays option is selected and date is a saturday
    const dateIsSaturday = state.value.filters.dayOfTravel.selectedLabel === 'Saturdays' && isSaturday(today);
    // sundays option is selected and date is a sunday
    const dateIsSunday = state.value.filters.dayOfTravel.selectedLabel === 'Sundays' && isSunday(today);
    // weekends option is selected and date is a weekend day
    const dateIsWeekend = state.value.filters.dayOfTravel.selectedLabel === 'Weekends' && isWeekend(today);

    const wasTodaySelected = state.value.filters.dayOfTravel.isToday;

    state.value.filters.dayOfTravel.isToday = (dateIsWeekday || dateIsSaturday || dateIsSunday || dateIsWeekend);
    state.value.filters.dayOfTravel.isWeekday = dateIsWeekday;
    state.value.filters.dayOfTravel.isSaturday = dateIsSaturday;
    state.value.filters.dayOfTravel.isSunday = dateIsSunday;
    state.value.filters.dayOfTravel.isWeekend = dateIsWeekend;

    if (wasTodaySelected !== state.value.filters.dayOfTravel.isToday) {
        setTimeOfTravelValue(-1);
    }
}

function setDayOfTravelDate(date) {
    state.value.filters.dayOfTravel.selectedLabel = null;
    state.value.filters.dayOfTravel.selected = format(date, 'MMM d, yyyy');
    state.value.filters.dayOfTravel.selectedDate = date;
    state.value.filters.dayOfTravel.selectedDayIndex = getISODay(date) - 1;

    const wasTodaySelected = state.value.filters.dayOfTravel.isToday;

    state.value.filters.dayOfTravel.isToday = isSameDay(date, getToday());
    state.value.filters.dayOfTravel.isWeekday = !isWeekend(date);
    state.value.filters.dayOfTravel.isSaturday = isSaturday(date);
    state.value.filters.dayOfTravel.isSunday = isSunday(date);
    state.value.filters.dayOfTravel.isWeekend = isWeekend(date);

    if (wasTodaySelected !== state.value.filters.dayOfTravel.isToday) {
        setTimeOfTravelValue(-1);
    }
}

function setDayOfTravelOptionsFromRoute(route) {
    const daysOfTravel = [];

    Object.keys(route.schedule.summary.effective_from_dates).forEach(function (key) {
        Object.values(route.schedule.summary.effective_from_dates[key]).forEach(function (dates) {
            if (daysOfTravel.indexOf(key) >= 0) {
                return;
            }

            daysOfTravel.push(key);
        });
    });

    const orderedDays = ["Weekdays", "Saturdays", "Sundays"];
    const intersection = orderedDays.filter(x => daysOfTravel.includes(x));
    const difference = daysOfTravel.filter(x => !orderedDays.includes(x));

    state.value.filters.dayOfTravel.options = [...intersection, ...difference].filter(function (d) {
        const isADate = new Date(d);
        const expireAfter = 1;
        const expiration = new Date(new Date() + expireAfter);

        return !isADate.getDate() || isADate.getTime() > expiration.getTime();
    }).map((d) => ({
        value: d,
        label: d,
    }));

    setDayOfTravelDate(getToday());
}

function setTimeOfTravelValue(value) {
    if (value === null || value === undefined || value.length === 0) {
        return;
    }
    state.value.filters.timeOfTravel.selected = value.toString();
}

function getNextArrivalFromStops(stops) {
    const today = getNow();

    // Select the earliest schedule time when the selected date is different from today
    // and weekday filter is not selected.
    if (isWeekday(today) && ! weekdayFilterIsSelected() && selectedDateIsDifferentFromToday()){
        return { value: '0' };
    }

    if (isSaturday(today) && ! saturdayFilterIsSelected() && selectedDateIsDifferentFromToday()){
        return { value: '0' };
    }

    if (isSunday(today) && ! sundayFilterIsSelected() && selectedDateIsDifferentFromToday()){
        return { value: '0' };
    }

    // Check all stops to see what has the next arrival time
    for (let stop of stops) {
        if (state.value.selectedStops.length > 0 && state.value.selectedStops[0].code === stop.code) {
            const nextTime = stop.times.find((time) => {
                return differenceInMinutes(time.date, today) > 0;
            });

            if (nextTime) {
                return nextTime;
            }
        }

        const selectedTime = stop.times.find((time) => {
            return differenceInMinutes(time.date, today) >= 0;
        });

        if (selectedTime) {
            return selectedTime;
        }
    }

    return { value: '0' };
}

function isWeekday(date) {
    return ! isWeekend(date);
}

function weekdayFilterIsSelected() {
    return state.value.filters.dayOfTravel.selectedLabel === 'Weekdays';
}

function saturdayFilterIsSelected() {
    return state.value.filters.dayOfTravel.selectedLabel === 'Saturdays';
}

function sundayFilterIsSelected() {
    return state.value.filters.dayOfTravel.selectedLabel === 'Sundays';
}

function selectedDateIsDifferentFromToday() {
    const today = getToday();
    const selectedDay = state.value.filters.dayOfTravel.selectedDate;

    return (! state.value.filters.dayOfTravel?.selectedDate
        || ! isSameDay(today, selectedDay))
}

function setGroupScrolls(group, showTop, showBottom) {
    state.value.routeGroups[group].showTop = showTop === true;
    state.value.routeGroups[group].showBottom = showBottom === true;
}

function setRouteScroll(showTop, showBottom) {
    state.value.routes.scroll.showTop = showTop === true;
    state.value.routes.scroll.showBottom = showBottom === true;
}

/**
 * Load data from the API.
 * @return Promise
 */
function loadDataFromApi() {
    return new Promise((resolve) => {
        state.value.isLoading = true;
        state.value.dataFetched = false;
        state.value.routes.fetched = false;
        state.value.routes.loading = true;
        state.value.alerts.fetched = false;
        state.value.alerts.loading = true;

        const apiPromises = Promise.all([
            api.getRoutes(),
            api.getServiceAlerts()
        ]);

        apiPromises.then((results) => {
            state.value.alerts = {
                fetched: true,
                data: results[1].data,
                stops: results[1].refs.stops,
                routes: results[1].refs.routes,
                groups: results[1].refs.groups,
            };

            state.value.routes = {
                fetched: true,
                schedules: {},
                data: results[0].data.map((r, ri) => {
                    r.group = find(results[0].refs.groups, (g) => g.name === r.group);
                    r.alert = [];

                    state.value.alerts.data.forEach((alertElement, alertIndex) => {
                        state.value.alerts.data[alertIndex].routes.forEach((routeElement) => {
                            (routeElement.id === r.id) ? r.alert.push(state.value.alerts.data[alertIndex]) : null
                        });
                    });

                    return r;
                }),
                groups: sortAndPrepareRouteGroups(results[0].refs.groups),
                scroll: {'showTop': false, 'showBottom': false},
            };

            buildRouteGroups();

            state.value.dataFetched = true;
            state.value.isLoading = false;
            state.value.routes.fetched = true;
            state.value.routes.loading = false;
            state.value.alerts.fetched = true;
            state.value.alerts.loading = false;

            emitter.emit('routesLoaded');

            resolve();
        });
    });
}

function buildRouteGroups() {
    const groupedRoutes = {};

    state.value.routes.data.forEach((route) => {
        if (!groupedRoutes.hasOwnProperty(route.group.name)) {
            groupedRoutes[route.group.name] = [];
        }

        groupedRoutes[route.group.name].push({
            ...route,
            hasAlerts: state.value.alerts.routes[route.id]
        });
    });

    state.value.routeGroups = map(state.value.routes.groups, (group) => {
        const groupAlerts = state.value.alerts.groups[group.apiName] || {count: 0};

        groupedRoutes[group.apiName] = groupedRoutes[group.apiName] || [];

        return {
            name: group.name,
            slug: kebabCase(group.name),
            mode: group.mode,
            alerts: groupAlerts,
            routes: groupedRoutes[group.apiName],
            // 4 is the number of routes that can be displayed without the need of any scrollbar.
            scrollTipVisible: groupedRoutes[group.apiName].length > 4,
            showTop: false,
            // when showing more than 4 routes, init the scroll down behavior
            showBottom: groupedRoutes[group.apiName].length > 4,
        }
    });

    state.value.routeSearch = new Fuse(store.state.routeGroups.reduce((routes, group) => routes.concat(group.routes), []), {
        threshold: 0.2,
        keys: ['longName', 'shortName'],
    });
}

/**
 * Get the index of the route.
 *
 * @param routeId
 * @returns {number}
 */
function getRouteIndex(routeId) {
    return findIndex(state.value.routes.data, (r) => r.id.toLowerCase() === routeId.toLowerCase());
}

/**
 * Transform the given stop time to a date object, optionally
 * using the given base date as the year/month/day.
 *
 * @param stopTime
 * @param baseDate
 * @returns {null|Date}
 */
function transformStopTimeToDate(stopTime, baseDate) {
    if (!stopTime) {
        return null;
    }

    const [h, m] = stopTime.split(':');
    const duration = parseInt(h) * 60 + parseInt(m);

    return addMinutes(
        startOfDay(baseDate || getToday()),
        duration
    );
}

/**
 * @param stopTime
 * @returns {string}
 */
export function formatTime(stopTime) {
    if (!stopTime) {
        return '';
    }

    return format(stopTime, 'h:mm aaaa');
}

/**
 * Load the schedules for the given route and cache it in the routes array.
 *
 * @param routeId
 * @returns {Promise<*>}
 */
export async function loadRouteData(routeId) {
    if (state.value.routes.fetched && state.value.routes.data.length === 0) {
        return {
            route: null
        };
    }

    const routeIndex = getRouteIndex(routeId);

    if (routeIndex < 0) {
        return {
            route: null
        };
    }

    const route = state.value.routes.data[routeIndex];

    // Load route schedule
    if (!route.schedule) {
        state.value.isLoading = true;
        state.value.dataFetched = false;

        route.schedule = await api.getRouteSchedules(slugify(routeId));

        state.value.isLoading = false;
        state.value.dataFetched = true;
    }

    // Prepare effective dates
    const dates = (function () {
        const result = [];

        each(route.schedule.summary.effective_from_dates, (effDateDirections, effDateName) => {
            each(effDateDirections, (effDateRanges, effDateDir) => {
                each(effDateRanges, range => {
                    result.push({
                        range,
                        direction: effDateDir,
                        calendarName: effDateName,
                    })
                })
            });
        });

        return result;
    })();

    route.effectiveDates = dates
        .map((x) => {
            const today = getToday();

            const [fromDate, toDate] = x.range.split('-');
            const start = parse(fromDate, 'yyyy/MM/dd', today);
            const end = parse(toDate, 'yyyy/MM/dd', today);

            return {
                calendarName: x.calendarName,
                direction: x.direction,
                fromDate,
                toDate,
                fromDateObj: start,
                toDateObj: end,
                formattedFromDate: format(start, 'MMM d, yyyy'),
                formattedToDate: format(end, 'MMM d, yyyy'),
                isPast: isAfter(today, end),
                isFuture: isBefore(today, start),
                isEffectiveFor(date) {
                    return (isAfter(date, start) || isSameDay(date, start)) && (isBefore(date, end) || isSameDay(date, end));
                },
            }
        })
        .filter(effDate => !effDate.isPast)
        .sort((a, b) => a.fromDateObj - b.fromDateObj);

    // Just overwrite the cached route
    state.value.routes.data[routeIndex] = route;

    return {
        route
    };
}

async function setCurrentRoute(routeId) {
    if (!routeId) {
        return state.value.routes.current = null;
    }

    const callback = async () => {
        const { route } = await loadRouteData(routeId);

        if (!route) {
            return state.value.routes.current = null;
        }

        // Update the direction of travel filter
        const directionOfTravelOptions = (route.schedule.summary.directions || []).map((d) => ({
            value: d.id.toString(),
            label: d.headsign,
        }));

        setDirectionOfTravelOptions(directionOfTravelOptions);
        setDirectionOfTravelValue(directionOfTravelOptions.length > 0 ? directionOfTravelOptions[0].value : null);
        setDayOfTravelOptionsFromRoute(route);

        state.value.routes.current = route;

    }

    if (!state.value.routes.fetched) {
        const unbind = emitter.on('routesLoaded', () => {
            unbind();
            callback();
        });

        return;
    }

    await callback();
}

/**
 * Build the selected schedule based in the selected filters.
 * @returns {null}
 */
async function buildSelectedSchedule(options = { processNextArrival: true, updateRouteShape: false }) {
    state.value.isProcessingSchedule = true;
    const route = state.value.routes.current;

    if (!route?.schedule?.data) {
        return state.value.selectedSchedule = null;
    }

    let result = route.schedule.data
        .map((schedule) => {
            // Jump to the next iteration if schedule does not have the required data
            if (!schedule.hasOwnProperty('directionId')
                || !schedule.hasOwnProperty('calendars')
                || parseInt(schedule.directionId) !== parseInt(state.value.filters.directionOfTravel.selected)
            ) {
                return null;
            }

            const dayOfTravel = state.value.filters.dayOfTravel;
            const selectedDayIndex = dayOfTravel.selectedDayIndex;
            const formattedDayOfTravel = (dayOfTravel.selectedDate)
                ? format(dayOfTravel.selectedDate, 'yyyy/MM/dd')
                : null;

            const availableCalendars = (dayOfTravel.selectedDate)
                ? schedule.calendars.filter(c => {
                    if (c.negativeExceptions && c.negativeExceptions.find(ne => ne === formattedDayOfTravel)) {
                        return false;
                    }

                    if (c.positiveExceptions && c.positiveExceptions.find(ne => ne === formattedDayOfTravel)) {
                        return true;
                    }

                    return c.dows[selectedDayIndex] === true;
                })
                : schedule.calendars.filter(c => c.name === dayOfTravel.selected);

            // Skip this schedule if any of the calendars has the current day number as true.
            if (availableCalendars.length === 0) {
                return null;
            }

            // Select the effective date
            const effectiveDate = (function () {
                if (route.effectiveDates.length === 0) {
                    return undefined;
                }

                // Otherwise, we look for the effective date matching selected day travel,
                // selected direction and picked date if selected.
                return route.effectiveDates
                    .filter(effDateOpt => availableCalendars.find(c => {
                        return c.ranges.find(r => r.from === effDateOpt.fromDate && r.to === effDateOpt.toDate);
                    }))
                    .find(effDateOpt => {
                        if (! dayOfTravel?.selectedDate && dayOfTravel.selected !== effDateOpt.calendarName) {
                            return false;
                        }

                        const isEffectiveForTheDate = (dayOfTravel.selectedDate)
                            ? effDateOpt.isEffectiveFor(dayOfTravel.selectedDate)
                            : true;

                        return effDateOpt.direction === state.value.filters.directionOfTravel.selected && isEffectiveForTheDate;
                    });
            })();

            if (!effectiveDate) {
                return null;
            }

            if (! schedule.tripCalendars.find(
                tripCal => tripCal.name === effectiveDate.calendarName
                    && tripCal.ranges.find(r => r.from === effectiveDate.fromDate && r.to === effectiveDate.toDate),
            )) {
                return null;
            }

            const data = cloneDeep(schedule);
            data.effectiveDate = effectiveDate;

            return data;
        })
        .filter(a => a !== null)
        .sort((a, b) => a.effectiveDate.fromDateObj - b.effectiveDate.fromDateObj)
        .shift();

    if (result) {
        const now = getNow();

        // Calculate the stops that must be displayed.
        let currentHeadsign = null;

        result.trips = result.trips.map((trip) => {
            return {
                ...trip,
                // Iterate through all trip's times and only keep those that
                // has a time defined - null values mean that stop is not
                // included in that trip.
                stops: trip.times
                    .map((time, timeIndex) => {
                        if (!time || !time?.dep) {
                            return null;
                        }

                        return {
                            ...result.stops[timeIndex],
                            time: formatTime(transformStopTimeToDate(time.dep, getToday())),
                            label: result.stops[timeIndex].name,
                        }
                    })
                    .filter((time) => !!time)
            }
        });

        // Transform the stops.
        // stop = { id, code, name }
        result.stops.forEach((stop, stopIndex) => {
            stop.alerts = (route.alert || []).filter((alert) => {
                return Boolean((alert.stops || []).find((alertStop) => alertStop.code.toString() === stop.code.toString()));
            });

            if (state.value.filters.dayOfTravel.selectedDate && stop.alerts.length > 0) {
                stop.alerts = stop.alerts.filter((alert) => {
                    const selectedDate = startOfDay(state.value.filters.dayOfTravel.selectedDate);
                    const activeFrom = alert.activeFrom ? startOfDay(parseFromISO(alert.activeFrom, 'UTC')) : null;
                    const activeTo = alert.activeTo ? endOfDay(parseFromISO(alert.activeTo, 'UTC')) : null;

                    return betweenDates(selectedDate, activeFrom, activeTo);
                });
            }

            stop.hasAlerts = stop.alerts.length > 0;

            // Get the list of times available for this stop.
            stop.times = (() => {
                const times = [];

                result.trips.forEach((trip, tripIndex) => {
                    if (trip.times[stopIndex]?.dep) {
                        const date = transformStopTimeToDate(trip.times[stopIndex].dep, state.value.filters.dayOfTravel.selectedDate);

                        const isInNoServiceAlert = state.value.filters.dayOfTravel.selectedDate !== null && stop.alerts.some((alert) => {
                            return alert.activeRanges?.some(range => {
                                if (alert.effect !== 'NO_SERVICE') {
                                    return false;
                                }

                                const activeFrom = (range.from) ? parseFromISO(range.from, 'UTC') : null;
                                const activeTo = (range.to) ? parseFromISO(range.to, 'UTC') : null;

                                return betweenDates(date, activeFrom, activeTo);
                            });
                        });

                        times.push({
                            date,
                            value: tripIndex.toString(),
                            label: formatTime(date),
                            nextRide: false,
                            enabled: ! isInNoServiceAlert,
                            stopIndex: stopIndex,
                        });
                    }
                });

                // Calculate the NextRide
                if (state.value.filters.dayOfTravel.isToday) {
                    for (let t of times) {
                        if (t.enabled && isSameDay(now, t.date) && differenceInMinutes(t.date, now) >= 0) {
                            t.nextRide = true;
                            break;
                        }
                    }
                }

                return times;
            })();

            stop.disabled = stop.times.every((time) => !time.enabled || !time.date);
        });

        // Calculate the "Runs on" text.
        result.runsOn = computed(() => result.tripCalendars.map((c, index) => {
            const ranges = c.ranges.map((r) => {
                const runsOn = getRunsOn();
                const dateFrom = format(parse(r.from, 'yyyy/MM/dd', getToday()), 'MMM do');
                const dateTo = format(parse(r.to, 'yyyy/MM/dd', getToday()), 'MMM do');

                return `<span>${runsOn} from <strong class="font-bold">${dateFrom}</strong> to <strong class="font-bold">${dateTo}</strong></span>`;
            });

            const exceptions = (c.negativeExceptions || []).map((e, index) => {
                const date = format(parse(e, 'yyyy/MM/dd', getToday()), 'MMM do');

                const prefix = (index === 0)
                    ? 'except '
                    : '';

                return `${prefix}<strong>${date}</strong>`
            });

            const label = ranges.length ? 'Runs ' : ''

            return {
                index,
                name: c.name,
                description: (index === 0 ? label : `<sup>${index}</sup>`) + ranges.concat(exceptions).join(', '),
            }
        }));

        // Calculate selected times for stops.
        let tripId = parseInt(state.value.filters.timeOfTravel.selected);

        if (state.value.filters.dayOfTravel.isToday) {
            if (state.value.filters.timeOfTravel.selected === '-1') {
                setTimeOfTravelValue(getNextArrivalFromStops(result.stops)?.value);
                tripId = parseInt(state.value.filters.timeOfTravel.selected);
            }
        }

        if (!result.trips[tripId]) {
            setTimeOfTravelValue(0);
            tripId = parseInt(state.value.filters.timeOfTravel.selected);
        }

        result.stops.forEach((stop, stopIndex) => {
            // Calculate the selected time for the stop
            stop.selectedTime = result.trips[tripId].times[stopIndex]?.dep
                ? stop.times.find((time) => time.value === tripId.toString())
                : null;

            if (stop.selectedTime) {
                // Get the stop's headsign, if applicable.
                const stopHeadsign = (() => {
                    if (result.trips[tripId].headsign) {
                        return result.trips[tripId].headsign;
                    }

                    const searchInRefs = state.value.routes.current.refs.trips.find((trip) => {
                        return trip.tripIds.find((tid) => stop.tripIds.includes(tid));
                    });

                    if (searchInRefs) {
                        return searchInRefs.headsign;
                    }

                    return null;
                })();

                if (currentHeadsign !== stopHeadsign) {
                    stop.headsign = currentHeadsign = stopHeadsign;
                }
            }
        });

        // Group stops by area ids
        const areas = result.areas.reduce((acc, area) => {
            return {
                ...acc,
                [area.id]: area.name,
            }
        }, {});

        let groupedStopsByArea = result.stops
            .reduce((acc, stop, stopIndex) => {
                const groupKeys = (! stop.areaIds || stop.areaIds.length === 0)
                    ? [stop.code]
                    : stop.areaIds;

                groupKeys.forEach((groupKey) => {
                    if (!acc.hasOwnProperty(groupKey)) {
                        acc[groupKey] = {};
                    }

                    acc[groupKey][stopIndex] = {
                        id: groupKey,
                        stopIndex: stopIndex,
                        stop: stop,
                        name: areas[groupKey],
                    };
                });

                return acc;
            }, {});

        // This is the same logic as in MakesScheduleCalendarEntity.php
        // It would be lovely to load it from API, so we could remove this and consume what we are doing in backend.
        groupedStopsByArea = Object.keys(groupedStopsByArea).reduce((acc, groupKey) => {
            let groupReduced = Object.keys(groupedStopsByArea[groupKey]).reduce((groupAcc, stopIndex) => {
                const item = groupedStopsByArea[groupKey][stopIndex];
                const order = groupAcc.hasOwnProperty(item.stopIndex - 1)
                    // If two stops sharing the same area are after each other, we will use only the first one.
                    ? item.stopIndex - 1
                    // Otherwise, we need to keep both as they're not in sequence (e.g. like for round trips).
                    : item.stopIndex;

                const data = groupAcc[order] || {
                    areaId: item.id,
                    name: item.name,
                    stopIndex: item.stopIndex,
                };

                groupAcc[order] = {
                    ...data,
                    alerts: [
                        ...data.alerts || [],
                        ...item.stop.alerts || [],
                    ],
                    hasAlerts: data.hasAlerts || item.stop.hasAlerts,
                    disabled: data.disabled || item.stop.disabled,
                    stops: [
                        ...data.stops || [],
                        item.stop,
                    ]
                };

                return groupAcc;
            }, {});

            groupReduced = Object.values(groupReduced).map((item) => {
                const times = item.stops.reduce((acc, stop) => {
                    stop.times.forEach((time) => {
                        if (! acc[time.value]?.date) {
                            acc[time.value] = time;
                        }
                    });

                    return acc;
                }, {});

                const selectedTimeStop = item.stops.find((stop) => stop.selectedTime !== null);
                const nextRide = Object.values(times).find((time) => time.nextRide);

                return {
                    ...item,
                    // Use the area name as the stop name because multiple stops sharing the same area needs
                    // to display the same head sign [...]
                    name: item.name,
                    // [...] but for id and code let's use the stop properties as such information are not
                    // provided by area, and we also want to show the stop info anyway as an area can have
                    // multiple stops (usually 2) in same location.
                    id: selectedTimeStop?.id || item.stops[0]?.id,
                    code: selectedTimeStop?.code || item.stops[0]?.code,
                    selectedTime: selectedTimeStop?.selectedTime,
                    times: sortBy(Object.values(times), (t) => parseInt(t.value) || -1)
                        .map((time) => {
                            time.nextRide = (nextRide?.value && time.value === nextRide.value);
                            return time;
                        }),
                }
            });

            return acc.concat(groupReduced);
        }, []);

        result.stopsByArea = sortBy(groupedStopsByArea, 'stopIndex');

        // Load the route shape
        result.shape = state.value.selectedSchedule?.shape;

        if (route.id && options.updateRouteShape) {
            const routeShape = await api.getRouteShapes(
                route.id,
                result.effectiveDate.fromDate ? result.effectiveDate.fromDate.replaceAll('/', '-') : null,
                result.effectiveDate.toDate ? result.effectiveDate.toDate.replaceAll('/', '-') : null
            );

            const bounds = routeShape.data.stops.reduce(function (bounds, stop) {
                stop = {
                    lat: stop.location.lat,
                    lng: stop.location.lng,
                }

                if (!bounds) {
                    return {
                        min: {lat: stop.lat, lng: stop.lng},
                        max: {lat: stop.lat, lng: stop.lng},
                        center: {lat: stop.lat, lng: stop.lng}
                    }
                }

                let routeBounds = {
                    min: {
                        lat: Math.min(stop.lat, bounds.min.lat),
                        lng: Math.min(stop.lng, bounds.min.lng)
                    },
                    max: {
                        lat: Math.max(stop.lat, bounds.max.lat),
                        lng: Math.max(stop.lng, bounds.max.lng)
                    }
                }

                routeBounds.center = {
                    lat: (routeBounds.min.lat + routeBounds.max.lat) / 2,
                    lng: (routeBounds.min.lng + routeBounds.max.lng) / 2
                }

                return routeBounds
            }, false)

            if (routeShape.data.routes.length > 0) {
                result.shape = {
                    lines: routeShape.data.routes[0].multiGeometry.lineStrings,
                    stops: result.stops.map((stop) => {
                        return routeShape.data.stops.find((shapeStop) => shapeStop.id === stop.id && shapeStop.code === stop.code);
                    }).filter((stop) => stop !== undefined),
                    allStops: routeShape.data.stops,
                    bounds: bounds,
                }
            } else {
                result.shape = {
                    line: [],
                    stops: [],
                    allStops: [],
                    bounds: [],
                }
            }
        }
    }

    state.value.isProcessingSchedule = false;
    state.value.selectedSchedule = result || {
        empty: true,
    };

    return state.value.selectedSchedule;
}

function setSelectedStops(stops) {
    state.value.selectedStops = stops;
}

function selectStop(stop) {
    if (!isStopSelected(stop)) {
        state.value.selectedStops.push(stop);
    }
}

function unselectStop(stop) {
    const index = findIndex(state.value.selectedStops, (s) => s.code === stop.code);

    if (index > -1) {
        state.value.selectedStops.splice(index, 1);
    }
}

function isStopSelected(stop) {
    const index = findIndex(state.value.selectedStops, (s) => s.code === stop.code);

    return index > -1;
}

function startLoading() {
    state.value.isLoading = true;
}

function endLoading() {
    state.value.isLoading = false;
}

function onScheduleChange(callback) {
    return emitter.on('scheduleChange', callback);
}

function setupWatchers() {
    if (unwatchHandlers.length) {
        unwatchHandlers.forEach(unwatch => unwatch())
        unwatchHandlers = []
    }

    unwatchHandlers.push(
        watch(() => [
            state.value.filters.dayOfTravel.selected,
            state.value.filters.directionOfTravel.selected,
            state.value.filters.timeOfTravel.selected,
            state.value.routes.current,
        ], (newValues, oldValues) => {
            buildSelectedSchedule({
                updateRouteShape: true,
                processNextArrival: false
            });
        })
    );
}

export function initStore() {
    setupWatchers()

    if (store) {
        provide('store', store);
        return store;
    }

    if (!api) {
        api = inject('api');
    }

    if (!state.value.dataFetched) {
        loadDataFromApi();
    }

    store = {
        state: readonly(state.value),

        // Functions
        endLoading,
        startLoading,
        setCurrentRoute,
        setDayOfTravelDate,
        setDayOfTravelValue,
        setTimeOfTravelValue,
        getNextArrivalFromStops,
        setDirectionOfTravelValue,
        selectStop,
        unselectStop,
        isStopSelected,
        setSelectedStops,
        setGroupScrolls,
        setRouteScroll,
        onScheduleChange,
    };

    provide('store', store);

    return store;
}

export function useStore() {
    return inject('store');
}
