<template>
    <div class="relative w-full bg-white xl:overflow-hidden">
        <SectionTitle :title="title" :subtitle="subtitle" link="regional-heat-impact" color="green" />

        <Section class="pb-6">
            <template #graphic>
                <Image class="graphic w-full p-4" alt="" src="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/static-city-heat.svg" />
            </template>

            <template #info>
                <div class="flex flex-wrap gap-20 md:gap-8 max-w-4xl justify-center mt-20 md:mt-16">
                    <InfoBox :content="infoBoxes[0]" halign="left" width-class="w-[280px]" color="qol4-full">
                        <template #header>
                            <Image :src="infoBoxes[0].icon" class="h-[5rem] -mt-20 md:-mt-16"/>
                        </template>
                        <template #content>
                            <div v-html="infoBoxes[0].text" class="p-2 leading-5" style="font-size: 1rem;"></div>
                        </template>
                    </InfoBox>

                    <InfoBox :content="infoBoxes[1]" halign="left" width-class="w-[280px]" color="qol4-full">
                        <template #header>
                            <Image :src="infoBoxes[1].icon" class="h-[5rem] -mt-20 md:-mt-16"/>
                        </template>
                        <template #content>
                            <div v-html="infoBoxes[1].text" class="p-2 leading-5" style="font-size: 1rem;"></div>
                        </template>
                    </InfoBox>

                    <InfoBox :content="infoBoxes[2]" halign="left" width-class="w-[280px]" class="mt-6" color="qol4-full">
                        <template #header>
                            <Image :src="infoBoxes[2].icon" class="h-[5rem] -mt-20 md:-mt-16"/>
                        </template>
                        <template #content>
                            <div v-html="infoBoxes[2].text" class="p-2 leading-5" style="font-size: 1rem;"></div>
                        </template>
                    </InfoBox>

                </div>

                <p class="text-gray-500 italic mt-8 px-4 text-center lg:w-[440px] lg:relative lg:top-6 lg:-right-36 lg:w-[300px] lg:text-left">
                    {{ note.text }}
                </p>
            </template>
        </Section>
    </div>
</template>

<script>
import {defineComponent, computed} from 'vue';
import Image from "../Components/Image";
import InfoBox from "../Components/InfoBox";
import InfoBoxIcon from "../Components/InfoBoxIcon";
import Section from "../Components/Section";
import SectionTitle from "../Components/SectionTitle";
import useTranslations from "../translations";

export default defineComponent({
    name: 'SummerHeatInPhoenix',
    components: {
        Image,
        Section,
        InfoBox,
        InfoBoxIcon,
        SectionTitle,
    },
    setup() {
        const { translations } = useTranslations();

        const title = computed(() => translations.value.page2.sections[0].title);
        const subtitle = computed(() => translations.value.page2.sections[0].subtitle);
        const note = computed(() => translations.value.page2.sections[0].note);
        const infoBoxes = computed(() => translations.value.page2.sections[0].infoBoxes);

        return {
            title,
            subtitle,
            note,
            infoBoxes,
        };
    }
});
</script>

<style scoped lang="scss">
    :deep(.left-side .graphic) {
        margin-left: -2rem;
        margin-right: -5rem;
        /*height: 40rem;*/
    }


    @media(min-width: theme('screens.lg')) {
        :deep(.left-side .graphic) {
            /*margin-left: 0 -7rem;*/
            margin-right: -23rem;
            height: 40rem;
        }
    }
</style>
