<template>
    <div class="border-b-8">
        <SectionTitle link="vehicle-miles-traveled" color="purple"
                      :title="$tx('Vehicle Miles Traveled')"
                      :subtitle="`${$tx('Maricopa County')}, 2000-2019`"/>
        <div class="flex flex-col lg:flex-row p-4 sm:p-12">
            <div class="flex w-full lg:flex-1 lg:mr-8">
                <ChartTableSwitch page-title="Vehicle Miles Traveled">
                    <template v-slot:chart>
                        <ChartRenderer
                            :chart="LineChart"
                            chartId="vehicle-miles-traveled-chart"
                            ref="chartRef"
                            :chartData="chartData"
                            :options="options"
                            class="sm:m-auto w-full h-[350px] md:h-450px] lg:h-[600px]"
                            label="Vehicle Miles Traveled Chart/graph. Refer to data table for details."
                        />
                    </template>
                    <template v-slot:table>
                        <div class="flex justify-center min-w-full mt-4">
                            <DataTable highlight-first :headings="dataTable.headings" :rows="dataTable.rows"
                                       class="w-full"/>
                        </div>
                    </template>
                </ChartTableSwitch>
            </div>
            <div class="flex flex-col justify-center mt-0 mx-auto md:w-1/3 lg:w-1/4">
                <InfoBox halign="left" color="qol1-full" class="mt-12 mb-8"
                         icon="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/QOL_information-shape_green_poor-air-quality.svg"
                >
                    <template #content>
                        <span
                            v-html="$tx('Congestion and sprawl lead to more time spent on the road. <br/><br/> More time spent on the road affects air quality, wear and tear on vehicles and infrastructure.')"></span>
                    </template>
                </InfoBox>
            </div>
        </div>
    </div>
</template>

<script>
// See link for registering chart types to allow for tree-shaking if desired
// https://vue-chart-3.netlify.app/guide/#chart-js-v3
import {Chart, registerables} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {computed, defineComponent, inject} from 'vue';

import {LineChart} from 'vue-chart-3';
import ChartRenderer from "../../../components/ChartRenderer";
import PrimaryButton from "../../../components/PrimaryButton";

import {useIsSmallScreen} from "../../../utils/helpers";
import ChartTableSwitch from "../Components/ChartTableSwitch";
import CovidDisclaimer from '../Components/CovidDisclaimer';
import DataTable from "../Components/DataTable";
import InfoBox from "../Components/InfoBox";

import SectionTitle from '../Components/SectionTitle';
import {generateDataLabelsSettings, generateTickSettings, generateTitleSettings} from "./chartsUtils";

Chart.register(...registerables);
Chart.register(ChartDataLabels);

export default defineComponent({
    components: {
        ChartRenderer,
        ChartTableSwitch,
        DataTable,
        PrimaryButton,
        SectionTitle,
        CovidDisclaimer,
        LineChart,
        InfoBox,
    },
    setup() {
        const tx = inject('tx')
        const isSmallScreen = useIsSmallScreen()

        const chartData = computed(() => ({
            labels: ['2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019'],
            datasets: [
                {
                    backgroundColor: 'rgb(89, 23, 105)',
                    borderColor: 'rgb(132, 132, 132)',
                    data: [22, 21.5, 22.4, 22.5, 23.75, 23.25, 23.5, 23.35, 23, 22.35, 23.4, 23, 22.85, 23.1, 23.15, 23.75, 23.6, 23, 23.1, 24.5],
                },
            ],
        }));

        const dataTable = computed(() => {
            const headings = {
                year: tx('Year'),
                value: tx('Daily VMT Per Capita (in millions)'),
            };

            const rows = chartData.value.labels.map((year, idx) => {
                return {
                    year,
                    value: chartData.value.datasets[0].data[idx],
                }
            });

            return {headings, rows};
        });

        const options = computed(() => ({
            hover: {mode: null},
            plugins: {
                tooltip: {
                    enabled: isSmallScreen.value,
                },
                legend: {
                    display: false,
                    position: 'bottom',
                },
                title: generateTitleSettings({
                    text: '',
                }),
                datalabels: generateDataLabelsSettings({
                    align: (context) => {
                        if (context.type === 'chart') {
                            return
                        }

                        const index = context.dataIndex;
                        const value = context.dataset.data[index];

                        let prevValue = undefined;
                        let nextValue = undefined;

                        if (index > 0) {
                            prevValue = context.dataset.data[index - 1]
                        }

                        if (index < context.dataset.data.length - 1) {
                            nextValue = context.dataset.data[index + 1]
                        }

                        if ((prevValue == undefined || prevValue > value) && (nextValue == undefined || nextValue > value)) {
                            return 'bottom'
                        } else if ((prevValue == undefined || prevValue < value) && (nextValue == undefined || nextValue < value)) {
                            return 'top'
                        } else if (prevValue > value && nextValue < value) {
                            return -45
                        } else if (prevValue < value && nextValue > value) {
                            return 45
                        }

                        return 'top'
                    },
                })
            },
            responsive: true,
            scales: {
                x: {
                    title: generateTitleSettings({
                        text: tx('Years'),
                    }),
                    stacked: true,
                    ticks: generateTickSettings(),
                },
                y: {
                    max: 25,
                    min: 21,
                    title: generateTitleSettings({
                        text: tx('Daily VMT Per Capita (in millions)'),
                    }),
                    stacked: true,
                    ticks: generateTickSettings(),
                }
            },
        }));

        return {
            options,
            LineChart,
            chartData,
            dataTable,
        };
    }
});
</script>
