<template>
    <div>
        <!-- Filters -->
        <div class="flex flex-col space-y-4 items-left md:justify-left md:inline-flex md:flex-row md:space-x-4 md:space-y-0">
            <!-- Time of Travel -->
            <div>
                <div id="day-of-travel-label" class="block mb-1 text-sm font-normal tracking-wide text-gray-600">
                    Day of Travel
                </div>
                <div role="group" aria-labelledby="day-of-travel-label" class="flex flex-col overflow-hidden border border-gray-200 divide-y divide-gray-200 rounded-lg md:inline-flex md:flex-row md:items-center md:justify-left md:divide-y-0 md:divide-x">
                    <template v-for="option in store.state.filters.dayOfTravel.options">
                        <button
                            type="button"
                            role="radio"
                            :aria-checked="store.state.filters.dayOfTravel.selected === option.value"
                            class="p-2 text-sm font-normal day-of-travel-btn ring-2 ring-inset first:rounded-l-lg"
                            :class="store.state.filters.dayOfTravel.selected === option.value ? 'bg-secondary text-white ring-transparent' : 'text-gray-700 bg-transparent ring-transparent hover:ring-secondary focus:ring-secondary'"
                            @click="store.setDayOfTravelValue(option)"
                        >
                            {{ option.label }}
                        </button>
                    </template>
                    <DatePicker ref="dayOfTravelPicker" id="day_of_travel" class="z-50" color="orange"
                                :modelValue="store.state.filters.dayOfTravel.selectedDate"
                                @popoverDidShow="onDatePickerPopoverOpen"
                                @update:modelValue="setDate"
                    >
                        <template #header-prev-button>
                            <svg stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" width="24" height="24" class="vc-base-icon">
                                <polyline points="15 18 9 12 15 6"></polyline>
                            </svg>
                            <span class="sr-only">Previous month</span>
                        </template>
                        <template #header-next-button>
                            <svg stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" width="24" height="24" class="vc-base-icon">
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                            <span class="sr-only">Next month</span>
                        </template>
                        <template #nav-prev-button>
                            <svg stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" width="22px" height="24px" class="vc-base-icon">
                                <polyline points="15 18 9 12 15 6"></polyline>
                            </svg>
                            <span class="sr-only">Previous year</span>
                        </template>
                        <template #nav-next-button>
                            <svg stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" width="22px" height="24px" class="vc-base-icon">
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                            <span class="sr-only">Next year</span>
                        </template>
                        <template v-slot="{ togglePopover }">
                            <div class="flex items-center">
                                <button
                                    class="pick-a-date-btn w-full p-2 text-sm font-normal ring-2 ring-inset rounded-r-lg"
                                    :class="store.state.filters.dayOfTravel.selectedDate !== null ? 'bg-secondary text-white ring-transparent hover:ring-white hover:bg-secondary-900 focus:bg-secondary-900' : 'text-gray-700 bg-transparent ring-transparent hover:ring-secondary focus:ring-secondary'"
                                    @click="togglePopover"
                                    :aria-label="store.state.filters.dayOfTravel.selectedDate ? `Pick a date - ${store.state.filters.dayOfTravel.selected}` : 'Pick a date'"
                                >
                                    <template v-if="store.state.filters.dayOfTravel.selectedDate">
                                        {{ store.state.filters.dayOfTravel.selected }}
                                    </template>
                                    <template v-else>
                                        Or pick a date
                                    </template>
                                </button>
                            </div>
                        </template>
                    </DatePicker>
                </div>
            </div>

            <!-- Direction of Travel -->
            <div v-if="store.state.filters.directionOfTravel.options.length > 0">
                <div id="direction-of-travel-label" class="block mb-1 text-sm font-normal tracking-wide text-gray-600">
                    Direction of Travel
                </div>
                <div role="group" aria-labelledby="direction-of-travel-label" class="flex flex-col overflow-hidden border border-gray-200 divide-y divide-gray-200 rounded-lg md:inline-flex md:flex-row md:items-center md:justify-left md:divide-x md:divide-y-0">
                    <template v-for="option in store.state.filters.directionOfTravel.options">
                        <button
                            type="button"
                            role="radio"
                            :aria-checked="store.state.filters.directionOfTravel.selected === option.value"
                            class="p-2 text-sm font-normal direction-of-travel-btn ring-2 ring-inset first:rounded-l-lg last:rounded-r-lg"
                            :class="store.state.filters.directionOfTravel.selected === option.value ? 'bg-secondary text-white ring-transparent' : 'text-gray-700 bg-transparent ring-transparent hover:ring-secondary focus:ring-secondary'"
                            @click="store.setDirectionOfTravelValue(option.value)"
                        >
                            {{ option.label }}
                        </button>
                    </template>
                </div>
            </div>
        </div>

        <div role="region" class="loadingSpinnerDiv" aria-live="polite">
            <div v-if="!currentRoute || !currentRoute.schedule" class="flex justify-center w-full py-8">
                <Spinner class="w-8 h-8 text-primary" />
            </div>
        </div>
        <div v-if="currentRoute && selectedSchedule" class="relative mt-8 lg:-ml-2 lg:-mr-2">
            <div>
                <div class="lg:pl-16">
                    <h3 role="presentation" class="text-3xl font-normal text-primary">
                        {{ store.state.filters.dayOfTravel.selected }}
                    </h3>
                    <h3 class="text-2xl font-normal text-primary">
                        {{ store.state.filters.directionOfTravel.selectedLabel }} Service
                    </h3>
                    <div v-if="!store.state.filters.dayOfTravel.selectedDate && selectedSchedule.effectiveDate?.isFuture"
                        class="flex items-center my-2 text-sm font-normal text-gray-900"
                        role="alert"
                        :aria-label="`Schedule for ${store.state.filters.dayOfTravel.selected} ${store.state.filters.directionOfTravel.selected} is effective from ${selectedSchedule.effectiveDate.formattedFromDate} to ${selectedSchedule.effectiveDate.formattedToDate}.`"
                    >
                        <Icon :icon="mdiAlert" class="w-6 h-6 mr-2 text-yellow-500" role="none" />
                        Effective from {{ selectedSchedule.effectiveDate.formattedFromDate }} to {{ selectedSchedule.effectiveDate.formattedToDate }}
                    </div>
                </div>

                <div v-if="selectedSchedule.empty" class="flex items-center justify-center w-full px-8 py-32 border-t-2 border-b-2 border-primary">
                    <div class="leading-tight prose prose-a:underline prose-a:text-secondary hover:prose-a:text-secondary-darker">
                        <div v-if="noServiceMessageHTML?.length" v-html="noServiceMessageHTML"></div>
                        <div v-if="noServiceMessageHTMLSpanish?.length" v-html="noServiceMessageHTMLSpanish" lang="es"></div>
                    </div>
                </div>
                <div v-else class="relative flex flex-col-reverse w-full border-t-2 border-b-2 lg:flex-row border-primary">
                    <span v-if="!selectedSchedule.empty" class="absolute left-[3rem] top-[-7px] w-3 h-3 bg-primary border-2 border-primary rounded-full z-10"></span>
                    <div class="relative flex w-full lg:w-[24rem] h-[51vh] overflow-hidden">
                        <div v-if="routeScroll.showTop" class="absolute top-0 z-10 left-3 text-primary blink-top">
                            <Icon class="w-8 h-8" :icon="mdiArrowUp" aria-label="Arrow up icon"/>
                        </div>

                        <MapsSchedulesRouteScheduleList :schedule="selectedSchedule"/>

                        <div v-if="routeScroll.showBottom"
                             class="absolute bottom-0 z-10 left-3 text-primary blink-bottom">
                            <Icon class="w-8 h-8" :icon="mdiArrowDown" aria-label="Arrow down icon"/>
                        </div>
                    </div>
                    <div class="flex-grow h-[51vh] min-h-[300px]">
                        <div class="w-full h-full overflow-hidden">
                            <RouteMap
                                v-if="currentRoute && selectedSchedule.shape"
                                class="w-full h-full"
                                :route="currentRoute"
                                :shape="mapShape"
                                :highlighted-stops="selectedStops"
                                @ready="onMapReady"
                                @onMarkerClicked="onMarkerClicked"
                            />
                        </div>
                    </div>
                </div>

                <MapsSchedulesRouteRunOn :schedule="selectedSchedule" class="mt-8 ml-8 text-sm text-gray-600"/>
            </div>

            <div role="region" class="loadingSpinnerDiv" aria-live="polite">
                <div v-if="store.state.isProcessingSchedule"
                     class="absolute top-0 left-0 z-50 flex items-center justify-center w-full h-full py-8 bg-gray-50 bg-opacity-90">
                    <Spinner class="w-8 h-8 text-primary" />
                </div>
            </div>
        </div>
        <div v-else class="relative z-50 flex items-center justify-center w-full py-8 bg-gray-50 bg-opacity-90" style="height: 400px">
            <div role="region" class="loadingSpinnerDiv" aria-live="polite">
                <Spinner class="w-8 h-8 text-primary" />
            </div>
        </div>
    </div>
</template>

<script>
import { mdiAlert, mdiArrowDown, mdiArrowUp, mdiTable, mdiViewList } from "@mdi/js";
import { DatePicker } from 'v-calendar';
import {computed, defineComponent, ref} from 'vue';
import { setGoogleApi } from "../../utils/google";
import Icon from "../Icon";
import InputSelect from "../InputSelect";
import LightButton from "../LightButton";
import RouteMap from "../RouteMap";
import Spinner from "../Spinner";
import MapsSchedulesRouteRunOn from "./MapsSchedulesRouteRunOn";
import MapsSchedulesRouteScheduleList from "./MapsSchedulesRouteScheduleList";
import MapsSchedulesStopInfo from "./MapsSchedulesStopInfo";
import { useStore } from "./store";
import { usePage } from "@inertiajs/vue3";

export default defineComponent({
    name: 'MapsSchedulesRouteSchedule',
    components: {
        Icon,
        Spinner,
        RouteMap,
        DatePicker,
        LightButton,
        InputSelect,
        MapsSchedulesStopInfo,
        MapsSchedulesRouteRunOn,
        MapsSchedulesRouteScheduleList
    },

    setup() {
        const page = usePage();
        const store = useStore();
        const dayOfTravelPicker = ref();
        const selectedSchedule = computed(() => store.state.selectedSchedule);
        const selectedStops = computed(() => store.state.selectedStops);
        const currentRoute = computed(() => store.state.routes.current);
        const routeScroll = computed(() => store.state.routes.scroll);

        const noServiceMessageHTML = computed(() => {
            if (! page?.props?.global?.routes?.no_service_available_message) {
                return '<p>No service for the selected Date of Travel and Direction of Travel.</p>';
            }

            return page.props.global.routes.no_service_available_message;
        });

        const noServiceMessageHTMLSpanish = computed(() => {
            if (! page?.props?.global?.routes?.no_service_available_message_spanish) {
                return '<p>No hay servicio para la fecha de viaje y dirección de viaje seleccionadas.</p>';
            }

            return page.props.global.routes.no_service_available_message_spanish;
        });

        const mapShape = computed(function () {
            if (! selectedSchedule.value?.shape) {
                return null;
            }

            return {
                ...selectedSchedule.value.shape,
                stops: selectedSchedule.value.shape.stops,
                originStop: selectedSchedule.value.shape.stops[0],
                destStop: selectedSchedule.value.shape.stops[selectedSchedule.value.stops.length - 1],
            };
        });

        function onMapReady(mapRef) {
            setGoogleApi(mapRef.value.api);

            // @todo: zoom to route here
        }

        function onMarkerClicked(marker) {
            store.setSelectedStops([marker.stop]);
        }

        function onDatePickerPopoverOpen() {
            const popoverEl = dayOfTravelPicker.value.popoverRef.$el;

            // Update aria attributes for each day in the calendar
            popoverEl
                .querySelectorAll('.vc-day')
                .forEach((el) => el.setAttribute('aria-pressed', 'false'));

            popoverEl
                .querySelector('.vc-highlight-content-solid')
                ?.setAttribute('aria-pressed', 'true');

            // Update aria attributes for each month when the month selector is open
            if (popoverEl.querySelector('[data-id]')) {
                popoverEl
                    .querySelectorAll('[data-id]')
                    .forEach((el) => {
                        const dateStr = el.getAttribute('data-id')?.split('.');

                        if (dateStr.length === 2) {
                            const date = new Date(parseInt(dateStr[0]), parseInt(dateStr[1]) - 1, 1, 0, 0, 0);
                            const month = date.toLocaleString('default', { month: 'long' });

                            el.setAttribute('aria-label', `${month} ${date.getFullYear()}`);
                        }
                    })

                popoverEl
                    .querySelectorAll('[data-id]:not(.is-active)')
                    .forEach((el) => el.setAttribute('aria-current', 'false'));

                popoverEl
                    .querySelector(`[data-id].is-active`)
                    ?.setAttribute('aria-current', 'true');
            }
        }

        function setDate(date) {
            if (!date) {
                return;
            }
            store.setDayOfTravelDate(date);
        }

        return {
            store,
            mapShape,
            routeScroll,
            currentRoute,
            selectedStops,
            selectedSchedule,
            dayOfTravelPicker,
            noServiceMessageHTML,
            noServiceMessageHTMLSpanish,

            // Icons
            mdiAlert,
            mdiTable,
            mdiViewList,
            mdiArrowUp,
            mdiArrowDown,

            // Methods
            setDate,
            onMapReady,
            onMarkerClicked,
            onDatePickerPopoverOpen,
        }
    },
});
</script>
