<template>
    <div class="w-full">
        <table class="overflow-hidden table-fixed w-full divide-y divide-gray-100 rounded-lg shadow-lg">
            <thead class="bg-primary-gray uppercase text-gray-600 font-bold">
            <tr>
                <th scope="col" class="px-6 py-3 text-left text-sm">
                    Local Bus & Light Rail
                </th>
                <th scope="col" class="w-24 px-6 py-3 text-left text-xs">
                    1-Day
                </th>
                <th scope="col" class="w-24 px-6 py-3 text-left text-xs">
                    1-Ride
                </th>
            </tr>
            </thead>
            <tbody class="align-top bg-gray-100 divide-y divide-primary-gray-500 font-medium text-gray text-base">
            <tr>
                <td class="px-6 py-4">
                    Full Fare <span class="block mt-2 text-sm text-gray-light">Ages 19-64</span>
                </td>
                <td class="px-6 py-4">
                    $4.00
                </td>
                <td class="px-6 py-4">
                    $2.00
                </td>
            </tr>
            <tr>
                <td class="px-6 py-4">
                    Reduced Fare <span class="block mt-2 text-sm text-gray-light">Ages 6–18, 65+, Medicare, people with disabilities.</span>
                </td>
                <td class="px-6 py-4">
                    $2.00
                </td>
                <td class="px-6 py-4">
                    $1.00
                </td>
            </tr>

            <tr>
                <td class="px-6 py-4" colspan="3">
                    Children 5 and under ride for free
                </td>
            </tr>

            <tr>
                <td class="px-6 py-4" colspan="3">
                    <a class="link" href="/fares">All Fare Types</a>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'FaresTable',
    props: {
        // @todo Allow admins to add rows with description and prices.
    },

    setup() {
        return {}
    },
});
</script>
