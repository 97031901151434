<template>
    <div class="flex flex-col-reverse w-full fit-screen lg:flex-row lg:overflow-hidden">
        <!-- inputs/trips summary/trip details -->
        <div class="flex flex-col h-full overflow-y-scroll bg-primary lg:w-96">
            <div v-if="! results.routeDetails">
                <div class="p-6">
                    <h1 class="text-4xl font-bold text-white">Plan a Trip</h1>
                    <div class="relative flex flex-col items-center justify-between mt-6">
                        <!-- Origin -->
                        <div class="w-full">
                            <InputLocation ref="locationFromRef" :modelValue="locationFrom" @update:modelValue="setLocationFrom" title="Origin" label="From:" name="trip_from" :api-key="googleApiKey" :mapBounds="mapBounds" class="text-sm"></InputLocation>
                        </div>
                        <!-- Switcher -->
                        <LightButton @click="switchInputLocations" type="button" bg-style="bg-secondary hover:bg-secondary-darker" textStyle="text-white" padding="p-1" class="absolute z-40 flex items-center justify-center mt-10 rounded-full">
                            <Icon class="w-6 h-6" :icon="mdiSwapVertical" aria-label="Swap locations icon" />
                        </LightButton>
                        <!-- Destination -->
                        <div class="w-full mt-2">
                            <InputLocation ref="locationToRef" :modelValue="locationTo" @update:modelValue="setLocationTo" title="Destination" label="To:" name="trip_to" :api-key="googleApiKey" :mapBounds="mapBounds" class="text-sm"></InputLocation>
                        </div>
                    </div>

                    <!-- Mode selector -->
                    <div class="flex flex-col justify-center mt-4 space-y-4">
                        <div class="flex items-center justify-between overflow-hidden border rounded border-primary">
                            <SecondaryButton size="small" @click="setMode(flags.MODE_LEAVING_NOW)" type="button" class="w-1/3 rounded-r-none" :inverted="mode === flags.MODE_LEAVING_NOW" :aria-current="mode === flags.MODE_LEAVING_NOW">Leaving Now</SecondaryButton>
                            <SecondaryButton size="small" @click="setMode(flags.MODE_DEPART_AT)" type="button" class="w-1/3 rounded-none" :inverted="mode === flags.MODE_DEPART_AT" :aria-current="mode === flags.MODE_DEPART_AT">Depart At</SecondaryButton>
                            <SecondaryButton size="small" @click="setMode(flags.MODE_ARRIVE_BY)" type="button" class="w-1/3 rounded-l-none" :inverted="mode === flags.MODE_ARRIVE_BY" :aria-current="mode === flags.MODE_ARRIVE_BY">Arrive By</SecondaryButton>
                        </div>
                        <div v-if="mode !== flags.MODE_LEAVING_NOW"  class="flex items-center space-x-2">
                            <label>
                                <span class="text-white text-sm">Time</span>
                                <InputSelect name="trip_time" tabindex="0" title="Select a time" placeholder="Select a time" class="w-full text-sm" :options="flags.TIME_OPTIONS" :modelValue="time" @update:modelValue="setTime"></InputSelect>
                            </label>
                            <label>
                                <span class="text-white text-sm">Date</span>
                                <InputSelect name="trip_date" tabindex="0" title="Select a date" placeholder="Select a date" class="w-full text-sm" :options="flags.DATE_OPTIONS" :modelValue="date" @update:modelValue="setDate"></InputSelect>
                            </label>
                        </div>
                    </div>

                    <!-- Submission Button -->
                    <div v-if="allFieldsOk"  class="flex justify-center mt-8">
                        <SecondaryButton @click="gotoTripsPage" type="button">
                            Get Directions <Icon class="w-4 h-4" :icon="mdiChevronRight" aria-label="Arrow right icon"/>
                        </SecondaryButton>
                    </div>

                    <template v-if="results.fetched">
                        <!-- OPTIONS -->
                        <transition name="fade">
                            <div class="my-6">
                                <button @click="startOver" type="button" class="inline-flex items-center text-sm font-normal text-white appearance-none p-1 rounded hover:underline focus:ring-2 focus:ring-white">
                                    <Icon class="w-6 h-6 mr-2" :icon="mdiChevronLeft" aria-label="Arrow left icon"/>
                                    Start Over
                                </button>
                                <TripPlannerOptions v-if="!results.error" :model-value="tripOption" @update:modelValue="setTripOption" class="pt-6 border-t border-dashed border-primary-500"></TripPlannerOptions>
                            </div>
                        </transition>

                        <!-- ROUTES -->
                        <transition name="fade">
                            <div>
                                <TripPlannerRoutes
                                    :routes="results.routes"
                                    :error="results.error"
                                    @selectRoute="selectRoute"
                                />
                            </div>
                        </transition>
                    </template>
                </div>
            </div>

            <!-- Route Details -->
            <transition name="fade">
                <div v-if="results.routeDetails" class="flex flex-col flex-grow">
                    <div class="p-6">
                        <button @click="selectRoute({ route: null })" type="button" class="inline-flex items-center text-sm font-normal text-white appearance-none p-1 rounded hover:underline focus:ring-2 focus:ring-white">
                            <Icon class="w-2 h-2 mr-2" :icon="mdiChevronLeft" aria-label="Arrow left icon"/>
                            Back to Trip Options
                        </button>
                    </div>
                    <TripPlannerRouteDetails
                        :route="results.routeDetails"
                        :highlighted-leg="results.highlightedLeg"
                        @highlightLeg="updateHighlightedLeg"
                        class="flex-grow" />
                </div>
            </transition>

            <transition name="fade">
                <!-- Share the Ride -->
                <div v-if="results.fetched" class="my-6 text-sm font-normal leading-normal text-center text-gray-200">
                    You can also find a carpool or vanpool partner in your area by visiting <a :href="shareTheRideUrl" target="_blank" class="text-white underline rounded focus:ring-0 focus:outline-2 focus:outline-offset-2 focus:outline-white">ShareTheRide.com</a>
                </div>
            </transition>
        </div>
        <!-- // inputs/trips summary/trip details -->

        <!-- Map -->
        <div
            v-show="largeMediaScreen.matches.value || (results.routeDetails && isMapVisibleOnSmallScreens)"
            class="bg-gray-100"
            :class="{
                'flex-0 h-64': !largeMediaScreen.matches.value && (results.routeDetails && isMapVisibleOnSmallScreens),
                'flex-1 h-full': largeMediaScreen.matches.value,
            }"
        >
            <TripPlannerMap
                class="w-full h-full"
                :google-api-key="googleApiKey"
                :route="results.routeDetails"
                :highlighted-leg="results.highlightedLeg"
                @locationChanged="mapLocationChanged"
                @highlightLeg="updateHighlightedLeg"
                @ready="onMapReady"
            />
        </div>
        <!-- // Map -->
    </div>
</template>

<script>
import {ChevronLeftIcon, ChevronRightIcon, SwitchVerticalIcon} from "@heroicons/vue/outline";
import {router} from "@inertiajs/vue3";
import {mdiChevronLeft, mdiChevronRight, mdiSwapVertical} from "@mdi/js";
import {format, parse} from "date-fns";
import {computed, inject, nextTick, onMounted, ref, watch} from "vue";
import {useMatchMedia} from "vue-composable";
import {betweenDates, formatAmPmTime, parseFromISO} from "../../utils/date";
import {setGoogleApi} from "../../utils/google";
import Icon from "../Icon";
import InputLocation from "../InputLocation";
import InputSelect from "../InputSelect";
import LightButton from "../LightButton";
import SecondaryButton from "../SecondaryButton";
import {getTripPlannerUrl} from "./plannerUrls"
import TripPlannerMap from "./TripPlannerMap";
import TripPlannerOptions from "./TripPlannerOptions";
import TripPlannerRouteDetails from "./TripPlannerRouteDetails";
import TripPlannerRoutes from "./TripPlannerRoutes";
import {MODE_ARRIVE_BY, useTripInput} from "./useTripInput";

export default {
    name: "TripPlanner",

    components: {
        InputSelect,
        SecondaryButton,
        Icon,
        LightButton,
        InputLocation,
        SwitchVerticalIcon,
        ChevronRightIcon,
        ChevronLeftIcon,
        TripPlannerRoutes,
        TripPlannerOptions,
        TripPlannerRouteDetails,
        TripPlannerMap,
    },

    props: {
        googleApiKey: {
            type: String,
            required: true,
        },
        mapBounds: {
            type: Object,
            required: true,
        },
        baseUrl: {
            type: String,
            required: true,
        },
        locationFrom: {
            type: Object,
            default: () => ({
                value: '',
                // { lat, lng }
                location: null,
            }),
        },
        locationTo: {
            type: Object,
            default: () => ({
                value: '',
                // { lat, lng }
                location: null,
            }),
        },
        mode: {
            type: String,
            default: 'now',
        },
        time: {
            type: String,
            default: '-',
        },
        date: {
            type: String,
            default: '-',
        },
    },

    setup(props) {
        const api = inject('api');
        const largeMediaScreen = useMatchMedia('(min-width: 1024px)');

        const locationFromRef = ref(null);
        const locationToRef = ref(null);

        // Import all input fields data and functions.
        const {
            mode,
            date,
            time,
            flags,
            tripOption,
            locationTo,
            locationFrom,
            allFieldsOk,
            isMapVisibleOnSmallScreens,
            setMode,
            setTime,
            setDate,
            setTripOption,
            setLocationTo,
            setLocationFrom,
            switchLocations,
        } = useTripInput();

        // This reference will hold the results from the API.
        // `error` can be a string and `routes` can be an array.
        const results = ref({
            fetched: false,
            error: null,
            routes: null,
            routeDetails: null,
            highlightedLeg: null,
        });

        /**i
         * Open a new window tab with the Share The Ride URL.
         */
        const shareTheRideUrl = computed(() => {
            if (! locationFrom.value.location || ! locationTo.value.location) {
                return;
            }

            const start = locationFrom.value;
            const end = locationTo.value;
            const formattedDate = (!date.value || date.value === '-') ? format(new Date, 'yyyy-MM-dd') : date.value;
            const formattedTime = (!time.value || time.value === '-') ? format(new Date, 'h:mm a') : format(parse(time.value, 'HH:mm', new Date), 'h:mm a');

            return `https://sharetheride.com/#/dashboard/plan/${start.value}%7C${start.location.lat},${start.location.lng}/${end.value}%7C${end.location.lat},${end.location.lng}/?date=${formattedDate}&time=${formattedTime}`;
        });

        function makeLegTime(route, leg, index) {
            if (index === 0 && !leg.depart && route.summary.depart) {
                return {
                    desc: `Depart at ${route.summary.depart.text}, by ${leg.mode}`,
                    text: formatAmPmTime(route.summary.depart.iso8601),
                }
            }

            if (leg.depart) {
                return {
                    desc: `Depart at ${leg.depart.text}, by ${leg.mode}`,
                    text: formatAmPmTime(leg.depart.iso8601),
                }
            }

            if (index > 0 && route.legs[index - 1].arrive) {
                return {
                    desc: `Arrive at ${route.legs[index-1].arrive.text}, by ${route.legs[index-1].mode}`,
                    text: formatAmPmTime(route.legs[index-1].arrive.iso8601),
                }
            }

            return null;
        }

        function makeLegTitle(route, leg, index) {
            if (index === 0) {
                return {
                    desc: `From ${route.summary.start_name}`,
                    text: route.summary.start_name,
                }
            }

            if (leg.start_name === '' && route.legs[index-1]) {
                return {
                    desc: `From ${route.legs[index-1].finish_name}`,
                    text: route.legs[index-1].finish_name,
                }
            }

            return {
                desc: `From ${leg.start_name}`,
                text: leg.start_name,
            }
        }

        /**
         * Fetch all service alerts.
         */
        async function fetchServiceAlerts() {
            try {
                const { data } = await api.getServiceAlerts();

                return data;
            } catch (e) {
                return [];
            }
        }

        /**
         * Fetch the trip routes from the Valley Metro API.
         *
         * @returns {Promise<null>}
         */
        async function getTrips() {
            if (! allFieldsOk.value) {
                results.value.fetched = false;
                return null;
            }

            const fetchMode = mode.value === MODE_ARRIVE_BY ? 'arrive' : 'depart';

            let transitRoutingPreference = tripOption?.value;

            if (transitRoutingPreference === 'best_route') {
                transitRoutingPreference = '';
            }

            const allAlerts = await fetchServiceAlerts();
            const { error, routes, fetched } = await api.fetchTrips(locationFrom, locationTo, fetchMode, date, time, transitRoutingPreference);

            results.value.highlightedLeg = null;
            results.value.fetched = fetched;
            results.value.error = error;
            results.value.routes = routes
                .map((route) => {
                    // To get the first leg we just need to find the first item that is not walk mode
                    // To get the last leg, we reverse the legs array and find the first that's not walk mode,
                    // then we subtract the total number of legs with the result, so we will know the proper index.
                    const transitLegs = route.legs
                        .map((leg, lIndex) => ({ ...leg, lIndex }))
                        .filter(leg => leg.transit_details);

                    const firstLeg = transitLegs.length ? transitLegs[0].lIndex : -1;
                    const lastLeg = transitLegs.length ? transitLegs[transitLegs.length - 1].lIndex : -1;

                    if (transitLegs.length === 0) {
                        return null;
                    }

                    const legs = route.legs
                        .map((leg, lIndex) => {
                            const alerts = (leg.transit_details?.line?.name)
                                ? allAlerts.filter(function (alert) {
                                    const includesRoute = Boolean(
                                        alert.routes.find(r => r.shortName.toLowerCase() === leg.transit_details.line.name.toLowerCase())
                                    );

                                    if (! includesRoute) {
                                        return false;
                                    }

                                    const departDate = parseFromISO(leg.depart.iso8601, 'UTC');

                                    return alert.activeRanges.some(range => {
                                        return betweenDates(
                                            departDate,
                                            (range.from) ? parseFromISO(range.from, 'UTC') : null,
                                            (range.to) ? parseFromISO(range.to, 'UTC') : null
                                        );
                                    });
                                })
                                : [];

                            let available = true;

                            if (leg.transit_details) {
                                // Is trip leg enabled? It will filter alerts to get only those
                                // that will affect the availability of the trip leg.
                                available = alerts.length === 0 || ! alerts.some(a => {
                                    // Only NO_SERVICE disables route legs
                                    if (a.effect !== 'NO_SERVICE') {
                                        return false;
                                    }

                                    if (leg.mode.toLowerCase() === 'walk') {
                                        return false;
                                    }

                                    const firstDisabled = (lIndex === firstLeg
                                        && a.stops.find(stop => stop.id.toString() === leg.transit_details.departure_stop.id.toString()));

                                    const lastDisabled = (lIndex === lastLeg
                                        && a.stops.find(stop => stop.id.toString() === leg.transit_details.arrival_stop.id.toString()));


                                    return firstDisabled || lastDisabled;
                                });
                            }

                            return {
                                ...leg,
                                title: makeLegTitle(route, leg, lIndex),
                                time: makeLegTime(route, leg, lIndex),
                                alerts,
                                available,
                            }
                        });

                    return {
                        ...route,
                        legs,
                        available: legs[firstLeg]?.available && legs[lastLeg]?.available
                    }
                })
                .filter(r => !!r);
        }

        /**
         * Rip off all the Trip Planner data completely.
         */
        function startOver() {
            router.get(`/${props.baseUrl}`);
        }

        /**
         * Visit the full Trip Planner URL so routes can be loaded.
         */
        function gotoTripsPage() {
            router.get(getTripPlannerUrl(props.baseUrl, locationFrom, locationTo, mode, date, time));
        }

        function selectRoute({ route }) {
            results.value.routeDetails = route;
        }

        function onMapReady(mapRef) {
            setGoogleApi(mapRef.value.api);
        }

        function mapLocationChanged(newTripInput) {
            setLocationFrom(newTripInput.from);
            setLocationTo(newTripInput.to);
            gotoTripsPage();
        }

        function updateHighlightedLeg({ index }) {
            if (index === results.value.highlightedLeg) {
                results.value.highlightedLeg = null;
                return;
            }

            results.value.highlightedLeg = index;
        }

        function switchInputLocations() {
            switchLocations()
            locationFromRef.value.clearResults()
            locationToRef.value.clearResults()
        }

        function setStateFromProps() {
            setLocationFrom(props.locationFrom);
            setLocationTo(props.locationTo);
            setMode(props.mode);
            setTime(props.time);
            setDate(props.date);

            // When all criteria are matched on first page load,
            // that means we should query the Valley Metro API to
            // get the available routes.
            nextTick(() => {
                if (allFieldsOk) {
                    getTrips();
                } else {
                    results.value.fetched = false;
                    results.value.routes = null;
                    results.value.error = null;
                }
            });
        }

        // Execute the hook when the component has been mounted.
        onMounted(() => setStateFromProps());

        watch(tripOption, () => getTrips());

        // -----------------------------------------------------------

        return {
            flags,
            mode,
            time,
            date,
            tripOption,
            locationFrom,
            locationTo,
            locationFromRef,
            locationToRef,
            allFieldsOk,
            results,
            largeMediaScreen,
            isMapVisibleOnSmallScreens,
            shareTheRideUrl,
            setMode,
            setDate,
            setTime,
            startOver,
            onMapReady,
            selectRoute,
            gotoTripsPage,
            setTripOption,
            setLocationTo,
            switchLocations,
            setLocationFrom,
            mapLocationChanged,
            switchInputLocations,
            updateHighlightedLeg,

            // Icons
            mdiChevronLeft,
            mdiChevronRight,
            mdiSwapVertical,
        }
    }
}
</script>

<style scoped>
@screen lg {
    .fit-screen {
        height: calc(100vh - 93px);
    }
}
</style>
