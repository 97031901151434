<template>
    <FieldContainer v-slot="{ field, fieldClasses }" disable-prepend disable-append :containerClasses="['w-full']">
        <template v-for="(label, value) in field.options">
            <label :class="{'block w-full mb-1': !field.inline}">
                <input type="checkbox" :name="`${field.handle}[]`" :value="value" :checked="isChecked(field, value)" :title="label || value">
                {{ label || value }}
            </label>
        </template>
    </FieldContainer>
</template>

<script setup>
import FieldContainer from "../FieldContainer";

const isChecked = (field, value) => {
    if (!field.old) {
        return false;
    }

    return field.old.find(v => v === value);
}
</script>
