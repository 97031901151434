<template>
    <input
        v-bind="$attrs"
        @input="onInput"
        :value="modelValue"
        :type="type"
        :class="{'form-input shadow rounded border-gray-500 text-black hover:border-gray-700 focus:border-gray-700 focus:ring-offset-2 focus:ring-2 focus:ring-gray-700': !unstyled, 'placeholder-gray-600': true}"
    />
</template>

<script>

export default {
    name: "InputText",
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: String,
            required: false,
            default: '',
        },
        type: {
            type: String,
            required: false,
            default: 'text',
        },
        unstyled: {
            type: Boolean,
            required: false,
            default: false,
        }
    },

    setup(props, {emit}) {
        function onInput(ev) {
            emit('update:modelValue', ev.target.value);
        }

        return {onInput};
    }
}
</script>

<style scoped>

</style>
