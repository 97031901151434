<template>
    <div @keydown.esc="mouseLeave">
        <button @mouseenter="mouseEnter" @click.prevent="clicked" @keydown.space.prevent="spacePressed" :aria-expanded="isShowing ? 'true' : 'false'" type="button" class="relative flex items-center justify-center w-full px-4 py-4 text-base font-medium text-gray-600 bg-white border border-t-4 group hover:text-gray-900 focus:ring-0 focus:bg-gray-50 focus:border-t-red lg:w-auto lg:py-8 lg:px-4 lg:inline-flex" :class="{'border-gray-200 border-t-red': isShowing, 'border-transparent': !isShowing}" >
            <span v-if="isShowing" class="absolute inset-x-0 bottom-0 z-40 hidden w-full h-1 -mb-1 bg-white lg:block"></span>
            <span>
                <slot name="label"></slot>
            </span>
            <!-- Icon -->
            <slot name="icon" class="w-5 h-5 ml-2 text-gray-400 group-hover:text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"></slot>
        </button>

        <div v-show="isShowing" @mouseleave="mouseLeave" @click.stop static class="absolute z-[100] left-0 right-0 mt-0 transform w-screen px-0 md:px-4 lg:px-8">
            <nav class="relative w-full p-2 bg-white border shadow-lg border-primary-gray ring-1 ring-black ring-opacity-5 md:px-5 md:py-6">
                <slot name="menu"></slot>
            </nav>
        </div>
    </div>
</template>

<script>
import {computed, onMounted, onUnmounted, ref} from 'vue';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import {useSiteMainNav} from "../utils/siteMainNav";

    export default {
        props: {
            id: {
                type: String,
                required: true,
            },

            toggleOnSpace: {
                type: Boolean,
                default: false,
            },
        },
        setup(props, { emit }) {
            const mainNav = useSiteMainNav();
            const isShowing = computed(() => mainNav.activeMenu.value === props.id);
            const waitingToHide = ref(false);
            const waitingToShow = ref(false);

            const breakpoints = useBreakpoints(breakpointsTailwind);
            const isSmallScreen = breakpoints.smaller('md');

            function closeMenuOnEsc(e) {
                if (e.keyCode === 27) {
                    mainNav.hideDropdown();
                }
            }

            onMounted(() => {
                window.addEventListener('keydown', closeMenuOnEsc);
            });

            onUnmounted(() => window.removeEventListener('keydown', closeMenuOnEsc));

            const toggle = (value = null) => {
                if (value == null) {
                    value = !isShowing.value;
                }

                if (value) {
                    mainNav.showDropdown(props.id);
                } else {
                    mainNav.hideDropdown();
                }
            };

            const mouseEnter = () => {
                if (isSmallScreen.value) {
                     return;
                }

                showDebounced();
                waitingToShow.value = true;
                waitingToHide.value = false;
            }

            const mouseLeave = () => {
                if (isSmallScreen.value) {
                    return;
                }

                hideDebounced();
                waitingToHide.value = true;
                waitingToShow.value = false;
            }

            const clicked = () => {
                toggle();
            };

            const spacePressed = () => {
                if (!props.toggleOnSpace) {
                    return;
                }

                toggle();
            };

            const hideDebounced = _.debounce(() => {
                if (!waitingToHide.value) {
                    return;
                }
                waitingToHide.value = false;
                mainNav.hideDropdown(props.id)
            }, 500);

            const showDebounced = _.debounce(() => {
                if (!waitingToShow.value) {
                    return;
                }
                waitingToShow.value = false;
                toggle(true);
            }, 400);

            return {
                isSmallScreen,
                isShowing,
                toggle,
                mouseEnter,
                mouseLeave,
                clicked,
                spacePressed
            };
        }
    }
</script>
