<template>
    <FieldContainer v-slot="{ field, fieldClasses }">
        <input
            :id="field.id"
            :name="field.handle"
            :placeholder="field.placeholder"
            :title="field.label || field.placeholder"
            :aria-label="field.label || field.placeholder"
            :value="field.old"
            :required="field.isRequired"
            :multiple="field.max_files && field.max_files > 1"
            :autocomplete="field.autocomplete !== 'n/a' ? field.autocomplete : undefined"
            class="w-full field-custom field-in-group"
            :class="fieldClasses"
            v-bind="buildAttrs(field)"
        />
    </FieldContainer>
</template>

<script setup>
import FieldContainer from "../FieldContainer";

function buildAttrs(field) {
    const attrs = {
        type: field.visibility === 'hidden' ? 'hidden' : field.input_type,
    };

    if (field.visibility !== 'hidden') {
        switch (field.type) {
            case 'integer':
                attrs.type = 'number';
                break;
            case 'toggle':
                attrs.type = 'checkbox';
                break;
            default:
                attrs.type = field.input_type;
        }
    }

    if (field.visibility === 'read_only') {
        attrs.readonly = true;
    }

    if (field.character_limit) {
        attrs.maxlength = field.character_limit;
    }

    if (attrs.type === 'file' && field.character_limit && field.character_limit > 1) {
        attrs.multiple = true;
    }

    return attrs;
}
</script>
