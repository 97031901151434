<template>
    <div ref="containerRef"
         class="relative overflow-y-scroll lg:w-full"
         :class="{'shadow-vertical-scroll-both': routeScroll.showTop && routeScroll.showBottom, 'shadow-vertical-scroll-down': routeScroll.showTop && !routeScroll.showBottom, 'shadow-vertical-scroll-up': routeScroll.showBottom && !routeScroll.showTop}"
         @scroll="onScroll($event)"
    >
        <div v-for="(stop, sIndex) in scheduleStops" :key="stop.id + sIndex"  :ref="el => generateStopRef(el, stop)" class="relative px-4" :class="{'bg-green-700': isStopSelected(stop)}">
                <!-- Path -->
                <div class="z-20 flex flex-col justify-center items-center absolute top-[59px] ml-8" aria-hidden="true" :class="{'h-full': sIndex < scheduleStops.length - 1}">
                    <div v-if="sIndex === 0" class="h-[100px] -mt-[100px] w-0.5 border-r-2 border-primary"></div>
                    <div class="w-3 h-3 bg-gray-100 border-2 rounded-full border-primary"></div>
                    <div v-if="sIndex < scheduleStops.length - 1" class="flex-grow w-0.5 border-r-2 border-primary"></div>
                </div>

                <!-- Button -->
                <div class="inline-block w-full p-2 pl-16 space-y-2">
                    <div class="flex flex-col items-left">
                        <div class="flex div">
                            <h4 class="text-xl font-bold" :class="{'text-gray-700': !stop.selectedTime}">
                                {{ stop.name }}&nbsp;
                            </h4>
                        </div>

                        <div class="-ml-4" :class="{'z-50': stopListboxIndex === sIndex}">
                            <Listbox :modelValue="stop.selectedTime?.value" @update:modelValue="value => setSelectedTime(value, stop)">
                                <div class="flex w-full">
                                    <div class="flex items-center mr-2">
                                        <WithTooltip v-if="stop.hasAlerts" :id="`stopAlert_${stop.code}`">
                                            <template v-slot:element="{ elementAttrs, show, hide }">
                                                <Icon :icon="mdiAlert" class="w-8 h-8 text-secondary" v-bind="elementAttrs" @mouseenter="show" @focus="show" @mouseleave="hide" @blur="hide" />
                                            </template>
                                            <template v-slot:tooltip>
                                                Check Rider Alert above for more information
                                            </template>
                                        </WithTooltip>
                                        <Icon v-else viewBox="5 0 24 24" class="w-8 h-8 text-primary" aria-hidden="true" :icon="mdiTimelineClockOutline" aria-label="Clock icon" />
                                    </div>
                                    <ListboxButton
                                        class="flex items-center justify-between w-full text-2xl text-left bg-transparent border border-gray-200 rounded hover:bg-white hover:hover-primary hover:shadow"
                                        :class="{'opacity-50': stop.disabled}"
                                        :aria-label="getListboxLabel(stop)"
                                    >
                                        <span class="w-[14rem] flex items-center justify-between p-3">
                                            <span class="block text-xl truncate">
                                                {{ getLabel(stop) }}
                                            </span>
                                            <span v-if="stop.selectedTime?.nextRide" class="p-1 ml-2 text-xs font-normal rounded bg-primary-900 text-primary-100 whitespace-nowrap">Next Arrival</span>
                                        </span>
                                        <span class="inset-y-0 flex items-center w-8 pointer-events-none">
                                            <Icon class="w-5 h-5" aria-hidden="true" :icon="mdiChevronDown" aria-label="Arrow down icon"/>
                                        </span>
                                    </ListboxButton>
                                    <ListboxOptions class="absolute z-20 w-auto py-1 ml-10 overflow-auto text-2xl bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-base">
                                        <ListboxOption
                                            v-for="time in stop.times"
                                            v-slot="{ active, selected }"
                                            :key="time.value"
                                            :value="time.value"
                                            :disabled="! time.enabled"
                                            as="template"
                                        >
                                            <li
                                                :class="[active ? 'text-gray-900 bg-green-700' : (selected ? 'text-primary-900 bg-secondary-200' : 'text-gray-900'), time.enabled ? 'opacity-100 cursor-pointer' : 'opacity-50 line-through', 'flex items-center select-none relative p-2 z-20']"
                                                :aria-label="`${stop.name} - ${time.label} - ${time.nextRide ? 'Next Arrival' : ''}`"
                                            >
                                                <span class="flex items-center w-6 pr-3 text-primary-600">
                                                    <Icon v-if="selected" class="w-5 h-5" aria-role="checkbox" aria-checked="true" :icon="mdiCheck" aria-label="Check icon"/>
                                                </span>
                                                <span :class="[selected ? 'font-medium' : 'font-normal', 'flex-1 flex items-center justify-between z-20']">
                                                    <span class="block truncate">{{ time.label }}</span>
                                                    <span v-if="time.nextRide" class="z-20 p-1 ml-2 text-xs font-normal rounded bg-primary-900 text-primary-100 whitespace-nowrap">Next Arrival</span>
                                                </span>
                                            </li>
                                        </ListboxOption>
                                    </ListboxOptions>
                                </div>
                            </Listbox>
                        </div>

                        <div class="flex mt-2 text-sm font-normal text-gray-600 div">
                            Stop #{{ stop.code }}
                        </div>

                    </div>

                    <div v-if="stop.selectedTime !== ''" class="flex items-center justify-between w-full py-2">
                        <div v-if="stop.headsign" class="flex items-center py-6 text-primary">
                            <div class="flex flex-row pl-2 -mb-2 -ml-20">
                                <Icon class="w-8 h-8 mt-8 -mr-2" :icon="mdiHail" :aria-label="`Headsign: ${store.state.filters.directionOfTravel.headsignLabel} to ${stop.headsign}`"></Icon>
                                <div class="w-4 mt-6 overflow-hidden">
                                    <Icon class="w-12 h-12" :icon="mdiBusStop" aria-hidden="true" aria-label="Bus stop icon"></Icon>
                                </div>
                                <Icon v-if="ICONS[currentRoute.group.mode]" class="ml-1 w-14 h-14" :icon="ICONS[currentRoute.group.mode]" aria-hidden="true" :aria-label="`Current route is a ${currentRoute.group.mode}`"></Icon>
                            </div>
                            <div class="flex items-center w-full p-1 pt-0 pb-0 -ml-4 space-x-2 text-base font-normal scale-75 rounded-lg sm:ml-0 sm:scale-100 bg-primary text-primary-50">
                                <div class="text-4xl text-left" :class="{'hidden': displayRouteAsText(currentRoute)}">
                                    <strong>{{ currentRoute.id }}</strong>
                                </div>
                                <div class="flex-1 text-2xl text-center whitespace-nowrap">
                                    <span :class="[displayRouteAsText(currentRoute) ? 'text-2xl' : 'text-base']">
                                        <span :class="{'hidden': !displayRouteAsText(currentRoute)}">
                                            {{ currentRoute.id }}
                                        </span>
                                        {{ store.state.filters.directionOfTravel.headsignLabel }} <span :class="{'hidden': displayRouteAsText(currentRoute)}">to</span>
                                    </span>
                                    <br />
                                    {{ stop.headsign }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</template>

<script>
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from "@headlessui/vue";
import {usePage} from "@inertiajs/vue3";
import {mdiAlert, mdiBusStop, mdiCheck, mdiChevronDown, mdiHail, mdiTimelineClockOutline} from "@mdi/js";
import {computed, defineComponent, nextTick, onMounted, ref} from 'vue';
import {ICONS} from "../../utils/icons";
import Icon from "../Icon";
import InputSelect from "../InputSelect";
import LightButton from "../LightButton";
import Spinner from "../Spinner";
import {useStore} from "./store";
import WithTooltip from "../WithTooltip.vue";

export default defineComponent({
    name: 'MapsSchedulesRouteScheduleList',
    components: {
    Icon,
    Spinner,
    InputSelect,
    LightButton,
    Listbox,
    ListboxOption,
    ListboxOptions,
    ListboxButton,
    WithTooltip
},
    props: {
        schedule: { type: Object, required: true },
    },

    setup(props) {
        const store = useStore();

        const currentRoute = computed(() => store.state.routes.current);
        const routeScroll = computed(() => store.state.routes.scroll);
        const isCustomDayOfTravel = computed(() => store.state.filters.dayOfTravel.selectedDate !== null);
        const stopListboxIndex = ref(-1);
        const containerRef = ref(null);
        const stopsRefs = ref({});

        const selectedStopCode = ref('');
        const selectedStopTime = ref({});

        const scheduleStops = computed(() => {
            return (usePage().props.global.feature_flags.maps_and_schedules_group_stops_by_area)
                ? props.schedule.stopsByArea
                : props.schedule.stops;
        });

        // Select the first NextRide by default.
        nextTick(() => {
            if (scheduleStops.value) {
                store.setTimeOfTravelValue(store.getNextArrivalFromStops(scheduleStops.value)?.value);
            }
        });

        function stopListboxToggled (index) {
             stopListboxIndex.value = (stopListboxIndex.value !== index)
                 ? index
                 : -1;
        }

        function highlightStop (stop) {
            store.setSelectedStops([stop]);
        }

        function isStopSelected (stop) {
            return (usePage().props.global.feature_flags.maps_and_schedules_group_stops_by_area)
                ? stop.stops.some(s => store.isStopSelected(s))
                : store.isStopSelected(stop);
        }

        function displayRouteAsText (route) {
            return route.id === '68CM' && route.group.mode !== 'bus' && !(parseFloat(route.id) + 1);
        }

        function generateStopRef (el, stop) {
            stopsRefs.value[stop.code] = el;
        }

        function onScroll(event) {
            const showTop = event.target.scrollTop > 15;
            const showBottom = event.target.scrollTop < event.target.scrollHeight - event.target.clientHeight - 20;

            store.setRouteScroll(showTop, showBottom)
        }

        function getLabel(stop) {
            if (isCustomDayOfTravel.value && stop.hasAlerts && (stop.disabled || (stop.selectedTime && !stop.selectedTime.enabled))) {
                return 'See Rider Alert';
            }

            return stop.selectedTime
                ? stop.selectedTime.label
                : '';
        }
        function setSelectedTime(value, stop) {
            const stopTime = _.find(stop.times, time => time.value === value);
            selectedStopCode.value = stopTime ? stop.code : '';
            selectedStopTime.value = stopTime ? stopTime : {};
            store.setTimeOfTravelValue(value);
        }

        const getListboxLabel = (stop) => {
            const selectedTime = (stop.code === selectedStopCode.value) ? selectedStopTime.value : stop.selectedTime;
            return `${stop.name} - ${selectedTime?.label} ${selectedTime?.nextRide ? ' - Next Arrival' : ''}`
        };

        onMounted(() => {
            if (store.state.selectedStops.length > 0) {
                const stopRef = stopsRefs.value[store.state.selectedStops[0].code];

                if (stopRef) {
                    containerRef.value.scroll({ top: stopRef.offsetTop });
                }
            }
        });

        return {
            store,
            stopsRefs,
            containerRef,
            routeScroll,
            currentRoute,
            stopListboxIndex,
            scheduleStops,

            // Methods
            onScroll,
            isStopSelected,
            displayRouteAsText,
            generateStopRef,
            getLabel,
            setSelectedTime,
            getListboxLabel,

            // Icons
            ICONS,
            mdiAlert,
            mdiCheck,
            mdiChevronDown,
            mdiHail,
            mdiBusStop,
            mdiTimelineClockOutline
        }
    },
});
</script>
