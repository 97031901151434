<template>
    <div class="information-box-container info-box mb-32 md:mb-60 flex flex-col justify-center p-6 w-full sm:min-w-[400px]" v-bind="$attrs">
        <div class="flex flex-1 items-center justify-center relative" :class="{'mt-[75px]': !icon}">
            <div class="info-box-image-container absolute left-0 right-0 h-56 z-10 flex flex-col justify-end overflow-hidden aspect-[1/1] m-auto" :class="iconClasses.concat([!rounded ? 'hidden' : ''])">
                <div class="image-aligner flex justify-center">
                    <img v-if="icon" class="w-full aspect-[1/1]" :src="icon" alt="" role="img">
                </div>
            </div>
            <div class="info-box-text-box text-center text-lg sm:text-2xl max-w-sm my-10 border w-full"
                :class="infoBoxTextBoxClasses">
                <div :class="{'hidden': !rounded}" class="info-box-text-stripe h-14 flex flex-row justify-center"></div>
                <div :class="{'border-t': rounded}" class="info-box-text-container p-5 sm:p-10 border-0">
                    <p>
                        <template v-for="{ value = null, covid = false, definition = false } in content">

                                <span v-if="value" v-html="value"></span>

                                <!-- Use <CovidIcon /> to render COVID icon -->
                                <CovidIcon v-if="covid" />

                                <!-- <def>term</def> will display bolded as a defined term. -->
                                <a v-if="definition"
                                    v-html="definition"
                                    href="#"
                                    @click.prevent="toggleDefinition"
                                    class="definition underline underline-offset-1"></a>

                        </template>
                    </p>

                    <div :aria-hidden="showDefinitionOnScreenReaders ? 'false' : 'true'" class="definition-container text-left text-base pt-0 m-0 h-0 opacity-0 transition-all duration-200">
                        <p class="mb-2" v-for="definition in definitions" v-html="definition" :key="definition"></p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, computed, ref} from 'vue';

import CovidIcon from './CovidIcon'

export default defineComponent({
    name: 'InformationBox',
    components: {
        CovidIcon,
    },
    props: {
        title: {
            type: String,
            required: false,
            default: '',
        },
        info: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: false,
        },
        iconClasses: {
            type: Array,
            default: () => ['-top-[84px]'],
        },
        color: {
            type: String,
            required: true,
        },
        bgColor: {
            type: String,
            default: 'bg-[#f3f4f6]',
        },
        rounded: {
            type: Boolean,
            default: true,
        },
        round: {
            type: String,
            required: false,
            default: 'left',
            validator (value) {
                return ['left', 'right', 'none'].includes(value);
            }
        },
        shadow: {
            type: Boolean,
            default: true,
        },
        definitions: {
            type: Array,
            required: false,
            default: [],
        }
    },

    setup(props) {
        const showDefinitionOnScreenReaders = ref(false)

        const toggleDefinition = (el) => {
            const container = el.target.closest('.info-box-text-container')

            const isVisible = container.classList.contains('definition-visible')

            isVisible
                ? container.classList.remove('definition-visible')
                : container.classList.add('definition-visible')

            showDefinitionOnScreenReaders.value = !isVisible

            return false
        }

        const content = computed(() => {
            return props.info
                .replaceAll('<CovidIcon />', '|<CovidIcon />|')
                .replaceAll(new RegExp(/<def>(.*?)<\/def>/,'g'),'|<def>$1|')
                .split('|')
                .filter((blank) => blank.length)
                .map((value) => value.indexOf('<CovidIcon />') > -1
                    ? { covid:true }
                    : value.indexOf('<def>') > -1
                        ? { definition:value.replace('<def>','') }
                        : { value:value }
                )
        })

        const shadowClass = (!props.shadow)
            ? ''
            : (props.round == 'left' ? 'shadow-angle-left' : 'shadow-angle-right');

        const infoBoxTextBoxClasses = [
            props.color,
            shadowClass,
            props.rounded ? ( props.round == 'left' ? 'rounded-tl-[60px]' : 'rounded-tr-[60px]' ) : '',
            props.bgColor,
        ];

        return {
            content,
            shadowClass,
            infoBoxTextBoxClasses,
            showDefinitionOnScreenReaders,
            toggleDefinition,
        }
    },
});
</script>

<style lang="scss">
    .information-box-container .info-box-text-box {

        .info-box-text-container > p {
            a.definition, b, strong {
                @apply font-bold text-2xl sm:text-4xl;
            }
        }

        .definition-visible .definition-container {
            @apply text-left text-base text-gray-800 border-t border-t-gray-400 h-full mt-4 pt-4 opacity-100 transition-all duration-200;

            b, strong {
                @apply text-lg text-black;
            }
        }

        &.gray-purple, &.gray-purple .info-box-text-container,
        &.purple, &.purple .info-box-text-container  {

            border-color: #5a1769;

            > p {
                a.definition, b, strong {
                    color: #5a1769;
                }
            }
        }

        &.purple {
            &.shadow-angle-left {
                @apply shadow-[15px_15px_#926d9c];
            }
            &.shadow-angle-right {
                @apply shadow-[-15px_15px_#926d9c];
            }
        }

        &.gray-orange, &.gray-orange .info-box-text-container,
        &.orange, &.orange .info-box-text-container  {

            border-color: #b96a15;

            > p {
                a.definition, b, strong {
                    color: #b96a16;
                }
            }
        }

        &.orange {
            &.shadow-angle-left {
                @apply shadow-[15px_15px_#fbd1a4];
            }
            &.shadow-angle-right {
                @apply shadow-[-15px_15px_#fbd1a4];
            }
        }

        &.gray-blue, &.gray-blue .info-box-text-container,
        &.blue, &.blue .info-box-text-container  {

            border-color: #2f8ccb;

            > p {
                a.definition, b, strong {
                    color: #226b98;
                }
            }
        }

        &.blue {
            &.shadow-angle-left {
                @apply shadow-[15px_15px_#a4d1ea];
            }
            &.shadow-angle-right {
                @apply shadow-[-15px_15px_#a4d1ea];
            }
        }

        &.gray-green, &.gray-green .info-box-text-container,
        &.green, &.green .info-box-text-container  {

            border-color: #556628;

            > p {
                a.definition, b, strong {
                    color: #556628;
                }
            }
        }

        &.green {
            &.shadow-angle-left {
                @apply shadow-[15px_15px_#cbdf93];
            }
            &.shadow-angle-right {
                @apply shadow-[-15px_15px_#cbdf93];
            }
        }
    }
</style>
