<template>
    <div class="relative w-full h-full max-w-screen-xl p-3 mx-auto blog-index bg-primary md:p-10 xl:rounded-xl xl:mb-10">
        <AppHead :title="title">
            <meta head-key="description" name="description" :content="title"/>
        </AppHead>

        <EditButton :url="edit_url"/>

        <h1 v-if="title" class="block pb-4 text-5xl font-extrabold leading-loose tracking-tight text-left text-white main-title tk-museo sm:text-5xl">
            {{ title }}
        </h1>

        <div class="relative p-2 mt-4 bg-white rounded-xl md:p-8">
            <div class="px-3 mt-3 lg:px-0">
                <CardGrid class="z-10 px-3">
                    <div class="col-span-1">
                        <Popover v-slot="{ open, close }">
                            <div class="relative w-full mb-2 font-bold text-gray-900 text-md">
                                <div>
                                    Category
                                </div>
                                <PopoverButton
                                    class="relative inline-flex items-center justify-between w-full p-2 text-base font-medium text-gray-800 bg-white border group hover:border-gray-800 focus:outline-none focus:ring-0"
                                    :class="{'border-gray-800': open, 'border-gray-500': !open}">
                                    <span>
                                        {{ category === '' ? 'All categories' : categories[category] }}
                                    </span>
                                    <Icon :icon="mdiChevronDown"
                                          class="w-5 h-5 text-gray-600 transition transition-transform"
                                          :class="{'transform rotate-180': open}"
                                          aria-label="Arrow down icon"
                                    />
                                </PopoverButton>
                                <PopoverPanel v-show="open" static
                                              class="absolute w-full z-10 left-0 top-[100%] -mt-[1px] border border-gray-800">
                                    <div
                                        class="relative w-full overflow-hidden bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                        <div @click="selectCategory('', close)" class="p-3 cursor-pointer"
                                             :class="{'bg-white text-gray-600 hover:text-gray-800 hover:bg-gray-100': category !== '*', 'bg-secondary-50 text-secondary': category === '*'}">
                                            All categories
                                        </div>
                                        <template v-for="(catLabel, catKey) in categories">
                                            <div
                                                @click="selectCategory(catKey, close)"
                                                class="p-3 cursor-pointer"
                                                :class="{'bg-white text-gray-600 hover:text-gray-800 hover:bg-gray-100': category !== catKey, 'bg-secondary-50 text-secondary': category === catKey}">
                                                {{ catLabel }}
                                            </div>
                                        </template>
                                    </div>
                                </PopoverPanel>
                            </div>
                        </Popover>
                    </div>
                    <div class="col-span-1">
                        <Popover v-slot="{ open, close }">
                            <div class="relative w-full mb-2 font-bold text-gray-900 text-md">
                                <div>
                                    Date
                                </div>
                                <PopoverButton
                                    class="relative inline-flex items-center justify-between w-full p-2 text-base font-medium text-gray-800 bg-white border group hover:border-gray-800 focus:outline-none focus:ring-0"
                                    :class="{'border-gray-800': open, 'border-gray-500': !open}">
                            <span>
                                {{ date === '' ? 'All' : dates[date] }}
                            </span>
                                    <Icon :icon="mdiChevronDown"
                                          class="w-5 h-5 text-gray-600 transition transition-transform"
                                          :class="{'transform rotate-180': open}"
                                          aria-label="Arrow down icon"
                                    />
                                </PopoverButton>
                                <PopoverPanel v-show="open" static
                                              class="absolute w-full z-10 left-0 top-[100%] -mt-[1px] border border-gray-800">
                                    <div
                                        class="relative w-full overflow-hidden bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                        <div @click="selectDate('', close)" class="p-3 cursor-pointer"
                                             :class="{'bg-white text-gray-600 hover:text-gray-800 hover:bg-gray-100': date !== '', 'bg-secondary-50 text-secondary': date === ''}">
                                            All
                                        </div>
                                        <template v-for="(label, key) in dates">
                                            <div
                                                @click="selectDate(key, close)"
                                                class="p-3 cursor-pointer"
                                                :class="{'bg-white text-gray-600 hover:text-gray-800 hover:bg-gray-100': date !== key, 'bg-secondary-50 text-secondary': date === key}">
                                                {{ label }}
                                            </div>
                                        </template>
                                    </div>
                                </PopoverPanel>
                            </div>
                        </Popover>
                    </div>
                </CardGrid>

                <Tabs class="z-0" :items="tabs"/>
            </div>
        </div>
    </div>
</template>

<script>
import {Popover, PopoverButton, PopoverPanel} from "@headlessui/vue";
import {router} from "@inertiajs/vue3";
import {mdiChevronDown} from "@mdi/js";
import {defineComponent, ref} from 'vue';
import AppHead from "../components/AppHead";
import CardGrid from "../components/CardGrid";
import EditButton from "../components/EditButton";
import Icon from "../components/Icon";
import Tabs from "../components/Tabs";

export default defineComponent({
    name: 'BlogIndex',
    components: {
        Tabs,
        Icon,
        AppHead,
        Popover,
        CardGrid,
        EditButton,
        PopoverPanel,
        PopoverButton,
    },
    props: {
        tabs: {type: Array, required: true},
        title: {type: String, required: true},
        dates: {type: Object, required: true},
        categories: {type: Object, required: true},
        edit_url: {type: [String, null], default: null},
        filters: {
            type: Object,
            default: () => ({
                category: null,
                date: null,
            })
        }
    },

    setup(props) {
        const isReloading = ref(false);
        const category = ref(props.filters.category);
        const date = ref(props.filters.date);

        function selectCategory(value, close) {
            category.value = value;
            close();
            search();
        }

        function selectDate(value, close) {
            date.value = value;
            close();
            search();
        }

        function search() {
            if (isReloading.value) {
                return;
            }

            isReloading.value = true;
            const payload = {};

            if (category.value) {
                payload.category = category.value;
            }

            if (date.value) {
                payload.date = date.value;
            }

            router.get(
                `${location.protocol}//${location.host}${location.pathname}`,
                payload,
            )
        }

        return {
            date,
            category,
            isReloading,
            mdiChevronDown,

            // methods...
            search,
            selectDate,
            selectCategory,
        }
    }
});
</script>
