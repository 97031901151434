<template>
    <div class="box__wrapper flex justify-center">
        <div class="relative inline-block bg-gray-100 border box" :class="containerClasses">
            <div v-if="! hideHeader" class="box__header flex justify-center items-center h-12 border-b lg:h-16">
                <slot name="header">
                    <img v-if="icon"
                         :src="icon"
                         alt=""
                         class="aspect-square aspect-[1/1]"
                         :class="iconClasses"
                         role="img"
                    />
                    <span v-if="title" v-html="title"></span>
                </slot>
            </div>
            <div class="overflow-hidden transition-[max-height] duration-300">
                <div ref="boxText" class="box__text" :class="textBoxClass">
                    <slot name="content">
                        <div v-html="content.text"></div>
                    </slot>
                </div>
                <div class="overflow-hidden">
                    <div v-show="definition" ref="boxDefinition"
                         class="p-4 border-t border-dashed border-gray-200 lg:p-8">
                        <div class="text-xs text-gray-600 lg:text-sm">
                            <strong>{{ definition?.title }}</strong>: {{ definition?.text }}
                        </div>
                        <div class="text-right mt-2 text-xs text-gray-600 lg:text-sm">
                            <span @click="hideDefinition" class="underline cursor-pointer">
                                Close
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {computed, defineComponent, nextTick, onMounted, onUnmounted, ref, watch} from 'vue';
import useTranslations from "../translations";

export default defineComponent({
    name: 'InfoBox',

    props: {
        title: {
            type: String,
            required: false
        },
        content: {
            type: Object,
            required: false,
        },
        icon: {
            type: String,
            required: false,
        },
        iconClasses: {
            type: String,
            default: 'mb-[32px] h-[6rem] lg:mb-[64px] lg:h-[10rem]',
        },
        color: {
            type: String,
            required: false,
        },
        halign: {
            type: String,
            required: true,
            validator(value) {
                return ['left', 'right'].includes(value);
            }
        },
        widthClass: {
            type: String,
            required: false,
            default: 'min-w-[290px] w-[290px]',
        },
        textBoxClass: {
            type: String,
            required: false,
            default: () => 'text-center',
        },
        hideHeader: {
            type: Boolean,
            default: false,
        }
    },

    setup(props) {
        const {onLocaleChange} = useTranslations();

        const definition = ref();
        const boxText = ref();

        const containerClasses = computed(() => {
            const classes = [
                props.widthClass,
                `box--${props.color}`,
            ];

            if (!props.hideHeader) {
                classes.push(`box--${props.halign}`);
            }

            return classes;
        });

        watch(() => props.content, () => hideDefinition());

        onLocaleChange(() => {
            unmountDefinitionsListeners();
            nextTick(() => {
                mountDefinitionsListeners()
            });
        });

        onMounted(() => mountDefinitionsListeners());
        onUnmounted(() => unmountDefinitionsListeners());

        function mountDefinitionsListeners() {
            boxText.value.querySelectorAll('[data-def]').forEach((el) => {
                el.addEventListener('click', viewDefinition);
            });
        }

        function unmountDefinitionsListeners() {
            boxText.value.querySelectorAll('[data-def]').forEach((el) => {
                el.removeEventListener('click', viewDefinition);
            });
        }

        function viewDefinition(event) {
            const key = parseInt(event.target.dataset.def);

            if (props.content?.definitions[key]) {
                definition.value = props.content.definitions[key];
            }
        }

        function hideDefinition() {
            definition.value = null;
        }

        return {
            boxText,
            definition,
            containerClasses,
            hideDefinition,
        }
    },
});
</script>

<style scoped>
.box--qol1 {
    --shadow-color: theme('colors.qol1.infoBoxShadow');
    --border-color: theme('colors.qol1.infoBoxBorder');
    --main-color: theme('colors.qol1.infoBoxText');
}

.box--qol1-full {
    --shadow-color: theme('colors.qol1-full.infoBoxShadow');
    --border-color: theme('colors.qol1-full.infoBoxBorder');
    --main-color: theme('colors.qol1-full.infoBoxText');
}

.box--qol2 {
    --shadow-color: theme('colors.qol2.infoBoxShadow');
    --border-color: theme('colors.qol2.infoBoxBorder');
    --main-color: theme('colors.qol2.infoBoxText');
}

.box--qol2-full {
    --shadow-color: theme('colors.qol2-full.infoBoxShadow');
    --border-color: theme('colors.qol2-full.infoBoxBorder');
    --main-color: theme('colors.qol2-full.infoBoxText');
}

.box--qol3 {
    --shadow-color: theme('colors.qol3.infoBoxShadow');
    --border-color: theme('colors.qol3.infoBoxBorder');
    --main-color: theme('colors.qol3.infoBoxText');
}

.box--qol3-full {
    --shadow-color: theme('colors.qol3-full.infoBoxShadow');
    --border-color: theme('colors.qol3-full.infoBoxBorder');
    --main-color: theme('colors.qol3-full.infoBoxText');
}

.box--qol4 {
    --shadow-color: theme('colors.qol4.infoBoxShadow');
    --border-color: theme('colors.qol4.infoBoxBorder');
    --main-color: theme('colors.qol4.infoBoxText');
}

.box--qol4-full {
    --shadow-color: theme('colors.qol4-full.infoBoxShadow');
    --border-color: theme('colors.qol4-full.infoBoxBorder');
    --main-color: theme('colors.qol4-full.infoBoxText');
}

.box__wrapper {
    @apply p-2;
}

.box--left {
    border-top-left-radius: 2rem;
    box-shadow: 0.5rem 0.5rem var(--shadow-color);
}

.box--right {
    border-top-right-radius: 2rem;
    box-shadow: -0.5rem 0.5rem var(--shadow-color);
}

@screen lg {
    .box--left {
        border-top-left-radius: 4rem;
        box-shadow: 1rem 1rem var(--shadow-color);
    }

    .box--right {
        border-top-right-radius: 4rem;
        box-shadow: -1rem 1rem var(--shadow-color);
    }
}

.box__text {
    @apply text-gray-800 py-2 px-3 text-xs lg:py-8 lg:px-12 lg:text-2xl;
}

.box__text:deep(strong) {
    @apply text-base font-bold lg:text-4xl;
    color: var(--main-color);
}

.box__text:deep([data-def]) {
    @apply cursor-pointer underline decoration-4 underline-offset-2;
    text-decoration-color: var(--main-color);
}

.box {
    border-color: var(--border-color);
    transition: max-height 200ms;
}

.box__header {
    border-color: var(--border-color);
}
</style>
