<template>
    <div class="relative w-full">
        <!-- title -->
        <div class="flex flex-col justify-around text-center bg-[#1A5581] text-white max-w-full overflow-hidden">

            <!-- Language switch -->
            <div id="language-switch" class="top-0 right-[3rem] z-[9] absolute inline-block">
                <button
                    lang="en"
                    type="button"
                    @click.prevent.stop="selectEnglishLocale"
                    class="p-2 text-sm font-normal uppercase focus:ring-2 focus:ring-inset"
                    :class="[
                        locale === 'English' ? 'bg-primary-800 text-white focus:ring-white' : 'bg-white bg-opacity-90 text-gray-700 hover:bg-opacity-100 focus:ring-primary-500'
                    ]"
                >
                    English
                </button>
                <button
                    lang="es"
                    type="button"
                    @click.prevent.stop="selectSpanishLocale"
                    class="p-2 text-sm font-normal uppercase focus:ring-2 focus:ring-inset"
                    :class="[
                        locale !== 'English' ? 'bg-primary-800 text-white focus:ring-white' : 'bg-white bg-opacity-90 text-gray-700 hover:bg-opacity-100 focus:ring-primary-500',
                    ]"
                >
                    Español
                </button>
            </div>

            <h1 class="font-museo uppercase text-4xl sm:text-5xl lg:text-7xl p-10 mt-4 z-[5]">{{ title }}</h1>

            <div class="flex flex-grow overflow-visible z-[4]" v-if="animation">
                <object
                    role="presentation"
                    class="h-full -mt-48 -mx-60 sm:-mt-44 md:-mt-48 lg:-mt-64 xl:-mt-80 md:-mx-20 lg:-mx-0 min-h-[510px] sm:min-h-[410px]"
                    data="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/qol-header-animation.svg"></object>
            </div>
        </div>

        <Introduction
            v-if="introduction.introduction"
            :introduction="introduction.introduction"
            :topics="introduction.topics"
            :covid="introduction.covid"
        />

    </div>
</template>

<script>
import { defineComponent } from 'vue'
import useTranslations from "../translations";

import Introduction from './Introduction'

export default defineComponent({
    name: 'Header',
    components: {
        Introduction
    },
    props: {
        introduction: {
            type: Object,
            required: false,
            default: {},
        },

        animation: {
            type: Boolean,
            default: true,
        },

        title: {
            type: String,
            required: true,
        },
    },
    setup() {
        const { locale, selectEnglishLocale, selectSpanishLocale } = useTranslations();

        return {
            locale,
            selectEnglishLocale,
            selectSpanishLocale,
        }
    },
});
</script>
