<template>
    <div class="main-container font-museo-sans max-w-[1536px] m-auto 2xl:border-x-2 overflow-hidden">
        <Header
            :title="title"
            :animation="false"
        />

        <!-- Gray/Purple -->
        <CostOfCongestion/>
        <EvolvingTravelPatterns/>
        <VehicleMilesTraveled/>

        <!-- Purple -->
        <RiderBoardings/>
        <FrequentServiceNetwork/>
        <AlternativeTransitModes/>
        <RidershipTrends/>
        <OnTimePerformance/>

        <!-- Gray/Orange -->
        <RegionalPopulation/>
        <RegionalEmployment/>
        <RegionalHousing/>

        <!-- Orange -->
        <LocalInfrastructureImprovements/>
        <EconomicInvestment/>
        <AccessToJobs/>

        <!-- Gray/Blue -->
        <AffordabilityOfTransportation/>
        <BikeAndPedestrianCollisions/>
        <RegionalTransportationTaxBreakdown/>

        <!-- Blue -->
        <RiderSurveyResults/>
        <RiderSurveyResults2/>
        <RiderImprovements/>
        <VehicleOwnershipCost/>
        <BikeAndPedestrianCollisionSafetyInvestment/>
        <SafetyOfTransit/>


        <!-- Gray/Green -->
        <RegionalHeatImpact/>
        <RegionalEmissions/>
        <RegionalAirQuality/>

        <!-- Green -->
        <EmissionsSaved/>
        <CombatingClimateChangeAndHeat/>
        <TransitStopFeatures/>
        <WalkableAccessInRailCorridor/>
        <ModeShareByCorridor/>

    </div>
</template>

<script>
import {defineComponent, computed} from 'vue'
import AppHead from "../components/AppHead";

import Translate from './QOL/Components/Translate'
import Header from './QOL/Components/Header'

// gray-purple
import CostOfCongestion from './QOL/Page2/CostOfCongestion'
import EvolvingTravelPatterns from './QOL/Page2/EvolvingTravelPatterns'
import VehicleMilesTraveled from './QOL/Page2/VehicleMilesTraveled'

// purple
import RiderBoardings from './QOL/Page2/RiderBoardings'
import FrequentServiceNetwork from './QOL/Page2/FrequentServiceNetwork'
import AlternativeTransitModes from './QOL/Page2/AlternativeTransitModes'
import RidershipTrends from './QOL/Page2/RidershipTrends'
import OnTimePerformance from './QOL/Page2/OnTimePerformance'

// gray/orange
import RegionalPopulation from './QOL/Page2/RegionalPopulation'
import RegionalHousing from './QOL/Page2/RegionalHousing'
import RegionalEmployment from './QOL/Page2/RegionalEmployment'

// orange
import LocalInfrastructureImprovements from './QOL/Page2/LocalInfrastructureImprovements'
import EconomicInvestment from './QOL/Page2/EconomicInvestment'

// gray-blue
import AffordabilityOfTransportation from './QOL/Page2/AffordabilityOfTransportation'
import BikeAndPedestrianCollisions from './QOL/Page2/BikeAndPedestrianCollisions'
import BikeAndPedestrianCollisionSafetyInvestment from "./QOL/Page2/BikeAndPedestrianCollisionSafetyInvestment";
import RegionalTransportationTaxBreakdown from './QOL/Page2/RegionalTransportationTaxBreakdown'

// blue
import RiderSurveyResults from './QOL/Page2/RiderSurveyResults'
import RiderSurveyResults2 from './QOL/Page2/RiderSurveyResults2'
import RiderImprovements from './QOL/Page2/RiderImprovements'
import VehicleOwnershipCost from './QOL/Page2/VehicleOwnershipCost'
import AccessToJobs from './QOL/Page2/AccessToJobs'
import SafetyOfTransit from './QOL/Page2/SafetyOfTransit'

// gray-green
import RegionalHeatImpact from './QOL/Page2/RegionalHeatImpact'
import RegionalEmissions from './QOL/Page2/RegionalEmissions'
import RegionalAirQuality from "./QOL/Page2/RegionalAirQuality";

// green
import EmissionsSaved from "./QOL/Page2/EmissionsSaved";
import CombatingClimateChangeAndHeat from './QOL/Page2/CombatingClimateChangeAndHeat'
import TransitStopFeatures from './QOL/Page2/TransitStopFeatures'
import WalkableAccessInRailCorridor from './QOL/Page2/WalkableAccessInRailCorridor'
import ModeShareByCorridor from './QOL/Page2/ModeShareByCorridor'
import useTranslations from "./QOL/translations";

export default defineComponent({
    components: {
        Translate,

        Header,

        EvolvingTravelPatterns,
        CostOfCongestion,
        VehicleMilesTraveled,

        RiderBoardings,
        FrequentServiceNetwork,
        AlternativeTransitModes,
        RidershipTrends,
        OnTimePerformance,

        RegionalPopulation,
        RegionalHousing,
        RegionalEmployment,

        LocalInfrastructureImprovements,
        EconomicInvestment,

        AffordabilityOfTransportation,
        BikeAndPedestrianCollisions,
        BikeAndPedestrianCollisionSafetyInvestment,
        RegionalTransportationTaxBreakdown,

        RiderSurveyResults,
        RiderSurveyResults2,
        RiderImprovements,
        VehicleOwnershipCost,
        AccessToJobs,
        SafetyOfTransit,

        RegionalHeatImpact,
        RegionalEmissions,
        RegionalAirQuality,

        EmissionsSaved,
        CombatingClimateChangeAndHeat,
        TransitStopFeatures,
        WalkableAccessInRailCorridor,
        ModeShareByCorridor,

    },
    setup() {
        const {translate} = useTranslations()

        const headTitle = computed(() => {
            document.title = translate.value(
                'Quality of Life 2022 Details - Valley Metro',
                'Detalles del Estudio de la Calidad de Vida de 2022 - Valley Metro',
            )

            return document.title
        })

        const title = computed(() => translate.value(
            'QUALITY OF LIFE STUDY 2022',
            'ESTUDIO DE LA CALIDAD DE VIDA DE 2022',
        ))

        return {
            title,
            headTitle,
        }
    }
})
</script>

<style scoped>

:deep(.box__wrapper) {
    max-width: 100%;
}

:deep(.box) {
    max-width: 100%;
}

:deep(.box__text strong) {
    @apply text-2xl;
}

@media (min-width: theme('screens.md')) {
    :deep(.box__text strong) {
        @apply text-3xl;
    }
}

:deep(.box__text) {
    @apply p-3 text-base;
}

</style>
