<template>
    <div class="inline-flex items-center justify-center px-2 h-5 text-sm text-primary-50 font-normal bg-primary rounded min-w-[3rem]">
        {{ label }}
    </div>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'Tag',

    props: {
        label: { type: String, required: true },
    },

    setup() {
        return {}
    },
});
</script>
