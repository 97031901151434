<template>
    <div class="main-container font-museo-sans max-w-[1536px] m-auto 2xl:border-x-2">
        <Header
            :title="title"
            :introduction="introduction"
        />

        <div v-for="(section, index) in sections" :key="index">
            <ContentSection :section="section" :backToTop="backToTop"/>
        </div>
    </div>
</template>

<script>
import {computed, defineComponent} from 'vue'
import ContentSection from "./QOL/Components/ContentSection"

import Header from "./QOL/Components/Header"
import Translate from './QOL/Components/Translate'
import useTranslations from "./QOL/translations";

export default defineComponent({
    name: 'QualityOfLife',
    components: {
        Header,
        ContentSection,
        Translate,
    },
    setup() {
        window.name = "qol-page-1"

        const {translate} = useTranslations();

        const headTitle = computed(() => {
            document.title = translate.value(
                'Quality of Life 2022 Details - Valley Metro',
                'Detalles del Estudio de la Calidad de Vida de 2022 - Valley Metro',
            );

            return document.title
        })

        const title = computed(() => translate.value(
            'QUALITY OF LIFE STUDY 2022',
            'ESTUDIO DE LA CALIDAD DE VIDA DE 2022',
        ))

        const introduction = computed(() => {
            return {
                topics: [
                    {
                        title: translate.value('Responding to the Valley\'s Needs', 'Respondiendo a las Necesidades del Valle'),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Responding-Needs.svg',
                        classes: 'border-qol1-topicBorder bg-qol1-topicBg',
                        link: 'responding-to-valley-needs',
                    },
                    {
                        title: translate.value('Improving Communities through Transit', 'Mejorando Comunidades a Través del Transporte'),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Improving-Communities.svg',
                        classes: 'border-qol2-topicBorder bg-qol2-topicBg',
                        link: 'improving-communities-through-transit',
                    },
                    {
                        title: translate.value('Prioritizing Riders', 'Priorizando a los Pasajeros'),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Prioritizing-Riders.svg',
                        classes: 'border-qol3-topicBorder bg-qol3-topicBg',
                        link: 'prioritizing-riders',
                    },
                    {
                        title: translate.value('Benefiting the Valley', 'Beneficiando al Valle'),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Benefiting-Valley.svg',
                        classes: 'border-qol4-topicBorder bg-qol4-topicBg',
                        link: 'benefiting-the-valley',
                    },
                ],

                introduction: translate.value(
                    'With a mission of connecting communities and enhancing lives, Valley Metro<sup>®</sup> serves the fastest growing region in the United States. Providing safe and reliable public transportation helps move people, while also supporting the local economy, healthy lifestyles and community well-being. The Quality of Life Study benchmarks the impact of our region’s transit investment and how it has benefitted overall mobility. This report, updated every three to five years, shares regional data followed by Valley Metro’s contributions.',
                    'Con la misión de conectar a las comunidades y mejorar vidas, Valley Metro<sup>®</sup> provee servicio a la región de más rápido crecimiento en los Estados Unidos. Proporcionar transporte público seguro y confiable ayuda a movilizar a las personas, mientras que apoya la economía local, los estilos de vida saludables, y el bienestar de la comunidad. El Estudio de la Calidad de Vida es un indicador del impacto de la inversión en el transporte en nuestra región, y cómo ha beneficiado a la movilidad en general. Este reporte, el cual es actualizado cada tres a cinco años, comparte datos regionales seguidos por las contribuciones de Valley Metro.',
                ),

                covid: translate.value(
                    'Indicates data impacted by the COVID-19 pandemic.',
                    'Indica los datos impactados por la pandemia del COVID-19.',
                )
            }
        });

        const sections = computed(() => [
            {
                id: introduction.value.topics[0].link,
                color: 'gray-purple',
                direction: 'left',

                title: translate.value('Responding to the Valley\'s Needs', 'Respondiendo a las Necesidades del Valle'),
                heading: translate.value('What\'s happening in the region', 'Qué está pasando en la región'),
                subheading: null,

                sectionClasses: [],

                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Responding-Needs.svg',

                backgroundImage: {
                    image: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/cities/qol-isometric-city-phoenix.svg',
                    classes: [
                        'min-w-[1850px]',
                        '-ml-[200px]',
                        '-mt-[140px]',
                        'md:ml-[150px]',
                        'md:mt-[140px]',
                        'md:min-w-[2000px]',
                    ],
                },

                infoboxContainerClasses: [
                    'bg-[url(https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/dashed-lines/phoenix-1.svg)]',
                    'bg-[left_61px_top_-5px]',
                    'sm:bg-[left_132px_top_-5px]',
                    'lg:bg-[left_215px_top_-5px]',
                    'bg-[length:916px]',
                    'sm:bg-[length:1325px]',
                ],
                infoBoxes: [
                    {
                        text: translate.value(
                            'Drivers spent <b>$1.5B</b> and <b>68M hours</b> in congestion in 2020<CovidIcon />',
                            'En 2020 los conductores gastaron <b>$1.5 mil millones de dólares</b> y <b>68 millones de horas</b> en congestionamientos<CovidIcon />'
                        ),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/QOL_information-shape_purple_congestion.svg',
                    },
                    {
                        text: translate.value(
                            'Over <b>65K jobs</b> added in Phoenix from 2009-2017 while job growth also boomed in outlying cities',
                            'De 2009 a 2017 se agregaron más de <b>65 mil empleos</b> en Phoenix y el crecimiento de empleos también vio un auge en las ciudades periféricas'
                        ),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/business-couple.svg',
                        classes: ['xl:-ml-[80px]'],
                    },
                    {
                        text: translate.value(
                            'Daily <def>Vehicle Miles Traveled</def> increased by <b>63%</b> between 2000 and 2019',
                            'Entre 2000 y 2019 <b>las millas vehiculares recorridas</b> diariamente aumentaron en un <b>63%</b>',
                        ),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/QOL_information-shape_blue_income-on-transportation.svg',
                        definitions: [
                            translate.value(
                                '<b>Vehicle Miles Traveled:</b> The total number of miles driven by all vehicles in metro Phoenix in a given year. One vehicle traveling one mile is one vehicle mile.',
                                '<b>Millas Vehiculares Viajadas:</b> El número total de millas recorridas por todos los vehículos en el área metropolitana de Phoenix en un año determinado. Un vehículo que viaja una milla es una milla vehicular.',
                            ),
                        ],
                    },
                ],

                buttonClasses: ['sm:-mt-32', 'md:-mt-52'],
                buttons: [
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_cost-congestion.svg',
                        text: translate.value('Cost of Congestion', 'Costo del Congestionamiento'),
                        href: 'cost-of-congestion',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_evolving-travel-patterns.svg',
                        text: translate.value('Evolving Travel Patterns', 'Patrones Evolventes de Viajes'),
                        href: 'evolving-travel-patterns',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_vehicle-miles.svg',
                        text: translate.value('Vehicle Miles Traveled', 'Millas Vehiculares Recorridas'),
                        href: 'vehicle-miles-traveled',
                    }
                ],

                bumps: [
                    {
                        image: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/grey-sides/grey-1.svg',
                        classes: [
                            'absolute',
                            'right-0',
                            'top-[580px]',
                            'sm:hidden',
                            'md:block',
                            'md:top-0',
                        ]
                    },
                    {
                        image: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/grey-sides/grey-2.svg',
                        classes: [
                            'absolute',
                            'left-0',
                            'top-[1400px]',
                            'sm:top-[1000px]',
                        ]
                    },
                    {
                        image: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/grey-sides/grey-2.svg',
                        classes: [
                            'absolute',
                            'left-0',
                            'bottom-[500px]',
                            'lg:hidden',
                        ]
                    },
                    {
                        image: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/grey-sides/grey-3.svg',
                        classes: [
                            'absolute',
                            'right-0',
                            'bottom-[-100px]',
                            'sm:-bottom-[200px]',
                            'xl:-bottom-[380px]'
                        ]
                    },
                ],
            },
            {
                id: 'how-valley-metro-contributes-to-the-region-response',
                color: 'purple',
                direction: 'right',

                title: translate.value('How Valley Metro contributes to the region', 'Cómo contribuye Valley Metro a la región'),
                heading: translate.value('Responding to the Valley\'s Needs', 'Respondiendo a las Necesidades del Valle'),
                subheading: null,

                addBackToTopButton: true,

                sectionClasses: [],

                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Responding-Needs.svg',

                backgroundImage: {
                    image: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/cities/qol-isometric-city-phoenix.svg',
                    classes: [
                        'min-w-[1850px]',
                        '-ml-[1150px]',
                        '-mt-[160px]',
                        '-top-[200px]',
                        '-right-[190px]',
                        'sm:absolute',
                        'sm:top-[150px]',
                        'sm:right-[275px]',
                        'md:right-[330px]',
                        'xl:right-[380px]',
                        'sm:min-w-[2000px]',
                        'md:min-w-[2400px]',
                    ],
                },

                infoboxContainerClasses: [
                    'bg-[url(https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/dashed-lines/phoenix-2.svg)]',
                    'bg-[right_0px_top_-5px]',
                    'sm:bg-[right_  0px_top_-5px]',
                    'md:bg-[right_15px_top_-5px]',
                    'xl:bg-[right_145px_top_-5px]',
                    'bg-[length:1200px]',
                    'sm:bg-[length:1375px]',
                    'md:bg-[length:1750px]',
                    'mb-12',
                    'md:mb-24',
                ],
                infoBoxes: [
                    {
                        text: translate.value(
                            'Valley Metro continues to <b>connect communities</b> by providing a <b>high level of service</b> for <def>essential workers</def><CovidIcon />',
                            'Valley Metro continúa <b>conectando a las comunidades</b> al proveer un <b>alto nivel de servicio</b> para los <def>trabajadores esenciales</def><CovidIcon />'
                        ),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/QOL_information-shape_purple_connect-communities.svg',
                        definitions: [
                            translate.value(
                                '<b>Essential Workers:</b> People that are essential to providing necessary human and community services. Essential trips include any travel that is necessary for one\'s employment, health, education, or other daily needs.',
                                '<b>Trabajadores Esenciales:</b> Las personas que son esenciales para proveer los servicios humanos y comunitarios necesarios. Los viajes esenciales incluyen cualquier viaje que sea necesario para el empleo, la salud, la educación u otras necesidades diarias.'
                            ),
                        ],
                    },
                    {
                        text: translate.value(
                            'More than <b>1M paratransit trips</b> supporting the disability community and <b>50M bus and light rail trips</b> provided annually <CovidIcon />',
                            'Anualmente se proveen más de <b>1 millón de viajes de paratránsito</b> apoyando a la comunidad de discapacitados y <b>50 millones de viajes en autobús y tren ligero</b><CovidIcon />'
                        ),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/QOL_information-shape_purple_paratransit.svg',
                        classes: ['xl:ml-[150px'],
                    },
                    {
                        text: translate.value(
                            '<def>Frequent service</def> <b>routes</b> have higher ridership, but account for just <b>16%</b> of our routes',
                            '<b>Las rutas de servicio frecuente</b> tienen un mayor número de pasajeros, pero ascienden a sólo el 16% de nuestras rutas'
                        ),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/QOL_information-shape_purple_frequent-service.svg',
                        definitions: [
                            translate.value(
                                '<b>Frequent Service:</b> Transit vehicles arriving every 15 minutes or better based on the Valley Metro Transit Standards and Performance Measures.',
                                '<b>Servicio Frecuente:</b> Los vehículos de transporte llegan cada 15 minutos ó más frecuentemente en conformidad con los Estándares de Transporte y las Medidas de Desempeño de Valley Metro.',
                            )
                        ],
                    },
                ],

                buttonClasses: ['sm:-mt-32', 'md:-mt-52'],
                buttons: [
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_rider-boardings.svg',
                        text: translate.value('Rider Boardings', 'Abordajes de Pasajeros'),
                        href: 'rider-boardings',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_frequent-service.svg',
                        text: translate.value('Frequent Service Network', 'Red de Servicio Frecuente'),
                        href: 'frequent-service-network',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_alternative-modes.svg',
                        text: translate.value('Alternative Transit Modes', 'Modos Alternativos de Transporte'),
                        href: 'alternative-transit-modes',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_ridership-trends.svg',
                        text: translate.value('Ridership Trends', 'Tendencias de los Pasajeros'),
                        href: 'ridership-trends',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_on-time-performance.svg',
                        text: translate.value('On-Time Performance', 'Desempeño a Tiempo'),
                        href: 'on-time-performance',
                    }
                ],

                bumps: [
                    {
                        image: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/grey-sides/grey-4.svg',
                        classes: [
                            'absolute',
                            'left-0',
                            'bottom-[190px]',
                            'sm:bottom-[300px]',
                            'md:bottom-[400px]',
                            'md:top-auto',
                        ]
                    },
                    {
                        image: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/grey-sides/grey-5.svg',
                        classes: [
                            'absolute',
                            'right-0',
                            'bottom-[10px]',
                            'sm:bottom-[30px]',
                            'md:bottom-[40px]',
                            'md:top-auto',
                        ]
                    },
                ],
            },
            {
                id: 'improving-communities-through-transit',
                color: 'gray-orange',
                direction: 'left',

                title: translate.value('Improving Communities through Transit', 'Mejorando Comunidades a Través del Transporte'),
                heading: translate.value('What\'s happening in the region', 'Qué está pasando en la región'),

                sectionClasses: [],

                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Improving-Communities.svg',

                backgroundImage: {
                    image: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/cities/qol-isometric-city-tempe.svg',
                    classes: [
                        'min-w-[1850px]',
                        '-ml-[800px]',
                        '-mt-[160px]',
                        'md:ml-[150px]',
                        'md:mt-[220px]',
                        'md:min-w-[2200px]',
                    ],
                },

                infoboxContainerClasses: [
                    'bg-[url(https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/dashed-lines/tempe-1.svg)]',
                    'bg-[left_50px_top_-5px]',
                    'sm:bg-[left_200px_top_-5px]',
                    'bg-[length:1100px]',
                    'sm:bg-[length:1525px]',
                ],
                infoBoxes: [
                    {
                        text: translate.value(
                            'Starting in 2010, population grew by <b>15.8%</b> leading to <b>4.4M</b> total residents by 2020',
                            'La población creció <b>15.8%</b> resultando en un total de <b>4.4 millones</b> de residentes para 2020'
                        ),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/QOL_information-shape_orange_population-growth.svg',
                    },
                    {
                        text: translate.value(
                            'Employment increased by <b>474K</b> jobs between 2010-2020',
                            'Hubo un aumento de <b>474,000</b> empleos entre 2010-2020'
                        ),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/business-couple.svg',
                        classes: [
                            'xl:ml-[-140px]',
                        ],
                    },
                    {
                        text: translate.value(
                            'More than <b>150K</b> units added from 2010-2020 resulting in a <b>9%</b> housing growth',
                            'Se agregaron más de <b>150 mil</b> unidades de 2010-2020 – resultando en un crecimiento del <b>9%</b> en la vivienda'
                        ),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/QOL_information-shape_orange_housing-growth.svg',
                    },
                ],

                buttonClasses: ['sm:-mt-32', 'md:-mt-52'],
                buttons: [
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_regional-population.svg',
                        text: translate.value('Regional Population', 'Población Regional'),
                        href: 'regional-population',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_regional-employment.svg',
                        text: translate.value('Regional Employment', 'Empleo Regional'),
                        href: 'regional-employment',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_regional-housing.svg',
                        text: translate.value('Regional Housing', 'Vivienda Regional'),
                        href: 'regional-housing',
                    }
                ],

                bumps: [
                    {
                        image: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/grey-sides/grey-6.svg',
                        classes: [
                            'absolute',
                            'left-0',
                            'top-[690px]',
                            'sm:top-[900px]',
                            'md:bottom-[700px]',
                            'md:top-auto',
                        ]
                    },
                    {
                        image: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/grey-sides/grey-7.svg',
                        classes: [
                            'absolute',
                            'right-0',
                            'bottom-[50px]',
                            'sm:-bottom-[100px]',
                            'xl:-bottom-[180px]'
                        ]
                    },
                ],
            },
            {
                id: 'how-valley-metro-contributes-to-the-region-improving',
                color: 'orange',
                direction: 'right',

                title: translate.value('How Valley Metro contributes to the region', 'Cómo contribuye Valley Metro a la región'),
                heading: translate.value('Improving Communities through Transit', 'Mejorando Comunidades a Través del Transporte'),
                subheading: null,

                addBackToTopButton: true,

                sectionClasses: [],

                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Improving-Communities.svg',

                backgroundImage: {
                    image: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/cities/qol-isometric-city-tempe.svg',
                    classes: [
                        '-ml-[700px]',
                        '-mt-[160px]',
                        '-top-[200px]',
                        '-right-[190px]',
                        'min-w-[1850px]',
                        'sm:absolute',
                        'sm:top-[300px]',
                        'sm:right-[275px]',
                        'md:right-[300px]',
                        'xl:right-[300px]',
                        'sm:min-w-[2000px]',
                        'md:min-w-[2350px]',
                    ],
                },

                infoboxContainerClasses: [
                    'bg-[url(https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/dashed-lines/tempe-2.svg)]',
                    'mb-12',
                    'md:mb-24',
                    'bg-[right_15px_top_-5px]',
                    'sm:bg-[right_-100px_top_-5px]',
                    'md:bg-[right_15px_top_-5px]',
                    'xl:bg-[right_145px_top_-5px]',
                    'bg-[length:1100px]',
                    'sm:bg-[length:1300px]',
                    'md:bg-[length:1450px]',
                ],
                infoBoxes: [
                    {
                        text: translate.value(
                            '<b>$1.2B</b> <def>non-transit infrastructure</def> <b>improvements</b> improve underground utilities and walkability along light rail corridors since 2006',
                            '<b>$1.2 mil millones de dólares en mejoramientos a la <def>infraestructura</def></b> mejoraron los servicios públicos subterráneos y el tránsito peatonal a lo largo de los corredores del tren ligero desde 2006'
                        ),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/QOL_information-shape_orange_improvements-infrastructures.svg',
                        definitions: [
                            translate.value(
                                '<b>Non-transit Infrastructure:</b> Features including crosswalks, landscaping, lighting, pavement, public art and utilities that are beneficial for both transit and non-transit users.',
                                '<b>Infraestructura Ajena al Transporte:</b> Los elementos incluyen cruces peatonales, jardinería ornamental, alumbrado, pavimento, arte público y servicios públicos que son beneficiosos tanto para los usuarios de transporte público como para los que no lo son.'
                            ),
                        ],
                    },
                    {
                        text: translate.value(
                            '<b>$16B</b> in <b>economic</b> <b>investments</b> in the light rail corridor',
                            '<b>$16 mil millones de dólares en inversiones económicas</b> en el corredor del tren ligero'
                        ),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/QOL_information-shape_orange_economic-investments.svg',
                        classes: 'md:ml-[30px] lg:ml-[55px] xl:ml-[125px]'
                    },
                    {
                        text: translate.value(
                            '<b>589K jobs</b> are located within <b>1/2 mile</b> of our frequent service network',
                            '<b>589.000 empleos</b> se encuentran dentro de <b>1/2 milla</b> de nuestra red de servicio frecuente'
                        ),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/business-couple.svg',
                    },
                ],

                buttonClasses: ['sm:-mt-32', 'md:-mt-52'],
                buttons: [
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_local-infrastructure.svg',
                        text: translate.value('Local Infrastructure Investments', 'Inversiones en Infraestructura Local'),
                        href: 'local-infrastructure-investments',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_transit-oriented-development.svg',
                        text: translate.value('Economic Investment', 'Inversión Económica'),
                        href: 'economic-investment',
                        iconClasses: ['scale-[1.02]']
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_job-creation.svg',
                        text: translate.value('Access to Jobs', 'Acceso a Empleos'),
                        href: 'access-to-jobs',
                    }
                ],

                bumps: [
                    {
                        image: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/grey-sides/grey-8.svg',
                        classes: [
                            'absolute',
                            'left-0',
                            'bottom-[10px]',
                            'sm:bottom-[30px]',
                            'md:bottom-[40px]',
                            'md:top-auto',
                        ]
                    },
                ],
            },
            {
                id: 'prioritizing-riders',
                color: 'gray-blue',
                direction: 'left',

                title: translate.value('Prioritizing Riders', 'Priorizando a los Pasajeros'),
                heading: translate.value('What\'s happening in the region', 'Qué está pasando en la región'),
                subheading: null,

                sectionClasses: [],

                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Prioritizing-Riders.svg',

                backgroundImage: {
                    image: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/cities/qol-isometric-city-mesa.svg',
                    classes: [
                        'min-w-[2200px]',
                        '-ml-[415px]',
                        '-mt-[160px]',
                        'md:ml-[350px]',
                        'md:mt-[175px]',
                    ],
                },

                infoboxContainerClasses: [
                    'bg-[url(https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/dashed-lines/mesa-1.svg)]',
                    'bg-[right_-575px_top_-5px]',
                    'sm:bg-[left_200px_top_-5px]',
                    'bg-[length:950px]',
                    'sm:bg-[length:1325px]',
                ],
                infoBoxes: [
                    {
                        text: translate.value(
                            'Maricopa County residents spend an average of <b>20% of</b> <b>income on</b> <b>transportation</b>. National average is <b>17%</b>',
                            'Los residentes del Condado Maricopa gastan un promedio del <b>20% de sus ingresos en transporte</b>. El promedio nacional es del <b>17%</b>'
                        ),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/cars-2.svg',
                        classes: [
                            'xl:-ml-[145px]'
                        ],
                    },
                    {
                        text: translate.value(
                            '<b>80% of all</b> <b>pedestrian</b> <b>fatalities</b> in Arizona occur in Maricopa County',
                            'El <b>80% de todas las muertes peatonales</b> en Arizona ocurren en el Condado Maricopa'
                        ),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/shopper-crosswalk.svg',
                        iconClasses: ['-top-[98px]'],
                        classes: [
                            'lg:-ml-[50px]',
                            'xl:-ml-[135px]',
                        ],
                    },
                    {
                        text: translate.value(
                            '<b>$571M</b> generated annually by <def><b>Prop. 400</b></def> funds with <b>one-third</b> dedicated to public transit',
                            '<b>$571 millones de dólares</b> son generados anualmente por los fondos de la <def><b>Proposición 400</b></def>, con una <b>tercera parte</b> de los mismos dedicada al transporte público'
                        ),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/blue-pie-chart.svg',
                        definitions: [
                            translate.value(
                                '<b>Prop. 400:</b> In 2004, voters in Maricopa County approved Proposition 400 to extend a 20-year, half-cent sales tax for transportation. The funds are used for freeways, state highways, street improvements and public transportation.',
                                '<b>Proposición 400:</b> En 2004, los votantes en el Condado Maricopa aprobaron la Proposición 400 para extender un impuesto sobre la venta de medio centavo s 20 años para el transporte. Los fondos se usan para autopistas, carreteras estatales, mejoramientos a las calles y transporte público.'
                            ),
                        ],
                    },
                ],

                buttonClasses: ['sm:-mt-32', 'md:-mt-52'],
                buttons: [
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_affordability-transportation.svg',
                        text: translate.value('Affordability of Transportation', 'Asequibilidad del Transporte'),
                        href: 'affordability-of-transportation',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_bike-ped-collisions.svg',
                        text: translate.value('Vehicle Collisions with Bicyclists and Pedestrians', 'Colisiones de Vehículos con Ciclistas y Peatones'),
                        href: 'collisions-involving-cyclists-and-pedestrians',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_tax-breakdown.svg',
                        text: translate.value('Regional Transportation Tax Breakdown', 'Desglose del Impuesto Regional de Transporte'),
                        href: 'regional-transportation-tax-breakdown',
                        iconClasses: ['scale-[1.02]']
                    }
                ],

                bumps: [
                    {
                        image: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/grey-sides/grey-9.svg',
                        classes: [
                            'absolute',
                            'right-0',
                            'top-[20px]',
                            'md:top-0',
                        ]
                    },
                    {
                        image: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/grey-sides/grey-10.svg',
                        classes: [
                            'absolute',
                            'left-0',
                            'top-[690px]',
                            'sm:top-[900px]',
                            'md:bottom-[700px]',
                            'md:top-auto',
                        ]
                    },
                    {
                        image: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/grey-sides/grey-11.svg',
                        classes: [
                            'absolute',
                            'right-0',
                            'bottom-[50px]',
                            'sm:-bottom-[200px]',
                            'xl:-bottom-[380px]'
                        ]
                    },
                ],
            },
            {
                id: 'how-valley-metro-contributes-to-the-region-prioritizing',
                color: 'blue',
                direction: 'right',

                title: translate.value('How Valley Metro contributes to the region', 'Cómo contribuye Valley Metro a la región'),
                heading: translate.value('Prioritizing Riders', 'Priorizando a los Pasajeros'),
                subheading: null,

                addBackToTopButton: true,

                sectionClasses: [],

                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Prioritizing-Riders.svg',

                backgroundImage: {
                    image: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/cities/qol-isometric-city-mesa.svg',
                    classes: [
                        'min-w-[1850px]',
                        '-ml-[1150px]',
                        '-mt-[160px]',
                        '-top-[200px]',
                        '-right-[190px]',
                        'sm:absolute',
                        'sm:top-[250px]',
                        'sm:right-[275px]',
                        'md:right-[250px]',
                        'xl:right-[250px]',
                        'sm:min-w-[2000px]',
                    ],
                },

                infoboxContainerClasses: [
                    'bg-[url(https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/dashed-lines/mesa-2.svg)]',
                    'mb-12',
                    'md:mb-24',
                    'bg-[right_15px_top_-5px]',
                    'sm:bg-[right_-100px_top_-5px]',
                    'md:bg-[right_15px_top_-5px]',
                    'xl:bg-[right_145px_top_-5px]',
                    'bg-[length:1000px]',
                    'sm:bg-[length:1300px]',
                    'md:bg-[length:1550px]',
                ],
                infoBoxes: [
                    {
                        text: translate.value(
                            '<b>Transit vehicle location and real-time tracking</b> is available to riders with the new <b>Valley Metro app</b>',
                            '<b>La ubicación y el seguimiento en tiempo real de los vehículos de transporte</b> están disponibles para los pasajeros con la nueva <b>aplicación de Valley Metro</b>'
                        ),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/QOL_information-shape_blue_vm-app.svg',
                    },
                    {
                        text: translate.value(
                            'Since 2015, light rail station construction included more than <b>$16M</b> in pedestrian/bike access and safety improvements',
                            'Desde 2015, la construcción de las estaciones del tren ligero incluyó más de <b>$16 millones de dólares</b> para el acceso peatonal/ciclista y mejoramientos a la seguridad.'
                        ),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/QOL_information-shape_blue_rail-station.svg',
                        iconClasses: ['h-[12rem]', '-top-[70px]'],
                        classes: [
                            'lg:ml-[110px]',
                            'xl:ml-[160px]',
                        ],
                    },
                    {
                        text: translate.value(
                            '<def>ShareTheRide.com</def> users saved over <b>$2M</b> in 2019 and <b>$4.5M</b> in 2020 by utilizing <def>alternative modes of transportation</def><CovidIcon />',
                            'Los usuarios de <def>ShareTheRide.com</def> ahorraron más de <b>$2 millones de dólares</b> en 2019 y <b>$4.5 millones de dólares</b> en 2020 usando <def>modos alternativos de transporte</def><CovidIcon />'
                        ),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/van.svg',
                        definitions: [
                            translate.value(
                                '<b>ShareTheRide.com:</b> A ride-matching system for commuters to quickly and securely find a carpool, vanpool, bus, light rail, bike or walk option. Commuters are matched based on proximity, destination, travel route, schedules and preferences.',
                                '<b>ShareTheRide.com:</b> Un sistema que conecta a los viajeros cotidianos segura y rápidamente con una opción de viaje compartido en auto o en camioneta, autobús, tren ligero, bicicleta o caminando. Dicha conexión se hace basándose en la proximidad, el destino, la ruta del viaje, los horarios y las preferencias.'
                            ),
                            translate.value(
                                '<b>Alternative Modes of Transportation:</b> Modes of transportation that help reduce air pollution and improve traffic congestion including riding transit, telecommuting, biking, carpooling and walking.',
                                '<b>Modos de transporte que ayudan a reducir la contaminación del aire y a mejorar el congestionamiento de tráfico, incluyendo transporte público, teletrabajo, ciclismo, viaje compartido en automóvil y caminar.'
                            ),
                        ],
                    },
                    {
                        // IMPORTANT: When updating this text, it must be updated in the `spacerBoxes` key of the parent object as well.
                        //            This infobox text is displayed on small screens but read by screen readers in all screen sizes.
                        //            It MUST be read after all the other info boxes.
                        text: translate.value(
                            '<span class="text-lg">Prop. 400 transit funding has leveraged more than $1.5B in federal discretionary funding to the region. These are new dollars, awarded competitively that would otherwise go to transit projects in other states and regions. The additional funding means contracts for local businesses and jobs for residents.</span>',
                            '<span class="text-lg">Los fondos para el transporte de la Proposición 400 han posibilitado más de $1.5 mil millones de dólares en fondos discrecionales federales para la región. Esto es dinero nuevo, otorgado competitivamente que de otra manera se destinaría a proyectos de transporte en otros estados y regiones. La financiación adicional significa contratos para empresas locales y empleos para los residentes.</span>'
                        ),
                        bgColor: 'bg-blue-50',
                        classes: [
                            //override the default infobox bottom margin settings
                            'mt-[-160px] lg:mt-0',
                            'mb-0',
                            'md:mb-0',
                            // place at the bottom when mobile
                            'relative',
                            'self-end',
                            // place absolutely when desktop
                            'lg:absolute',
                            'lg:top-[1570px]',
                            // screen reader
                            'not-sr-only md:sr-only',
                        ],
                        shadow: false,
                    },
                ],
                spacerBoxes: [
                    {
                        // IMPORTANT: When updating this text, it must be updated in the `infoBoxes` key of the parent object as well.
                        //            The spaceBox object is located visually in the spacer area, but it should not be read by screen readers.
                        text: translate.value(
                            '<span class="text-lg">Prop. 400 transit funding has leveraged more than $1.5B in federal discretionary funding to the region. These are new dollars, awarded competitively that would otherwise go to transit projects in other states and regions. The additional funding means contracts for local businesses and jobs for residents.</span>',
                            '<span class="text-lg">Los fondos para el transporte de la Proposición 400 han posibilitado más de $1.5 mil millones de dólares en fondos discrecionales federales para la región. Esto es dinero nuevo, otorgado competitivamente que de otra manera se destinaría a proyectos de transporte en otros estados y regiones. La financiación adicional significa contratos para empresas locales y empleos para los residentes.</span>'
                        ),
                        bgColor: 'bg-blue-50',
                        classes: [
                            'hidden md:flex sm:min-w-[none]',
                            //override the default infobox bottom margin settings
                            'mt-[-160px] lg:mt-0',
                            'mb-0',
                            'md:mb-0',
                            // place at the bottom when mobile
                            'relative',
                            'self-end',
                            // place absolutely when desktop
                            'lg:absolute',
                            'lg:top-[1570px]'
                        ],
                        shadow: false,
                        attrs: {
                            'aria-hidden': 'true',
                        }
                    },
                ],

                buttonClasses: ['mt-[-5rem]'],
                buttons: [
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_rider-survey-results.svg',
                        text: translate.value('Rider Survey Results', 'Resultados de la Encuesta de los Pasajeros'),
                        href: 'rider-survey-results',
                        iconClasses: ['scale-[1.02]']
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_rider-improvements.svg',
                        text: translate.value('Rider Improvements', 'Mejoramientos para los Pasajeros'),
                        href: 'rider-improvements',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_vehicle-ownership.svg',
                        text: translate.value('Vehicle Ownership Cost', 'Costo de Poseer Un Vehículo'),
                        href: 'vehicle-ownership-cost',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_bike-ped-safety.svg',
                        text: translate.value('Cyclist and Pedestrian Collision Safety Investments', 'Inversión en la Seguridad Contra Colisiones de Ciclistas y Peatones'),
                        href: 'bike-pedestrian-collisions-safety-investment',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_safety-transit.svg',
                        text: translate.value('Safety of Transit', 'Seguridad del Transporte'),
                        href: 'safety-of-transit',
                    }
                ],

                bumps: [
                    {
                        image: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/grey-sides/grey-12.svg',
                        classes: [
                            'absolute',
                            'left-0',
                            'bottom-[190px]',
                            'sm:bottom-[300px]',
                            'md:bottom-[400px]',
                            'md:top-auto',
                        ]
                    },
                    {
                        image: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/grey-sides/grey-13.svg',
                        classes: [
                            'absolute',
                            'right-0',
                            'bottom-[10px]',
                            'sm:bottom-[30px]',
                            'md:bottom-[40px]',
                            'md:top-auto',
                        ]
                    },
                ],
            },
            {
                id: 'benefiting-the-valley',
                color: 'gray-green',
                direction: 'left',

                title: translate.value('Benefiting the Valley', 'Beneficiando al Valle'),
                heading: translate.value('What\'s happening in the region', 'Qué está pasando en la región'),
                subheading: null,

                sectionClasses: [],

                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Benefiting-Valley.svg',

                backgroundImage: {
                    image: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/cities/qol-isometric-city-south-central-car.svg',
                    classes: [
                        'min-w-[2250px]',
                        '-ml-[900px]',
                        '-mt-[160px]',
                        'md:ml-[240px]',
                        'md:mt-[50px]',
                    ],
                },

                infoboxContainerClasses: [
                    'bg-[url(https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/dashed-lines/south-central-1.svg)]',
                    'bg-[right_-1080px_top_-5px]',
                    'sm:bg-[right_-1200px_top_-5px]',
                    'md:bg-[right_-953px_top_-5px]',
                    'lg:bg-[left_185px_top_-5px]',
                    'bg-[length:1414px]',
                    'sm:bg-[length:1800px]',
                ],
                infoBoxes: [
                    {
                        text: translate.value(
                            'From 2000-2014, <b>temperatures rose</b> between <b>4 and 13 degrees</b> Fahrenheit',
                            'De 2000 a 2014, <b>las temperaturas aumentaron</b> entre <b>4 y 13 grados</b> Fahrenheit'
                        ),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/qol-temperature-rose.svg',
                        iconClasses: ['h-[12rem] -top-[70px]'],
                    },
                    {
                        text: translate.value(
                            'Valley Metro carries <b>2% of all commuters</b> while only contributing <b>0.4% of <def>greenhouse gas (GHG) emissions</def></b> from vehicles',
                            'Valley Metro transporta al <b>2%</b> de todos los viajeros cotidianos, mientras que sus vehículos sólo contribuyen <b>0.4% de las <def>emisiones de gases de efecto invernadero “GHG”</def></b>'
                        ),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/QOL_information-shape_green_emissions.svg',
                        definitions: [
                            translate.value(
                                '<b>Greenhouse Gas Emissions:</b> Air pollutants that trap heat. Greenhouse gas emissions from man-made activities strengthen the greenhouse effect causing climate change.',
                                '<b>Emisiones de Gas de Efecto Invernadero:</b> Contaminantes del aire que atrapan el calor. Las emisiones de gases de efecto invernadero provenientes de las actividades realizadas por el hombre fortalecen al efecto invernadero que causa el cambio climático.'
                            ),
                        ],
                        classes: [
                            'lg:ml-[-50px]',
                            'xl:ml-[-150px]',
                        ],
                    },
                    {
                        text: translate.value(
                            'The number of days with <def>poor air quality</def> has <b>doubled since 2013</b>',
                            'El número de días con <def>mala calidad del aire</def> se ha <b>duplicado desde 2013</b>'
                        ),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/QOL_information-shape_green_poor-air-quality.svg',
                        iconClasses: ['-top-[86px]'],
                        definitions: [
                            translate.value(
                                '<b>Poor Air Quality:</b> A 24-hour period when the air quality index exceeds "healthy" levels as defined by the U.S. Environmental Protection Agency.',
                                '<b>Mala Calidad del Aire:</b> Un período de 24 horas en el que el índice de calidad del aire excede los niveles “saludables” según lo definido por la Agencia de Protección Medioambiental de los Estados Unidos.'
                            ),
                        ],
                        classes: [
                            'md:mt-[-120px]',
                            'xl:ml-[100px]',
                        ],
                    },
                ],

                buttonClasses: ['sm:-mt-32', 'md:-mt-52'],
                buttons: [
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_heat-impact.svg',
                        text: translate.value('Regional Heat Impact', 'Impacto Regional del Calor'),
                        href: 'regional-heat-impact',
                        iconClasses: ['scale-[1.01]']
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_regional-emissions.svg',
                        text: translate.value('Regional Emissions', 'Emisiones Regionales'),
                        href: 'regional-emissions',
                        iconClasses: ['scale-[1.02]']
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_regional-air.svg',
                        text: translate.value('Regional Air Quality', 'Calidad Regional del Aire'),
                        href: 'regional-air-quality',
                        iconClasses: ['scale-[1.01]']
                    }
                ],

                bumps: [
                    {
                        image: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/grey-sides/grey-14.svg',
                        classes: [
                            'absolute',
                            'left-0',
                            'top-[690px]',
                            'sm:top-[900px]',
                            'md:bottom-[700px]',
                            'md:top-auto',
                        ]
                    },
                    {
                        image: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/grey-sides/grey-15.svg',
                        classes: [
                            'absolute',
                            'right-0',
                            'bottom-[50px]',
                            'sm:-bottom-[200px]',
                            'xl:-bottom-[380px]'
                        ]
                    },
                ],
            },
            {
                id: 'how-valley-metro-contributes-to-the-region-benefiting',
                color: 'green',
                direction: 'right',

                title: translate.value('How Valley Metro contributes to the region', 'Cómo contribuye Valley Metro a la región'),
                heading: translate.value('Benefiting the Valley', 'Beneficiando al Valle'),
                subheading: null,

                addBackToTopButton: true,

                sectionClasses: [],

                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Benefiting-Valley.svg',

                backgroundImage: {
                    image: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/cities/qol-isometric-city-south-central-bus.svg',
                    classes: [
                        'min-w-[1850px]',
                        '-ml-[1150px]',
                        '-mt-[160px]',
                        '-top-[210px]',
                        '-right-[190px]',
                        'sm:absolute',
                        'sm:right-[275px]',
                        'md:top-[400px]',
                        'md:right-[250px]',
                        'xl:right-[250px]',
                        'sm:min-w-[2000px]',
                        'md:min-w-[2350px]',
                    ],
                },

                infoboxContainerClasses: [
                    'bg-[url(https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/dashed-lines/south-central-2.svg)]',
                    'mb-12',
                    'md:mb-24',
                    'bg-[right_15px_top_-100px]',
                    'sm:bg-[right_-100px_top_-5px]',
                    'md:bg-[right_15px_top_-5px]',
                    'xl:bg-[right_145px_top_-5px]',
                    'bg-[length:1500px]',
                    'sm:bg-[length:1300px]',
                    'md:bg-[length:1900px]',
                ],
                infoBoxes: [
                    {
                        text: translate.value(
                            'Valley Metro <b>displaces</b> <b>more emissions</b> than it creates by providing <def>shared-mode</def> <b>transit</b> options',
                            'Valley Metro <b>substituye más emisiones</b> de las que crea al proveer opciones de <def>modos de transporte compartido</def>'
                        ),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/van.svg',
                        definitions: [
                            translate.value(
                                '<b>Shared-mode:</b> Transportation services shared among users including public transit, bikeshare, rideshare, carpool, vanpool and scooters for the intent of offering improved mobility.',
                                '<b>Modo de Compartido:</b> Servicios de transporte compartido entre los usuarios, incluyendo transporte público, bicicletas compartidas, servicio Rideshare, viajes compartidos en auto y en camioneta, y escúteres con la intención de ofrecer mejor movilidad.'
                            ),
                        ],
                    },
                    {
                        text: translate.value(
                            '<b>Shade protection</b> exists at <b>94%</b> of <def>high-use bus stops</def>',
                            'Hay <b>protección sombreada</b> en el <b>94%</b> de las <def>paradas de autobús de alto uso</def>'
                        ),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/QOL_information-shape_green_shade-protection.svg',
                        definitions: [
                            translate.value(
                                '<b>High-use bus stops:</b> Stops that serve more than 71+ riders/day based on the Bus Stop Program and Standards Report.',
                                '<b>Paradas de Autobús de Alto Uso:</b> Paradas que proveen servicio a más de 71 pasajeros/día basados en el Programa de Paradas de Autobús y el Reporte de Estándares.'
                            )
                        ],
                    },
                    {
                        text: translate.value(
                            'Very few <def>criteria pollutants</def> are created by <b>electric-powered trains and buses</b> powered by alternative fuels and hybrids, which is <b>69%</b> of the bus fleet',
                            'Muy pocos <def>contaminantes criterio</def> son creados por los <b>trenes eléctricos</b> y <b>autobuses</b> propulsados por combustibles alternativos e híbridos, lo cual asciende al <b>69%</b> de la flotilla de autobuses'
                        ),
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/QOL_information-shape_green_CNG-powered.svg',
                        definitions: [
                            translate.value(
                                '<b>Criteria Pollutants:</b> Refers to six common air pollutants: carbon monoxide, lead, ground-level ozone, particulate matter, nitrogen dioxide and sulfur dioxide. At high levels, these pollutants negatively impact human health.',
                                '<b>Contaminantes Criterio:</b> Se refiere a seis contaminantes comunes del aire: monóxido de carbono, plomo, ozono a nivel del suelo, material particulado, dióxido de nitrógeno y dióxido de azufre. En niveles altos, estos contaminantes tienen un impacto negativo en la salud humana.'
                            )
                        ],
                    },
                ],

                buttonClasses: ['mt-[-11rem]'],
                buttons: [
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_emissions-saved.svg',
                        text: translate.value('Emissions Saved', 'Emisiones Ahorradas'),
                        href: 'emissions-saved',
                        iconClasses: ['scale-[1.01]']
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_combating-climate.svg',
                        text: translate.value('Combating Climate Change and Heat', 'Luchando Contra el Cambio Climático y el Calor'),
                        href: 'combating-climate-change-and-heat',
                        iconClasses: ['scale-[1.01]']
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_transit-stop.svg',
                        text: translate.value('Transit Stop Features', 'Características de las Paradas de Transporte'),
                        href: 'transit-stop-features',
                        iconClasses: ['scale-[1.01]']
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_walkable-access.svg',
                        text: translate.value('Walkable Access in Rail Corridor', 'Acceso Transitable a Pie en el Corredor del Tren Ligero'),
                        href: 'walkable-access-in-rail-corridor',
                        iconClasses: ['-ml-[8px] scale-[1.05]']
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_mode-share.svg',
                        text: translate.value('Mode Share by Corridor', 'Porcentaje de Modos por Corredor'),
                        href: 'mode-share-by-corridor',
                        iconClasses: ['-ml-[8px] scale-[1.05]']
                    }
                ],

                statements: [
                    {
                        content: translate.value(
                            'Valley Metro wants to hear from you on how it supports your community. We invite you to <a target="_blank" href="https://www.surveymonkey.com/r/QofLS">take this quick survey</a>.',
                            'Valley Metro quiere su opinión con respecto a cómo apoyamos a su comunidad. Le invitamos para que <a target="_blank" href="https://www.surveymonkey.com/r/QofLS">conteste esta rápida encuesta</a>.'
                        )
                    },
                    {
                        content: translate.value(
                            'Stay up-to-date on local transit news and projects by <a target="_blank" href="https://www.valleymetro.org/contact/valley-metro/email-notices">subscribing to Valley Metro Updates</a>.',
                            'Manténgase al día sobre las noticias y los proyectos locales de transporte <a target="_blank" href="https://www.valleymetro.org/contact/valley-metro/email-notices">suscribiéndose a las actualizaciones de Valley Metro</a>.'
                        )
                    },
                    {
                        content: translate.value(
                            'Learn more about light rail’s impact in the Valley from our 2018 edition, Building Communities & Enhancing Lives: <a target="_blank" href="https://drupal-space.nyc3.cdn.digitaloceanspaces.com/s3fs-public/uploads/event-resources/quality_of_life_report_12-3-18_-_final_reduced.pdf">A Quality of Life Report</a>.',
                            'Aprenda más sobre el impacto del tren ligero en el Valle en nuestra edición de 2018, Construyendo Comunidades y Mejorando Vidas: <a target="_blank" href="https://drupal-space.nyc3.cdn.digitaloceanspaces.com/s3fs-public/uploads/event-resources/quality_of_life_report_12-3-18_-_final_reduced.pdf">Un Reporte de la Calidad de Vida</a>'
                        )
                    },
                ],

                bumps: [
                    {
                        image: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/grey-sides/grey-16.svg',
                        classes: [
                            'absolute',
                            'left-0',
                            'bottom-[190px]',
                            'sm:bottom-[300px]',
                            'md:bottom-[400px]',
                            'md:top-auto',
                        ]
                    },
                    {
                        image: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/grey-sides/grey-13.svg',
                        classes: [
                            'absolute',
                            'right-0',
                            'bottom-[10px]',
                            'sm:bottom-[30px]',
                            'md:bottom-[40px]',
                            'md:top-auto',
                        ]
                    },
                ],
            },
        ])

        const backToTop = computed(() => translate.value(
            'Back to Top',
            'Regresar al Inicio'
        ))

        return {
            title,
            sections,
            headTitle,
            introduction,

            backToTop,
        }
    },
});
</script>
