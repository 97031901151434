<template>
    <div class="border-b-8">
        <SectionTitle link="on-time-performance" color="purple" class="mb-12"
            :title="$tx('On-Time Performance')"
            :subtitle="`${$tx('Schedule Deviation of Timepoints Served')} (2021)`"/>
        <div class="flex flex-col w-full m-auto p-4 md:p-12">
            <ChartTableSwitch page-title="On-Time Performance" view-table="View Tables" view-chart="View Charts">
                <template v-slot:chart>
                    <div class="flex flex-1 flex-col w-full relative space-y-6 md:space-y-0 h-[350px] md:h-450px] lg:h-[600px] md:flex-row">
                        <!-- Only one role/aria-label since these charts are actually just a single chart -->
                        <ChartRenderer :chart="BarChart" chartId="on-time-performance-early" :chartData="chartDataEarly" :plugins="[ChartDataLabels]" :options="optionsEarly" class=" flex flex-1 -mt-[8px]" label="Minutes Early performance Chart/Graph. Refer to data table for details." />
                        <ChartRenderer :chart="BarChart" chartId="on-time-performance-on-time" :chartData="chartDataOnTime" :plugins="[ChartDataLabels]" :options="optionsOnTime" class="flex flex-1 lg:border-x" label="On-time performance Chart/Graph. Refer to data table for details." />
                        <ChartRenderer :chart="BarChart" chartId="on-time-performance-late" :chartData="chartDataLate" :plugins="[ChartDataLabels]" :options="optionsLate" class="flex flex-1" label="Minutes Late performance Chart/Graph. Refer to data table for details." />
                    </div>
                </template>

                <template v-slot:table>
                    <div class="flex justify-center min-w-full mt-4">
                        <div class="flex flex-col space-y-4 w-full px-4 lg:flex-row lg:space-y-0">
                            <div class="px-6 mx-auto w-full lg:w-1/3">
                                <DataTable v-bind="dataTableEarly" />
                            </div>
                            <div class="px-6 mx-auto w-full lg:w-1/3">
                                <DataTable v-bind="dataTableOnTime" />
                            </div>
                            <div class="px-6 mx-auto w-full lg:w-1/3">
                                <DataTable v-bind="dataTableLate" />
                            </div>
                        </div>
                    </div>
                </template>
            </ChartTableSwitch>

            <div class="flex m-auto mt-8">
                <div class="max-w-lg text-center">
                    <InfoBox halign="left" color="qol1-full" class="mb-8 mt-4 md:mt-20" icon="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/QOL_information-shape_green_CNG-powered.svg">
                        <template #content>
                            <span v-html="$tx('Valley Metro completes 74.3% of scheduled service on time.')"></span>
                        </template>
                    </InfoBox>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {computed, defineComponent, inject} from 'vue';
import ChartRenderer from "../../../components/ChartRenderer";
import {useIsSmallScreen} from "../../../utils/helpers";
import ChartTableSwitch from "../Components/ChartTableSwitch";
import DataTable from "../Components/DataTable";

import SectionTitle from '../Components/SectionTitle';
import InfoBox from "../Components/InfoBox";

import { BarChart } from 'vue-chart-3';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// See link for registering chart types to allow for tree-shaking if desired
// https://vue-chart-3.netlify.app/guide/#chart-js-v3
import { Chart, registerables } from "chart.js";
import {
    font20,
    generateDataLabelsSettings,
    generateTickSettings,
    generateTitleSettings,
    generateTooltipStettings
} from "./chartsUtils";
Chart.register(...registerables);

export default defineComponent({
    components: {
        ChartRenderer,
        DataTable,
        ChartTableSwitch,
        SectionTitle,
        InfoBox,
        ChartDataLabels,
        BarChart,
    },
    setup() {
        const tx = inject('tx');
        const isSmallScreen = useIsSmallScreen()

        /**
         * Helper to build the subgroups of data table.
         * @param key
         * @param label
         * @param chartData
         * @returns {{headings: {}, fullTableConfig: {data: {}, heading: {}}, rows: *}}
         */
        function buildDataTableSubgroup(key, label, chartData) {
            const headings = {
                minutes: {
                    label: tx('Minutes'),
                    classes: 'w-40',
                },
                value: tx('Percentage of Trips') + '%',
            };

            const rows = chartData.labels.map((minutes, index) => {
                return {
                    minutes: minutes ? minutes.toString() : tx('On Schedule'),
                    value: chartData.datasets[0].data[index] + '%',
                }
            });

            const fullTableConfig = {
                heading: {
                    [key]: {
                        label,
                        children: {},
                    }
                },
                data: {},
            };

            rows.forEach((row, index) => {
                fullTableConfig.heading[key].children[`${key}-${index}`] = row.minutes;
                fullTableConfig.data[`${key}-${index}`] = row.value;
            });

            return {
                fullTableConfig,
                headings,
                label,
                rows,
            };
        }

        /**
         * Build options to charts.
         *
         * @param xLabel
         * @param yLabel
         * @param showTicks
         * @returns {{plugins: {legend: boolean, tooltip: *, datalabels: {offset: number, anchor: string, align: string, clamp: boolean, clip: boolean, font: {size: number, weight: string, family: string}}}, responsive: boolean, scales: {x: {ticks: {drawTicks: boolean}, grid: {drawBorder: boolean, display: boolean, drawTicks: boolean}, title: {display: boolean, text: *, font: {size: number, weight: string, family: string}}}, y: {min: number, max: number, ticks: {padding: number, display, callback: (function(*): string), drawTicks, font: {size: number, weight: string, family: string}}, grid: {drawBorder, drawTicks}, title: {display: boolean, text: *, font: {size: number, weight: string, family: string}}}}, maintainAspectRatio: boolean}}
         */
        function buildChartOptions ({ xLabel, yLabel, showTicks, maxPercentageOnMobile }) {
            return {
                hover: { mode: null },
                plugins: {
                    datalabels: generateDataLabelsSettings({
                        anchor: 'end',
                        align: 'end',
                        offset: 10,
                        clamp: true,
                        clip: false,
                    }),
                    legend: false,
                    tooltip: generateTooltipStettings({
                        callbacks: {
                            label: (context) => `${context.formattedValue}%`
                        }
                    }),
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        grid: {
                            display: false,
                            drawTicks: false,
                            drawBorder: false,
                        },
                        ticks: generateTickSettings({
                            drawTicks: false,
                        }),
                        title: generateTitleSettings({
                            text: xLabel,
                        })
                    },
                    y: {
                        min: 0,
                        max: isSmallScreen.value ? maxPercentageOnMobile : 25,
                        grid:  {
                            drawBorder: false,
                            drawTicks: showTicks,
                        },
                        title: generateTitleSettings({
                            enabled: (yLabel && yLabel.length > 0),
                            text: yLabel,
                        }),
                        ticks: generateTickSettings({
                            drawTicks: showTicks,
                            display: showTicks,
                            padding: 0,
                            callback: function (value) {
                                return value + '%'
                            },
                        }),
                    }
                }
            };
        }

        /*
         * EARLY CHART
         */
        const chartDataEarly = computed(() => ({
            labels: [5, 4, 3, 2],
            datasets: [
                {
                    backgroundColor: ['rgb(247, 141, 30)'],
                    borderColor: 'rgb(132, 132, 132)',
                    data: [1.49,2.15,3.16,4.87],
                    datalabels: generateDataLabelsSettings({
                        labels: {
                            name: false,
                            value: {
                                color: '#000000',
                                formatter: (value) => `${value}%`,
                            },
                        },
                    }),
                },
            ],
        }));

        const optionsEarly = computed(() => buildChartOptions({
            xLabel: tx('Minutes Early'),
            yLabel: tx('Percentage of Trips'),
            showTicks: true,
            maxPercentageOnMobile: 6,
        }));

        /*
         * ON TIME
         */

        const chartDataOnTime = computed(() => ({
            labels: ['-1', '', '+1' , '+2' , '+3' , '+4' , '+5'],
            datasets: [
                {
                    backgroundColor: ['rgb(170, 205, 79)'],
                    borderColor: 'rgb(132, 132, 132)',
                    data: [9.62,21.59,13.95,10.53,7.97,6.06,4.57],
                    datalabels: generateDataLabelsSettings({
                        labels: {
                            name: {
                                padding: 10,
                                font: font20,
                                color: '#000000',
                                formatter: (value, context) => {
                                    if (context.dataIndex === 1) {
                                        return (context.chart.width < 250)
                                            ? tx('On\nSchedule\n\n')
                                            : tx('On Schedule');
                                    }

                                    return '';
                                },
                            },
                            value: {
                                color: '#000000',
                                padding: {
                                    left: 20,
                                    bottom: 0,
                                    top: 0,
                                },
                                formatter: (value, context) => {
                                    if (context.dataIndex === 1) {
                                        return `\n${value}% ${tx('of trips')}`
                                    }

                                    return `${value}%`;
                                },
                            },
                        },
                    }),
                },
            ]
        }))

        const optionsOnTime = computed(() => {
            const options = buildChartOptions({
                xLabel: tx('On Time'),
                yLabel: isSmallScreen.value ? tx('Percentage of trips') : null,
                showTicks: isSmallScreen.value,
                maxPercentageOnMobile: 25,
            });

            if (options.plugins.tooltip.enabled) {
                options.plugins.tooltip.callbacks = {
                    title: (context) => context[0].dataIndex === 1 ? tx('On Schedule') : context[0].label
                }
            }

            return options;
        });

        /*
         * LATE CHART
         */

        const chartDataLate = computed(() => ({
            labels: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
            datasets: [
                {
                    backgroundColor: ['rgb(89, 23, 105)'],
                    borderColor: 'rgb(132, 132, 132)',
                    data: [3.46,2.59,1.91,1.49,1.10,0.82,0.63,0.50,0.38,0.30],
                    datalabels: generateDataLabelsSettings({
                        labels: {
                            name: false,
                            value: {
                                color: '#000000',
                                formatter: (value) => `${value}%`,
                            },
                        },
                    }),
                },
            ],
        }));

        const optionsLate = computed(() => buildChartOptions({
            xLabel: tx('Minutes Late'),
            yLabel: isSmallScreen.value ? tx('Percentage of Trips') : null,
            showTicks: isSmallScreen.value,
            maxPercentageOnMobile: 4,
        }));

        /*
         * Build the data table.
         */

        const dataTableEarly = computed(() => buildDataTableSubgroup('minutesEarly', tx('Minutes Early'), chartDataEarly.value));
        const dataTableOnTime = computed(() => buildDataTableSubgroup('onTime', tx('On Time'), chartDataOnTime.value));
        const dataTableLate = computed(() => buildDataTableSubgroup('minutesLate', tx('Minutes Late'), chartDataLate.value));

        const dataTable = computed(() => {
            const headings = {
                ...dataTableEarly.value.fullTableConfig.heading,
                ...dataTableOnTime.value.fullTableConfig.heading,
                ...dataTableLate.value.fullTableConfig.heading,
            };

            const data = {
                ...dataTableEarly.value.fullTableConfig.data,
                ...dataTableOnTime.value.fullTableConfig.data,
                ...dataTableLate.value.fullTableConfig.data,
            };

            const rows = [data];

            return {
                headings,
                rows,
            }
        });

        return {
            BarChart,
            dataTable,
            dataTableEarly,
            dataTableOnTime,
            dataTableLate,
            chartDataEarly,
            chartDataOnTime,
            chartDataLate,
            optionsEarly,
            optionsOnTime,
            optionsLate,
            ChartDataLabels,
        };
    }
});
</script>
