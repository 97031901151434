<template>
    <div class="flex flex-col md:flex-row space-x-4 pb-4 border-b border-gray-400">
        <template v-if="available">
            <div v-if="field.display" class="w-[120px] self-center md:self-start pb-4 md:pb-0">
                <a :href="field.preview" target="_blank" class="flex flex-col w-[120px] rounded shadow-sm overflow-hidden bg-gray-100 hover:shadow-md">
                    <img class="block w-full h-auto" :src="field.preview" :alt="field.display || ''" />
                    <span class="block w-full p-2 text-center text-secondary">
                        Preview
                    </span>
                </a>
            </div>
            <div class="flex-grow">
                <div class="text-lg text-black font-semibold">
                    {{ field.display }}
                </div>
                <div v-if="field.instructions" class="text-base text-gray-700 font-normal mt-2">
                    {{ field.instructions }}
                </div>
            </div>
            <div class="pr-4 md:pr-0 w-full md:w-[140px] h-auto flex items-end justify-end">
                <template v-if="field.orders_by_language">
                    <div class="space-y-3">
                        <div class="flex items-center justify-end space-x-2">
                            <label :for="`field_${field.handle}_english`" class="text-base text-black font-bold">English</label>
                            <input :id="`field_${field.handle}_english`" type="number" min="0" step="1" :name="`${field.handle}[english]`" :value="old('english')" :title="`${field.display} - English order`" class="field">
                        </div>
                        <div class="flex items-center justify-end space-x-2">
                            <label :for="`field_${field.handle}_spanish`" class="text-base text-black font-bold">Spanish</label>
                            <input :id="`field_${field.handle}_spanish`" type="number" min="0" step="1" :name="`${field.handle}[spanish]`" :value="old('spanish')" :title="`${field.display} - Spanish order`" class="field">
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="flex items-center justify-end space-x-2">
                        <label :for="`field_${field.handle}_order`" class="text-base text-black font-bold">Order</label>
                        <input :id="`field_${field.handle}_order`" type="number" min="0" step="1" :name="`${field.handle}[order]`" :value="old('order')" :title="`${field.display} order`" class="field">
                    </div>
                </template>
            </div>
        </template>
        <template v-else>
            <div class="w-full text-center text-lg text-black font-semibold">
                {{ field.display }} ({{ unavailableMessage }})
            </div>
        </template>
    </div>
</template>

<style scoped>
.field {
    padding: 4px !important;
    width: 56px !important;
}
</style>

<script setup>
import {computed} from "vue";
import Image from "../../../sets/Image.vue";
import {defineField} from "./BaseField.js";

const field = defineField();

const available = computed(() => {
    return !field.not_available && !field.out_of_stock;
});

const unavailableMessage = computed(() => {
    if (field.not_available) {
        return 'Not Available';
    }

    if (field.out_of_stock) {
        return 'Out of Stock';
    }

    return '';
});

function old(language) {
    if (! field.old) {
        return '';
    }

    return field.old[language] || '';
}
</script>
