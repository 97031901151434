<template>
<div>
    <div v-for="calendar in schedule.runsOn" :key="calendar.index" v-html="calendar.description"></div>
</div>
</template>

<script>
import {computed, defineComponent} from 'vue';
import {format, parse} from "date-fns";

export default defineComponent({
    name: 'MapsSchedulesRouteRunOn',
    props: {
        schedule: { type: Object, required: true },
    },

    setup() {
        return {};
    },
});
</script>
