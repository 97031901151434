<template>
    <div>
        <h5 class="text-sm font-normal text-white mb-2">Trip Options:</h5>

        <div>
            <select
                @change="tripOptionUpdated"
                :value="modelValue"
                tabindex="0"
                role="listbox"
                title="Select to change the trip options"
                aria-label="Select the type of trip"
                class="form-select on-primary-color w-full"
            >
                <option
                    v-for="(optLabel, optValue) in TRIP_OPTIONS" :key="optValue"
                    :value="optValue"
                    role="option">
                    {{ optLabel }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
import {TRIP_BEST_ROUTE, TRIP_FEWER_TRANSFERS, TRIP_LESS_WALKING} from "./useTripInput";
import {defineComponent} from "vue";

const TRIP_OPTIONS = {
    [TRIP_BEST_ROUTE]: 'Best route',
    [TRIP_LESS_WALKING]: 'Less walking',
    [TRIP_FEWER_TRANSFERS]: 'Fewer transfers',
};

export default defineComponent({
    name: "TripPlannerOptions",

    props: {
        modelValue: {
            type: String,
            validator(value) {
                return TRIP_OPTIONS.hasOwnProperty(value);
            }
        }
    },

    setup(props, { emit }) {
        function tripOptionUpdated(event) {
            emit('update:modelValue', event.target.value);
        }

        return {
            TRIP_OPTIONS,
            tripOptionUpdated,
        }
    }
});
</script>
