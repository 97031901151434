<template>
    <div class="border-b-8">
        <SectionTitle :title="title" :subtitle="subtitle" link="regional-transportation-tax-breakdown" color="blue" class="mb-8" />
        <div class="flex flex-col max-w-4xl m-auto">
            <!-- REVENUE -->
            <div class="flex justify-center">
                <h3 class="text-4xl">{{ $tx('Revenue') }}</h3>
            </div>
            <div class="flex-1 flex flex-col py-0 px-[4rem] md:p-4 md:flex-row md:justify-between md:items-center">
                <template v-for="(graphicUrl, index) in revenueGraphics.desktop">
                    <div class="w-1/3 hidden md:block">
                        <Image :src="graphicUrl" :alt="revenueGraphics.config[index].alt" class="w-full" :class="revenueGraphics.config[index].class" />
                    </div>
                </template>
                <template v-for="(graphicUrl, index) in revenueGraphics.mobile">
                    <div class="block md:hidden">
                        <Image :src="graphicUrl" :alt="revenueGraphics.config[index].alt" class="w-full" :class="revenueGraphics.config[index].class" />
                    </div>
                </template>
            </div>

            <!-- OPERATIONS -->
            <div class="flex justify-center">
                <h3 class="text-4xl">{{ $tx('Operations') }}</h3>
            </div>
            <div class="flex-1 flex flex-col py-0 px-[4rem] md:p-4 md:flex-row md:justify-center md:items-center md:space-x-8">
                <template v-for="(graphicUrl, index) in operationsGraphics.graphics">
                    <div class="w-full md:w-1/3">
                        <Image :src="graphicUrl" :alt="operationsGraphics.config[index].alt" class="w-full" :class="operationsGraphics.config[index].class" />
                    </div>
                </template>
                <div class="w-full md:w-1/3">
                    <div>
                        <InfoBox hide-header halign="left" color="qol3-full" class="mb-8">
                            <template #content>
                                <span v-html="infoBox"></span>
                            </template>
                        </InfoBox>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, computed, inject} from 'vue';
import Image from "../Components/Image";
import InfoBox from "../Components/InfoBox";
import InfoBoxIcon from "../Components/InfoBoxIcon";
import SectionTitle from '../Components/SectionTitle'
import useTranslations from "../translations";

export default defineComponent({
    name: 'Prop400Distribution',
    components: {
        InfoBox,
        InfoBoxIcon,
        Image,
        SectionTitle
    },
    setup() {
        const tx = inject('tx');
        const { translate } = useTranslations();

        const title = computed(() => translate.value('Prop. 400 Distribution', 'Distribución de la Proposición 400'));
        const subtitle = computed(() => translate.value('Regional Transportation Tax Breakdown', 'Desglose del Impuesto de Transporte Regional'));
        const infoBox = computed(() => translate.value(
            'RPTA operations is paid mostly by Prop. 400 then augmented with sources including fares and Regional Area Road Funds (RARF). RARF contributed an additional $5M, bringing total operating costs to $116M.',
            'Las operaciones de la autoridad RPTA se pagan principalmente con la Proposición 400 y se aumentan con fuentes que incluyen tarifas y Fondos Regionales de Caminos del Área (RARF por sus siglas en inglés). Los fondos RARF contribuyeron $5 millones de dólares adicionales, con los costos operativos totales ascendiendo a $116 millones de dólares.'
        ));

        const revenueGraphics = computed(() => ({
            config: [
                {alt: tx('Prop 400 Revenue. $631 million total annual tax revenue. Two-thirds goes to freeways, highways, streets and intersection improvements. One-third goes to public transit, totaling $210 million.'), class: ''},
                {alt: tx('Of the $210 million Public Transportation Fund, $91 million goes to Valley Metro Rail and the remaining $119 million goes to the Regional Public Transportation Authority (RPTA).'), class: ''},
                {alt: tx('$119M RPTA fund distribution. $8 millon is used for capital projects and the other $111 million is used for RPTA operations.'), class: 'pr-0 md:pr-[50px]'},
            ],
            desktop: [
                translate.value(
                    'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/prop400/400-distribution_revenue-$631M-english-computer.svg',
                    'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/prop400/400-distribution_revenue-$631M-spanish-mobile.svg',
                ),
                translate.value(
                    'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/prop400/400-distribution_revenue-$210M-english-computer.svg',
                    'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/prop400/400-distribution_revenue-$210M-spanish-computer.svg',
                ),
                translate.value(
                    'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/prop400/400-distribution_revenue-$119M-english.svg',
                    'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/prop400/400-distribution_revenue-$119M-spanish.svg',
                )
            ],
            mobile: [
                translate.value(
                    'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/prop400/400-distribution_revenue-$631M-english-mobile.svg',
                    'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/prop400/400-distribution_revenue-$631M-spanish-mobile.svg',
                ),
                translate.value(
                    'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/prop400/400-distribution_revenue-$210M-english-mobile.svg',
                    'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/prop400/400-distribution_revenue-$210M-spanish-mobile.svg'
                ),
                translate.value(
                    'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/prop400/400-distribution_revenue-$119M-english.svg',
                    'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/prop400/400-distribution_revenue-$119M-spanish.svg',
                )
            ],
        }));

        const operationsGraphics = computed(() => ({
            config: [
                {alt: tx('Bus Operations, $60.6M. ADA Paratransit, $35.4M. Other Operating, $13.9M, includes debt, regional services and safety/security. Administration, $6.1M.'), class: ''},
                {alt: tx('Total $116M'), class: ''},
            ],
            graphics: [
                translate.value(
                    'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/prop400/400-distribution_bus-operations-$60.6M-english.svg',
                    'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/prop400/400-distribution_bus-operations-$60.6M-spanish.svg'
                ),
                translate.value(
                    'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/prop400/400-distribution_total-$116M-english.svg',
                    'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/prop400/400-distribution_total-$116M-english.svg'
                ),
            ],
        }));

        return {
            title,
            infoBox,
            subtitle,
            revenueGraphics,
            operationsGraphics
        };
    }
});
</script>
