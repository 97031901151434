import {ref, computed} from "vue";

const activeMenu = ref(null);

export function useSiteMainNav() {
    return {
        activeMenu: computed(() => activeMenu.value),
        showDropdown: (menuId) => activeMenu.value = menuId,
        hideDropdown: (menuId) => {
            if ((activeMenu.value === menuId) || !menuId) {
                activeMenu.value = null
            }
        },
    }
}
