<script>
    import { computed, ref } from 'vue'
    import useTranslations from '../translations'

    export default {
        name: 'Translate',
        setup() {
            // support legacy translations
            const { selectEnglishLocale, selectSpanishLocale } = useTranslations()

            const browserLocale = (() => {
                const params = new URLSearchParams(window.location.search.replace(/^\?/, ''))
                return params.get('locale') == 'es' ? 'Espanol' : 'English'
            })()
            const locale = ref(browserLocale)

            const selectedLocale = computed(() => locale.value)

            const translate = computed(() => function(english, spanish) {
                return selectedLocale.value == 'English' ? english : spanish
            })

            const selectEnglishLocaleNEW = function () {
                locale.value = 'English'
                selectEnglishLocale()
            }
            const selectSpanishLocaleNEW = function () {
                locale.value = 'Spanish'
                selectSpanishLocale()
            }

            const selectLanguageFunctions = ref({
                selectEnglishLocaleNEW,
                selectSpanishLocaleNEW,
                selectedLocale,
            })

            return {
                translate,
                selectLanguageFunctions,
            }
        },
    }
</script>
