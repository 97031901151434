<template>
    <div class="relative w-full" >
        <div class="flex flex-col w-full p-1 overflow-hidden bg-white border border-gray-500 rounded shadow ring-0 hover:border-gray-700 md:flex-row md:items-center">
            <div v-if="label" class="w-16 px-4 py-2 text-sm font-bold text-gray-800">
                <label :for="`location_${name}`">{{ label }}</label>
            </div>

            <MapInputText
                v-if="placeSelected"
                :ref="el => setInputRef(el)"
                @keydown="onKeyDown"
                :value="inputValue"
                :name="name"
                :placeholder="placeholder"
                :title="title || label || placeholder"
                role="searchbox"
                :read-only="false"
                :aria-controls="ariaControls"
                :aria-label="computedAriaLabel"
                :aria-autocomplete="ariaAutocomplete"
                :aria-activedescendant="ariaActivedescendant"
                autocomplete="off"
                unstyled
                class="w-full flex-1 py-2 px-4 rounded border-0 shadow-0 ring-0 text-black hover:bg-gray-100 focus:outline-none focus:bg-gray-100 bg-gray-200 text-gray-500"
            ></MapInputText>

            <InputText
                v-else
                :ref="el => setInputRef(el)"
                v-bind="$attrs"
                v-model="inputValue"
                :id="`location_${name}`"
                @input="setIsFetchingPlace(($event.target.value || '').trim().length > 0)"
                @focusin="focusInput"
                @focusout="focusOutInput"
                @keydown.esc="focused = false"
                @keydown.down="focusNext"
                @keydown.up="focusPrevious"
                @keydown.enter="selectFocusedOption"
                :name="name"
                :placeholder="placeholder"
                :title="title || label || placeholder"
                :disabled="!ready"
                aria-autocomplete="list"
                :aria-label="computedAriaLabel"
                :aria-expanded="open"
                :aria-controls="`location_${name}_listbox`"
                :aria-activedescendant="currentFocus >= 0 ? `location_${name}_${results[currentFocus]?.place_id}` : ''"
                autocomplete="off"
                role="combobox"
                unstyled
                tabindex="0"
                class="flex-1 px-4 py-2 text-black border-0 rounded shadow-0 ring-0 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:ring-gray-700"
            />

            <light-button v-if="placeSelected" @keydown.enter="clearSelectedPlace" @click="clearSelectedPlace" type="button" class="px-2 ml-1" :aria-label="`Clear '${label ? label : computedAriaLabel}' input`">
                <XIcon class="w-5 h-5" />
            </light-button>

            <light-button v-else-if="isGeolocationEnabled" @click="getUserLocation" type="button" class="px-2 ml-1" aria-label="Get user location automatically">
                <LocationMarkerIcon class="w-5 h-5" />
            </light-button>
        </div>
        <div role="alert" aria-live="assertive">
            <ul
                role="listbox"
                v-show="open"
                aria-label="Locations"
                :id="`location_${name}_listbox`"
                class="absolute z-50 w-full -mt-1 text-sm font-normal text-black bg-white border border-gray-200 divide-y divide-gray-100 rounded-b shadow-md tk-museo-sans"
                :aria-activedescendant="`location_${name}_${results[currentFocus]?.place_id}`"
            >
                <li v-if="isFetchingPlaces" :aria-busy="isFetchingPlaces ? 'true' : 'false'" role="alert" aria-live="assertive" class="w-full px-10 py-4 text-sm font-normal text-center">
                    Loading...
                </li>
                <template v-else>
                    <li
                        v-for="(option, index) in results"
                        :key="index"
                        :id="`location_${name}_${option.place_id}`"
                        :ref="setOptionRef"
                        :class="index === currentFocus ? 'bg-green-500' : ''"
                        role="option"
                        :aria-selected="index === currentFocus ? 'true' : 'false'"
                        @click="selectPlace(option)"
                        class="w-full py-3 pl-20 pr-2 text-left truncate appearance-none cursor-pointer hover:bg-green-500 focus:bg-green-500 focus:outline-none focus:ring-inset-2 focus:ring-gray-500"
                    >
                        <strong>{{ option.main_text }}</strong> <span class="text-xs text-gray-900">{{ option.secondary_text }}</span>
                    </li>
                </template>
            </ul>
        </div>
        <div ref="mapsAttributionsRef" class="my-1 text-sm text-gray-600"></div>
    </div>
</template>

<script>
import { LocationMarkerIcon, XIcon } from "@heroicons/vue/outline";
import {defineComponent, ref, watch, nextTick, computed} from "vue";
import { refDebounced } from "vue-composable";
import {getPlaceDetails, searchPlace, useAutocompleteService, useGoogleApi, usePlacesService} from "../utils/google";
import InputText from "./InputText";
import LightButton from "./LightButton";
import { useGeolocation } from "./useGeolocation";
import MapInputText from "./MapInputText.vue";

export default defineComponent({
    components: {
        MapInputText,
        InputText,
        LightButton,
        LocationMarkerIcon,
        XIcon
    },
    emits: ['update:modelValue'],
    props: {
        apiKey: {
            type: String,
            required: true,
        },
        mapBounds: {
            type: Object,
            required: true,
        },
        modelValue: {
            type: Object,
            required: false,
            default: () => ({
                value: '',
                location: null,
            }),
        },
        name: {
            required: true,
            type: String,
        },
        label: {
            required: false,
            type: [String, null],
            default: null,
        },
        title: {
            type: [String, null],
            default: null,
        },
        placeholder: {
            required: false,
            type: [String, null],
            default: 'Search...',
        },
        button: {
            required: false,
            type: String,
            default: 'Search',
        },
        ariaControls: {
            type: String,
            required: false,
            default: '',
        },
        ariaActivedescendant: {
            type: String,
            required: false,
            default: '',
        },
        ariaLabel: {
            type: [String, null],
            required: false,
            default: null,
        },
        ariaAutocomplete: {
            type: String,
            required: false,
            default: 'none',
        },
    },

    setup (props, { emit }) {
        // Element references
        const optionRefs = [];
        const mapsAttributionsRef = ref(null);
        const placeSelected = ref(false);

        const { isGeolocationEnabled } = useGeolocation();
        const { onGoogleApiSet } = useGoogleApi();
        const { getAutocompleteService, createAutocompleteSessionToken } = useAutocompleteService();
        const { getPlacesService } = usePlacesService(props.name, mapsAttributionsRef);

        // Non reactive data
        let sessionToken = null;

        // Reactive data
        const services = ref({
            googleApi: null,
            autocomplete: null,
            places: null,
        });
        const input = ref(null);
        const results = ref([]);
        const ready = ref(false);
        const focused = ref(false);
        const currentFocus = ref(-1);
        const isFetchingPlaces = ref(false);
        const inputValue = ref(props.modelValue.value);
        const debouncedInputValue = refDebounced(inputValue.value, 750);
        const autocompleteSessionToken = ref(null);
        const open = ref(false);

        const computedAriaLabel = computed(() => {
            if (props.ariaLabel) {
                return props.ariaLabel;
            }

            return `${props.label}: ${props.placeholder}`;
        });

        // Initialize the autocomplete service when the places api comes to life.
        onGoogleApiSet().then((api) => {
            services.value.googleApi = api;

            Promise.all([
                getAutocompleteService(),
                getPlacesService(),
            ]).then((all) => {
                services.value.autocomplete = all[0];
                services.value.places = all[1];
                ready.value = true;
            });
        });

        function setOptionRef(el) {
            if (el) {
                optionRefs.push(el);
            }
        }

        function getUserLocation() {
            //
        }

        function clearResults() {
            results.value = [];
        }

        function setOpenState(state) {
            setTimeout(() => open.value = state, 100);
        }

        function setIsFetchingPlace(state) {
            isFetchingPlaces.value = state;
        }

        async function search(value) {
            const query = value.trim();

            if (query.length === 0) {
                results.value = [];
                return;
            }

            autocompleteSessionToken.value = createAutocompleteSessionToken();

            try {
                results.value = await searchPlace({
                    query: value,
                    bounds: props.mapBounds,
                    placesService: services.value.places,
                    sessionToken: autocompleteSessionToken.value,
                    location: {
                        lat: 0,
                        lng: 0,
                    },
                });

                setIsFetchingPlace(false);
            } catch (error) {}
        }

        async function selectPlace(option) {
            try {
                focused.value = false;
                currentFocus.value = -1;

                const placeDetails = await getPlaceDetails(
                    autocompleteSessionToken.value,
                    services.value.places,
                    option.place_id
                );

                emit('update:modelValue', {
                    value: option.title,
                    location: {
                        lat: placeDetails.geometry.location.lat(),
                        lng: placeDetails.geometry.location.lng(),
                    },
                });
                placeSelected.value = true;
                await nextTick(async () => {
                    input.value.$el.focus();
                });
            } catch (error) {
                console.log(error)
            }
        }

        function focusPrevious(event) {
            event.preventDefault();

            currentFocus.value = (currentFocus.value > 0)
                ? currentFocus.value - 1
                : results.value.length - 1;

            optionRefs[currentFocus.value]?.focus();
        }

        function focusNext(event) {
            event.preventDefault();

            currentFocus.value = (currentFocus.value < results.value.length - 1)
                ? currentFocus.value + 1
                : 0;

            optionRefs[currentFocus.value]?.focus();
        }

        function focusOutInput() {
            setTimeout(() => focused.value = false, 100);
        }

        function focusInput() {
            if (focused.value) {
                return;
            }
            setTimeout(async () => {
                if (results.value.length === 0) {
                    results.value = await searchPlace({
                        query: 'a',
                        bounds: props.mapBounds,
                        placesService: services.value.places,
                        sessionToken: createAutocompleteSessionToken(),
                    });
                }
                focused.value = true;
            }, 100);
        }

        function selectFocusedOption() {
            if (currentFocus.value === -1) {
                return;
            }

            selectPlace(results.value[currentFocus.value]);
        }

        function onKeyDown(ev) {
            ev.stopPropagation();
            emit('keydown', ev);
        }

        function setInputRef(el) {
            if (el) {
                input.value = el;
            }
        }

        async function clearSelectedPlace() {
            try {
                inputValue.value = '';
                emit('update:modelValue', {
                    value: '',
                    location: null,
                });
                placeSelected.value = false;
                await nextTick(async () => {
                    input.value.$el.focus();
                });
            } catch (error) {
                console.log(error)
            }
        }

        watch([focused, isFetchingPlaces, results, inputValue], (
            [newFocused, newIsFetchingPlaces, newResults, newInputValue],
            [oldFocused, oldIsFetchingPlaces, oldResults, oldInputValue]
        ) => {
            if (newInputValue.length === 0) {
                setOpenState(false);
                return;
            }

            if (newFocused === oldFocused && newIsFetchingPlaces === oldIsFetchingPlaces && newResults.length === oldResults.length) {
                return;
            }

            setOpenState(newIsFetchingPlaces || (newFocused && newResults.length > 0));
        });

        watch(props.modelValue, (newValue) => {
            inputValue.value = newValue.value;
        })

        watch(inputValue, (newValue) => {
            debouncedInputValue.value = newValue;
        });

        watch(debouncedInputValue, (newValue) => {
            search(newValue);
        });

        return {
            mapsAttributionsRef,

            // Properties
            open,
            input,
            ready,
            focused,
            results,
            inputValue,
            isFetchingPlaces,
            isGeolocationEnabled,
            currentFocus,
            computedAriaLabel,

            // Methods
            search,
            focusNext,
            focusInput,
            focusOutInput,
            clearResults,
            focusPrevious,
            selectPlace,
            setOptionRef,
            getUserLocation,
            setIsFetchingPlace,
            selectFocusedOption,
            placeSelected,
            onKeyDown,
            clearSelectedPlace,
            setInputRef
        };
    }
})
</script>
