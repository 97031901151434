<template>
    <div>
        <!-- Errors -->
        <transition name="fade">
            <div v-if="error" tabindex="0" class="p-6 bg-yellow-300 text-yellow-700 rounded-lg my-6">
                <h3 class="text-sm font-bold">Whoops!</h3>
                <p class="text-sm font-normal">{{ error }}</p>
            </div>
        </transition>

        <ul class="space-y-4">
            <template v-for="(route, routeIndex) in routes">
                <!-- Results Summary -->
                <transition name="fade">
                    <li :aria-label="`Trip number ${routeIndex + 1}, select to access full trip details`">
                        <TripPlannerRouteSummary :index="routeIndex + 1" :route="route" @selected="selectRoute(route)"></TripPlannerRouteSummary>
                    </li>
                </transition>
            </template>
        </ul>
    </div>
</template>

<script>
import TripPlannerRouteSummary from "./TripPlannerRouteSummary";
import TripPlannerRouteDetails from "./TripPlannerRouteDetails";

export default {
    name: "TripPlannerRoutes",
    components: {TripPlannerRouteSummary, TripPlannerRouteDetails},
    props: {
        routes: {
            type: [Array, null],
        },
        error: {
            type: [String, null],
        },
    },

    setup(_, { emit }) {
        function selectRoute(route) {
            emit('selectRoute', {
                route
            });
        }

        return {
            selectRoute,
        }
    }
}
</script>

<style scoped>

</style>
