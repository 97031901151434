<template>
    <div class="flex flex-wrap justify-center gap-x-8 gap-y-16 lg:gap-y-8">
        <slot></slot>
    </div>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'InfoBoxContainer',

    setup() {
        return {}
    },
});
</script>
