<template>
    <PopoverGroup as="nav">
        <slot></slot>
    </PopoverGroup>
</template>

<script>
import {PopoverGroup} from '@headlessui/vue'

export default {
    name: "FlyoutMenu",
    components: {PopoverGroup},
}
</script>
