<template>
    <div class="relative w-full bg-white xl:overflow-hidden">
        <SectionTitle :title="title" :subtitle="subtitle" link="transit-stop-features" color="green" />

        <Section>
            <template #graphic>
                <Image class="graphic" alt="" src="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/isolated/static-bus-stop-amenities-v2.svg" />
            </template>
            <template #info>
                <div class="overflow">
                    <div class="flex flex-col gap-8 justify-center mt-12" width-class="w-[380px]">

                        <div class="flex m-auto">
                            <InfoBox width-class="w-[290px] md:w-[420px]" halign="left" color="qol4-full">
                                <template #header>
                                    <div class="flex justify-around">
                                        <Image v-for="icon in infoBoxes[2].icons" :key="icon" :src="icon" class="h-24"/>
                                    </div>
                                </template>
                                <template #content>
                                    <div class="box--qol4">
                                        <InfoTable class="px-0" :header="infoBoxes[2].table.header" :rows="infoBoxes[2].table.rows"/>
                                    </div>
                                </template>
                            </InfoBox>
                        </div>

                        <div class="flex flex-wrap justify-center md:gap-8">
                            <InfoBox width-class="w-[290px] md:w-[420px]" halign="left" class="mt-8 md:mt-0" color="qol4-full">
                                <template #header>
                                    <div class="flex justify-center -mt-12">
                                        <InfoBoxIcon :icon="infoBoxes[0].icon"/>
                                    </div>
                                </template>
                                <template #content>
                                    <div class="stat" v-html="infoBoxes[0].text"></div>
                                </template>
                            </InfoBox>

                            <InfoBox width-class="w-[290px] md:w-[420px]" halign="left" class="mt-16 md:mt-0" color="qol4-full">
                                <template #header>
                                    <div class="flex justify-center -mt-12">
                                        <InfoBoxIcon :icon="infoBoxes[1].icon"/>
                                    </div>
                                </template>
                                <template #content>
                                    <div class="stat" v-html="infoBoxes[1].text"></div>
                                </template>
                            </InfoBox>
                        </div>

                    </div>
                </div>
            </template>
        </Section>
    </div>
</template>

<script>
import {defineComponent, computed} from 'vue';
import Image from "../Components/Image";
import Section from "../Components/Section";
import SectionTitle from "../Components/SectionTitle";
import InfoBox from "../Components/InfoBox";
import InfoBoxIcon from "../Components/InfoBoxIcon";
import InfoTable from "../Components/InfoTable";
import useTranslations from "../translations";

export default defineComponent({
    name: 'BusStopAmenities',
    components: {
        Image,
        Section,
        SectionTitle,
        InfoBox,
        InfoBoxIcon,
        InfoTable,
    },
    setup() {

        const { translations } = useTranslations();

        const title = computed(() => translations.value.page2.sections[2].title);
        const subtitle = computed(() => translations.value.page2.sections[2].subtitle);
        const infoBoxes = computed(() => translations.value.page2.sections[2].infoBoxes);

        return {
            title,
            subtitle,
            infoBoxes,
        };
    }
});
</script>

<style scoped lang="scss">
    .stat {
        @apply p-3;
    }

    .box__text .stat:deep( strong) {
        font-size: 2rem;
    }

    @screen md {
        .box__text .stat:deep( strong) {
            font-size: 4rem;
        }
    }
</style>
