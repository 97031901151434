<template>
    <div class="border-b-8">
        <SectionTitle link="rider-boardings" color="purple"
                      :title="$tx('Boardings: Overall and by Mode')"
                      :subtitle="`${$tx('Rider Boardings')}, 2017-2021`"/>
        <div class="flex flex-col w-full p-4 sm:p-12">
            <ChartTableSwitch page-title="Overall Boardings by Mode">
                <template v-slot:chart>
                    <ChartRenderer :chart="BarChart" chartId="rider-boardings-monthly" :chartData="chartData" :options="optionsMonthly" class="h-[450px] lg:h-[600px] hidden md:flex" label="Rider boardings by mode Chart/Graph. Refer to data table for details."/>
                    <ChartRenderer :chart="BarChart" chartId="rider-boardings-yearly" :chartData="chartDataYearly" :options="optionsYearly" class="h-[350px] flex md:hidden" label="Rider boardings by mode Chart/Graph. Refer to data table for details."/>
                    <div class="w-full max-w-lg mx-auto mt-4 text-center">
                        <p>{{ $tx('Click on legend items to include or exclude specific transit modes.') }}</p>
                    </div>
                </template>

                <template v-slot:table>
                    <div class="flex justify-center min-w-full mt-4">
                        <DataTable highlight-first :headings="dataTable.headings" :rows="dataTable.rows"
                                   class="w-full hidden md:block"/>
                        <DataTable highlight-first :headings="dataTableYearly.headings" :rows="dataTableYearly.rows"
                                   class="w-full block md:hidden"/>
                    </div>
                </template>
            </ChartTableSwitch>

            <div class="flex m-auto mt-4">
                <div class="max-w-lg text-center">
                    <CovidDisclaimer/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// See link for registering chart types to allow for tree-shaking if desired
// https://vue-chart-3.netlify.app/guide/#chart-js-v3
import {Chart, registerables} from "chart.js"
import numeral from 'numeral';
import {computed, defineComponent, inject, onUnmounted, ref} from 'vue';

import {BarChart} from 'vue-chart-3';
import ChartRenderer from "../../../components/ChartRenderer";
import {useIsSmallScreen} from "../../../utils/helpers";
import ChartTableSwitch from "../Components/ChartTableSwitch";
import CovidDisclaimer from '../Components/CovidDisclaimer';
import DataTable from "../Components/DataTable";

import SectionTitle from '../Components/SectionTitle';
import {font20, generateTickSettings, generateTitleSettings} from "./chartsUtils";

Chart.register(...registerables);

export default defineComponent({
    components: {
        ChartRenderer,
        DataTable,
        ChartTableSwitch,
        SectionTitle,
        CovidDisclaimer,
        BarChart,
    },
    setup() {
        const isSmallScreen = useIsSmallScreen();
        const tx = inject('tx')

        const datalabelOption = {
            color: 'transparent'
        };

        // DESKTOP CHART
        const chartData = computed(() => ({
            labels: [
                `${tx('January')} 2017`, `${tx('February')} 2017`, `${tx('March')} 2017`, `${tx('April')} 2017`, `${tx('May')} 2017`, `${tx('June')} 2017`, `${tx('July')} 2017`, `${tx('August')} 2017`, `${tx('September')} 2017`, `${tx('October')} 2017`, `${tx('November')} 2017`, `${tx('December')} 2017`,
                `${tx('January')} 2018`, `${tx('February')} 2018`, `${tx('March')} 2018`, `${tx('April')} 2018`, `${tx('May')} 2018`, `${tx('June')} 2018`, `${tx('July')} 2018`, `${tx('August')} 2018`, `${tx('September')} 2018`, `${tx('October')} 2018`, `${tx('November')} 2018`, `${tx('December')} 2018`,
                `${tx('January')} 2019`, `${tx('February')} 2019`, `${tx('March')} 2019`, `${tx('April')} 2019`, `${tx('May')} 2019`, `${tx('June')} 2019`, `${tx('July')} 2019`, `${tx('August')} 2019`, `${tx('September')} 2019`, `${tx('October')} 2019`, `${tx('November')} 2019`, `${tx('December')} 2019`,
                `${tx('January')} 2020`, `${tx('February')} 2020`, `${tx('March')} 2020`, `${tx('April')} 2020`, `${tx('May')} 2020`, `${tx('June')} 2020`, `${tx('July')} 2020`, `${tx('August')} 2020`, `${tx('September')} 2020`, `${tx('October')} 2020`, `${tx('November')} 2020`, `${tx('December')} 2020`,
                `${tx('January')} 2021`, `${tx('February')} 2021`, `${tx('March')} 2021`, `${tx('April')} 2021`, `${tx('May')} 2021`, `${tx('June')} 2021`
            ],
            datasets: [
                {
                    label: tx('Local Bus'),
                    backgroundColor: 'rgb(111, 65, 127)',
                    borderColor: 'rgb(132, 132, 132)',
                    data: [3675302, 3596788, 3925454, 3628970, 3691984, 3161060, 3020423, 3894332, 3882288, 4083148, 3916219, 3739929, 3920722, 3712090, 3951816, 3788440, 3741995, 3316511, 3130275, 3896975, 3726355, 4093143, 3855639, 3523105, 3781973, 3411387, 3623089, 3691768, 3583276, 3048541, 3013867, 3533235, 3465296, 3850275, 3417632, 3290496, 3518749, 3350539, 2509556, 1656164, 1707134, 1665133, 1522793, 1432912, 1580006, 1768790, 1659237, 1694426, 1621555, 1559635, 1703593, 1683513, 1640271, 1467781],
                },
                {
                    label: tx('Light Rail'),
                    backgroundColor: 'rgb(170, 205, 79)',
                    borderColor: 'rgb(132, 132, 132)',
                    data: [1395420, 1333557, 1478554, 1514456, 1374231, 1203112, 1173622, 1387778, 1365280, 1462623, 1331737, 1248553, 1351769, 1273131, 1408402, 1360297, 1276749, 1146970, 1132145, 1300396, 1290632, 1366460, 1300637, 1198822, 1316749, 1187969, 1321957, 1295839, 1255510, 1117196, 1102434, 1265384, 1289552, 1387730, 1315355, 1204532, 1283967, 1279471, 980537, 587183, 585091, 545234, 518917, 543467, 548090, 584177, 529681, 427311, 564632, 478243, 527249, 594496, 561359, 559917],
                },
                {
                    label: tx('Circulators'),
                    backgroundColor: 'rgb(92, 158, 213)',
                    borderColor: 'rgb(132, 132, 132)',
                    data: [407794, 418030, 437971, 401711, 374713, 317383, 295388, 441972, 437199, 444173, 422505, 351207, 415384, 418189, 418878, 397189, 323623, 265950, 285460, 440410, 411759, 435490, 380151, 291671, 369572, 369750, 360323, 382554, 338181, 272793, 299909, 412733, 428213, 465896, 401029, 356177, 399665, 412941, 263839, 94438, 87157, 87952, 89836, 114273, 115470, 139780, 125738, 113729, 113201, 116533, 127887, 144000, 75400, 124622],
                },
                {
                    label: tx('Express/RAPID'),
                    backgroundColor: 'rgb(248, 199, 60)',
                    borderColor: 'rgb(132, 132, 132)',
                    data: [119318, 111380, 130688, 113253, 123239, 119457, 104572, 128890, 112918, 122320, 108536, 94989, 118719, 110970, 122064, 121333, 121918, 114750, 110235, 127498, 108483, 127258, 103490, 87359, 115206, 105102, 113518, 118333, 112757, 106232, 110489, 117400, 110118, 127327, 95872, 93119, 115602, 104832, 66724, 10301, 9049, 9190, 8585, 7846, 10092, 11853, 8604, 8357, 8477, 8507, 10319, 10073, 4062, 12071],
                },
                {
                    label: tx('Vanpool'),
                    backgroundColor: 'rgb(247, 141, 30)',
                    borderColor: 'rgb(132, 132, 132)',
                    data: [104866, 91293, 95245, 95653, 108657, 106894, 95536, 110772, 100601, 101208, 84561, 67675, 70989, 71577, 76514, 78567, 94352, 82150, 87406, 92767, 81696, 88907, 78965, 78467, 82184, 77005, 81453, 86593, 89863, 78712, 85650, 89642, 84452, 90121, 77689, 74747, 93069, 82341, 59710, 42076, 38019, 36356, 34378, 36015, 37037, 38969, 36480, 31784, 37542, 34478, 37872, 38843, 35933, 35275],
                },
                {
                    label: tx('Paratransit'),
                    backgroundColor: 'rgb(241, 91, 87)',
                    borderColor: 'rgb(132, 132, 132)',
                    data: [53571, 51879, 58087, 54378, 58207, 56734, 43335, 52101, 49884, 39629, 52035, 47935, 48955, 45883, 49447, 49870, 50286, 49010, 46246, 50517, 47952, 52112, 49654, 47290, 52108, 48161, 53027, 55600, 55989, 52122, 54397, 58124, 56322, 60750, 54797, 54852, 58796, 55696, 40848, 19983, 23873, 28716, 28107, 28965, 31917, 34398, 31731, 31163, 29134, 30095, 35182, 37368, 38200, 37784],
                },
                {
                    label: tx('Rural'),
                    backgroundColor: 'rgb(124, 126, 134)',
                    borderColor: 'rgb(132, 132, 132)',
                    data: [1606, 1571, 1857, 1614, 1503, 1471, 1197, 1384, 1371, 1469, 1474, 1643, 1455, 1405, 1553, 1282, 1291, 1098, 964, 1009, 964, 1209, 1059, 780, 1094, 985, 1215, 1092, 1010, 845, 879, 1048, 1015, 1155, 726, 764, 784, 741, 606, 329, 397, 512, 275, 378, 403, 499, 441, 384, 377, 370, 522, 528, 458, 551],
                },
            ],
        }))
        const optionsMonthly = computed(() => ({
            hover: {mode: null},
            plugins: {
                tooltip: { enabled: isSmallScreen.value },
                legend: {
                    position: 'bottom',
                    align: 'center',
                    labels: {
                        font: font20,
                        boxHeight: 25,
                        boxWidth: 25,
                        padding: 8,
                    },
                },
                title: generateTitleSettings({
                    text: tx('Monthly Ridership')
                }),
                datalabels: datalabelOption,
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    title: { display: false },
                    stacked: true,
                    ticks: generateTickSettings({
                        maxTicksLimit: 18
                    }),
                },
                y: {
                    title: generateTitleSettings({
                        text: tx('Riders'),
                    }),
                    stacked: true,
                    ticks: generateTickSettings({
                        autoSkipPadding: 5,
                        callback: (value) => {
                            if (! value) {
                                return null;
                            }

                            return numeral(value).format('0.0a').replace('.0', '').toUpperCase()
                        }
                    }),
                },
            }
        }))

        // MOBILE CHART
        const chartDataYearly = computed(() => ({
            labels: [
                '2017',
                '2018',
                '2019',
                '2020',
                `${tx('Jan-Jun')} 2021`
            ],
            datasets: [
                {
                    label: tx('Local Bus'),
                    backgroundColor: 'rgb(111, 65, 127)',
                    borderColor: 'rgb(132, 132, 132)',
                    data: [44215897, 44657066, 41710835, 24065439, 9676348],
                },
                {
                    label: tx('Light Rail'),
                    backgroundColor: 'rgb(170, 205, 79)',
                    borderColor: 'rgb(132, 132, 132)',
                    data: [16268923, 15406410, 15060207, 8413126, 3285896],
                },
                {
                    label: tx('Circulators'),
                    backgroundColor: 'rgb(92, 158, 213)',
                    borderColor: 'rgb(132, 132, 132)',
                    data: [4750046, 4484154, 4457130, 2044818, 701643],
                },
                {
                    label: tx('Express/RAPID'),
                    backgroundColor: 'rgb(248, 199, 60)',
                    borderColor: 'rgb(132, 132, 132)',
                    data: [1389560, 1374077, 1325473, 371035, 53509],
                },
                {
                    label: tx('Vanpool'),
                    backgroundColor: 'rgb(247, 141, 30)',
                    borderColor: 'rgb(132, 132, 132)',
                    data: [1162961, 982357, 998111, 566234, 219943],
                },
                {
                    label: tx('Paratransit'),
                    backgroundColor: 'rgb(241, 91, 87)',
                    borderColor: 'rgb(132, 132, 132)',
                    data: [617775, 587222, 656249, 414193, 207763]
                },
                {
                    label: tx('Rural'),
                    backgroundColor: 'rgb(124, 126, 134)',
                    borderColor: 'rgb(132, 132, 132)',
                    data: [18160, 14069, 11828, 5749, 2806]
                },
            ],
        }))
        const optionsYearly = computed(() => ({
            hover: {mode: null},
            plugins: {
                tooltip: { enabled: isSmallScreen.value },
                legend: {
                    position: 'bottom',
                    labels: {
                        font: font20,
                        boxHeight: 25,
                        boxWidth: 25,
                        padding: 25,
                    },
                },
                title: generateTitleSettings({
                    text: 'Yearly',
                }),
                datalabels: datalabelOption,
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    title: { display: false },
                    stacked: true,
                    ticks: generateTickSettings({
                        maxTicksLimit: 18
                    }),
                },
                y: {
                    title: generateTitleSettings({
                        text: 'Riders',
                    }),
                    stacked: true,
                    ticks: generateTickSettings({
                        autoSkipPadding: 5,
                        callback: (value) => {
                            if (! value) {
                                return null;
                            }

                            return numeral(value).format('0.0a').replace('.0', '').toUpperCase()
                        }
                    }),
                },
            }
        }))

        const dataTable = computed(() => {
            let headings = {
                month: tx('Month'),
            };

            chartData.value.datasets.forEach((dataset) => {
                headings[dataset.label] = dataset.label;
            });

            const rows = chartData.value.labels.map((month, idx) => {
                let row = {month};

                chartData.value.datasets.forEach((dataset) => {
                    row[dataset.label] = Number(dataset.data[idx]).toLocaleString();
                });

                return row;
            });

            return {headings, rows};
        });

        const dataTableYearly = computed(() => {
            let headings = {
                year: tx('Year'),
            };

            chartDataYearly.value.datasets.forEach((dataset) => {
                headings[dataset.label] = dataset.label;
            });

            const rows = chartDataYearly.value.labels.map((year, idx) => {
                let row = {year};

                chartDataYearly.value.datasets.forEach((dataset) => {
                    row[dataset.label] = Number(dataset.data[idx]).toLocaleString();
                });

                return row;
            });

            return {headings, rows};
        });

        return {
            BarChart,
            chartData,
            dataTable,
            dataTableYearly,
            optionsYearly,
            optionsMonthly,
            chartDataYearly,
        };
    },
});
</script>
