// TODO: refactor these external dependencies to be options of some sort
import { selectedLocale } from '../pages/QOL/translations';
import map from '../translations';

export const tx = (key) => {
    if(selectedLocale.value.toLowerCase() === 'english') {
        return key
    } else {
        // If you see [missing translation] on a web page, you should run `node tx.js` in the `resources/site` directory
        return map[key] || '[missing translation]'
    }
}

export default {
    install: (app, options) => {
        app.config.globalProperties.$tx = tx
        app.provide('tx', tx)
    }
}
