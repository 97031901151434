/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import {createInertiaApp} from '@inertiajs/vue3';
import {createPinia} from "pinia";
import {setupCalendar} from 'v-calendar';
import {createApp, h} from 'vue'
import DefaultLayout from "./components/DefaultLayout";
import {metaManager} from "./metaManager";
import {installApiPlugin} from "./plugins/api";
import translation from "./plugins/translation";
import 'v-calendar/style.css';

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page.
 */

const el = document.getElementById('app');

if (el?.dataset?.page) {
    const pageData = JSON.parse(el.dataset.page);

    createInertiaApp({
        resolve: name => {
            const comp = require(`./pages/${name}`).default;

            // If the page does not set a layout, we just use the
            // default one.
            comp.layout = comp.layout || DefaultLayout;

            return comp;
        },
        setup({el, App, props, plugin}) {
            createApp({ render: () => h(App, props) })
                .use(plugin)
                .use(metaManager)
                .use(createPinia())
                .use(setupCalendar, {})
                .use(installApiPlugin({
                    baseUrl: pageData.props.global.base_url,
                    endpoint: pageData.props.global.api_endpoint,
                    laravelEndpoint: pageData.props.global.laravel_api_endpoint,
                    featureFlags: pageData.props.global.feature_flags,
                }))
                .use(translation)
                .mount(el);
        },
    });

    // Disable link navigation when site is viewed in preview mode.
    if (pageData.props.global.preview_mode) {
        document.addEventListener('inertia:before', (event) => {
            event.preventDefault();
            event.stopImmediatePropagation();

            return false;
        });

        document.addEventListener('click', (event) => {
            let target = event.target;

            if (target && target.targetName !== 'a') {
                target = target.closest('a');
            }

            if (target) {
                event.stopImmediatePropagation();
                event.preventDefault();

                return false;
            }
        });
    }
}
