<template>
    <form v-if="! enableGoogleSearchEngine" class="relative w-full" @submit.prevent="submit" tabindex="off">
        <InputSearchBox ref="inputEl" name="Search" :value="form.q" @input="onInput" title="Type the terms to search in Valley Metro website" />
    </form>
    <div v-else class="px-4 sm:min-w-[400px]" ref="inputEl">
        <div
            class="gcse-searchbox-only"
            :data-gname="name"
            :data-resultsUrl="searchRoute"
            data-enableHistory="true"
            data-enableAutoComplete="true"
            data-autoCompleteMaxCompletions="5"
            data-autoCompleteMatchType="any"
            data-queryParameterName="q"
        ></div>
    </div>
</template>

<script>
import {setActivePinia} from "pinia";
import {computed, onMounted, reactive, ref} from 'vue';
import InputSearchBox from './InputSearchBox';
import { usePage, router } from '@inertiajs/vue3';
export default {
    name: 'SiteSearchInput',
    components: { InputSearchBox },
    props: {
        name: {
            type: String,
            default: 'main-search',
        },
    },
    setup() {
        const form = reactive({
            q: usePage().props.q,
        });

        const searchRoute = computed(() =>
            '/search'
        );

        const googleSearchEngineEnabled = computed(() =>
            usePage().props.global.google.google_search_engine_enabled ?? false
        );
        const googleSearchEngineScript = computed(() =>
            usePage().props.global.google.google_search_engine_script_url ?? ''
        );

        const clear = ref(false);
        const inputEl = ref();

        onMounted(() => {
            if (googleSearchEngineEnabled.value) {
                let Script = document.createElement("script");
                Script.setAttribute("src", googleSearchEngineScript.value);
                Script.setAttribute("async", "true");
                document.body.appendChild(Script);

                Script.onload = () => {
                    const setRoleInterval = setInterval(() => {
                        const googleInput = inputEl.value.querySelector('input');

                        if (googleInput) {
                            googleInput.setAttribute('role', 'combobox');
                            googleInput.setAttribute('aria-label', 'Enhanced by Google Search');

                            clearInterval(setRoleInterval);
                        }
                    }, 500);
                };
            }
        });

        function focusOnInput() {
            if (googleSearchEngineEnabled.value) {
                inputEl.value.querySelector('input').focus();
            } else {
                inputEl.value.focusOnInput();
            }
        }

        function onInput(ev) {
            form.q = ev.target.value;
            clear.value = true;
        }

        function submit() {
            router.get('/search', form, {
                onFinish: () => (clear.value = false)
            });
        }

        return {
            form,
            enableGoogleSearchEngine: googleSearchEngineEnabled,
            clear,
            inputEl,
            onInput,
            submit,
            focusOnInput,
            searchRoute
        };
    },
};
</script>
