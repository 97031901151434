<template>
    <div class="border-t-8 relative w-full bg-white xl:overflow-hidden">
        <SectionTitle :title="title" :subtitle="subtitle" link="combating-climate-change-and-heat" color="green" />

        <Section class="">
            <template #graphic>
                <Image class="graphic" alt="" src="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/isolated/static-creating-heat-resiliency-v2.svg" />
            </template>
            <template #info>
                <div class="flex flex-wrap gap-8 justify-center mt-12">
                    <InfoBox class="mb-8 lg:mb-0" width-class="w-[290px] md:w-[420px]" :content="infoBoxes[0]" halign="left" color="qol4-full">
                        <template #header>
                            <Image :src="infoBoxes[0].icon" class="h-[7rem] -mt-12 -mb-4"/>
                        </template>
                        <template #content>
                            <div v-html="infoBoxes[0].text" class="text-center"></div>
                        </template>
                    </InfoBox>
                    <InfoBox class="mb-8 lg:mb-0" width-class="w-[290px] md:w-[420px]" :content="infoBoxes[1]" halign="left" color="qol4-full">
                        <template #header>
                            <Image :src="infoBoxes[1].icon" class="h-[7rem] -mt-12 -mb-4"/>
                        </template>
                        <template #content>
                            <div v-html="infoBoxes[1].text" class="text-center"></div>
                        </template>
                    </InfoBox>
                    <InfoBox class="mb-8 lg:mb-0" width-class="w-[290px] md:w-[420px]" :content="infoBoxes[2]" halign="left" color="qol4-full">
                        <template #header>
                            <Image :src="infoBoxes[2].icon" class="h-[7rem] -mt-12 -mb-4"/>
                        </template>
                        <template #content>
                            <div v-html="infoBoxes[2].text" class="text-center"></div>
                        </template>
                    </InfoBox>
                    <InfoBox class="mb-8 lg:mb-0" width-class="w-[290px] md:w-[420px]" :content="infoBoxes[3]" halign="left" color="qol4-full">
                        <template #header>
                            <Image :src="infoBoxes[3].icon" class="h-[7rem] -mt-12 -mb-4"/>
                        </template>
                        <template #content>
                            <div v-html="infoBoxes[3].text" class="text-center"></div>
                        </template>
                    </InfoBox>
                    <InfoBox class="mb-8 lg:mb-0" width-class="w-[290px] md:w-[420px]" :content="infoBoxes[4]" halign="left" color="qol4-full">
                        <template #header>
                            <Image :src="infoBoxes[4].icon" class="h-[7rem] -mt-12 -mb-4"/>
                        </template>
                        <template #content>
                            <div v-html="infoBoxes[4].text" class="text-center"></div>
                        </template>
                    </InfoBox>
                </div>
            </template>
        </Section>
    </div>
</template>

<script>
import {defineComponent, computed} from 'vue';
import Image from "../Components/Image";
import Section from "../Components/Section";
import SectionTitle from "../Components/SectionTitle";
import InfoBox from "../Components/InfoBox";
import InfoBoxIcon from "../Components/InfoBoxIcon";
import useTranslations from "../translations";

export default defineComponent({
    name: 'CreatingHeatResiliency',
    components: {
        Image,
        Section,
        SectionTitle,
        InfoBox,
        InfoBoxIcon,
    },
    setup() {
        const { translations } = useTranslations();

        const title = computed(() => translations.value.page2.sections[5].title);
        const subtitle = computed(() => translations.value.page2.sections[5].subtitle);
        const infoBoxes = computed(() => translations.value.page2.sections[5].infoBoxes);

        return {
            title,
            subtitle,
            infoBoxes,
        };
    }
});
</script>
