import {createNanoEvents} from "nanoevents";
import {computed, ref} from "vue";

const qolPage2Url = computed(() => {
    translateUrl(window.qualityOfLifeConfig?.page2Url || (location.origin + '/quality-of-life-2'))
})

const contents = {
    English: {
        headerTitle: 'QUALITY OF LIFE STUDY 2022',
        headerText: 'With a mission of connecting communities and enhancing lives, Valley Metro<sup>®</sup> serves the fastest growing region in the United States. Providing safe and reliable public transportation helps move people, while also supporting the local economy, healthy lifestyles and community well-being. The Quality of Life Study benchmarks the impact of our region’s transit investment and how it has benefitted overall mobility. This report, updated every three to five years, shares regional data followed by Valley Metro’s contributions.',
        headerCovid: 'Indicates data impacted by the COVID-19 pandemic.',
        backToTop: 'Back to Top',
        topics: [
            'Responding to the Valley\'s Needs',
            'Improving Communities through Transit',
            'Prioritizing Riders',
            'Benefiting the Valley',
        ],
        sections: [
            // SECTION 1
            {
                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Responding-Needs.svg',
                title: 'What\'s happening in the region',
                heading: 'Responding to the Valley\'s Needs',
                infoBoxes: [
                    { text: 'Drivers spent <strong>$1.5B</strong> and <strong>68M hours</strong> in congestion in 2020<span><img alt="COVID" role="img" src="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/covid-icon.svg" class="inline-block w-[24px] h-[24px] mt-0 mx-[12px] lg:w-[32px] lg:h-[32px] lg:-mt-[8px]" /></span>' },
                    { text: 'Over <strong>65K jobs</strong> added in Phoenix from 2009-2017 while job growth also boomed in outlying cities' },
                    {
                        text: 'Daily <strong data-def="0">Vehicle Miles Traveled</strong> increased by <strong>63%</strong> between 2000 and 2019',
                        definitions: [
                            {
                                title: 'Vehicle Miles Traveled',
                                text: 'The total number of miles driven by all vehicles in metro Phoenix in a given year. One vehicle traveling one mile is one vehicle mile.'
                            },
                        ],
                    },
                ],
                buttons: [
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_cost-congestion.svg',
                        text: 'Cost of Congestion',
                        href: `${qolPage2Url}#cost-of-congestion`
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_evolving-travel-patterns.svg',
                        text: 'Evolving Travel Patterns',
                        href: `${qolPage2Url}#evolving-travel-patterns`
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_vehicle-miles.svg',
                        text: 'Vehicle Miles Traveled',
                        href: `${qolPage2Url}#vehicle-miles-traveled`
                    }
                ]
            },
            // SECTION 2
            {
                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Responding-Needs.svg',
                title: 'Responding to the Valley\'s Needs',
                heading: 'How Valley Metro contributes<br/> to the region',
                infoBoxes: [
                    {
                        text: 'Valley Metro continues to <strong>connect</strong> <strong>communities</strong> by providing a <strong>high level</strong> <strong>of service</strong> for <span data-def="0">essential workers</span><span class="inline-block"><img alt="COVID" role="img" src="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/covid-icon.svg" class="inline-block w-[24px] h-[24px] mt-0 mx-[12px] lg:w-[32px] lg:h-[32px] lg:-mt-[8px]" /></span>',
                        definitions: [
                            {
                                title: 'Essential Workers',
                                text: 'People that are essential to providing necessary human and community services. Essential trips include any travel that is necessary for one\'s employment, health, education, or other daily needs.'
                            }
                        ]
                    },
                    { text: 'More than <strong>1M paratransit trips</strong> supporting the disability community and <strong>50M bus and light rail trips</strong> provided annually <span class="inline-block"><img alt="COVID" role="img" src="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/covid-icon.svg" class="inline-block w-[24px] h-[24px] mt-0 mx-[12px] lg:w-[32px] lg:h-[32px] lg:-mt-[8px]" /></span>' },
                    {
                        text: '<strong data-def="0">Frequent service</strong><strong> routes</strong> have higher ridership, but account for just <strong>16%</strong> of our routes',
                        definitions: [
                            {
                                title: 'Frequent Service',
                                text: 'Transit vehicles arriving every 15 minutes or better based on the Valley Metro Transit Standards and Performance Measures.',
                            }
                        ]
                    },
                ],
                buttons: [
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_rider-boardings.svg',
                        text: 'Rider Boardings',
                        href: `${qolPage2Url}#rider-boardings`
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_frequent-service.svg',
                        text: 'Frequent Service NetworkFrequent Service Network',
                        href: `${qolPage2Url}#frequent-service-network`
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_alternative-modes.svg',
                        text: 'Alternative Transit Modes',
                        href: `${qolPage2Url}#alternative-transit-modes`
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_ridership-trends.svg',
                        text: 'Ridership Trends',
                        href: `${qolPage2Url}#ridership-trends`
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_on-time-performance.svg',
                        text: 'On-Time Performance',
                        href: `${qolPage2Url}#on-time-performance`
                    },
                ]
            },
            // SECTION 3
            {
                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Improving-Communities.svg',
                title: 'What\'s happening in the region',
                heading: 'Improving Communities through Transit',
                subheading: 'In Maricopa County from 2010-2020',
                infoBoxes: [
                    { text: '<strong>15.8%</strong> population growth, <strong>4.4M</strong> total residents' },
                    { text: 'Increase of  <strong>474K jobs</strong>' },
                    { text: 'More than <strong>150K</strong> units added — a <strong>9%</strong> housing growth' },
                ],
                buttons: [
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_regional-population.svg',
                        text: 'Regional Population',
                        href: `${qolPage2Url}#regional-population`
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_regional-employment.svg',
                        text: 'Regional Employment',
                        href: `${qolPage2Url}#regional-employment`
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_regional-housing.svg',
                        text: 'Regional Housing',
                        href: `${qolPage2Url}#regional-housing`
                    },
                ]
            },
            // SECTION 4
            {
                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Improving-Communities.svg',
                title: 'Improving Communities through Transit',
                heading: 'How Valley Metro contributes<br/> to the region',
                infoBoxes: [
                    {
                        text: '<strong>$1.2B</strong> <strong><span data-def="0">non-transit infrastructure</span> improvements</strong> improve underground utilities and walkability along light rail corridors since 2006',
                        definitions: [
                            {
                                title: 'Non-transit Infrastructure',
                                text: 'Features including crosswalks, landscaping, lighting, pavement, public art and utilities that are beneficial for both transit and non-transit users.'
                            }
                        ]
                    },
                    { text: '<strong>$16B</strong> in <strong>economic</strong> <strong>investments</strong> in the light rail corridor' },
                    { text: 'More than <strong>11K jobs</strong> were created within <strong>one-half</strong> <strong>mile of light rail</strong> in the first 10 years of operation' },
                ],
                buttons: [
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_local-infrastructure.svg',
                        text: 'Local Infrastructure Investments',
                        href: `${qolPage2Url}#local-infrastructure-investments`
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_transit-oriented-development.svg',
                        text: 'Economic Investment',
                        href: `${qolPage2Url}#economic-investment`
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_job-creation.svg',
                        text: 'Job Creation',
                        href: `${qolPage2Url}#job-creation`
                    },
                ]
            },
            // SECTION 5
            {
                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Prioritizing-Riders.svg',
                title: 'What\'s happening in the region',
                heading: 'Prioritizing Riders',
                infoBoxes: [
                    { text: 'Maricopa County residents spend an average of <strong>20% of</strong> <strong>income on</strong> <strong>transportation</strong>. National average is <strong>17%</strong>' },
                    { text: '<strong>80% of all</strong> <strong>pedestrian</strong> <strong>fatalities</strong> in Arizona occur in Maricopa County' },
                    {
                        text: '<strong>$571M</strong> generated annually by <strong data-def="0">Prop. 400</strong> funds with <strong>one-third</strong> dedicated to public transit',
                        definitions: [
                            {
                                title: 'Prop. 400',
                                text: 'In 2004, voters in Maricopa County approved Proposition 400 to extend a 20-year, half-cent sales tax for transportation. The funds are used for freeways, state highways, street improvements and public transportation.',
                            }
                        ]
                    },
                ],
                buttons: [
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_affordability-transportation.svg',
                        text: 'Affordability of Transportation',
                        href: `${qolPage2Url}#affordability-of-transportation`
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_bike-ped-collisions.svg',
                        text: 'Vehicle Collisions with Bicyclists and Pedestrians',
                        href: `${qolPage2Url}#collisions-involving-cyclists-and-pedestrians`,
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_tax-breakdown.svg',
                        text: 'Regional Transportation Tax Breakdown',
                        href: `${qolPage2Url}#regional-transportation-tax-breakdown`
                    },
                ]
            },
            // SECTION 6
            {
                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Prioritizing-Riders.svg',
                title: 'Prioritizing Riders',
                heading: 'How Valley Metro contributes<br/> to the region',
                infoBoxes: [
                    { text: '<strong>Transit vehicle location and real-time tracking</strong> is available to riders with the new <strong>Valley Metro app</strong>' },
                    { text: 'Since 2015, light rail station construction included more than <strong>$16M</strong> in pedestrian/bike access and safety improvements' },
                    {
                        text: '<strong data-def="0">ShareTheRide.com</strong> users saved over <strong>$2M</strong> in 2019 and <strong>$4.5M</strong> in 2020 by utilizing <span data-def="1">alternative modes of transportation</span><span><img alt="COVID" role="img" src="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/covid-icon.svg" class="inline-block w-[24px] h-[24px] mt-0 mx-[12px] lg:w-[32px] lg:h-[32px] lg:-mt-[8px]" /></span>',
                        definitions: [
                            {
                                title: 'ShareTheRide.com',
                                text: 'A ride-matching system for commuters to quickly and securely find a carpool, vanpool, bus, light rail, bike or walk option. Commuters are matched based on proximity, destination, travel route, schedules and preferences.'
                            },
                            {
                                title: 'Alternative Modes of Transportation',
                                text: 'Modes of transportation that help reduce air pollution and improve traffic congestion including riding transit, telecommuting, biking, carpooling and walking.',
                            }
                        ]
                    },
                    { text: '<strong>Prop. 400 transit funding</strong> has leveraged more than <strong>$1.5B in federal discretionary funding</strong> to the region. These are <strong>new dollars, awarded competitively</strong> that would otherwise go to transit projects in other states and regions. The additional funding means contracts for local businesses and jobs for residents' },
                ],
                buttons: [
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_rider-survey-results.svg',
                        text: 'Rider Survey Results',
                        href: `${qolPage2Url}#rider-survey-results`
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_rider-improvements.svg',
                        text: 'Rider Improvements',
                        href: `${qolPage2Url}#rider-improvements`
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_vehicle-ownership.svg',
                        text: 'Vehicle Ownership Cost',
                        href: `${qolPage2Url}#vehicle-ownership-cost`,
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_bike-ped-safety.svg',
                        text: 'Cyclist and Pedestrian Collision Safety Investments',
                        // href: `${qolPage2Url}#bike-pedestrian-collisions-safety-investment`
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_safety-transit.svg',
                        text: 'Safety of Transit',
                        href: `${qolPage2Url}#safety-of-transit`,
                    },
                ]
            },
            // SECTION 7
            {
                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Benefiting-Valley.svg',
                title: 'What\'s happening in the region',
                heading: 'Benefiting the Valley',
                subheading: 'In Maricopa County',
                infoBoxes: [
                    {
                        text: 'From 2000-2014, <strong>temperatures rose</strong> between <strong>4 and 13 degrees</strong> Fahrenheit',
                    },
                    {
                        text: 'Valley Metro carries <strong>2% of all commuters</strong> while only contributing <strong>0.4% of <span data-def="0">greenhouse gas (GHG) emissions</strong> from vehicles',
                        definitions: [
                            {
                                title: 'Greenhouse Gas Emissions',
                                text: 'Air pollutants that trap heat. Greenhouse gas emissions from man-made activities strengthen the greenhouse effect causing climate change.'
                            }
                        ]
                    },
                    {
                        text: 'The number of days with <strong data-def="0">poor air quality</strong> has <strong>doubled since 2013</strong>',
                        definitions: [
                            {
                                title: 'Poor Air Quality',
                                text: 'A 24-hour period when the air quality index exceeds "healthy" levels as defined by the U.S. Environmental Protection Agency.'
                            }
                        ]
                    },
                ],
                buttons: [
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_heat-impact.svg',
                        text: 'Regional Heat Impact',
                        href: `${qolPage2Url}#regional-heat-impact`
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_regional-emissions.svg',
                        text: 'Regional Emissions',
                        // href: `${qolPage2Url}#regional-emissions`
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_regional-air.svg',
                        text: 'Regional Air Quality',
                        // href: `${qolPage2Url}#regional-air-quality`
                    },
                ]
            },
            // SECTION 8
            {
                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Benefiting-Valley.svg',
                title: 'Benefiting the Valley',
                heading: 'How Valley Metro contributes<br/> to the region',
                infoBoxes: [
                    {
                        text: 'Valley Metro <strong>displaces</strong> <strong>more emissions</strong> than it creates by providing <strong data-def="0">shared-mode</strong> <strong>transit</strong> options',
                        definitions: [
                            {
                                title: 'Shared-mode',
                                text: 'Transportation services shared among users including public transit, bikeshare, rideshare, carpool, vanpool and scooters for the intent of offering improved mobility.'
                            }
                        ]
                    },
                    {
                        text: '<strong>Shade protection</strong> exists at <strong>94%</strong> of <span data-def="0">high-use bus stops</span>',
                        definitions: [
                            {
                                title: 'High-use bus stops',
                                text: 'Stops that serve more than 71+ riders/day based on the Bus Stop Program and Standards Report.'
                            }
                        ]
                    },
                    {
                        text: 'Very few <strong data-def="0">criteria pollutants</strong> are created by <strong>electric-powered trains and buses</strong> powered by alternative fuels and hybrids, which is <strong>69%</strong> of the bus fleet.',
                        definitions: [
                            {
                                title: 'Criteria Pollutants',
                                text: 'Refers to six common air pollutants: carbon monoxide, lead, ground-level ozone, particulate matter, nitrogen dioxide and sulfur dioxide. At high levels, these pollutants negatively impact human health.'
                            }
                        ]
                    },
                ],
                buttons: [
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_emissions-saved.svg',
                        text: 'Emissions Saved',
                        // href: `${qolPage2Url}#emissions-saved`
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_combating-climate.svg',
                        text: 'Combating Climate Change and Heat',
                        href: `${qolPage2Url}#combating-climate-change-and-heat`
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_transit-stop.svg',
                        text: 'Transit Stop Features',
                        href: `${qolPage2Url}#transit-stop-features`
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_walkable-access.svg',
                        text: 'Walkable Access in Rail Corridor',
                        href: `${qolPage2Url}#walkable-access-in-rail-corridor`
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_mode-share.svg',
                        text: 'Mode Share by Corridor',
                        href: `${qolPage2Url}#mode-share-by-corridor`,
                    },
                ]
            },
        ],
        page2: {
            sections: [
                // SECTION 1
                {
                    title: 'Regional Heat Impact',
                    subtitle: 'Summer Heat in Phoenix',
                    note: {
                        text: 'Hot summer city temperatures have serious health impacts, including heat stress, and dangerous ground-level ozone levels on the hottest days.'
                    },
                    infoBoxes: [
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/assets/qol-buildings.svg',
                            text: 'Up to <strong>21°</strong> hotter in the city than in nearby rural areas'
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/assets/qol-thermometer.svg',
                            text: '<strong>Nine</strong> more days above 110° each year compared to rural areas'
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/assets/qol-sun.svg',
                            text: '<strong>43</strong> excessive heat warning days in Phoenix in 2020, compared to 8 in 2014'
                        },
                    ],
                },
                // SECTION 2
                {
                    title: 'Evolving Travel Patterns',
                    timeSpan: '2009 - 2019',
                    infoBoxes: [
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/assets/qol-red-pushpin.svg',
                            title: 'Most Jobs Added',
                            table: {
                                rows: [
                                    ['#1 Phoenix', '75,265'],
                                    ['#2 Gilbert', '26,160'],
                                    ['#3 Chandler', '15,050'],
                                    ['#4 Surprise', '14,887'],
                                    ['#5 Tempe', '13,373'],
                                ]
                            }
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/assets/qol-blue-pushpin.svg',
                            title: 'Most Job Percentage Growth',
                            table: {
                                rows: [
                                    ['#1 Gila Bend', '667%'],
                                    ['#2 Fort McDowell', '280%'],
                                    ['#3 Surprise', '231%'],
                                    ['#4 Queen Creek', '142%'],
                                    ['#5 Avondale', '64%'],
                                ]
                            }
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/bus.svg',
                            title: 'Room For Transit Growth',
                            text: 'The most jobs were added closer to the urban core where <strong>High Capacity Transit</strong> is prevelant. However, job growth percentage increased highest in areas not yet covered by HCT.',
                        },
                    ],
                },
                // SECTION 3
                {
                    title: 'Transit Stop Features',
                    subtitle: 'Bus Stop Amenities',
                    infoBoxes: [
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/bus_stop_amenities_1.svg',
                            text: `<div class="flex gap-2 justify-center items-center">
                                <strong>100%</strong>
                                <div>of Very High ridership bus stops have shade</div>
                            </div>`,
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/bus_stop_amenities_2.svg',
                            text: `<div class="flex gap-2 justify-center items-center">
                                <strong>55%</strong>
                                <div>of bus stops have bus shelters that provide shade</div>
                            </div>`,
                        },
                        {
                            icons: [
                                'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/bus_stop_amenities_3.svg',
                                'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/bus_stop_amenities_2.svg',
                                'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/bus_stop_amenities_4.svg',
                            ],
                            table: {
                                header: ['Bus Stop: Average Daily Ridership', 'Stops with Shelters', 'Ridership Category'],
                                rows: [
                                    ['71+', '100%', '<span class="text-sm">Very High</span>'],
                                    ['36-70', '97%', 'High'],
                                    ['10-35', '87%', 'Moderate'],
                                    ['<10', '49%', 'Low'],
                                ]
                            }
                        },
                    ],
                },
                // SECTION 4
                {
                    title: 'Cost of Congestion',
                    disclaimer: 'Data from 2020 was impacted by the COVID-19 pandemic.',
                    infoBoxes: [
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/cost_of_congestion_1.svg',
                            title: 'Annual travel delay',
                            table: {
                                rows: [
                                    ['2017', '163M hrs'],
                                    ['2018', '166M hrs'],
                                    ['2019', '168M hrs'],
                                    ['2020', '68M hrs'],
                                ]
                            }
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/cost_of_congestion_2.svg',
                            title: 'Annual delay per commuter',
                            table: {
                                rows: [
                                    ['2017', '62 hrs<br/>(7.75 work days)'],
                                    ['2018', '62 hrs<br/>(7.75 work days)'],
                                    ['2019', '61 hrs<br/>(7.5 work days)'],
                                    ['2020', '25 hrs<br/>(3 work days)'],
                                ]
                            }
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/cost_of_congestion_3.svg',
                            title: 'Annual cost',
                            table: {
                                rows: [
                                    ['2017', '$3.4B'],
                                    ['2018', '$3.6B'],
                                    ['2019', '$3.7B'],
                                    ['2020', '$1.5B'],
                                ]
                            }
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/cost_of_congestion_3.svg',
                            title: 'Annual cost per capita',
                            table: {
                                rows: [
                                    ['2017', '$1,149'],
                                    ['2018', '$1,169'],
                                    ['2019', '$1,179'],
                                    ['2020', '$489'],
                                ]
                            }
                        },
                    ],
                },
                // SECTION 5
                {
                    title: 'Cost of Vehicle Ownership',
                    graphics: [
                        {
                            title: 'Driving a vehicle',
                            alt: 'Driving a vehicle: Tax, title, license and registration, Fuel, Insurance, Maintenance, repair & tires, Finance, Depreciation, Parking fees',
                            url: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/cost-vehicle-ownership_driving-vehicle-english.svg',
                        },
                        {
                            title: 'Taking public transit',
                            alt: 'Taking public transit: A 1-day transit pass is $4. 1-ride is $2',
                            url: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/cost-vehicle-ownership_taking-public-transit-english.svg',
                        },
                    ]
                },
                // SECTION 6
                {
                    title: 'Combating Climate Change and Heat',
                    subtitle: 'Creating Heat Resiliency',
                    infoBoxes: [
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/creating_heat_resiliency_1.svg',
                            text: 'All <strong>38</strong> light rail stations provide shade.'
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/creating_heat_resiliency_5.svg',
                            text: 'Valley Metro updated the 2020 Light Rail Design Criteria Manual to offer <strong>more shade</strong> at future stations.'
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/creating_heat_resiliency_2.svg',
                            text: '<strong>800+</strong> shades trees were located along the light rail system.'
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/creating_heat_resiliency_3.svg',
                            text: 'The East Valley Bus Operations and Maintenance Center in Tempe has <br/><strong style="font-size: 1.5em;">LEED Gold Certification</strong>.'
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/creating_heat_resiliency_4.svg',
                            text: '<strong style="font-size: 1.25em; line-height: 1;">Pervious pavement Peoria Park-and-Ride and Glendale Park-and-Ride</strong> lowers the urban heat island effect and places rainwater back into the ground.'
                        },
                    ],
                },
                // SECTION 7
                {
                    title: 'Mode Share by Corridor',
                    infoBoxes: [
                        {
                            title: 'Light rail corridors',
                            text: `
                                <p>Central Ave/Osborn Rd: <strong>33%</strong> of trips taken via transit</p>
                                <p>Central Ave/Roosevelt St: <strong>53%</strong> of trips taken via transit</p>
                                <p>Apache Blvd/Dorsey Ln: <strong>36%</strong> of trips taken via light rail</p>`
                        },
                        {
                            title: 'Local bus corridors',
                            text: `
                                <p>35th Ave/Campbell Ave: <strong>6%</strong> of trips taken via bus</p>
                                <p>Thomas Rd/21st Ave: <strong>7%</strong> of trips taken via bus</p>
                                <p>Central Ave/Lincoln St: <strong>14%</strong> of trips taken via bus</p>`
                        }
                    ]
                },
                // SECTION 8
                {
                    title: ' Non-transit Local Infrastructure Improvements',
                    subtitle: 'Light rail corridor, 2004-2021',
                    infoBoxes: [
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/non_transit_infrastructure_1.svg',
                            text: `<div>
                                <div>Public art</div>
                                <strong>$16 million</strong>
                            </div>`,
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/non_transit_infrastructure_2.svg',
                            text: `<div>
                                <div>Pedestrian experience</div>
                                <strong>$66 million</strong>
                            </div>`,
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/non_transit_infrastructure_3.svg',
                            text: `<div>
                                <div>Crossing protection</div>
                                <strong>$104 million</strong>
                            </div>`,
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/non_transit_infrastructure_4.svg',
                            text: `<div>
                                <div>Utility upgrades and improvements</div>
                                <strong>$763 million</strong>
                            </div>`,
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/non_transit_infrastructure_5.svg',
                            text: `<div>
                                <div>Pavement upgrades</div>
                                <strong>$201 million</strong>
                            </div>`,
                        }
                    ],
                },
                // Section 9
                {
                    title: 'Prop. 400 Distribution',
                    subtitle: 'Regional Transportation Tax Breakdown',
                    graphics: [
                        {
                            url: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/isolated/static-prop-400-distribution-v2-b.svg',
                            mobile_url: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/static-prop-400-distribution-01.svg',
                            alt: 'Prop 400 Distribution, Revenue TBD',
                        },
                        {
                            url: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/isolated/static-prop-400-distribution-v2-a.svg',
                            mobile_url: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/static-prop-400-distribution-02.svg',
                            alt: 'Total 117.5M. Bus Operations, 63.5M. ADA Paratransit, $35.4M. Other Operating, $12.9M, includes debt, regional services and safety/security. Administration, $5.7M.',
                        }
                    ],
                    infoBox: 'RPTA operations is paid mostly by Prop. 400 then augmented with sources including fares and Regional Area Road Funds (RARF). RARF contributed an additional $5M, bringing total operating costs to $116M.',
                },
                // Section 10
                {
                    title: 'Safety of Riding Transit',
                    infoBoxes: [
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/safety_of_riding_transit_1.svg',
                            text: '<strong style="font-size: 1.4em; line-height: 1;">Signalized intersections</strong> for pedestrians and auto traffic' },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/safety_of_riding_transit_2.svg',
                            text: '<strong style="font-size: 1.4em; line-height: 1;">Signs</strong> to warn motorists and pedestrians of the light rail tracks and approaching trains' },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/safety_of_riding_transit_3.svg',
                            text: '<strong style="font-size: 1.4em; line-height: 1;">Bells</strong> to alert passengers, pedestrians and motorists to trains arriving and departing, or moving through crowded pedestrian environments'
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/safety_of_riding_transit_4.svg',
                            text: '<strong style="font-size: 1.4em; line-height: 1;">Headlights and turn signals</strong> will flash when the audible warnings are initiated as a <strong style="font-size: 1.4em; line-height: 1;">visual warning</strong> for the hearing impaired'
                        }
                    ]
                },
                // Section 11
                {
                    title: 'Vehicle Collisions with Bicyclists and Pedestrians',
                    infoBoxes: [
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/vehicle_collisions_1.svg',
                            text: 'Arizona has the <strong>5th</strong> highest pedestrian fatality rate in the nation.'
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/vehicle_collisions_2.svg',
                            text: 'There were over <strong>1,600</strong> vehicle collisions with bicyclists and pedestrians in Maricopa County in 2020.'
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/vehicle_collisions_3.svg',
                            text: 'Maricopa County accounts for over <strong>80%</strong><br class="hidden md:inline" /> all pedestrian fatalities in Arizona, with only <br class="hidden md:inline" /><strong>62%</strong> of the of the population.'
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/vehicle_collisions_4.svg',
                            text: 'Pedestrian deaths are up <strong>193%</strong><br/> in Arizona since 2009.'
                        }
                    ]
                },
                // Section 12
                {
                    title: 'Regional Air Quality',
                    subtitle: 'Days of Poor Air Quality in Maricopa County',
                },
                // Section 13
                {
                    title: 'Emissions Saved',
                    subtitle: 'Emission Impacts of Valley Metro\'s Transit Services',
                    chart1: {
                        title: 'Criteria Pollutant Emissions & Savings (in tons)',
                    },
                    chart2: {
                        title: 'Greenhouse Gas Emissions & Savings (in tons)'
                    },
                    infoBox: 'By providing shared modes of transportation, Valley Metro <strong>displaces more emissions than we create.</strong>'
                }
            ]
        }
    },
    Espanol: {
        headerTitle: 'ESTUDIO DE LA CALIDAD DE VIDA DE 2022',
        headerText: 'Con la misión de conectar a las comunidades y mejorar vidas, Valley Metro<sup>®</sup> provee servicio a la región de más rápido crecimiento en los Estados Unidos. Proporcionar transporte público seguro y confiable ayuda a movilizar a las personas y los bienes, mientras que apoya la economía local, los estilos de vida saludables, y el bienestar de la comunidad. El Estudio de la Calidad de Vida es un indicador del impacto de la inversión en el transporte en nuestra región, y cómo ha beneficiado a la movilidad en general. Este reporte, el cual es actualizado cada tres a cinco años, comparte datos regionales seguidos por las contribuciones de Valley Metro.',
        headerCovid: 'Este símbolo indica que los datos específicos se vieron impactados por la pandemia del COVID-19.',
        backToTop: 'Regresar al Inicio',
        topics: [
            'Respondiendo a las Necesidades del Valle',
            'Mejorando Comunidades a Través del Transporte',
            'Priorizando a los Pasajeros',
            'Beneficiando al Valle',
        ],
        sections: [
            // SECTION 1
            {
                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Responding-Needs.svg',
                title: 'Respondiendo a las Necesidades del Valle',
                heading: 'Qué está pasando en la región',
                infoBoxes: [
                    { text: 'En 2020 los conductores gastaron <strong>$1.5 mil millones de dólares</strong> y <strong>68 millones de horas</strong> en congestionamientos' },
                    { text: 'De 2009 a 2017 se agregaron más de <strong>65 mil empleos</strong> en Phoenix y el crecimiento de empleos también vio un auge en las ciudades periféricas' },
                    {
                        text: 'Entre 2000 y 2019 <strong data-def="0">las millas vehiculares recorridas</strong> diariamente aumentaron en un <strong>63%</strong>',
                        definitions: [
                            {
                                title: 'Millas Vehiculares Viajadas',
                                text: 'El número total de millas recorridas por todos los vehículos en el área metropolitana de Phoenix en un año determinado. Un vehículo que viaja una milla es una milla vehicular.'
                            }
                        ]
                    },
                ],
                buttons: [
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_evolving-travel-patterns.svg',
                        text: 'Patrones Evolventes de Viajes',
                        href: `${qolPage2Url}?locale=es#evolving-travel-patterns`
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_cost-congestion.svg',
                        text: 'Costo del Congestionamiento',
                        href: `${qolPage2Url}?locale=es#cost-of-congestion`
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_vehicle-miles.svg',
                        text: 'Millas Vehiculares Recorridas',
                    },
                ]
            },
            // SECTION 2
            {
                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Responding-Needs.svg',
                title: 'Respondiendo a las Necesidades del Valle',
                heading: 'Cómo contribuye Valley Metro a la región',
                infoBoxes: [
                    {
                        text: 'Valley Metro continúa <strong>conectando a las comunidades</strong> al proveer un <strong>alto nivel de servicio</strong> para los <span data-def="0">trabajadores esenciales</span><span><img alt="COVID" role="img" src="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/covid-icon.svg" class="inline-block w-[24px] h-[24px] mt-0 mx-[12px] lg:w-[32px] lg:h-[32px] lg:-mt-[8px]" /></span>',
                        definitions: [
                            {
                                title: 'Trabajadores Esenciales',
                                text: 'Las personas que son esenciales para proveer los servicios humanos y comunitarios necesarios. Los viajes esenciales incluyen cualquier viaje que sea necesario para el empleo, la salud, la educación u otras necesidades cotidianas.'
                            }
                        ]
                    },
                    { text: 'Anualmente se proveen más de <strong>1 millón de viajes cotidianos anuales de paratránsito</strong> a la comunidad de discapacitados y <strong>50 millones</strong> de viajes en autobús y tren ligero' },
                    {
                        text: '<strong>16%</strong> de las rutas locales de autobús ofrecen <strong data-def="0">servicio frecuente</strong> y tienen un mayor número de pasajeros que las rutas con menos frecuencia',
                        definitions: [
                            {
                                title: 'Servicio Frecuente',
                                text: 'Frecuencia cada 15 minutos ó más seguido basándose en los Estándares y las Medidas de Desempeño de Transporte de Valley Metro.'
                            }
                        ]
                    },
                ],
                buttons: [
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_rider-boardings.svg',
                        text: 'Abordajes de Pasajeros',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_frequent-service.svg',
                        text: 'Red de Servicio Frecuente',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_alternative-modes.svg',
                        text: 'Modos Alternativos de Transporte',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_ridership-trends.svg',
                        text: 'Tendencias de los Pasajeros',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_on-time-performance.svg',
                        text: 'Desempeño a Tiempo',
                    },
                ]
            },
            // SECTION 3
            {
                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Improving-Communities.svg',
                title: 'Mejorando Comunidades a Través del Transporte',
                heading: 'Qué está pasando en la región',
                subheading: 'En el Condado Maricopa de 2010-2020',
                infoBoxes: [
                    { text: 'Crecimiento del <strong>15.8%</strong> de la población a <strong>4.4 millones</strong> de residentes' },
                    { text: 'Aumento  <strong>474,000 empleos</strong>' },
                    { text: 'Más de <strong>150 mil</strong> unidades agregadas – un crecimiento del <strong>9%</strong> en la vivienda' },
                ],
                buttons: [
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_regional-population.svg',
                        text: 'Población Regional',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_regional-housing.svg',
                        text: 'Vivienda Regional',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_regional-employment.svg',
                        text: 'Empleo Regional',
                    },
                ]
            },
            // SECTION 4
            {
                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Improving-Communities.svg',
                title: 'Mejorando Comunidades a Través del Transporte',
                heading: 'Cómo contribuye Valley Metro a la región',
                infoBoxes: [
                    {
                        text: '<strong>$1.2 mil millones de dólares en mejoramientos</strong> a la <span data-def="0">infraestructura ajena al transporte</span> como parte de proyectos del tren ligero desde 2006',
                        definitions: [
                            {
                                title: 'Infraestructura Ajena al Transporte',
                                text: 'Elementos que incluyen cruces peatonales, jardinería ornamental, alumbrado público, pavimento, arte público y servicios públicos que son de beneficio tanto para los usuarios de transporte como para los que no lo son. '
                            }
                        ]
                    },
                    { text: '<strong>$16 mil millones de dólares en inversiones económicas</strong> en el corredor del tren ligero' },
                    { text: 'Se crearon más de <strong>11 mil empleos</strong> dentro de <strong>media milla del tren ligero</strong> en sus primeros 10 años de operación' },
                ],
                buttons: [
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_local-infrastructure.svg',
                        text: 'Inversiones en Infraestructura Local',
                        href: `${qolPage2Url}?locale=es#non-transit-infrastructure-improvements`,
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_transit-oriented-development.svg',
                        text: 'Desarrollo Orientado al Transporte',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_job-creation.svg',
                        text: 'Creación de Empleos',
                    },
                ]
            },
            // SECTION 5
            {
                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Prioritizing-Riders.svg',
                title: 'Priorizando a los Pasajeros',
                heading: 'Qué está pasando en la región',
                infoBoxes: [
                    { text: 'Los residentes del Condado Maricopa gastan un promedio del <strong>19% de sus ingresos en transporte</strong>. El promedio nacional es del <strong>17%</strong>' },
                    { text: 'El <strong>80% de todas las muertes peatonales</strong> en Arizona ocurren en el Condado Maricopa' },
                    {
                        text: '<strong>$571 millones de dólares</strong> son generados anualmente por los fondos de la <strong data-def="0">Proposición 400</strong>. Sólo una <strong>tercera parte</strong> está dedicada al transporte público',
                        definitions: [
                            {
                                title: 'Proposición 400',
                                text: 'En 2004, los votantes en el Condado Maricopa aprobaron la Proposición 400 para extender un impuesto sobre la venta de medio centavo para el transporte a 20 años. Los fondos se utilizan para autopistas, carreteras estatales, mejoramientos a las calles y transporte público.  '
                            }
                        ]
                    },
                ],
                buttons: [
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_affordability-transportation.svg',
                        text: 'Asequibilidad del Transporte',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_bike-ped-collisions.svg',
                        text: 'Colisiones de Bicicletas y Peatones',
                        href: `${qolPage2Url}?locale=es#bike-and-pedestrian-collisions`,
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_tax-breakdown.svg',
                        text: 'Desglose del Impuesto Regional de Transporte',
                    },
                ]
            },
            // SECTION 6
            {
                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Prioritizing-Riders.svg',
                title: 'Priorizando a los Pasajeros',
                heading: 'Cómo contribuye Valley Metro a la región',
                infoBoxes: [
                    { text: '<strong>La ubicación y el seguimiento en tiempo real de los vehículos de transporte</strong> están disponibles para los pasajeros con la nueva <strong>aplicación de Valley Metro</strong>' },
                    { text: 'Desde 2015, la construcción de las estaciones del tren ligero incluyó más de <strong>$16 millones de dólares</strong> para el acceso peatonal/ciclista y mejoramientos a la jardinería ornamental.' },
                    {
                        text: 'Los usuarios de <strong data-def="0">ShareTheRide.com</strong> ahorraron más de <strong>$2 millones</strong> de dólares en 2019 y $4.5 millones de dólares en 2020 usando <strong data-def="1">modos alternativos de transporte</strong> <span><img alt="COVID" role="img" src="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/covid-icon.svg" class="inline-block w-[24px] h-[24px] mt-0 mx-[12px] lg:w-[32px] lg:h-[32px] lg:-mt-[8px]" /></span>',
                        definitions: [
                            {
                                title: 'ShareTheRide.com',
                                text: 'Un sistema de apareamiento de viajes para que los viajeros encuentren de forma rápida y segura una opción para compartir sus viajes en auto o camioneta, autobús, tren ligero, bicicleta o caminando. Los viajeros se aparean basándose en su proximidad, destino, ruta del viaje, horarios y preferencias.'
                            },
                            {
                                title: 'Modos Alternativos de Transporte',
                                text: 'Modos de transporte que ayudan a reducir la contaminación del aire y mejorar el congestionamiento de tráfico, incluyendo el uso de transporte masivo, teletrabajo, ciclismo, viajes compartidos en auto y caminando.'
                            }
                        ]
                    },
                    { text: '<strong>Los fondos para el transporte de la Proposición 400</strong> han posibilitado más de <strong>$1.5 mil millones de dólares en fondos discrecionales federales</strong> para la región. Esto es <strong>dinero nuevo, otorgado competitivamente</strong> que de otra manera se destinaría a proyectos de transporte en otros estados y regiones. La financiación adicional significa contratos para empresas locales y empleos para los residentes.' },
                ],
                buttons: [
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_rider-survey-results.svg',
                        text: 'Resultados de la Encuesta de los Pasajeros',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_rider-improvements.svg',
                        text: 'Mejoramientos para los Pasajeros',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_vehicle-ownership.svg',
                        text: 'Costo de Poseer Un Vehículo',
                        href: `${qolPage2Url}locale=es#cost-of-vehicle-ownership`,
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_bike-ped-safety.svg',
                        text: 'Inversión en la Seguridad Contra Colisiones de Bicicletas y Peatones',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_safety-transit.svg',
                        text: 'Seguridad del Transporte',
                        href: `${qolPage2Url}locale=es#safety-of-riding-transit`,
                    },
                ]
            },
            // SECTION 7
            {
                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Benefiting-Valley.svg',
                title: 'Beneficiando al Valle',
                heading: 'Qué está pasando en la región',
                subheading: 'En el Condado Maricopa',
                infoBoxes: [
                    { text: 'De 2000 a 2014, <strong>las temperaturas aumentaron</strong> entre <strong>4 y 13 grados</strong> Fahrenheit' },
                    {
                        text: 'Valley Metro transporta al <strong>2%</strong> de todos los viajeros cotidianos, mientras que sus vehículos sólo contribuyen <strong>0.4% de las <span data-def="0">emisiones de gases de efecto invernadero “GHG”</span></strong>',
                        definitions: [
                            {
                                title: 'Emisiones de Gas de Efecto Invernadero',
                                text: 'Contaminantes del aire que atrapan el calor. Las emisiones de gases de efecto invernadero provenientes de las actividades humanas fortalecen el efecto invernadero que causa el cambio climático.'
                            }
                        ]
                    },
                    {
                        text: 'El número de días con <strong data-def="0">mala calidad del aire</strong> se ha <strong>duplicado desde 2013</strong>',
                        definitions: [
                            {
                                title: 'Mala Calidad del Aire',
                                text: 'Un período de 24 horas en el que el índice de la calidad del aire excede los niveles "saludables" en conformidad con aquello definido por la Agencia de Protección Medioambiental de los Estados Unidos.'
                            }
                        ]
                    },
                ],
                buttons: [
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_heat-impact.svg',
                        text: 'Impacto Regional del Calor',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_regional-emissions.svg',
                        text: 'Emisiones Regionales',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_regional-air.svg',
                        text: 'Calidad Regional del Aire',
                    },
                ]
            },
            // SECTION 8
            {
                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/topic-icons/QOL_topic-icons_Benefiting-Valley.svg',
                title: 'Beneficiando al Valle',
                heading: 'Cómo contribuye Valley Metro a la región',
                infoBoxes: [
                    {
                        text: 'Valley Metro <strong>substituye más emisiones</strong> de las que crea al proveer opciones de <strong data-def="0">modos de transporte compartido</strong>',
                        definitions: [
                            {
                                title: 'Modo de Transporte Compartido',
                                text: 'Servicios de transporte compartidos entre los usuarios, incluyendo transporte público, bicicletas compartidas, viajes compartidos en auto y en camioneta, y escúteres con la intención de ofrecer mejor movilidad.'
                            }
                        ]
                    },
                    {
                        text: 'Hay <strong>protección sombreada</strong> en el <strong>94%</strong> de las <span data-def="0">paradas de autobús de alto uso</span>',
                        definitions: [
                            {
                                title: 'Paradas de Autobús de Alto Uso',
                                text: 'Paradas que proveen servicio a más de 71 pasajeros/día basándose en el Programa de Paradas de Autobús y el Reporte de Estándares.'
                            }
                        ]
                    },
                    {
                        text: 'Muy pocos <span data-def="0">contaminantes criterio</span> son creados por los <strong>trenes eléctricos</strong> y <strong>autobuses potenciados por gas natural <span data-def="1">“CNG”</span></strong>',
                        definitions: [
                            {
                                title: 'Contaminantes Criterio',
                                text: 'Se refiere a seis contaminantes comunes del aire: monóxido de carbono, plomo, ozono a nivel del suelo, material particulado, dióxido de nitrógeno y dióxido de azufre. En niveles altos, estos contaminantes tienen un impacto negativo en la salud humana.'
                            },
                            {
                                title: 'CNG',
                                text: 'El Gas Natural Comprimido (CNG por sus siglas en inglés) es un combustible comúnmente usado en los autobuses de transporte público.'
                            }
                        ]
                    },
                ],
                buttons: [
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_emissions-saved.svg',
                        text: 'Emisiones Ahorradas',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_combating-climate.svg',
                        text: 'Luchando Contra el Cambio Climático y el Calor',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_transit-stop.svg',
                        text: 'Características de las Paradas de Transporte',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_walkable-access.svg',
                        text: 'Acceso Transitable a Pie en el Corredor del Tren Ligero',
                    },
                    {
                        icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/buttons/qol-button_mode-share.svg',
                        text: 'Porcentaje de Modos por Corredor',
                        href: `${qolPage2Url}?locale=es#mode-share-by-corridor`,
                    },
                ]
            },
        ],
        page2: {
            sections: [
                // SECTION 1
                {
                    title: 'Impacto Regional del Calor',
                    subtitle: 'Calor del Verano en Phoenix',
                    note: {
                        text: 'Las altas temperaturas de la ciudad durante el verano tienen serios impactos a la salud, incluyendo el estrés por calor, y peligrosos niveles de ozono a nivel del suelo durante los días más calurosos.'
                    },
                    infoBoxes: [
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/assets/qol-buildings.svg',
                            text: 'Hasta <strong>21°</strong> más caliente en la ciudad que en las áreas rurales cercanas'
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/assets/qol-thermometer.svg',
                            text: '<strong>Nueve</strong> días adicionales a más de 110º cada año en comparación con las áreas rurales'
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/assets/qol-sun.svg',
                            text: '<strong>43</strong> días con advertencias de calor excesivo en Phoenix en 2020, en comparación con 8 en 2014'
                        },
                    ],
                },
                // SECTION 2
                {
                    title: 'Patrones de Viaje Evolucionando',
                    note: {
                        title: 'Espacio para el Crecimiento del Transporte',
                        text: 'La mayoría de los empleos se agregaron más cerca del núcleo urbano, donde prevalece el <strong>Transporte de Alta Capacidad.</strong> Sin embargo, el porcentaje de crecimiento de empleos aumentó más en las áreas que aún no están cubiertas por el transporte de alta capacidad.'
                    },
                    timeSpan: '2009 - 2019',
                    infoBoxes: [
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/assets/qol-red-pushpin.svg',
                            title: 'Mayoría de Empleos Agregados',
                            table: {
                                rows: [
                                    ['#1 Phoenix', '75,265'],
                                    ['#2 Gilbert', '26,160'],
                                    ['#3 Chandler', '15,050'],
                                    ['#4 Surprise', '14,887'],
                                    ['#5 Tempe', '13,373'],
                                ]
                            }
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/assets/qol-blue-pushpin.svg',
                            title: 'Mayor Porcentaje de Crecimiento de Empleos',
                            table: {
                                rows: [
                                    ['#1 Gila Bend', '667%'],
                                    ['#2 Fort McDowell', '280%'],
                                    ['#3 Surprise', '231%'],
                                    ['#4 Queen Creek', '142%'],
                                    ['#5 Avondale', '64%'],
                                ]
                            }
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/bus.svg',
                            title: 'Room For Transit Growth',
                            text: 'The most jobs were added closer to the urban core where <strong>High Capacity Transit</strong> is prevelant. However, job growth percentage increased highest in areas not yet covered by HCT.',
                        },
                    ],
                },
                // SECTION 3
                {
                    title: 'Características de la Parada de Transporte',
                    subtitle: 'Servicios en la Parada de Autobús',
                    infoBoxes: [
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/bus_stop_amenities_1.svg',
                            text: `<div class="flex gap-2 justify-center items-center">
                                <strong>100%</strong>
                                <div>de las paradas de autobús de Muy Alto número de pasajeros cuentan con sombra</div>
                            </div>`,
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/bus_stop_amenities_2.svg',
                            text: `<div class="flex gap-2 justify-center items-center">
                                <strong>55%</strong>
                                <div>de las paradas de autobús tienen refugios que proveen sombra</div>
                            </div>`,
                        },
                        {
                            icons: [
                                'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/bus_stop_amenities_3.svg',
                                'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/bus_stop_amenities_2.svg',
                                'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/bus_stop_amenities_4.svg',
                            ],
                            table: {
                                header: ['Parada de Autobús: Promedio de Pasajeros Diarios', 'Paradas con Refugios', 'Categoría de Número de Pasajeros'],
                                rows: [
                                    ['71+', '100%', 'Muy Alto'],
                                    ['36-70', '97%', 'Alto'],
                                    ['10-35', '87%', 'Moderado'],
                                    ['<10', '49%', 'Bajo'],
                                ]
                            }
                        },
                    ],
                },
                // SECTION 4
                {
                    title: 'Costo del Congestionamiento',
                    disclaimer: 'Los datos de 2020 fueron impactados por la pandemia del COVID-19.',
                    infoBoxes: [
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/cost_of_congestion_1.svg',
                            title: 'Retraso anual de viajes en horas',
                            table: {
                                rows: [
                                    ['2017', '163 millones'],
                                    ['2018', '166 millones'],
                                    ['2019', '168 millones'],
                                    ['2020', '68 millones'],
                                ]
                            }
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/cost_of_congestion_2.svg',
                            title: 'Retraso anual por viajero cotidiano',
                            table: {
                                rows: [
                                    ['2017', '62 hrs (7.75 días laborales)'],
                                    ['2018', '62 hrs (7.75 días laborales)'],
                                    ['2019', '61 hrs (7.5 días laborales)'],
                                    ['2020', '25 hrs (3 días laborales)'],
                                ]
                            }
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/cost_of_congestion_3.svg',
                            title: 'Costo anual per cápita',
                            table: {
                                rows: [
                                    ['2017', '$1,149'],
                                    ['2018', '$1,169'],
                                    ['2019', '$1,179'],
                                    ['2020', '$489'],
                                ]
                            }
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/cost_of_congestion_3.svg',
                            title: 'Costo anual',
                            table: {
                                rows: [
                                    ['2017', '3.4 mil millones'],
                                    ['2018', '3.6 mil millones'],
                                    ['2019', '3.7 mil millones'],
                                    ['2020', '1.5 mil millones'],
                                ]
                            }
                        },
                    ],
                },
                // SECTION 5
                {
                    title: 'Costo por Poseer Un Vehículo',
                    graphics: [
                        {
                            title: 'Conduciendo un vehículo',
                            alt: 'Conduciendo un vehículo: Impuestos, título, placa y registro, Combustible, Seguro, Mantenimiento, reparación y llantas, Finanzas, Depreciación, Tarifas de estacionamiento',
                            url: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/cost-vehicle-ownership_driving-vehicle-spanish.svg',
                        },
                        {
                            title: 'Tomando transporte público',
                            alt: 'Tomando transporte público: Un pase de transporte para 1 día cuesta $4. 1 viaje cuesta $2',
                            url: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/cost-vehicle-ownership_taking-public-transit-spanish.svg',
                        },
                    ]
                },
                // SECTION 6
                {
                    title: 'Combatiendo el Cambio Climático y el Calor',
                    subtitle: 'Creando Resiliencia al Calor',
                    infoBoxes: [
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/creating_heat_resiliency_1.svg',
                            text: 'Todas las <strong>38</strong> estaciones del tren ligero proveen sombra'
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/creating_heat_resiliency_1.svg',
                            text: 'Valley Metro actualizó el Manual de Criterios de Diseño del Tren Ligero de 2020 para ofrecer <strong>más sombra</strong> en las estaciones futuras'
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/creating_heat_resiliency_2.svg',
                            text: 'Hay <strong>más de 800</strong> árboles de sombra a lo largo del sistema del tren ligero'
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/creating_heat_resiliency_3.svg',
                            text: 'El Centro de Operaciones y Mantenimiento de Autobuses del Este del Valle en Tempe cuenta con una <br/><strong style="font-size: 1.5em;">Certificación LEED de Oro</strong>'
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/creating_heat_resiliency_4.svg',
                            text: '<strong style="font-size: 1.25em; line-height: 1;">El pavimento permeable en los sitios de estacionamiento Peoria Park-and-Ride y Glendale Park-and-Ride</strong> reduce el efecto de la isla de calor urbano y reintroduce el agua de lluvia otra vez al suelo'
                        },
                    ],
                },
                // SECTION 7
                {
                    title: 'Porcentajes de Modos de Transporte por Corredor',
                    infoBoxes: [
                        {
                            title: 'Corredores del tren ligero de Valley Metro',
                            text: `
                                <p>Central Ave/Osborn Rd: <strong>33%</strong> de los viajes usando transporte público</p>
                                <p>Central Ave/Roosevelt St: <strong>53%</strong> de los viajes usando transporte público</p>
                                <p>Apache Blvd/Dorsey Ln: <strong>36%</strong> de los viajes usando al tren ligero rail</p>`
                        },
                        {
                            title: 'Corredores del autobús local',
                            text: `
                                <p>35th Ave/Campbell Ave: <strong>6%</strong> de los viajes usando autobús</p>
                                <p>Thomas Rd/21st Ave: <strong>7%</strong> de los viajes usando autobús</p>
                                <p>Central Ave/Lincoln St: <strong>14%</strong> de los viajes usando autobús</p>`
                        }
                    ]
                },
                // SECTION 8
                {
                    title: 'Mejoramientos a la Infraestructura Ajena al Transporte',
                    infoBoxes: [
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/non_transit_infrastructure_1.svg',
                            text: `<div>
                                <div>Arte Público</div>
                                <strong>$16 millones</strong>
                            </div>`,
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/non_transit_infrastructure_2.svg',
                            text: `<div>
                                <div>Experiencia peatonal</div>
                                <strong>$66 millones</strong>
                            </div>`,
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/non_transit_infrastructure_3.svg',
                            text: `<div>
                                <div>Protección al cruzar</div>
                                <strong>$104 millones</strong>
                            </div>`,
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/non_transit_infrastructure_4.svg',
                            text: `<div>
                                <div>Actualizaciones y mejoramientos a los servicios públicos</div>
                                <strong>$763 millones</strong>
                            </div>`,
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/non_transit_infrastructure_5.svg',
                            text: `<div>
                                <div>Actualizaciones al pavimento</div>
                                <strong>$201 millones</strong>
                            </div>`,
                        }
                    ],
                },
                // Section 9
                {
                    title: 'Distribución de la Proposición 400 ',
                    subtitle: '',
                    infoBox: 'Las operaciones de RPTA se pagan principalmente con la Prop. 400 y luego se aumentan con fuentes que incluyen tarifas y fondos de carreteras del área regional (RARF). RARF contribuyó con $ 5 millones adicionales, lo que elevó los costos operativos totales a $ 116 millones.',
                    graphics: [
                        {
                            url: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/static-prop-400-distribution-v2-Spanish-01.svg'
                        },
                        {
                            url: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/static-prop-400-distribution-v2-Spanish-02.svg'
                        }
                    ]
                },
                // Section 10
                {
                    title: 'Seguridad Viajando en Transporte Masivo',
                    infoBoxes: [
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/safety_of_riding_transit_1.svg',
                            text: '<strong style="font-size: 1.4em; line-height: 1;">Intersecciones con semáforos</strong> para el tráfico peatonal y vehicular' },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/safety_of_riding_transit_2.svg',
                            text: '<strong style="font-size: 1.4em; line-height: 1;">Señales</strong> para advertir a los automovilistas y peatones sobre las vías del tren ligero y los trenes aproximándose' },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/safety_of_riding_transit_3.svg',
                            text: '<strong style="font-size: 1.4em; line-height: 1;">Campanas</strong> para alertar a los pasajeros, peatones y automovilistas sobre los trenes llegando y saliendo, o movilizándose por entornos peatonales abarrotados'
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/safety_of_riding_transit_4.svg',
                            text: '<strong style="font-size: 1.4em; line-height: 1;">Los faros y las señales para dar vuelta</strong> parpadearán cuando se inicien las advertencias audibles como una <strong style="font-size: 1.4em; line-height: 1;">advertencia visual</strong> para las personas con discapacidades auditivas'
                        }
                    ]
                },
                // Section 11
                {
                    title: 'Colisiones Vehiculares con Ciclistas y Peatones',
                    infoBoxes: [
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/vehicle_collisions_1.svg',
                            text: 'Arizona tiene la <strong>5<sup>to</sup></strong> tasa de mortalidad peatonal más alta de la nación'
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/vehicle_collisions_2.svg',
                            text: 'Hubo más de <strong>1,600</strong> colisiones de vehículos con ciclistas y peatones en el Condado Maricopa en 2020'
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/vehicle_collisions_3.svg',
                            text: 'Más del <strong>80%</strong><br/> de todas las muertes peatonales en Arizona ocurrieron en el Condado Maricopa, con sólo el 62% de la población'
                        },
                        {
                            icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/vehicle_collisions_4.svg',
                            text: 'as muertes de peatones han aumentado un <strong>193%</strong><br/> Arizona desde 2009'
                        }
                    ]
                },
                // @todo: Section 12
                {
                    title: 'Regional Air Quality',
                    subtitle: 'Days of Poor Air Quality in Maricopa County',
                },
                // @todo: Section 13
                {
                    title: 'Emissions Saved',
                    subtitle: 'Emission Impacts of Valley Metro\'s Transit Services',
                    chart1: {
                        title: 'Criteria Pollutant Emissions & Savings (in tons)',
                    },
                    chart2: {
                        title: 'Greenhouse Gas Emissions & Savings (in tons)'
                    }
                }
            ]
        }
    },
};

const locale = ref();
const translations = computed(() => contents[locale.value]);
export const selectedLocale = computed(() => locale.value);

const translate = computed(() => function (english, spanish, callback = null) {
    if (callback) {
        setTimeout(() => callback(), 200)
    }

    return locale.value == 'English' ? english : spanish
})

function translateUrl(url, hash) {
    url = new URL(url);

    if (hash) {
        url.hash = hash;
    }

    const localeSlug = locale.value === 'English' ? 'en' : 'es';

    if (localeSlug === 'es') {
        url.searchParams.set('l', 'es');
        return url.toString();
    }

    url.searchParams.delete('l');
    return url.toString();
}

const emitter = createNanoEvents();

function selectEnglishLocale() {
    locale.value = 'English';
    document.documentElement.setAttribute('lang', 'en');
    emitter.emit('localeChanged');
}

function selectSpanishLocale() {
    locale.value = 'Espanol';
    document.documentElement.setAttribute('lang', 'es');
    emitter.emit('localeChanged');
}

function onLocaleChange(cb) {
    return emitter.on('localeChanged', cb);
}

const params = new URLSearchParams(document.location.search.replace(/^\?/, ''))

params.get('l') == 'es'
    ? selectSpanishLocale()
    : selectEnglishLocale();

export default function useTranslations() {
    return {
        locale,
        translations,
        selectedLocale,

        translate,
        qolPage2Url,

        translateUrl,
        onLocaleChange,
        selectEnglishLocale,
        selectSpanishLocale,
    }
}

