import {isNumber} from "lodash";
import {computed} from "vue";
import {usePage} from "@inertiajs/vue3";
import tailwindConfig from '../../tailwind.config.js';
import resolveConfig from "tailwindcss/resolveConfig";
import {useBreakpointTailwindCSS} from "vue-composable";

export function slugify (value) {
    if (value === null) {
        return ''
    }
    return value
        .toString()
        .trim()
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^\w\-]+/g, '')
        .replace(/\-\-+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');
}

export function updateBrowserUrl(url) {
    window.history.pushState(
        {},
        null,
        url
    );
}

/**
 * Get the straight distance in miles between two geolocation points
 * using the Haversine formula.
 *
 * @param point1
 * @param point2
 * @returns {number}
 */
export function haversineDistance(point1, point2) {
    // Radius of the Earth in miles
    const R = 3958.8;

    // Convert degrees to radians
    const rlat1 = point1.lat * (Math.PI/180);
    const rlat2 = point2.lat * (Math.PI/180);

    // Calculate radian difference
    const difflat = rlat2 - rlat1;
    const difflon = (point2.lng - point1.lng) * (Math.PI/180);

    return 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2) * Math.sin(difflat/2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon/2) * Math.sin(difflon/2)));
}

export function useTailwindBreakpoints() {
    return useBreakpointTailwindCSS(resolveConfig(tailwindConfig));
}

export function useIsSmallScreen() {
    const tailwindBreakpoints = useTailwindBreakpoints();

    if (! tailwindBreakpoints) {
        return false;
    }


    return computed(() => (tailwindBreakpoints.current.value === undefined || tailwindBreakpoints.current.value === 'sm'));
}

export function sortAndPrepareRouteGroups(groups) {
    const groupsConfig = usePage().props.global.routes.groups_order.reduce((groupsOrder, item, itemIndex) => {
        if (item.enabled) {
            groupsOrder[item.group_name.toLowerCase()] = {
                order: itemIndex,
                customName: item.custom_name && item.custom_name.trim().length > 0
                    ? item.custom_name
                    : null,
            };
        }

        return groupsOrder;
    }, {});

    return groups
        .map((group) => {
            const cfg = groupsConfig[group.name.toLowerCase()] || {};

            return {
                ...group,
                name: cfg.customName || group.name,
                apiName: group.name,
                order: (isNumber(cfg.order) && cfg.order >= 0)
                    ? cfg.order
                    : (group.order || 0) + 9999,
            }
        })
        .sort((a, b) => a.order - b.order);
}
