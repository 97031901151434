<template>
    <div class="section flex flex-col lg:flex-row" :class="{ 'border-b-8': !removeBorder }">
        <div class="left-side bottom mt-8 lg:mt-0">
            <slot name="graphic"/>
        </div>
        <div class="right-side top lg:px-4 pb-12">
            <slot name="info"/>
        </div>
    </div>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'Section',
    props: {
        removeBorder: {
            type: Boolean,
            default: false,
        }
    },
    components: {

    },
    setup() {

    }
});
</script>

<style scoped lang="scss">
    .section {
        font-size: 1rem;
        line-height: 1.4;
    }

    .top {
        order:  1;
        z-index: 1;
    }

    .bottom {
        order:  2;
        z-index: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .left-side:deep(.graphic) {
        height: 100%;
        max-width: none;
    }

    @media(min-width: theme('screens.lg')) {
        .left-side {
            order:  1;
            width: 40%;
        }
        .right-side {
            order:  2;
            width: 60%;
        }
    }
</style>
