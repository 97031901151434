<template>
    <div class="border-b-8">
        <SectionTitle link="rider-improvements" color="blue" class="mb-8"
            :title="$tx('Rider Improvements')"
            :subtitle="$tx('Valley Metro App Downloads, July-Dec. 2021')"/>
        <div class="flex flex-col p-4 md:p-12">
            <div class="flex flex-col flex-1 m-auto w-full">
                <p class="hidden m-auto mb-8 text-xl text-slate-600 lg:text-3xl lg:block">{{ $tx('46,025 App Downloads in 2021') }}</p>
                <ChartTableSwitch page-title="Rider Improvements">
                    <template v-slot:chart>
                        <ChartRenderer :chart="LineChart" chartId="rider-improvements-chart" :chartData="chartData" :options="options" class="w-full h-[350px] md:h-450px] lg:h-[600px]"
                            label="Valley Metro App downloads Chart/Graph. Refer to data table for details." />
                    </template>
                    <template v-slot:table>
                        <DataTable highlight-first v-bind="dataTable" />
                    </template>
                </ChartTableSwitch>
            </div>
            <div class="flex m-auto mt-8">
                <div class="max-w-lg text-center">
                    <p class="mb-8 text-xl text-slate-600 lg:text-3xl lg:hidden">{{ $tx('46,025 App Downloads in 2021') }}</p>

                    <p class="text-lg md:text-xl text-slate-600">
                        {{ $tx('Previous rider app, RideKick') }}
                    </p>
                    <p class="text-md md:text-lg text-slate-600 mt-2">
                        {{ $tx('141,504 downloads, 2015-2020') }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {computed, defineComponent, inject} from 'vue';
import ChartRenderer from "../../../components/ChartRenderer";
import {useIsSmallScreen} from "../../../utils/helpers";
import ChartLabelHint from "../Components/ChartLabelHint";
import ChartTableSwitch from "../Components/ChartTableSwitch";
import DataTable from "../Components/DataTable";

import SectionTitle from '../Components/SectionTitle';

import { LineChart } from 'vue-chart-3';
// See link for registering chart types to allow for tree-shaking if desired
// https://vue-chart-3.netlify.app/guide/#chart-js-v3
import { Chart, registerables } from "chart.js";
import {font20, generateDataLabelsSettings, generateTickSettings, generateTitleSettings} from "./chartsUtils";
Chart.register(...registerables);

export default defineComponent({
    components: {
        ChartRenderer,
        ChartLabelHint,
        DataTable,
        ChartTableSwitch,
        SectionTitle,
        LineChart,
    },
    setup() {
        const isSmall = useIsSmallScreen();
        const tx = inject('tx');

        const labelformat = (context) => {
            if (context.dataset.label == tx('Total Downloads')) {
                return context.formattedValue + ' ' + tx('total downloads')
            }

            return `${context.formattedValue} ${tx('downloads in')} ${context.label}`
        }

        const titleformat = (context) => {
           return context[0].label + ' 2021'
        }

        const chartData = computed(() => ({
            labels: [
                tx('July'),
                tx('August'),
                tx('September'),
                tx('October'),
                tx('November'),
                tx('December')
            ],
            datasets: [
                {
                    label: tx('Monthly Downloads'),
                    backgroundColor: 'rgba(208, 229, 244, 0.5)',
                    borderColor: 'rgb(7, 89, 133)',
                    data: [5649,8651,6614,8349,8962,7800],
                    fill: 'start',
                    tension: 0.5,
                    datalabels: generateDataLabelsSettings({
                        anchor: 'end',
                        align: (context) => context.dataIndex === 0 ? 0 : -135,
                        color: '#000000',
                        labels: {
                            value: {
                                formatter: (value) => {
                                    return value.toLocaleString?.('en-US');
                                }
                            }
                        }
                    })
                },
                {
                    label: tx('Total Downloads'),
                    backgroundColor: 'rgba(89, 23, 105, 0.5)',
                    borderColor: 'rgb(89, 23, 105)',
                    data: [5649,14300,20914,29263,38225,46025],
                    fill: 'start',
                    tension: 0.5,
                    datalabels: generateDataLabelsSettings({
                        anchor: 'end',
                        align: (context) => context.dataIndex === 0 ? 0 : -135,
                        color: '#000000',
                        labels: {
                            value: {
                                formatter: (value) => {
                                    return value.toLocaleString?.('en-US');
                                }
                            }
                        }
                    }),
                },
            ],
        }));

        const options = computed(() => ({
            hover: { mode: null },
            padding: 4,
            plugins: {
                tooltip: {
                    enabled: isSmall.value,
                    callbacks: {
                        label: labelformat,
                        title: titleformat,
                    },
                    displayColors: false,
                },
                title: { display: false },
                legend: {
                    position: 'bottom',
                    reverse: true,
                    labels: {
                        boxWidth: 25,
                        boxHeight: 25,
                        font: font20,
                        usePointStyle: false,
                    },
                },
            },
            elements: {
                point: {
                    borderWidth: 8,
                    borderColor: 'rgb(7, 89, 133)',
                    radius: 1,
                    hoverRadius: 2,
                    hoverBorderWidth: 12,
                    hitRadius: 10,
                },
            },
            responsive: true,
            scales: {
                x: {
                    ticks: generateTickSettings(),
                },
                y: {
                    min: 5000,
                    title: generateTitleSettings({
                        text: tx('Downloads'),
                    }),
                    ticks: generateTickSettings({
                        callback: (value, index) => {
                            return value.toLocaleString("en-US")
                        }
                    }),
                },
            },
        }));

        const dataTable = computed(() => {
            const headings = {
                month: tx('Month'),
            };

            chartData.value.datasets.forEach((dataset) => {
                headings[dataset.label] = dataset.label;
            });

            const rows = chartData.value.labels.map((month, idx) => {
                const row = { month };
                chartData.value.datasets.forEach((dataset) => row[dataset.label] = Number(dataset.data[idx]).toLocaleString());
                return row;
            })

            return { headings, rows };
        });

        return { LineChart, tx, chartData, options, dataTable };
    }
});
</script>
