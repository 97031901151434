<template>
    <div class="relative w-full h-full p-6 md:p-8 lg:p-16 max-w-screen-xl mx-auto bg-primary xl:rounded-xl xl:mb-10">
        <AppHead :title="title">
            <meta head-key="description" name="description" :content="`${title}`"/>
        </AppHead>

        <div class="w-full max-w-7xl mx-auto flex justify-end mb-2 pt-4 px-2 md:px-8" v-if="localizations.length > 1">
            <content-locale-selector :options="localizations" :locale="locale"/>
        </div>

        <EditButton :url="edit_url"/>

        <!-- Page title -->
        <div class="relative w-full max-w-7xl mx-auto rounded-lg">
            <!-- Title -->
            <h1 v-if="title" class="tk-museo block pb-4 text-5xl text-left leading-loose font-extrabold tracking-tight text-white sm:text-5xl">
                {{ title }}
            </h1>
        </div>

        <div class="relative w-full max-w-7xl mx-auto">
            <Webform v-bind="formConfig"></Webform>
        </div>

    </div>
</template>
<script>
import {Link} from '@inertiajs/vue3';
import {defineComponent} from "vue";
import AppHead from "../components/AppHead";
import ContentLocaleSelector from "../components/ContentLocaleSelector";
import EditButton from "../components/EditButton";
import Webform from "../components/Forms/Webform";
import SetsRenderer from "../components/SetsRenderer";

export default defineComponent({
    name: "WebformEntry",
    components: {Webform, EditButton, AppHead, SetsRenderer, Link, ContentLocaleSelector},
    props: {
        title: {type: String, required: true},
        content: {type: [Array, null], required: true},
        formConfig: {type: Object, required: true},
        localizations: {type: Array, required: true},
        locale: {type: String, required: true},
        edit_url: {type: [String, null], default: null},
    },
});
</script>

<style scoped>

</style>
