<template>
    <div class="relative w-full bg-white xl:overflow-hidden">
        <SectionTitle :title="content.title" :subtitle="content.subtitle" link="local-infrastructure-investments" color="orange" class="mb-8" />

        <Section class="">
            <template #graphic>
                <Image class="graphic" alt="" src="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/isolated/static-non-transit-infrastructure-v2.svg" />
            </template>
            <template #info>
                <div class="flex flex-wrap gap-8 justify-center">
                    <InfoBox v-for="(infoBox, index) in content.infoBoxes" :key="index" halign="left" color="qol2-full" width-class="w-[300px]">
                        <template #header>
                            <Image :src="infoBox.icon" class="mb-[28px] h-[4rem] lg:mb-[40px] lg:h-[8rem]" />
                        </template>
                        <template #content>
                            <div v-html="infoBox.text" class="text-center"></div>
                        </template>
                    </InfoBox>
                </div>
            </template>
        </Section>
    </div>
</template>

<script>
import {defineComponent, computed} from 'vue';
import Image from "../Components/Image";
import Section from "../Components/Section";
import SectionTitle from "../Components/SectionTitle";
import InfoBox from "../Components/InfoBox";
import InfoBoxIcon from "../Components/InfoBoxIcon";
import useTranslations from "../translations";

export default defineComponent({
    name: 'NonTransitInfrastructureImprovements',
    components: {
        Image,
        Section,
        SectionTitle,
        InfoBox,
        InfoBoxIcon,
    },
    setup() {
        const { translate, translations } = useTranslations();

        const content = computed(() => ({
            title: translate.value(
                'Non-transit Local Infrastructure Improvements',
                'Mejoramientos a la Infraestructura Ajena al Transporte'
            ),
            subtitle: translate.value(
                'Light rail corridor, 2004-2021',
                'Corredor del Tren Ligero, 2004-2021'
            ),
            infoBoxes: [
                {
                    icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/non_transit_infrastructure_1.svg',
                    text: `<div>
                                <div>${translate.value('Public art', 'Arte público')}</div>
                                <strong>${translate.value('$16 million', '$16 millones de dólares')}</strong>
                            </div>`,
                },
                {
                    icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/non_transit_infrastructure_2.svg',
                    text: `<div>
                                <div>${translate.value('Pedestrian experience', 'Experiencia peatonal')}</div>
                                <strong>${translate.value('$66 million', '$66 millones de dólares')}</strong>
                            </div>`,
                },
                {
                    icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/non_transit_infrastructure_3.svg',
                    text: `<div>
                                <div>${translate.value('Crossing protection', 'Protección al cruzar')}</div>
                                <strong>${translate.value('$104 million', '$104 millones de dólares')}</strong>
                            </div>`,
                },
                {
                    icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/non_transit_infrastructure_4.svg',
                    text: `<div>
                                <div>${translate.value('Utility upgrades and improvements', 'Actualizaciones y mejoramientos a los servicios públicos')}</div>
                                <strong>${translate.value('$763 million', '$763 millones de dólares')}</strong>
                            </div>`,
                },
                {
                    icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/non_transit_infrastructure_5.svg',
                    text: `<div>
                                <div>${translate.value('Pavement upgrades', 'Actualizaciones al pavimento')}</div>
                                <strong>${translate.value('$201 million', '$201 millones de dólares')}</strong>
                            </div>`,
                }
            ]
        }))

        return {
            content,
        };
    }
});
</script>
