<template>
    <InputLocation
        :api-key="googleApiKey"
        :map-bounds="googleMapBounds"
        :modelValue="modelValue"
        @keydown="onKeyDown"
        @update:modelValue="$emit('update:modelValue', $event)"
        @change="$emit('change', $event)"
        :id="`${id}-site-search-term`"
        name="maps_schedules_search_term"
        :placeholder="placeholder"
        :aria-label="placeholder"
        aria-autocomplete="list"
        :aria-controls="ariaControls"
        :aria-activedescendant="ariaActivedescendant"
        tabindex="-1"
    />
</template>
<script>

import InputLocation from '../../InputLocation.vue';
import {ref} from "vue";

export default {
    name: "FindByLocationInput",
    components: {InputLocation},
    emits: ['update:modelValue', 'change', 'keydown'],
    props: {
        id: {
            type: String,
            required: true
        },
        modelValue: {
            type: Object,
            required: true
        },
        placeholder: {
            type: String,
            required: true
        },
        ariaControls: {
            type: String,
            required: true
        },
        ariaActivedescendant: {
            type: String,
            required: true
        },
        googleApiKey: {
            type: String,
            required: true
        },
        googleMapBounds: {
            type: Object,
            required: true
        },
    },

    setup(props, { emit }) {
        const inputEl = ref();

        function onInput (value) {
            emit('input', value);
        }
        function onKeyDown(ev) {
            ev.stopPropagation();
            emit('keydown', ev);
        }

        return { inputEl, onInput, onKeyDown };
    }
}
</script>
