<template>
    <Head :title="title ? `${title} | Valley Metro` : 'Valley Metro'">
        <slot />
    </Head>
</template>

<script>
import { Head } from '@inertiajs/vue3'
import {defineComponent} from "vue";

export default defineComponent({
    components: {
        Head,
    },
    props: {
        title: String,
    },
})
</script>
