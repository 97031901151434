<template>
    <div class="border-b-8">
        <SectionTitle :title="title" :subtitle="subtitle" link="bike-pedestrian-collisions-safety-investment" color="blue" class="mb-8" />
        <div class="m-auto space-y-8">
            <div class="p-4 w-full">
                <div class="w-full m-auto overflow-x-scroll lg:overflow-x-hidden">
                    <Image :src="image" :alt="imageAlt" class="block w-[1200px] max-w-[1200px] lg:w-full lg:max-w-full" />
                </div>
                <div class="my-2 block text-sm text-center text-gray-600 lg:hidden">
                    Scroll right to navigate the chart
                </div>
            </div>
            <div class="flex flex-col p-4 m-auto">
                <InfoBox halign="left" color="qol3-full" class="mb-8">
                    <template #header>
                        <InfoBoxIcon icon="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/vehicle_collisions_2.svg" class="-mt-10 -mb-2"/>
                    </template>
                    <template #content>
                        <span v-html="infoBoxText"></span>
                    </template>
                </InfoBox>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, computed} from 'vue';
import Image from "../Components/Image";
import InfoBoxIcon from "../Components/InfoBoxIcon";

import SectionTitle from '../Components/SectionTitle'
import InfoBox from "../Components/InfoBox";

import useTranslations from "../translations";

export default defineComponent({
    name: 'BikeAndPedestrianCollisionSafetyInvestment',
    components: {
        InfoBoxIcon,
        Image,
        SectionTitle,
        InfoBox,
    },
    setup() {
        const { translate } = useTranslations();

        const title = computed(() => translate.value(
          'Bicyclist and Pedestrian Collision Safety Improvements',
          'Mejoramientos a la Seguridad Contra Colisiones Ciclistas y Peatonales'
        ));

        const subtitle = computed(() => translate.value(
            'Constructed in the light rail corridor, 2008-2021',
            'Construido en el corredor del tren Ligero, 2008-2021',
        ));

        const image = computed(() => translate.value(
          'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/bike-ped-safety-hct.svg',
          'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/bike-ped-safety-hct-spanish.svg'
        ))

        const imageAlt = computed(() => translate.value(
            'Bike and Pedestrian Safety in High-capacity Transit (HCT) Corridors.',
            'Seguridad Ciclista y Peatonal en Corredores de Transporte de Alta Capacidad (HCT).'
        ))

        const infoBoxText = computed(() => translate.value(
            'In 2017, MAG’s Regional Council approved the use of <strong>$2.5 million</strong> of transit funding to improve accessibility at transit stops in the region.',
            'En 2017, el Concilio Regional de la organización MAG aprobó el uso de <strong>$2.5 millones de dólares</strong> de los fondos de transporte para mejorar la accesibilidad en las paradas de transporte de la región.'
        ))

        return {
            title,
            subtitle,
            image,
            imageAlt,
            infoBoxText,
        };
    }
});
</script>
