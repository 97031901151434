<template>
    <div class="border-b-8">
        <SectionTitle :title="title" link="vehicle-ownership-cost" color="blue" />
        <div class="flex flex-col lg:flex-row gap-12 p-4 xl:gap-32 md:p-12">
            <div class="flex-1 text-center mt-8">
                <h3 class="text-3xl sm:text-3xl xl:text-4xl mb-4 md:mb-8">{{ graphics[0].title }}</h3>
                <Image class="max-w-none mx-0 md:-ml-[30px] md:-mr-[-6rem]" :src="graphics[0].url" :alt="graphics[0].alt" />
            </div>
            <div class="flex-1 text-center mt-8">
                <h3 class="text-3xl sm:text-3xl xl:text-4xl mb-4 md:mb-8">{{ graphics[1].title }}</h3>
                <Image :src="graphics[1].url" :alt="graphics[1].alt" />
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, computed} from 'vue';
import Image from "../Components/Image";
import SectionTitle from '../Components/SectionTitle'
import useTranslations from "../translations";

export default defineComponent({
    name: 'CostOfVehicleOwnership',
    components: {
        Image,
        SectionTitle
    },
    setup() {
        const { translations } = useTranslations();

        const title = computed(() => translations.value.page2.sections[4].title);
        const graphics = computed(() => translations.value.page2.sections[4].graphics);

        return {
            title,
            graphics,
        };
    }
});
</script>
