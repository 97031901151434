<template>
<div class="flex flex-col">
    <div class="px-6">
        <h4 class="flex items-center justify-between space-x-4 text-lg font-bold text-white">
            Trip Details
        </h4>
    </div>

    <div class="flex flex-col flex-1 mt-6 bg-gray-100">
        <!-- summary header -->
        <div class="p-6 bg-gray-200">
            <div class="flex justify-between text-sm font-normal text-gray-700">
                <div class="text-base font-bold text-gray-900" :aria-label="`Trip duration ${route.summary.duration.total.text}`">
                    {{ route.summary.duration.total.text }}
                </div>
                <div class="flex items-center justify-end flex-1 mt-2 ml-2 text-sm text-gray-700" :aria-description="`${route.summary.duration.by_walk_vehicle.walk.text} and ${route.summary.calories.text} walk`">
                    <Icon class="w-5 h-5 mr-1 text-gray-600" :icon="ICONS[LEG_MODE_WALK]" aria-hidden="true"></Icon>
                    {{ route.summary.duration.by_walk_vehicle.walk.text }}, {{ route.summary.calories.text }}
                </div>
            </div>

            <!-- fare info -->
            <a href="/fares" title="Fare information" class="inline-flex items-center space-x-2 text-sm font-normal text-primary p-1 rounded hover:underline focus:ring-2 focus:ring-primary">
                <Icon :icon="ICONS.iconTicket" class="w-5 h-5 text-primary-500" aria-hidden="true" />
                <span>See Fare Information</span>
            </a>
        </div>

        <!-- MOBILE: hide / show map option -->
        <div
            v-if="largeMediaScreen.matches"
            @click="setMapVisibilityOnSmallScreens(!isMapVisibleOnSmallScreens)"
            class="flex-1 block w-full p-4 text-sm font-normal text-center text-red-800 bg-red-200 cursor-pointer lg:hidden"
        >
            {{ isMapVisibleOnSmallScreens ? 'Hide Map' : 'Show Map' }}
        </div>

        <!-- details -->
        <div :aria-busy="isLoading ? 'true' : 'false'" aria-live="assertive" role="alert" class="py-8 text-base font-normal text-gray-600 lg:py-0">
            <div v-if="isLoading" class="flex items-center justify-center flex-1 w-full">
                Loading...
            </div>
        </div>

        <div v-if="!isLoading" class="flow-root">
            <ul role="list">
                <template v-for="(leg, index) in legs">
                    <li>
                        <div
                            tabindex="0"
                            role="button"
                            class="relative flex pb-8 cursor-pointer hover:bg-gray-200 focus:bg-gray-200 focus:border-t focus:border-b focus:border-secondary"
                            :class="{'bg-gray-200 border-t border-b border-secondary': index === highlightedLeg}"
                            :aria-pressed="index === highlightedLeg ? 'true' : 'false'"
                            @click="legClicked(index)"
                            @keyup.enter="legClicked(index)"
                            @keyup.space="legClicked(index)"
                        >
                            <div class="flex flex-col items-center w-32 px-4 py-4 -mt-1 text-sm font-normal text-gray-900">
                                <template v-if="leg.time">
                                    <div :aria-label="leg.time.desc">
                                        {{ leg.time.text }}
                                    </div>
                                </template>

                                <div class="flex mt-4 space-x-2">
                                    <Icon v-if="ICONS[leg.mode]" :icon="ICONS[leg.mode]" aria-hidden="true" class="w-8 h-8 text-gray-600" />
                                    <span v-else class="text-sm text-gray-600">{{ leg.mode }}</span>

                                    <span v-if="leg.transit_details" class="leg">
                                        {{ leg.transit_details.line.name }}
                                    </span>
                                </div>
                            </div>
                            <div class="absolute top-0 flex flex-col items-center justify-center h-full mt-4" style="left: 8rem;" aria-hidden="true">
                                <div class="w-3 h-3 bg-gray-100 border-2 rounded-full border-primary"></div>
                                <div class="flex-grow w-0.5 border-r-2 border-primary" :class="{'border-dashed': leg.mode === 'walk'}"></div>
                            </div>
                            <div class="flex-1 py-4 pl-8 pr-4 -mt-1">
                                <div class="text-sm font-bold text-gray-900" :aria-label="leg.title.desc">
                                    {{ leg.title.text }}
                                </div>

                                <div class="mt-3 text-sm font-normal text-gray-600">
                                    <template v-if="leg.transit_details">
                                        <div v-if="leg.transit_details.departure_stop.code" class="mb-2 text-xs">Stop #{{ leg.transit_details.departure_stop.code }}</div>
                                        <div>{{ leg.transit_details.head_sign }}</div>
                                    </template>

                                    <button v-if="leg.steps"
                                         tabindex="0"
                                         type="button"
                                         class="flex items-center space-x-1 text-secondary-800 font-bold hover:cursor-pointer"
                                         title="Open leg instructions"
                                         :aria-label="`${leg.duration.text}, ${leg.distance.text}: Open leg instructions`"
                                         :aria-expanded="currentInstructions === index"
                                         @click="toggleInstructions(index)"
                                    >
                                        <template v-if="leg.steps">
                                            <Icon :icon="index === currentInstructions ? ICONS.close : ICONS.chevronDown" class="w-5 h-5" aria-hidden="true"></Icon>
                                        </template>
                                        <template v-if="leg.mode === LEG_MODE_WALK">
                                            <span>{{ leg.duration.text }}, {{ leg.distance.text }}</span>
                                        </template>
                                    </button>

                                    <template v-if="leg.alert">
                                        <div class="p-2 text-sm font-normal text-yellow-800 bg-yellow-200 rounded" v-html="leg.alert"></div>
                                    </template>

                                    <div v-if="leg.steps && index === currentInstructions" class="mt-2 text-sm font-light text-gray-800">
                                        <ul>
                                            <li v-for="(step, stepIndex) in leg.steps" class="mb-2" tabindex="0" :key="stepIndex" v-html="step.instruction"></li>
                                        </ul>
                                    </div>

                                    <div class="mt-3 text-sm text-gray-700" v-if="leg.transit_details  && leg.transit_details.num_of_stops">
                                        <strong>{{ leg.transit_details.num_of_stops }}</strong> stops, {{ leg.duration.text }}
                                    </div>
                                </div>

                                <!-- service alerts integration -->
                                <div class="space-y-1 text-xs" :class="{'mt-2': leg.alerts.length}">
                                    <template v-for="(alert, alertIndex) in leg.alerts">
                                        <div
                                            class="flex items-start justify-start hover:cursor-pointer"
                                            :title="alert.description"
                                            :aria-label="alert.description"
                                            @click="toggleLegAlert(leg, alertIndex)"
                                        >
                                            <Icon class="w-4 h-4 mr-1 text-yellow-500" :icon="ICONS.alert" />
                                            <div class="flex-1">
                                                <div class="flex items-start justify-between text-yellow-800">
                                                    <span>{{ alert.title }}</span>
                                                    <span class="px-2 py-1">
                                                        <Icon class="w-4 h-4" :icon="leg.alertDisplayIndex === alertIndex ? ICONS.close : ICONS.chevronDown" />
                                                    </span>
                                                </div>
                                                <div v-if="leg.alertDisplayIndex === alertIndex" class="mt-1 font-light text-yellow-800">
                                                    {{ alert.description }}
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </li>
                </template>

                <!-- Add the final leg -->
                <li tabindex="0" class="hover:bg-gray-200" @click="legClicked(legs.length)">
                    <div class="relative flex pb-8">
                        <div class="flex flex-col items-center w-32 px-4 py-4 -mt-1 text-sm font-normal text-gray-900">
                            <div v-if="route.summary.arrive" :aria-label="`Arrive at ${route.summary.arrive.text}`">
                                {{ formatAmPmTime(route.summary.arrive.iso8601) }}
                            </div>
                        </div>
                        <div class="absolute top-0 flex flex-col items-center h-full mt-4" style="left: 8rem;" aria-hidden="true">
                            <div class="w-3 h-3 bg-gray-100 border-2 rounded-full border-primary"></div>
                        </div>
                        <div class="flex-1 py-4 pl-8 pr-4 -mt-1">
                            <div class="text-sm font-bold text-gray-900">
                                {{ route.summary.finish_name }}
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <!-- Share trip -->
        <div class="py-6 border-t border-gray-200">
            <h5 class="mb-4 text-base text-center text-gray-600">Share this Trip</h5>
            <div class="flex items-center justify-center">
                <ShareNetwork
                    class="flex items-center justify-center w-8 h-8 group"
                    v-for="network in shareNetworks"
                    :key="network.network"
                    :network="network.network"
                    :url="network.url"
                    :title="network.title"
                >
                    <span class="sr-only">Share this trip on {{ network.network }}</span>
                    <Icon class="w-6 h-6 text-gray-700 group-hover:text-gray-600" :icon="network.icon" />
                </ShareNetwork>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Icon from "../Icon";
import {ICONS, LEG_MODE_WALK} from "../../utils/icons";
import {formatAmPmTime} from "../../utils/date";
import {onUnmounted, ref, watch} from "vue";
import {Dialog, DialogDescription, DialogOverlay, DialogTitle, TransitionRoot} from "@headlessui/vue";
import LightButton from "../LightButton";
import {useTripInput} from "./useTripInput";
import {ShareNetwork} from "vue-social-sharing/src/vue-social-sharing";
import {useMatchMedia} from "vue-composable";
import InputText from "../InputText";
import {usePage} from '@inertiajs/vue3';
import PrimaryButton from "../PrimaryButton";

export default {
    name: "TripPlannerRouteDetails",
    components: {
        PrimaryButton,
        InputText,
        Icon,
        LightButton,
        ShareNetwork,
        Dialog,
        DialogTitle,
        DialogOverlay,
        DialogDescription,
        TransitionRoot
    },

    props: {
        route: {
            type: Object,
            required: true,
        },
        highlightedLeg: {
            type: [Number, null],
            default: null,
        }
    },

    setup(props, { emit }) {
        const largeMediaScreen = useMatchMedia('(min-width: 1024px)');
        const inertiaPage = usePage();

        const {isMapVisibleOnSmallScreens, setMapVisibilityOnSmallScreens, locationFrom, locationTo} = useTripInput();
        const currentInstructions = ref(-1);
        const shareNetworks = ref([]);
        const isLoading = ref(true);
        const legs = ref([]);

        async function updateDataFromRoute() {
            isLoading.value = true;

            legs.value = props.route.legs.map((l, i) => {
                return {
                    ...l,
                    alertDisplayIndex: -1,
                }
            });

            const shareableUrl = `${window.location.protocol}//${window.location.host}${inertiaPage.url}`;

            shareNetworks.value = [
                {network: 'facebook', url: shareableUrl, icon: ICONS.facebook, title: ' '},
                {network: 'twitter', url: shareableUrl, icon: ICONS.twitter, title: ' '},
                {network: 'email', url: shareableUrl, icon: ICONS.email, title: ' '},
            ];

            isLoading.value = false;
        }

        function toggleLegAlert(leg, index) {
            leg.alertDisplayIndex = (leg.alertDisplayIndex !== index)
                ? index
                : -1;
        }

        function legClicked(index) {
            emit('highlightLeg', { index });
        }

        function toggleInstructions(legIndex) {
            currentInstructions.value = legIndex !== currentInstructions.value ? legIndex : -1;
        }

        updateDataFromRoute();

        watch(() => props.route, async () => {
            await updateDataFromRoute();
        });

        return {
            ICONS,
            LEG_MODE_WALK,

            // Data
            legs,
            isLoading,
            shareNetworks,
            largeMediaScreen,
            currentInstructions,
            isMapVisibleOnSmallScreens,

            // Methods
            legClicked,
            formatAmPmTime,
            toggleLegAlert,
            toggleInstructions,
            setMapVisibilityOnSmallScreens,
        }
    }
}
</script>

<style scoped>
.leg {
    @apply relative flex justify-center items-center px-2;
    @apply text-sm text-primary-50 font-normal;
    @apply bg-primary;
    @apply rounded;
}
</style>
