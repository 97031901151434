<template>
    <div class="relative w-full h-full max-w-screen-xl mx-auto page-entry bg-primary xl:rounded-xl xl:mb-10">
        <AppHead :title="title">
            <meta head-key="description" name="description" :content="title" />
        </AppHead>

        <div class="flex justify-end w-full px-2 pt-4 mx-auto mb-2 max-w-7xl md:px-8">
            <content-locale-selector :options="localizations" :locale="locale"/>
        </div>

        <EditButton :url="edit_url" />

        <div class="relative w-full p-2 mx-auto max-w-7xl md:p-8">
            <!-- Thumbnails -->
            <nav aria-label="breadcrumb" role="navigation" v-if="showBreadcrumbs && breadcrumbs.length > 0" class="flex items-center mb-6 space-x-2 text-sm font-light uppercase breadcrumbs">
                <template v-for="(bread, index) in breadcrumbs">
                    <span class="text-primary-gray">/</span>
                    <Link :href="bread.permalink" class="breadcrumb-link text-primary-gray hover:underline focus:ring-secondary-700" v-bind="bread.options">{{ bread.title }}</Link>
                </template>
            </nav>

            <!-- Page title -->
            <div class="relative w-full mx-auto rounded-lg max-w-7xl">
                <!-- Title -->
                <h1 v-if="title" class="block pb-4 font-extrabold leading-relaxed tracking-tight text-left text-white border-b border-gray-200 main-title tk-museo text-5xl px-4 md:px-0 md:text-4xl">
                    {{ title }}
                </h1>
            </div>

            <!-- Tabs -->
            <div class="py-4">
                <Tabs :items="tabs" :tabs_style="tabs_style" />
            </div>
        </div>
    </div>
</template>

<script>
import {Link} from '@inertiajs/vue3';
import {defineComponent} from 'vue';
import AppHead from "../components/AppHead";
import ContentLocaleSelector from "../components/ContentLocaleSelector";
import EditButton from "../components/EditButton";
import SetsRenderer from "../components/SetsRenderer";
import Tabs from "../components/Tabs";

export default defineComponent({
    name: 'PagesEntry',
    components: {EditButton, AppHead, SetsRenderer, Link, Tabs, ContentLocaleSelector},
    props: {
        tabs_style: { type: Object, default: {value: 'card', key: 'card', label: 'Card'} },
        tabs: {type: Array, required: true},
        title: {type: String, required: true},
        breadcrumbs: {type: Array, default: () => []},
        showBreadcrumbs: {type: Boolean, default: true},
        locale: { type: String, required: true },
        localizations: { type: Array, required: true },
        edit_url: { type: [String, null], default: null },
    },
});
</script>
