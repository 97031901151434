<template>
<div>
    <div v-if="store.state.isLoading" class="w-full flex justify-center py-8">
        <Spinner class="text-primary w-8 h-8" />
    </div>

    <div v-else>
        <transition name="fade">
            <div v-if="store.state.dataFetched && store.state.routes.current">
                <div class="border border-gray-100 rounded-lg p-2">
                    <MapsSchedulesRouteSchedule />
                </div>
            </div>
        </transition>
    </div>
</div>
</template>

<script>
import {Tab} from "@headlessui/vue";
import {mdiDownload} from "@mdi/js";
import {defineComponent} from 'vue';
import {ICONS} from "../../utils/icons";
import Icon from "../Icon";
import ServiceAlert from "../ServiceAlert";
import Spinner from "../Spinner";
import MapsSchedulesRouteSchedule from "./MapsSchedulesRouteSchedule";
import {useStore} from "./store";

export default defineComponent({
    name: 'MapsSchedulesRouteDetails',
    components: {
        Tab,
        Icon,
        Spinner,
        ServiceAlert,
        MapsSchedulesRouteSchedule,
    },

    setup(props) {
        const store = useStore();

        function tabClasses (selected) {
            return [
                'w-32 -mb-px p-3 font-medium text-base leading-5 border-b-2 whitespace-nowrap',
                'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-primary-400 ring-white ring-opacity-60',
                selected
                    ? 'border-primary text-primary'
                    : 'border-transparent text-gray-600 hover:text-gray-800',
            ];
        }

        return {
            store,

            // Functions
            tabClasses,

            // Icons
            ICONS,
            mdiDownload,
        }
    },
});
</script>
