<template>
    <div class="relative w-full bg-white xl:overflow-hidden">
        <SectionTitle :title="title" :subtitle="subtitle" link="evolving-travel-patterns" color="purple" />

        <Section class="pb-6">
            <template #graphic>
                <Image class="graphic px-6 mt-0 lg:px-0 lg:pt-32 w-full" alt="" src="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/static-travel-pattern.svg" />
            </template>

            <template #info>
                <div class="flex flex-col gap-8 mt-6 items-center justify-center">
                    <InfoBox width-class="w-[290px] md:w-[340px]" halign="left" color="qol1-full">
                        <template #header>
                            <div class="w-full flex justify-center items-center pb-0">
                                <Image :src="infoBoxes[0].icon" class="relative h-12 lg:h-16 -top-2 lg:-top-4" />
                                <span>{{ infoBoxes[0].title }}</span>
                            </div>
                        </template>
                        <template #content>
                            <InfoTable :rows="infoBoxes[0].table.rows"/>
                        </template>
                    </InfoBox>

                    <InfoBox width-class="w-[290px] md:w-[340px]" halign="left" color="qol1-full">
                        <template #header>
                            <div class="w-full flex justify-center items-center pb-0">
                                <Image :src="infoBoxes[1].icon" class="relative h-12 lg:h-16 -top-2 lg:-top-4"/>
                                <span>{{ infoBoxes[1].title }}</span>
                            </div>
                        </template>
                        <template #content>
                            <InfoTable :rows="infoBoxes[1].table.rows"/>
                        </template>
                    </InfoBox>
                    <InfoBox width-class="w-[290px] md:w-[340px]" halign="left" color="qol1-full">
                        <template #header>
                            <div class="w-full flex justify-center items-center pb-0">
                                <Image :src="infoBoxes[2].icon" class="relative h-12 lg:h-20 top-0"/>
                                <span class="ml-[8px] mt-[6px] md:mt-[14px]">{{ infoBoxes[2].title }}</span>
                            </div>
                        </template>
                        <template #content>
                            <div v-html="infoBoxes[2].text" class="text-center"></div>
                        </template>
                    </InfoBox>
                </div>
            </template>
        </Section>
    </div>
</template>

<script>
import {defineComponent, computed} from 'vue';
import Image from "../Components/Image";
import Section from "../Components/Section";
import SectionTitle from "../Components/SectionTitle";
import InfoBox from "../Components/InfoBox";
import InfoBoxIcon from "../Components/InfoBoxIcon";
import InfoTable from "../Components/InfoTable";
import useTranslations from "../translations";

export default defineComponent({
    name: 'EvolvingTravelPatterns',
    components: {
        Image,
        Section,
        SectionTitle,
        InfoBox,
        InfoTable,
        InfoBoxIcon,
    },
    setup() {
        const { translate } = useTranslations();

        const title = computed(() => translate.value(
            'Room for Transit Growth',
            'Espacio para el Crecimiento del Transporte'
        ));

        const subtitle = computed(() => translate.value(
            'Maricopa County, 2009-2019',
            'Condado Maricopa, 2009-2019',
        ));

        const infoBoxes = computed(() => [
            {
                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/assets/qol-red-pushpin.svg',
                title: translate.value(
                    'Most Jobs Added',
                    'Mayoría de Empleos Agregados'
                ),
                table: {
                    rows: [
                        ['#1 Phoenix', '75,265'],
                        ['#2 Gilbert', '26,160'],
                        ['#3 Chandler', '15,050'],
                        ['#4 Surprise', '14,887'],
                        ['#5 Tempe', '13,373'],
                    ]
                }
            },
            {
                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/assets/qol-blue-pushpin.svg',
                title: translate.value(
                    'Most Job Percentage Growth',
                    'Mayor Porcentaje de Crecimiento de Empleos'
                ),
                table: {
                    rows: [
                        ['#1 Gila Bend', '667%'],
                        ['#2 Fort McDowell', '280%'],
                        ['#3 Surprise', '231%'],
                        ['#4 Queen Creek', '142%'],
                        ['#5 Avondale', '64%'],
                    ]
                }
            },
            {
                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/bus.svg',
                title: translate.value(
                    'Room For Transit Growth',
                    'Espacio para el Crecimiento del Transporte'
                ),
                text: translate.value(
                    'The most jobs were added closer to the urban core where <strong>High Capacity Transit</strong> is prevalent. However, job growth percentage increased highest in areas not yet covered by HCT.',
                    'La mayoría de los empleos se agregaron más cerca del núcleo urbano, donde prevalece el <strong>Transporte de Alta Capacidad</strong>. Sin embargo, el porcentaje de crecimiento de empleos aumentó más en las áreas que aún no están cubiertas por el transporte de alta capacidad',
                ),
            },
        ]);

        return {
            title,
            subtitle,
            infoBoxes,
        };
    }
});
</script>

<style scoped lang="scss">
    :deep(.box__header) {
        align-items: flex-end;
    }
    @media(min-width:  theme('screens.lg')) {

        .graphic {
            margin-right: -10rem;
            max-width: 100% !important;
        }
    }

</style>
