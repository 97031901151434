<template>
    <div class="border-b-8">
        <SectionTitle link="regional-population" color="orange" class="mb-12"
            :title="$tx('Regional Population Growth')"
            :subtitle="`${$tx('Maricopa County')}, 2010-2019`"/>
        <div class="flex flex-col w-full p-6 md:p-12">
            <ChartTableSwitch page-title="Regional Population Growth">
                <template v-slot:chart>
                    <ChartRenderer :chart="BarChart" chartId="regional-population-chart" :chartData="chartData" :options="options" class="h-[350px] md:h-450px] lg:h-[500px]" label="Regional population Growth Chart/Graph. Refer to data table for details." />
                </template>

                <template v-slot:table>
                    <DataTable highlight-first :headings="dataTable.headings" :rows="dataTable.rows" />
                </template>
            </ChartTableSwitch>
            <div class="flex m-auto mt-4">
                <div class="max-w-lg text-center">
                    <InfoBox halign="left" color="qol2-full" class="mb-8 mt-20" icon="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/Buildings_Icon.svg">
                        <template #content>
                            <p v-html="$tx('According to the U.S. Census Bureau, Maricopa County was the fastest growing county from<br/>2010-2019 and will continue to grow rapidly.')"></p>
                        </template>
                    </InfoBox>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {computed, defineComponent, inject} from 'vue';
import ChartRenderer from "../../../components/ChartRenderer";
import ChartTableSwitch from "../Components/ChartTableSwitch";
import DataTable from "../Components/DataTable";

import SectionTitle from '../Components/SectionTitle';
import InfoBox from "../Components/InfoBox";

import { BarChart } from 'vue-chart-3';
// See link for registering chart types to allow for tree-shaking if desired
// https://vue-chart-3.netlify.app/guide/#chart-js-v3
import { Chart, registerables } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(...registerables);
Chart.register(ChartDataLabels);

import { useIsSmallScreen } from "../../../utils/helpers";

import numeral from 'numeral';
import {generateDataLabelsSettings, generateTickSettings, generateTitleSettings} from "./chartsUtils";

export default defineComponent({
    components: {
        ChartRenderer,
        DataTable,
        ChartTableSwitch,
        SectionTitle,
        InfoBox,
        BarChart,
    },
    setup() {
        const tx = inject('tx')
        const isSmallScreen = useIsSmallScreen()

        const chartData = {
            labels: ['2010','2011','2012','2013','2014','2015','2016','2017','2018','2019'],
            datasets: [
                {
                backgroundColor: 'rgb(247, 141, 30)',
                borderColor: 'rgb(132, 132, 132)',
                data: [3827371,3880224,3942169,4009412,4087191,4167947,4242997,4307033,4410824,4485414],
                }
            ],
        };

        const options = computed(() => ({
            hover: { mode: null },
            plugins: {
                tooltip: { enabled: isSmallScreen.value },
                legend: { display: false },
                title: { display: false },
                datalabels: generateDataLabelsSettings({
                    anchor: 'end',
                    align: 'end',
                    labels: {
                        value: {
                            formatter: (value) => {
                                return value.toLocaleString?.('en-US');
                            }
                        }
                    }
                })
            },
            responsive: true,
            scales: {
                x: {
                    title: generateTitleSettings({
                        text: tx('Years'),
                    }),
                    stacked: true,
                    ticks: generateTickSettings(),
                },
                y: {
                    max: 4500000,
                    min: 3800000,
                    title: generateTitleSettings({
                        text: tx('Population'),
                    }),
                    stacked: true,
                    ticks: generateTickSettings({
                        callback: (value, index, values) => {
                            if (! value) {
                                return null;
                            }

                            return numeral(value).format('0.0a').replace('.0', '').toUpperCase();
                        }
                    })
                }
            }
        }));

        const dataTable = computed(() => {
            const headings = {
                year: tx('Year'),
                population: tx('Population'),
            };

            const rows = chartData.labels.map((year, idx) => ({
                year,
                population: Number(chartData.datasets[0].data[idx]).toLocaleString()
            }));

            return { headings, rows };
        });

        return { BarChart, chartData, options, dataTable };
    }
});
</script>
