<template>
    <div :id="section.id" class="main-content-section relative sm:pt-10 mb-20" :class="section.sectionClasses">
        <div class="absolute top-0 bottom-0 left-0 right-0">
            <div v-for="item in section.bumps" :class="item.classes" :key="item.image">
                <img class="w-[55px]" :src="item.image" alt="" role="img" />
            </div>
        </div>

        <SectionHeader
            :color="section.color"
            :icon="section.icon"
            :title="section.title"
            :heading="section.heading"
            :subheading="section.subheading"
        />

        <div class="main-content-info-boxes-container flex flex-col-reverse relative overflow-hidden bg-no-repeat" :class="[section.infoboxContainerClasses,{'sm:flex-col': section.direction == 'right', 'sm:flex-row-reverse justify-center': section.direction != 'right'}]">
            <div v-if="section.direction == 'right'" class="background-image object-right w-full z-1 sm:absolute sm:top-0 sm:left-0 sm:right-0 sm:bottom-0 sm:overflow-hidden">
                <div class="relative">
                    <img :class="section.backgroundImage.classes" :src="section.backgroundImage.image" alt="" role="img">
                </div>
            </div>
            <div v-else class="background-image z-1 sm:overflow-hidden sm:absolute sm:top-0 sm:left-0 sm:bottom-0 sm:right-0">
                <img :class="section.backgroundImage.classes" :src="section.backgroundImage.image" alt="" role="img">
            </div>

            <div class="info-boxes-container flex w-full z-2" :class="[{'flex-row': section.direction == 'right', 'flex-row-reverse': section.direction != 'right'}]">
                <div class="spacer-for-info-boxes-sub-container relative hidden sm:flex sm:flex-1">
                    <InformationBox v-for="(content, index) in section.spacerBoxes"
                        :key="index"

                        v-bind="content.attrs || {}"

                        :color="section.color"
                        :bg-color="content.bgColor"
                        :round="section.direction"
                        :rounded="false"
                        :shadow="content.hasOwnProperty('shadow') ? content.shadow : true"

                        :icon="content.icon"
                        :info="content.text"
                        :definitions="content.definitions"

                        :class="content.classes"
                    />
                </div>
                <div class="info-boxes-sub-container flex flex-1 flex-col sm:p-6 pt-40 sm:pt-40 w-full relative">
                    <template v-for="content in section.infoBoxes">
                        <div v-if="content.screenReaderText" class="sr-only">
                            <p>{{ content.screenReaderText }}</p>
                        </div>
                        <InformationBox
                            v-bind="content.attrs || {}"

                            :color="section.color"
                            :bg-color="content.bgColor"
                            :round="section.direction"
                            :rounded="!content.showInSpacer"
                            :shadow="content.hasOwnProperty('shadow') ? content.shadow : true"

                            :icon="content.icon"
                            :icon-classes="content.iconClasses"
                            :info="content.text"
                            :definitions="content.definitions"

                            :class="content.classes"
                        />
                    </template>
                </div>
            </div>
        </div>

        <div v-if="section.buttons.length" class="main-content-buttons flex flex-row justify-center flex-wrap w-full pb-20" :class="section.buttonClasses">
            <CircleButton v-for="(button, index) in section.buttons"
                :key="index"
                :color="section.color"
                :icon="button.icon"
                :text="button.text"
                :href="button.href"
                :iconClasses="button.iconClasses"
            />
        </div>

        <SectionStatements v-if="section.statements" :statements="section.statements"/>

        <div v-if="section.addBackToTopButton">
            <BackToTop :text="backToTop" />
        </div>
    </div>
</template>

<script>
import {computed, defineComponent} from 'vue'

import SectionHeader from "./SectionHeader"
import SectionStatements from "./SectionStatements"
import InformationBox from './InformationBox'
import CircleButton from "./CircleButton"
import BackToTop from "./BackToTop"

export default defineComponent({
    name: 'ContentSection',
    components: {
        SectionHeader,
        SectionStatements,
        CircleButton,
        InformationBox,
        BackToTop,
    },
    props: {
        section: {
            type: Object,
            required: true,
        },
        backToTop: {
            type: String,
            required: true,
        }
    },
    setup(props) {

    },
})
</script>
