<template>
    <div class="relative w-full h-full max-w-screen-xl mx-auto custom-transit-book-app bg-primary xl:rounded-xl xl:mb-10">
        <AppHead title="My eTransit Book">
            <meta head-key="description" name="description" content="My eTransit Book"/>
        </AppHead>

        <div class="relative w-full p-2 mx-auto max-w-7xl md:p-8">

            <div aria-label="breadcrumb" role="navigation" class="flex items-center mb-6 space-x-2 text-sm font-light uppercase">
                <template v-for="bread in breadcrumbs">
                    <span class="text-gray-300">/</span>
                    <Link :href="bread.permalink" class="text-primary-gray hover:underline" v-bind="bread.options">
                        {{ bread.title }}
                    </Link>
                </template>
                <span class="text-gray-300">/</span>
                <Link aria-current="page" href="/maps-schedules/my-etransit-book" class="text-primary-gray hover:underline" preserve-state>My eTransit Book</Link>
            </div>

            <h1 class="text-4xl text-white">My eTransit Book</h1>

            <p class="p-6 px-8 mt-4 text-lg bg-white rounded-lg">Create your own custom eTransit Book! First, select your favorite pages and routes from the list below. Then download your eTransit Book to your mobile device or print them out to take on your trip.</p>

            <template v-if="! loadingRoutes">
                <div class="mt-6">
                    <div v-for="group in extraPdfAssetGroups" :key="group.title" class="mt-2">
                        <Disclosure v-slot="{ open }">
                            <div class="disclosure">
                                <h3>
                                    <DisclosureButton class="disclosure-button">
                                        <div class="flex items-center justify-start flex-1 space-x-2 text-lg font-bold">
                                            <span>{{ group.title }}</span>
                                        </div>
                                        <div>
                                            <Icon :icon="mdiChevronDown" class="w-5 h-5 transition transition-transform text-primary" :class="{'transform rotate-180': open}" aria-label="Arrow down icon"/>
                                        </div>
                                    </DisclosureButton>
                                </h3>
                                <DisclosurePanel>
                                    <div class="relative">
                                        <div v-if="group.showTop" class="absolute top-0 left-1/2 text-primary blink-top">
                                            <Icon class="w-8 h-8" :icon="mdiArrowUp" aria-label="Arrow up icon"/>
                                        </div>
                                        <div class="overflow-y-scroll divide-y divide-gray-100 max-h-60" :class="{'shadow-vertical-scroll-both': group.showTop && group.showBottom, 'shadow-vertical-scroll-down': group.showTop && !group.showBottom, 'shadow-vertical-scroll-up': group.showBottom && !group.showTop}" @scroll="onGroupScroll(groupIndex, $event)">
                                            <button v-for="asset in group.assets" @click="selectedExtras[asset.id] = !selectedExtras[asset.id]" class="flex items-center justify-between w-full p-4 space-x-2 bg-white group hover:bg-gray-300 focus:bg-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-gray-300" :aria-label="asset.title">
                                                <span class="flex items-center font-normal bg-white border-4 border-transparent flex-0 hover:bg-gray-300 focus:bg-gray-500">
                                                    <Switch
                                                        v-model="selectedExtras[asset.id]"
                                                        :class="selectedExtras[asset.id] ? 'bg-green-700' : 'bg-gray-500'"
                                                        class="relative inline-flex items-center h-6 rounded-full w-11"
                                                        @click.stop
                                                    >
                                                        <span class="sr-only">Select asset: "{{ asset.title }}"</span>
                                                        <span
                                                          :class="selectedExtras[asset.id] ? 'translate-x-6' : 'translate-x-1'"
                                                          class="inline-block w-4 h-4 transform bg-white rounded-full"
                                                        />
                                                    </Switch>
                                                </span>
                                                <span class="flex-1 text-left">{{ asset.title }}</span>
                                            </button>
                                        </div>
                                        <div v-if="group.showBottom" class="absolute bottom-0 -mt-2 left-1/2 text-primary blink-bottom">
                                            <Icon class="w-8 h-8" :icon="mdiArrowDown" aria-label="Arrow down icon"/>
                                        </div>
                                    </div>
                                </DisclosurePanel>
                            </div>
                        </Disclosure>
                    </div>
                </div>

                <div class="mt-6">
                    <div v-for="group in routeGroups" :key="group.name" class="mt-2">
                        <Disclosure v-slot="{ open }">
                            <div class="disclosure">
                                <h2>
                                    <DisclosureButton class="disclosure-button">
                                        <div class="flex items-center justify-start flex-1 space-x-2 text-lg font-bold">
                                            <Icon v-if="transitIcons[group.mode]" class="w-6 h-6 mr-1 text-primary-gray-600" :icon="transitIcons[group.mode]" aria-hidden="true"></Icon>
                                            <span>{{ group.name }}</span>
                                        </div>
                                        <div>
                                            <Icon :icon="mdiChevronDown" class="w-5 h-5 transition transition-transform text-primary" :class="{'transform rotate-180': open}" aria-hidden="true" />
                                        </div>
                                    </DisclosureButton>
                                </h2>
                                <DisclosurePanel>
                                    <div class="relative">
                                        <div v-if="group.showTop" class="absolute top-0 left-1/2 text-primary blink-top">
                                            <Icon class="w-8 h-8" :icon="mdiArrowUp" />
                                        </div>
                                        <div
                                            class="overflow-y-scroll divide-y divide-gray-100 max-h-60"
                                             :class="{
                                                'shadow-vertical-scroll-both': group.showTop && group.showBottom,
                                                'shadow-vertical-scroll-down': group.showTop && !group.showBottom,
                                                'shadow-vertical-scroll-up': group.showBottom && !group.showTop
                                            }"
                                            @scroll="onGroupScroll(groupIndex, $event)"
                                            tabindex="0"
                                        >
                                            <div v-for="route in sortedRouteGroup(group)"
                                                 :key="route.id"
                                                 class="flex items-center justify-between w-full p-4 space-x-2 group"
                                                 :class="{
                                                     'saturate-0 opacity-75 cursor-not-allowed pointer-events-none': route.pdfs.length === 0,
                                                 }"
                                            >
                                                <span class="flex items-center font-normal flex-0">
                                                    <Switch
                                                        v-model="selectedRoutes[route.id]"
                                                        :class="{
                                                            'bg-green-700 focus:ring-green-700': selectedRoutes[route.id],
                                                            'bg-gray-500 hover:bg-gray-700 focus:ring-gray-500': !selectedRoutes[route.id],
                                                        }"
                                                        class="relative inline-flex items-center h-6 rounded-full w-11 focus:ring-2 focus:ring-offset-2"
                                                        :aria-label="`Route ${route.id} - ${route.title}`"
                                                        :disabled="route.pdfs.length === 0"
                                                        :aria-disabled="route.pdfs.length === 0"
                                                        @click.stop
                                                    >
                                                        <span :class="selectedRoutes[route.id] ? 'translate-x-6' : 'translate-x-1'" class="inline-block w-4 h-4 transform bg-white rounded-full"/>
                                                    </Switch>
                                                </span>
                                                <span class="flex items-center justify-center w-12 h-6 px-2 text-sm font-normal uppercase rounded text-primary-50 bg-primary">{{ route.id }}</span>
                                                <span class="flex-1 text-left">{{ route.title }}</span>
                                            </div>
                                        </div>
                                        <div v-if="group.showBottom" class="absolute bottom-0 -mt-2 left-1/2 text-primary blink-bottom">
                                            <Icon class="w-8 h-8" :icon="mdiArrowDown" />
                                        </div>
                                    </div>
                                </DisclosurePanel>
                            </div>
                        </Disclosure>
                    </div>
                </div>

                <!-- Number of routes selected -->
                <div class="mt-6 text-base font-bold text-white">
                    {{ selectedRoutesCount || 'No' }} route{{ selectedRoutesCount > 1 ? 's' : '' }} selected
                </div>

                <!-- Button to create and download -->
                <div class="flex items-center mt-6">
                    <button type="button" class="flex flex-col justify-center items-center w-[220px] h-[92px] link link--button font-museo" :class="{downloading: 'bg:opacity-50'}" @click="download" :disabled="downloading">
                        <span v-if="! downloading">
                            Create and Download<br/>
                            My eTransit Book PDF
                        </span>
                        <template v-else>
                            <span>Generating eTransit Book PDF</span>
                            <Spinner class="w-8 h-8" />
                        </template>
                    </button>
                </div>
            </template>
            <template v-else>
                <div class="flex items-center my-6">
                    <Spinner class="text-white wh-8 h-8" />
                    <div class="ml-6 text-base font-bold text-white">Loading routes</div>
                </div>
            </template>
        </div>
    </div>
</template>

<style scoped>
.disclosure {
    @apply overflow-hidden p-2;
    @apply transition transition-all;
    @apply bg-white shadow-sm rounded-lg;
}

.disclosure-button {
    @apply w-full flex items-center justify-between space-x-4 p-3 rounded-md;
    @apply transition transition-all;
    @apply font-museo text-left text-primary-gray-darker;
    @apply bg-transparent;
}
.disclosure-button:hover,
.disclosure-button:focus {
    @apply bg-orange-800 text-white outline-none;
}

.disclosure-button:focus {
    @apply ring-2 ring-offset-2 ring-orange-800;
}

.link--button:hover {
    @apply bg-white text-secondary ring-0;
}

.link--button:focus {
    @apply bg-white text-secondary ring-0 ring-offset-0 outline outline-offset-2 outline-white;
}
</style>

<script>
import {Disclosure, DisclosureButton, DisclosurePanel, Switch} from '@headlessui/vue';
import {Link} from '@inertiajs/vue3';
import {mdiAlert, mdiArrowDown, mdiArrowUp, mdiBus, mdiCheck, mdiChevronDown, mdiFerry, mdiGondola, mdiTrain, mdiTram,} from "@mdi/js";
import axios from 'axios';
import {sortBy} from 'lodash';
import {computed, defineComponent, ref} from 'vue';

import AppHead from '../components/AppHead';
import Icon from '../components/Icon';
import Spinner from '../components/Spinner';
import {sortAndPrepareRouteGroups} from "../utils/helpers.js";

export default defineComponent({
    name: 'CustomTransitBookApp',
    components: {
        AppHead,
        Link,
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        Switch,
        Icon,
        Spinner
    },
    props: {
        breadcrumbs: {type: Array, default: () => []},
    },
    setup(props) {
        const extraPdfAssetGroups = ref()
        const selectedExtras = ref({})

        const routeGroups = ref()
        const selectedRoutes = ref({})
        const selectedRoutesCount = computed(() =>
            Object.values(selectedRoutes.value).filter(item => item).length
        )

        const loadingRoutes = ref(false)
        const downloading = ref(false)

        const load = async () => {
            loadingRoutes.value = true

            return axios.get('/api/transit-book/routes')
                .then(response => response.data)
                .then(data => {
                    routeGroups.value = sortAndPrepareRouteGroups(data.route_groups)
                    extraPdfAssetGroups.value = data.extras

                    selectedExtras.value = data.extras.reduce((acc, extraGroup) => {
                        return { ...acc, ...extraGroup.assets.reduce((acc2, asset) => {
                            return { ...acc2, [asset.id]: asset.preselected }
                        }, {})}
                    }, {})

                    loadingRoutes.value = false
                })
        }

        load()

        const download = async () => {
            if(downloading.value) {
                return
            }

            downloading.value = true

            const params = new URLSearchParams()

            const routeIds = Object.entries(selectedRoutes.value).reduce((acc, [routeId, isSelected]) => {
                return isSelected ? [...acc, routeId] : acc
            }, [])
            routeIds.forEach(routeId => params.append('routes[]', routeId))

            const extraIds = Object.entries(selectedExtras.value).reduce((acc, [extraId, isSelected]) => {
                return isSelected ? [...acc, extraId] : acc
            }, [])
            extraIds.forEach(extraId => params.append('extras[]', extraId))

            const url = `/api/transit-book/build-book?${params.toString()}`

            const response = await axios.get(url, {
                responseType: "arraybuffer",
            });

            downloading.value = false

            const blob = new Blob([response.data], { type: "application/pdf" });
            const link = document.createElement("a");

            link.href = window.URL.createObjectURL(blob);
            link.download = 'My eTransit Book.pdf';

            link.click();
        }

        function sortedRouteGroup(groupData) {
            if(groupData.name === 'Neighborhood Circulators') {
                return sortBy(groupData.routes, 'title');
            }

            return groupData.routes;
        }

        return {
            transitIcons: {
                'tram': mdiTram,
                'subway': mdiTrain,
                'rail': mdiTrain,
                'bus': mdiBus,
                'ferry': mdiFerry,
                'cable-car': mdiTram,
                'gondola': mdiGondola,
                'funicular': mdiGondola,
            },

            mdiAlert,
            mdiCheck,
            mdiArrowUp,
            mdiArrowDown,
            mdiChevronDown,

            selectedRoutes,
            selectedRoutesCount,

            extraPdfAssetGroups,
            selectedExtras,

            routeGroups,
            onGroupScroll(group, $event) {
                //
            },

            download,
            downloading,
            loadingRoutes,
            sortedRouteGroup
        }
    }
})
</script>
