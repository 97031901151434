<template>
    <div class="space-y-1">
        <template v-if="field.visibility === 'hidden'">
            <slot v-bind="{ field }"></slot>
        </template>
        <template v-else>
            <component :is="disableLabel ? 'div' : FieldLabel" v-bind="labelAttrs">
                <FieldInstructions v-if="field.instructions_position === 'above'" :field="field">
                    {{ field.instructions }}
                </FieldInstructions>

                <div :class="containerClasses">
                    <span v-if="!disablePrepend && field.prepend" class="inline-flex items-center rounded-l-md border-r border-gray-400 bg-gray-100 px-3 py-3 text-gray-600 text-sm sm:text-xs">
                        {{ field.prepend }}
                    </span>

                    <slot v-bind="{ field, fieldClasses: {'rounded-l-md': !field.prepend, 'rounded-r-md': !field.append} }"></slot>

                    <span v-if="!disableAppend && field.append" class="inline-flex items-center rounded-r-md border-l border-gray-400 bg-gray-100 px-3 py-3 text-gray-600 text-sm sm:text-xs">
                        {{ field.append }}
                    </span>
                </div>

                <FieldInstructions v-if="field.instructions_position === 'below'" :field="field">
                    {{ field.instructions }}
                </FieldInstructions>
            </component>
        </template>
    </div>
</template>

<script setup>
import {computed} from "vue";
import FieldInstructions from "./FieldInstructions";
import FieldLabel from "./FieldLabel";
import {defineField} from "./Fields/BaseField";

const props = defineProps({
    containerClasses: {
        type: Array,
        default: () => [
            'w-full flex mt-1 rounded-md shadow-sm border border-gray-700 overflow-hidden',
            'focus-within:border-primary-500 focus-within:ring-2 focus-within:ring-primary-500',
        ],
    },
    disablePrepend: {
        type: Boolean,
        default: false,
    },
    disableAppend: {
        type: Boolean,
        default: false,
    },
    disableLabel: {
        type: Boolean,
        default: false,
    },
})

const field = defineField();

const labelAttrs = computed(() => {
    return (!props.disableLabel)
        ? {field}
        : {};
});
</script>
