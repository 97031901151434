<template>
    <div>
        <GoogleMap
            ref="mapRef"
            :api-key="googleApiKey"
            :center="{'lat':mapCenter.lat, 'lng':mapCenter.lng}"
            :zoom="zoom"
            :libraries="['places', 'geometry']"
            style="height: 100%; width: 100%;"
        >
            <!-- User location marker -->

            <!-- Origin/Destination markers -->
            <template v-if="!hideFromAndTo">
                <Marker v-if="originMarker" :options="originMarker"/>
                <Marker v-if="destMarker" :options="destMarker"/>
            </template>

            <!-- render lines -->
            <template v-for="marker in mapMarkers">
                <Marker :options="marker" @click="$emit('onMarkerClicked', marker)"/>
            </template>

            <template v-for="line in mapLines">
                <Polyline :options="line"/>
            </template>
        </GoogleMap>
    </div>
</template>

<script>
import { usePage } from '@inertiajs/vue3';
import { findIndex } from "lodash";
import { computed, defineComponent, ref, watch } from "vue";
import { GoogleMap, Marker, Polyline } from "vue3-google-map";
import { ICONS } from '../utils/icons';

function buildMarkers(mapRef, stops, highlightedStops) {
    return (stops || []).map((stop) => {
        const index = findIndex(highlightedStops, (hs) => hs.code === stop.code);
        const icon = (index > -1)
            ? Object.assign({}, ICONS.mapCircle, ICONS.mapCircleHighlighted)
            : ICONS.mapCircle;

        return {
            stop,
            icon,
            title: `${stop.name} - ${stop.code}`,
            position: {
                lat: parseFloat(stop.location.lat),
                lng: parseFloat(stop.location.lng),
            },
            optimized: false,
        }
    });
}

function buildLines(mapRef, lines) {
    const selectedLeg = -1;

    return (lines || []).map((line, index) => {
        const path = line.coordinates.split(' ').map((coord) => {
            const [lng, lat] = coord.split(',');

            return {
                lat: parseFloat(lat),
                lng: parseFloat(lng),
            }
        });

        return {
            path,
            geodesic: true,
            strokeColor: '#502059',
            strokeWeight: 2,
            strokeOpacity: (index === selectedLeg) ? 1 : 0.5,
            icons: [],
        };
    });
}

export default defineComponent({
    name: "RouteMap",
    components: {
        GoogleMap,
        Polyline,
        Marker,
    },

    props: {
        zoom: {
            type: Number,
            default: 11,
        },
        center: {
            type: Object,
            default: () => ({lat: 33.451284, lng: -112.07454}),
        },
        centerOnBounds: {
            type: Boolean,
            default: true,
        },
        shape: {
            type: Object,
            required: true,
        },
        route: {
            type: [Object, null],
            required: false,
            default: null,
        },
        highlightedLeg: {
            type: Number,
            required: false,
            default: -1,
        },
        highlightedStops: {
            type: Array,
            required: false,
            default: () => [],
        },
        hideFromAndTo: {
            type: Boolean,
            default: false,
        },
        draggableFromAndTo: {
            type: Boolean,
            default: false,
        },
        clickableFromAndTo: {
            type: Boolean,
            default: false,
        },
    },

    setup(props, {emit}) {
        const mapRef = ref(null);
        const isMapVisibleOnSmallScreens = ref(false);
        const googleApiKey = usePage().props.global.google.maps_api_key;

        // Change the map visibility for small screens
        function setMapVisibilityOnSmallScreens(isVisible) {
            isMapVisibleOnSmallScreens.value = isVisible;
        }

        // Generate the markers.
        const originMarker = computed(() => {
            const locationFrom = props.shape.stops[0]?.location;

            if (!locationFrom) {
                return null;
            }

            return {
                id: 'origin',
                title: 'From',
                icon: ICONS.mapCircle,
                clickable: props.clickableFromAndTo,
                draggable: props.draggableFromAndTo,
                position: {
                    lat: parseFloat(locationFrom.lat),
                    lng: parseFloat(locationFrom.lng),
                },
                label: {
                    text: 'From',
                    color: '#fff',
                    background: '#000',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    className: 'route-map__origin-dest-marker'
                },
            };
        });

        const destMarker = computed(() => {
            const locationTo = props.shape.stops[props.shape.stops.length - 1]?.location;

            if (!locationTo) {
                return null;
            }

            return {
                id: 'destination',
                title: 'To',
                icon: ICONS.mapCircle,
                clickable: props.clickableFromAndTo,
                draggable: props.draggableFromAndTo,
                position: {
                    lat: parseFloat(locationTo.lat),
                    lng: parseFloat(locationTo.lng),
                },
                label: {
                    text: 'To',
                    color: '#fff',
                    background: '#000',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    className: 'route-map__origin-dest-marker'
                },
            };
        });

        // Build trip markers and lines.
        const mapLines = ref([]);
        const mapMarkers = ref([]);

        function updateMarkersAndLines() {
            mapMarkers.value = buildMarkers(mapRef, props.shape.stops, props.highlightedStops);
            mapLines.value = buildLines(mapRef, props.shape.lines);
        }

        const defaultMapCenter = {lat: 33.451284, lng: -112.07454};
        const mapCenter = ref(props.center);

        function setMapCenter({lat, lng}) {
            mapCenter.value = {lat, lng};
        }

        function centerMapOnBounds() {
            setMapCenter(props?.shape?.bounds?.center ? props.shape.bounds.center : defaultMapCenter);
        }

        function updateMap() {
            updateMarkersAndLines();

            if (props.centerOnBounds) {
                centerMapOnBounds();
            }
        }

        // Fit the map into the selected route bounds.
        watch(() => [props.route, props.shape], () => updateMap());
        watch(() => props.highlightedStops, () => updateMarkersAndLines());

        // Emit an event when the Google maps library is ready.
        watch(() => mapRef.value?.ready, () => {
            if (mapRef.value.ready) {
                emit('ready', mapRef);
                updateMap();
            }
        });

        function locationMarkerDragged(locationType, event) {
            const newLocation = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            };

            emit('location-changed', {
                from: locationType === 'origin' ? newLocation : props.locationFrom,
                to: locationType === 'destination' ? newLocation : props.locationTo,
            });
        }

        return {
            mapRef,
            mapLines,
            mapMarkers,
            mapCenter,
            originMarker,
            destMarker,
            googleApiKey,
            setMapCenter,
            locationMarkerDragged,
        }
    }
})
</script>

<style>
.route-map__origin-dest-marker {
    @apply relative;
    @apply px-2 py-1;
    @apply bg-gray-800;
    width: 3rem;
    margin-left: -4.2rem;
}

.route-map__origin-dest-marker::after {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: 100%;
    z-index: 100;
    border-style: solid;
    border-color: transparent theme('colors.gray.800');
    border-width: 12px 0 12px 12px;
    width: 0;
    height: 0;
}
</style>
