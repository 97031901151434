import { addDays, addHours, addMinutes, eachDayOfInterval, eachMinuteOfInterval, format } from 'date-fns';
import { findIndex } from "lodash";
import { computed, ref } from "vue";
import { getToday } from "../../utils/date.js";

function generateTimeOptions() {
    const start = new Date(0).setHours(0, 0, 0);
    const end = addMinutes(addHours(start, 23), 59);

    return eachMinuteOfInterval({
        start,
        end,
    }, {
        step: 15
    }).map((date) => {
        return {
            value: format(date, 'HH:mm'),
            label: format(date, 'hh:mm aaaa'),
        };
    });
}

function generateDateOptions() {
    const interval = {
        start: getToday(),
        end: addDays(getToday(), 30),
    }

    return eachDayOfInterval(interval).map((date) => {
        return {
            value: format(date, 'yyyy-MM-dd'),
            label: format(date, 'E, MMM do'),
        };
    });
}

function nowDate() {
    return format(new Date, 'yyyy-MM-dd');
}

function nowTime() {
    return format(new Date, 'HH:mm');
}

export const MODE_LEAVING_NOW = 'now';
export const MODE_DEPART_AT = 'depart';
export const MODE_ARRIVE_BY = 'arrive';

export const TRIP_BEST_ROUTE = 'best_route';
export const TRIP_LESS_WALKING = 'less_walking';
export const TRIP_FEWER_TRANSFERS = 'fewer_transfers';

const validTripOptions = [
    TRIP_BEST_ROUTE,
    TRIP_LESS_WALKING,
    TRIP_FEWER_TRANSFERS,
];

const validModes = [
    MODE_ARRIVE_BY,
    MODE_DEPART_AT,
    MODE_LEAVING_NOW,
];

const TIME_OPTIONS = generateTimeOptions();
const DATE_OPTIONS = generateDateOptions();

const flags = {
    MODE_LEAVING_NOW,
    MODE_DEPART_AT,
    MODE_ARRIVE_BY,
    TRIP_BEST_ROUTE,
    TRIP_LESS_WALKING,
    TRIP_FEWER_TRANSFERS,
    TIME_OPTIONS,
    DATE_OPTIONS,
}

const state = ref({
    from: {
        value: '',
        location: null,
    },
    to: {
        value: '',
        location: null,
    },
    mode: MODE_LEAVING_NOW,
    time: nowTime(),
    date: nowDate(),
    tripOption: TRIP_BEST_ROUTE,
    isMapVisibleOnSmallScreens: false,
});

const locationFrom = computed(() => state.value.from);
const locationTo = computed(() => state.value.to);
const mode = computed(() => state.value.mode);
const time = computed(() => state.value.time);
const date = computed(() => state.value.date);
const tripOption = computed(() => state.value.tripOption);
const allFieldsOk = computed(() => isValid());
const isMapVisibleOnSmallScreens = computed(() => state.value.isMapVisibleOnSmallScreens);

function setMapVisibilityOnSmallScreens(isVisible) {
    state.value.isMapVisibleOnSmallScreens = isVisible;
}

function setLocationFrom({ value, location }) {
    if(!location) return;

    state.value.from.value = value;
    state.value.from.location = {
        lng: location.lng ? parseFloat(location.lng) : undefined,
        lat: location.lat ? parseFloat(location.lat) : undefined,
    };
}

function setLocationTo({ value, location }) {
    if(!location) return;

    state.value.to.value = value;
    state.value.to.location = {
        lng: location.lng ? parseFloat(location.lng) : undefined,
        lat: location.lat ? parseFloat(location.lat) : undefined,
    }
}

function switchLocations() {
    const newTo = {
        value: locationFrom.value.value,
        location: locationFrom.value.location,
    };

    setLocationFrom(locationTo.value);
    setLocationTo(newTo);
}

function setMode(value) {
    if (value && ! validModes.includes(value)) {
        throw new Error('Invalid trip mode: ' + value);
    }

    state.value.mode = value;

    if (value === MODE_LEAVING_NOW) {
        state.value.date = '-';
        state.value.time = '-';
    } else {
        state.value.date = DATE_OPTIONS[0].value;
        state.value.time = TIME_OPTIONS[0].value;
    }
}

function setTime(value) {
    state.value.time = value;
}

function setDate(value) {
    state.value.date = value;
}

function setTripOption(value) {
    if (! validTripOptions.includes(value)) {
        throw new Error('Invalid Trip Option: ' + value);
    }

    state.value.tripOption = value;
}

function isLocationValid(location) {
    return location.value
        && location.value.trim()
        && location.location;
}

function isTimeAndDateValid() {
    if (state.value.time.trim() === '' || state.value.time.trim() === '') {
        return false;
    }

    return ((findIndex(TIME_OPTIONS, {value: state.value.time}) > -1)
        && (findIndex(DATE_OPTIONS, {value: state.value.date}) > -1));
}

function isValid() {
    if (! state.value.tripOption || ! validTripOptions.includes(state.value.tripOption)) {
        return false;
    }

    if (!isLocationValid(state.value.from) || !isLocationValid(state.value.to)) {
        return false;
    }

    if (state.value.mode === MODE_LEAVING_NOW) {
        return true;
    }

    if (state.value.mode === MODE_DEPART_AT || state.value.mode === MODE_ARRIVE_BY) {
        return isTimeAndDateValid();
    }

    return false;
}

export function useTripInput () {
    return {
        mode,
        time,
        date,
        flags,
        tripOption,
        locationTo,
        allFieldsOk,
        locationFrom,
        isMapVisibleOnSmallScreens,
        setMode,
        setTime,
        setDate,
        setTripOption,
        setLocationTo,
        setLocationFrom,
        switchLocations,
        setMapVisibilityOnSmallScreens,
    }
}
