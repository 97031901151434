<template>
    <div class="w-full">
        <div class="px-2 text-left">
            <PrimaryButton type="button" @click="toggleMode" class="mb-6" :aria-label="$tx(btnAriaLabel)">
                {{ $tx(btnText) }}
            </PrimaryButton>
        </div>

        <div v-if="mode === 'chart'">
            <div class="mx-4">
                <ChartLabelHint/>
            </div>
            <slot name="chart"/>
        </div>
        <div v-if="mode === 'table'">
            <slot name="table"/>
        </div>
    </div>
</template>

<script>
import {computed, defineComponent, inject, ref} from 'vue';
import PrimaryButton from "../../../components/PrimaryButton";
import ChartLabelHint from "./ChartLabelHint";
import DataTable from "./DataTable";

export default defineComponent({
    name: 'ChartTableSwitch',
    components: {
        DataTable,
        PrimaryButton,
        ChartLabelHint
    },
    props: {
        pageTitle: {
            type: String,
            required: true,
        },
        viewTable: {
            type: String,
            default: 'View Table',
        },
        viewChart: {
            type: String,
            default: 'View Chart',
        }
    },
    setup(props) {
        const tx = inject('tx');
        const mode = ref('chart');

        const btnText = computed(() => {
            return mode.value === 'chart' ? props.viewTable : props.viewChart;
        })

        const btnAriaLabel = computed(() => {
            return `View ${props.pageTitle} ${btnText.value.replace('View ', '')}`;
        });

        function toggleMode() {
            mode.value = (mode.value === 'chart')
                ? 'table'
                : 'chart';
        }

        return {
            mode,
            btnText,
            btnAriaLabel,
            toggleMode,
        }
    },
});
</script>
