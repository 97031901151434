<template>
    <img class="covid-icon inline-block mx-4 w-8" src="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/covid-icon.svg" alt="COVID" role="img" />
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'CovidIcon',
})
</script>
