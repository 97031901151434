<template>
    <div class="relative w-full lg:grid lg:grid-cols-6 lg:gap-5">
        <div :class="classes.content">
            <div v-if="title && !hide_title">
                <h2 class="block pb-4 text-2xl text-left leading-loose font-extrabold tracking-tight text-gray-900">
                    {{ title }}
                </h2>
            </div>

            <div class="space-y-16">
                <SetsRenderer :sets="content" />
            </div>
        </div>
        <div v-if="resources && resources.length > 0" :class="classes.sidebar">
            <div class="relative w-full max-w-7xl mx-auto bg-white rounded-lg">
                <div class="divider-y divider-y-gray-100 space-y-4">
                    <SetsRenderer :sets="resources" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent} from 'vue';
import SetsRenderer from "./SetsRenderer";

export default defineComponent({
    name: 'Tab',
    components: {SetsRenderer},
    props: {
        title: {type: [String, null], required: true},
        content: {type: Array, required: true},
        resources: {type: Array, default: []},
        hide_title: {type: Boolean, default: false},
        withSubheading: {type: Boolean, default: false},
    },

    setup(props) {
        const classes = props.resources.length
            ? { content: 'lg:col-span-4', sidebar: 'lg:col-span-2' }
            : { content: 'lg:col-span-6' };

        return {
            classes,
        }
    },
});
</script>
