<template>
    <div class="space-y-2 divide-y divide-gray-100">
        <ul v-for="item in items" :key="item.id" class="text-gray-800 text-base font-normal py-2 list-none">
            <li class="flex gap-2 items-center">{{ item.name }}</li>
            <li v-if="item.title" class="mt-1 mb-3 text-xs text-gray-600">{{ item.title }}</li>

            <li><a v-if="item.phone" :href="`tel:${item.phone}`" class="block text-secondary text-base font-normal underline">{{ item.phone }}</a></li>
            <li><a v-if="item.email" :href="`mailto:${item.email}`" class="block text-secondary text-base font-normal underline">{{ item.email }}</a></li>
        </ul>
    </div>
</template>

<script>
import {computed, defineComponent} from 'vue';

export default defineComponent({
    name: 'ResourceContacts',
    props: {
        contacts: {type: Array, required: true},
    },

    setup(props) {
        const items = computed(() => {
            return props.contacts.map((contact) => ({
                id: contact.contact.id,
                name: contact.contact.title,
                title: contact.displayed_title || contact.contact.contact_title,
                phone: contact.contact.phone,
                email: contact.contact.email
            }))
        });

        return {
            items
        }
    },
});
</script>
