<template>
    <div class="flex justify-center py-1 px-4">
        <table :role="!header?.length ? 'none' : null">
            <thead v-if="header">
                <tr>
                    <th v-for="(col, idx) in header" :key="idx" scope="col">{{ col }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in rows" scope="row" class="align-top">
                    <td v-for="(col, idx) in row" :key="idx" class="table-cell text-left" v-html="col"></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'InfoTable',
    props: {
        header: {
            type: Array,
            required: false,
        },
        rows: {
            type: Array,
            required: true
        }
    },
    setup(props) {
    }
});
</script>

<style scoped>
    .table-cell {
        font-size: 1rem;
        line-height: 1.4;
        padding: 0 .5rem;
    }
</style>
