<template>
    <FieldContainer v-slot="{ field }" disable-prepend disable-append>
        <textarea
            :id="field.id"
            :name="field.handle"
            :placeholder="field.placeholder"
            :required="field.isRequired"
            rows="5"
            class="w-full field-custom field-in-group"
            v-bind="buildAttrs(field)" :value="field.old"
        ></textarea>
    </FieldContainer>
</template>

<script setup>
import FieldContainer from "../FieldContainer";

function buildAttrs(field) {
    const attrs = {};

    if (field.character_limit) {
        attrs.maxlength = field.character_limit;
    }

    return attrs;
}
</script>
