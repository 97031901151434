<template>
<div>
    <div v-if="store.state.isLoading" class="flex justify-center w-full py-32">
        <Spinner class="w-8 h-8 text-primary" />
    </div>

    <div v-else>
        <transition name="fade">
            <div v-if="store.state.dataFetched" class="space-y-2">
                <Disclosure v-for="(group, groupIndex) in store.state.routeGroups" :default-open="isRouteGroupOpenByDefault(group)" v-slot="{ open }" :key="group.name">
                    <div class="disclosure">
                        <DisclosureButton class="disclosure-button group">
                            <div class="flex items-center justify-start flex-1 space-x-2 text-lg font-bold">
                                <Icon v-if="ICONS[group.mode]" class="w-6 h-6 mr-1 text-primary-gray-600 group-hover:text-primary-50 group-focus:text-primary-50" :icon="ICONS[group.mode]" :aria-label="group.mode"></Icon>
                                <span>{{ group.name }}</span>
                            </div>
                            <div v-if="group.alerts.count > 0" class="flex items-center font-normal text-secondary-900 group-hover:text-primary-50 group-focus:text-primary-50">
                                <Icon :icon="mdiAlert" class="w-5 h-5 mr-2"  aria-label="Alert icon"/>
                                <span class="text-sm">{{ group.alerts.count }} Rider {{ group.alerts.count > 1 ? 'Alerts' : 'Alert' }}</span>
                            </div>
                            <div>
                                <Icon :icon="mdiChevronDown" class="w-5 h-5 transition transition-transform text-primary group-hover:text-primary-50 group-focus:text-primary-50" :class="{'transform rotate-180': open}" aria-label="Arrow down icon" />
                            </div>
                        </DisclosureButton>
                        <DisclosurePanel>
                            <div class="relative">
                                <div v-if="group.showTop" class="absolute top-0 left-1/2 text-primary blink-top">
                                    <Icon class="w-8 h-8" :icon="mdiArrowUp" aria-label="Arrow up icon"/>
                                </div>
                                <div class="p-2 mt-2 overflow-y-scroll divide-y divide-gray-100 max-h-60" :class="{'shadow-vertical-scroll-both': group.showTop && group.showBottom, 'shadow-vertical-scroll-down': group.showTop && !group.showBottom, 'shadow-vertical-scroll-up': group.showBottom && !group.showTop}" @scroll="onGroupScroll(groupIndex, $event)">
                                    <button v-for="route in sortedRouteGroup(group)" @click="viewRoute($event, route)" class="disclosure-button group" :aria-label="`Route ${route.id} ${route.longName} - ${route.hasAlerts ?
                                    'has rider alerts' : 'no rider alerts'}`">
                                        <span class="flex items-center font-normal flex-0">
                                            <Icon v-if="route.hasAlerts" :icon="mdiAlert" class="w-5 h-5 mr-2 text-secondary-900 group-hover:text-white group-focus:text-white" aria-hidden="true" />
                                            <Icon v-else :icon="mdiCheck" class="w-5 h-5 mr-2 text-green-900 group-hover:text-white group-focus:text-white" aria-hidden="true" />
                                        </span>
                                        <span class="flex items-center justify-center w-12 h-6 px-2 text-sm font-normal rounded text-primary-50 bg-primary">{{ route.id }}</span>
                                        <span class="flex-1 text-left">{{ route.longName }}</span>
                                    </button>
                                </div>
                                <div v-if="group.showBottom" class="absolute bottom-0 -mt-2 left-1/2 text-primary blink-bottom">
                                    <Icon class="w-8 h-8" :icon="mdiArrowDown" aria-label="Arrow down icon"/>
                                </div>
                            </div>
                        </DisclosurePanel>
                    </div>
                </Disclosure>
            </div>
        </transition>
    </div>
</div>
</template>

<style scoped>
.disclosure {
    @apply overflow-hidden p-2;
    @apply transition transition-all;
    @apply bg-white shadow-sm rounded-lg;
}

.disclosure-button {
    @apply w-full flex items-center justify-between space-x-4 p-3 rounded-md;
    @apply transition transition-all;
    @apply font-museo text-left text-primary-gray-darker;
    @apply bg-transparent;
}
.disclosure-button:hover,
.disclosure-button:focus {
    @apply bg-orange-800 text-white outline-none;
}

.disclosure-button:focus {
    @apply ring-2 ring-offset-2 ring-orange-800;
}
</style>

<script>
import {Disclosure, DisclosureButton, DisclosurePanel} from "@headlessui/vue";
import {mdiAlert, mdiArrowDown, mdiArrowUp, mdiCheck, mdiChevronDown, mdiClock, mdiMap} from "@mdi/js";
import {defineComponent} from 'vue';
import {ICONS} from "../../utils/icons";
import Icon from "../Icon";
import Spinner from "../Spinner";
import {useStore} from "./store";
import {sortBy} from "lodash";

export default defineComponent({
    name: 'MapsSchedulesList',
    components: {
        Icon,
        Spinner,
        Disclosure,
        DisclosurePanel,
        DisclosureButton,
    },

    setup() {
        const store = useStore();
        const urlParams = new URLSearchParams(document.location?.search);

        function viewRoute (event, route, mode) {
            // Stop propagation otherwise View Map button would not work because its parent already
            // catches the click events.
            event.stopImmediatePropagation();

            let href = `/maps-schedules/${route.id}`;

            if (mode) {
                href = `${href}/${mode}`;
            }

            store.startLoading();
            window.location.href = href;
        }

        function sortedRouteGroup(groupData) {
            if(groupData.slug === 'neighborhood-circulators') {
                return sortBy(groupData.routes, 'longName');
            }

            return groupData.routes;
        }

        const onGroupScroll = (group, event) => {
            const showTop = event.target.scrollTop > 15;
            const showBottom = event.target.scrollTop < event.target.scrollHeight - event.target.clientHeight - 20;

            store.setGroupScrolls(group, showTop, showBottom);
        };

        const isRouteGroupOpenByDefault = (group) => {
            return urlParams.get('tab') === group.slug;
        }

        return {
            store,

            // Icons
            ICONS,
            mdiMap,
            mdiAlert,
            mdiClock,
            mdiCheck,
            mdiArrowUp,
            mdiArrowDown,
            mdiChevronDown,

            // Functions
            viewRoute,
            onGroupScroll,
            isRouteGroupOpenByDefault,
            sortedRouteGroup
        }
    },
});
</script>
