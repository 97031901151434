<template>
    <div class="gap-y-2" :class="containerClasses">
        <a v-if="image_link && image_url" :href="image_link" target="_blank">
            <img
                :class="{
                        'w-full object-cover': image_width.value === 'auto' || image_width.value === 'full',
                        'rounded-lg': image_width.value === 'original',
                        'mr-auto': image_alignment.value === 'left',
                        'mx-auto': image_alignment.value === 'center',
                        'ml-auto': image_alignment.value === 'right',
                    }"
                :style="buildImageStyle()"
                :src="image_url"
                :alt="getImageAlt()"
                aria-hidden="false"
            >
        </a>

        <img
            v-if="!image_link && image_url"
            :class="{
                        'w-full object-cover': image_width.value === 'auto' || image_width.value === 'full',
                        'rounded-lg': image_width.value === 'original',
                        'mr-auto': image_alignment.value === 'left',
                        'mx-auto': image_alignment.value === 'center',
                        'ml-auto': image_alignment.value === 'right',
                    }"
            :style="buildImageStyle()"
            :src="image_url"
            :alt="getImageAlt()"
            aria-hidden="false"
        >

        <template v-if="!image_url" v-for="img in image">
            <figure
                :class="{
                    'w-full rounded-lg overflow-hidden': image_width.value === 'auto' || image_width.value === 'full',
                }"
                :style="buildFigureStyle(img)"
            >
                <a v-if="image_link" :href="image_link" target="_blank">
                    <img
                        :class="{
                        'w-full object-cover': image_width.value === 'auto' || image_width.value === 'full',
                        'rounded-lg': image_width.value === 'original',
                        'mr-auto': image_alignment.value === 'left',
                        'mx-auto': image_alignment.value === 'center',
                        'ml-auto': image_alignment.value === 'right',
                    }"
                        :style="buildImageStyle(img)"
                        :src="img.permalink"
                        :alt="getImageAlt(img)"
                        :aria-hidden="getImageAlt(img).length === 0"
                    >
                </a>
                <img
                    v-else
                    :class="{
                        'w-full object-cover': image_width.value === 'auto' || image_width.value === 'full',
                        'rounded-lg': image_width.value === 'original',
                        'mr-auto': image_alignment.value === 'left',
                        'mx-auto': image_alignment.value === 'center',
                        'ml-auto': image_alignment.value === 'right',
                    }"
                    :style="buildImageStyle(img)"
                    :src="img.permalink"
                    :alt="getImageAlt(img)"
                    :aria-hidden="getImageAlt(img).length === 0"
                >
                <figcaption class="text-sm text-gray-600 text-center font-normal">{{ title }}</figcaption>
            </figure>
        </template>
    </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
    name: "Image",
    props: {
        title: {
            type: [String, null],
            default: null,
        },
        image: {
            type: Array,
            required: true,
        },
        image_url: {
            type: [String, null],
            default: null,
        },
        aspect_ratio: {
            type: [Object, null],
            default: null,
        },
        vue: {
            type: [Object, null],
            default: null,
        },
        image_alt_revealer: {
            type: [Boolean, null],
            default: null,
        },
        image_alt: {
            type: [String, null],
            default: null,
        },
        image_width: {
            type: Object,
            default: {value: 'auto', label: 'Auto'},
        },
        image_alignment: {
            type: Object,
            default: {value: 'center', label: 'Center'},
        },
        image_link: {
            type: String,
            default: null,
        },
    },

    setup({ image_alt_revealer, image_alt, aspect_ratio, vue, image_width }) {
        const containerWidthClassOptions = {
            auto: 'max-w-lg',
            full: '',
            original: '',
        };

        const containerClasses = vue && vue.class
            ? [vue.class, containerWidthClassOptions[image_width.value]]
            : [];

        function getImageAlt (image) {
            if (image_alt_revealer) {
                return (image_alt || '').trim();
            }

            return (image?.alt || '').trim();
        }

        function buildFigureStyle () {
            const style = {};

            if (aspect_ratio?.value) {
                style.aspectRatio = aspect_ratio.value;
            }

            return style;
        }

        function buildImageStyle (image) {
            const style = buildFigureStyle();

            if (image?.focus) {
                const [top, left, zoom] = image.focus.split('-');

                style.transform = `scale(${zoom})`;
                style.objectPosition = `${top}% ${left}%`;
            }

            return style;
        }

        return {
            containerClasses,
            getImageAlt,
            buildImageStyle,
            buildFigureStyle,
        };
    }
});
</script>
