<template>
    <input
        v-bind="$attrs"
        @input="onInput"
        @keydown="onKeyDown"
        :value="modelValue"
        :type="type"
        :role="role"
        :readonly="readOnly"
        :aria-controls="ariaControls"
        :aria-label="ariaLabel"
        :aria-autocomplete="ariaAutocomplete"
        :aria-activedescendant="ariaActivedescendant"
        :class="{'form-input shadow rounded border-gray-500 text-black hover:border-gray-700 focus:border-gray-700 focus:ring-offset-2 focus:ring-2 focus:ring-gray-700': !unstyled, 'placeholder-gray-600': true}"
    />
</template>

<script>

export default {
    name: "InputText",
    emits: ['update:modelValue', 'keydown'],
    props: {
        modelValue: {
            type: String,
            required: false,
            default: '',
        },
        type: {
            type: String,
            required: false,
            default: 'text',
        },
        unstyled: {
            type: Boolean,
            required: false,
            default: false,
        },
        readOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
        role: {
            required: false,
            type: String,
            default: '',
        },
        ariaControls: {
            type: String,
            required: false,
            default: '',
        },
        ariaActivedescendant: {
            type: String,
            required: false,
            default: '',
        },
        ariaLabel: {
            type: String,
            required: false,
            default: '',
        },
        ariaAutocomplete: {
            type: String,
            required: false,
            default: 'none',
        },
    },

    setup(props, {emit}) {
        function onInput(ev) {
            emit('update:modelValue', ev.target.value);
        }

        function onKeyDown(ev) {
            ev.stopPropagation();
            emit('keydown', ev);
        }

        return {onInput, onKeyDown};
    }
}
</script>

<style scoped>

</style>
