<template>
    <div class="relative w-full bg-white xl:overflow-hidden">
        <SectionTitle :title="title" link="cost-of-congestion" color="purple" class="mb-0" />
        <div class="flex w-full">
            <div class="relative w-full bg-white overflow-hidden">
                <Section class="pb-6">
                    <template #graphic>
                        <Image class="graphic mt-0 mb-4" src="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/isolated/static-cost-congestion-v2.svg" alt="" />
                    </template>
                    <template #info>
                        <InfoBoxContainer class="pb-10 mt-20 mb-8 lg:mt-40 lg:mb-0">
                            <InfoBox halign="left" v-for="(infoBox, index) in infoBoxes" :key="index" width-class="w-[290px] mb-8" color="qol1-full">
                                <template #header>
                                    <div class="flex flex-col items-center">
                                        <Image :src="infoBox.icon" :alt="infoBox.alt" class="h-32 -mb-[1rem]" />
                                        <span>{{ infoBox.title }}</span>
                                    </div>
                                </template>
                                <template #content>
                                    <InfoTable :header="infoBox.table.header" :rows="infoBox.table.rows"/>
                                </template>
                            </InfoBox>
                        </InfoBoxContainer>

                        <CovidDisclaimer />
                    </template>
                </Section>
            </div>
        </div>
    </div>

</template>

<script>
import {defineComponent, computed} from 'vue';
import Image from "../Components/Image";
import InfoBoxContainer from "../Components/InfoBoxContainer";

import Section from "../Components/Section";
import SectionTitle from "../Components/SectionTitle";
import InfoBox from "../Components/InfoBox";

import InfoTable from "../Components/InfoTable";

import useTranslations from "../translations";

import CovidDisclaimer from '../Components/CovidDisclaimer';

export default defineComponent({
    name: 'CostOfCongestion',
    components: {
        InfoBoxContainer,
        Image,
        Section,
        SectionTitle,
        InfoBox,
        InfoTable,
        CovidDisclaimer,
    },
    setup() {
        const { translate, translations } = useTranslations();

        const title = computed(() => translate.value(
            'Cost of Congestion',
            'Costo del Congestionamiento',
        ));

        const workDaysLabel = computed(() => translate.value('work days', 'días laborales'));

        const infoBoxes = computed(() => [
            {
                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/cost_of_congestion_1.svg',
                title: translate.value('Annual travel delay', 'Retraso anual de viajes en horas'),
                table: {
                    rows: [
                        ['2017', '163M hrs'],
                        ['2018', '166M hrs'],
                        ['2019', '168M hrs'],
                        ['2020', '68M hrs'],
                    ]
                },
            },
            {
                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/cost_of_congestion_2.svg',
                title: translate.value('Annual delay per commuter', 'Retraso anual por viajero cotidiano'),
                table: {
                    rows: [
                        ['2017', `62 hrs<br/>(7.75 ${workDaysLabel.value})`],
                        ['2018', `62 hrs<br/>(7.75 ${workDaysLabel.value})`],
                        ['2019', `61 hrs<br/>(7.5 ${workDaysLabel.value})`],
                        ['2020', `25 hrs<br/>(3 ${workDaysLabel.value})`],
                    ]
                }
            },
            {
                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/cost_of_congestion_3.svg',
                title: translate.value('Annual cost', 'Costo anual'),
                table: {
                    rows: [
                        ['2017', '$3.4B'],
                        ['2018', '$3.6B'],
                        ['2019', '$3.7B'],
                        ['2020', '$1.5B'],
                    ]
                }
            },
            {
                icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/cost_of_congestion_3.svg',
                title: translate.value('Annual cost per capita', 'Costo anual per cápita'),
                table: {
                    rows: [
                        ['2017', '$1,149'],
                        ['2018', '$1,169'],
                        ['2019', '$1,179'],
                        ['2020', '$489'],
                    ]
                }
            },
        ]);

        return {
            title,
            infoBoxes,
        };
    }
});
</script>

<style scoped lang="scss">
:deep(.box__header) {
    align-items: flex-end;
}

a.definition {
    @apply underline underline-offset-1 decoration-purple-900/50 decoration-dotted;
}

a.definition.bolded {
    @apply font-bold text-2xl sm:text-4xl
}

div.definition {
    @apply text-base text-gray-700 border-t border-t-gray-400 h-full mt-4 pt-4 opacity-100 transition-all duration-1000
}

div.definition-hidden > div.definition {
    @apply p-0 m-0 h-0 opacity-0 transition-all duration-1000
}
</style>
