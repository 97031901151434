<template>
    <div class="border-b-8">
        <SectionTitle link="regional-employment" class="mb-12"
            :title="$tx('Regional Job Growth')" color="orange"
            :subtitle="`${$tx('Maricopa County')}, 2009-2019`"/>
        <div class="p-6 md:p-12">
            <ChartTableSwitch page-title="Regional Job Growth">
                <template v-slot:chart>
                    <ChartRenderer :chart="BarChart" chartId="regional-employment-chart" :chartData="chartData" :options="options" class="h-[350px] md:h-450px] lg:h-[500px]" label="Regional job growth Chart/Graph. Refer to data table for details."/>
                </template>

                <template v-slot:table>
                    <DataTable v-bind="dataTable"/>
                </template>
            </ChartTableSwitch>
        </div>
        <div class="m-auto mt-4 max-w-xl text-center mb-12">
            <InfoBox halign="left" color="qol2-full" class="mb-8 mt-20" icon="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/business-couple.svg">
                <template #content>
                    <p>
                        {{ $tx('From 2009 to 2019, there was a 28% increase in jobs in the region.') }}
                    </p>
                </template>
            </InfoBox>
        </div>
    </div>
</template>

<script>
// See link for registering chart types to allow for tree-shaking if desired
// https://vue-chart-3.netlify.app/guide/#chart-js-v3
import {computed, defineComponent, inject} from 'vue';
import {Chart, registerables} from "chart.js";

import {BarChart} from 'vue-chart-3';
import ChartRenderer from "../../../components/ChartRenderer";
import ChartTableSwitch from "../Components/ChartTableSwitch";
import DataTable from "../Components/DataTable";
import InfoBox from "../Components/InfoBox";

import SectionTitle from '../Components/SectionTitle';

import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables);
Chart.register(ChartDataLabels);

import { useIsSmallScreen } from "../../../utils/helpers";

import numeral from 'numeral';
import {generateDataLabelsSettings, generateTickSettings, generateTitleSettings} from "./chartsUtils";

export default defineComponent({
    components: {
        ChartRenderer,
        DataTable,
        ChartTableSwitch,
        SectionTitle,
        BarChart,
        InfoBox,
    },
    setup() {
        const tx = inject('tx')
        const isSmallScreen = useIsSmallScreen()

        const chartData = {
            labels: ['2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019'],
            datasets: [
                {
                    backgroundColor: 'rgb(247, 141, 30)',
                    borderColor: 'rgb(132, 132, 132)',
                    data: [1630352, 1614161, 1661311, 1675369, 1742447, 1781837, 1854998, 1912313, 1956165, 2026092, 2088229],
                }
            ],
        };

        const options = computed(() => ({
            hover: { mode: null },
            plugins: {
                tooltip: { enabled: isSmallScreen.value },
                legend: { display: false },
                title: { display: false },
                datalabels: generateDataLabelsSettings({
                    anchor: 'end',
                    align: 'end',
                    labels: {
                        value: {
                            formatter: (value) => {
                                return value.toLocaleString?.('en-US');
                            }
                        }
                    }
                }),
            },
            responsive: true,
            scales: {
                x: {
                    title: generateTitleSettings({
                        text: tx('Years'),
                    }),
                    ticks: generateTickSettings(),
                    stacked: true,
                },
                y: {
                    title: generateTitleSettings({
                        text: tx('Employment'),
                    }),
                    stacked: true,
                    min: 1500000,
                    max: 2200000,
                    ticks: generateTickSettings({
                        callback: (value, index, values) => {
                            return numeral(value).format('0.0a').toUpperCase()
                        }
                    }),
                }
            }
        }));

        const dataTable = computed(() => {
            const headings = {
                year: tx('Year'),
                value: tx('Employment'),
            };

            const rows = chartData.labels.map((year, idx) => ({
                year,
                value: Number(chartData.datasets[0].data[idx]).toLocaleString(),
            }));

            return { headings, rows };
        });

        return {BarChart, chartData, options, dataTable};
    }
});
</script>
