<template>
<div class="relative w-full h-full max-w-screen-xl mx-auto bg-primary xl:rounded-xl xl:mb-10">
    <AppHead :title="event.title">
        <meta head-key="description" name="description" :content="event.title" />
    </AppHead>

    <div class="flex justify-end w-full px-2 pt-4 mx-auto mb-2 max-w-7xl md:px-8">
        <ContentLocaleSelector :options="localizations" :locale="locale"/>
    </div>

    <EditButton :url="edit_url" />

    <div class="relative w-full p-2 mx-auto max-w-7xl md:p-8">
        <!-- Thumbnails -->
        <nav aria-label="breadcrumb" role="navigation" v-if="breadcrumbs.length > 0" class="flex items-center mb-6 space-x-2 text-sm font-light uppercase breadcrumbs">
            <template v-for="(bread, index) in breadcrumbs">
                <span class="text-primary-gray">/</span>
                <Link :aria-current="(index >= breadcrumbs.length - 1) ? 'page' : null" :href="bread.permalink" class="breadcrumb-link text-primary-gray hover:underline" v-bind="bread.options">{{ bread.title }}</Link>
            </template>
        </nav>

        <!-- Page title -->
        <div class="relative w-full mx-auto rounded-lg max-w-7xl">
            <!-- Title -->
            <h1 v-if="event.title" class="block pb-4 text-4xl font-extrabold leading-relaxed tracking-tight text-left text-white border-b border-gray-200 main-title tk-museo sm:text-5xl">
                {{ event.title }}
            </h1>
        </div>

        <div class="p-3 mt-4 bg-white card rounded-xl">
            <!-- Picture / Date / Location -->
            <div class="flex flex-col mt-6 rounded-lg md:flex-row md:space-x-6">
                <div class="w-full mb-4 bg-gray-100 md:w-4/6 h-96 md:mb-0">
                    <img v-if="event.main_image" :src="event.main_image.url" :alt="event.main_image.alt || ''" class="object-cover w-full h-full" role="img">
                </div>
                <div class="w-full md:w-2/6">
                    <template v-if="event.dates.sameDay">
                        <div class="flex items-start">
                            <Icon :icon="mdiCalendar" class="w-6 h-6 mr-4 text-gray-400" aria-label="Calendar icon"/>
                            <time class="text-base font-normal tracking-wide text-gray-800 tk-museo" :datetime="event.dates.start.iso">
                                {{ event.dates.start.date }}
                            </time>
                        </div>
                        <div v-if="event.dates.start.time" class="flex items-start mt-4">
                            <Icon :icon="mdiClock" class="w-6 h-6 mr-4 text-gray-400" aria-label="Arrow clock icon"/>
                            <span class="text-base font-normal tracking-wide text-gray-800 tk-museo">
                                {{ event.dates.start.time }}
                                <span v-if="event.dates.end.time && event.dates.start.date === event.dates.end.date && event.dates.start.time !== event.dates.end.time">
                                    - {{ event.dates.end.time }}
                                </span>
                            </span>
                        </div>
                    </template>
                    <template v-else>
                        <div class="flex items-start">
                            <Icon :icon="mdiCalendarStart" class="w-6 h-6 mt-1 mr-4 text-gray-400" aria-label="Calendar icon"/>
                            <time class="text-base font-normal tracking-wide text-gray-800 tk-museo" :datetime="event.dates.start.iso">
                                <span>{{ event.dates.start.date }}</span><span v-if="event.dates.start.time"><br/>{{ event.dates.start.time }}</span>
                            </time>
                        </div>
                        <div class="flex items-start mt-2">
                            <Icon :icon="mdiCalendarEnd" class="w-6 h-6 mt-1 mr-4 text-gray-400" aria-label="Calendar icon"/>
                            <time class="text-base font-normal tracking-wide text-gray-800 tk-museo" :datetime="event.dates.end.iso">
                                <span>{{ event.dates.end.date }}</span><span v-if="event.dates.end.time"><br/>{{ event.dates.end.time }}</span>
                            </time>
                        </div>
                    </template>


                    <div v-if="event.location_description" class="flex items-start mt-4">
                        <Icon :icon="mdiMapMarker" class="w-6 h-6 mr-4 text-gray-400" aria-label="Map marker icon"/>
                        <span class="text-base font-normal tracking-wide text-gray-800 tk-museo">Location</span>
                    </div>
                    <div class="mt-2 ml-3 space-y-4">
                        <SetsRenderer :sets="event.locations" class="text-sm text-gray-600 border-l border-gray-200 border-dashed pl-7" />
                    </div>
                <div class="pl-10 mt-6">
                    <PopoverGroup as="div">
                        <Popover v-slot="{ open, close }">
                            <div class="relative inline">
                                <PopoverButton class="relative inline-flex items-center justify-center p-2 text-base font-medium bg-white border text-secondary group hover:text-secondary hover:border-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary" :class="{'border-secondary': open, 'border-secondary-100': !open}">
                                    <Icon class="w-5 h-5 mr-4" :icon="mdiCalendar" aria-label="Calendar icon"/>
                                    <span>Add to Calendar</span>
                                </PopoverButton>
                                <PopoverPanel v-show="open" static class="absolute w-full z-10 left-0 top-[25px] mt-0 border border-secondary">
                                    <div class="relative w-full p-3 space-y-2 overflow-hidden bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                        <div>
                                            <a :href="event.links.iCalendar" target="_blank" class="text-secondary hover:text-secondary-900">iCalendar</a>
                                        </div>
                                        <div>
                                            <a :href="event.links.googleCalendar" target="_blank" class="text-secondary hover:text-secondary-900">Google Calendar</a>
                                        </div>
                                        <div>
                                            <a :href="event.links.outlook" target="_blank" class="text-secondary hover:text-secondary-900">Outlook</a>
                                        </div>
                                        <div>
                                            <a :href="event.links.yahooCalendar" target="_blank" class="text-secondary hover:text-secondary-900">Yahoo! Calendar</a>
                                        </div>
                                    </div>
                                </PopoverPanel>
                            </div>
                        </Popover>
                    </PopoverGroup>
                </div>
            </div>
            </div>

            <!-- Content / Resources -->
            <div class="relative w-full mt-6 lg:grid lg:grid-cols-6 lg:gap-5">
                <div class="lg:col-span-4">
                    <!-- Description -->
                    <div class="flex items-center mt-8 mb-4">
                        <Icon :icon="mdiText" class="w-6 h-6 mr-4 text-gray-400" aria-label="Description icon"/>
                        <span class="text-lg font-normal tracking-wide text-gray-800 tk-museo">Description</span>
                    </div>

                    <div class="relative w-full p-6 mx-auto bg-white rounded-lg max-w-7xl lg:p-4">
                        <SetsRenderer :sets="event.description" />
                    </div>
                </div>

                <div class="lg:col-span-2">
                    <!-- Contact -->
                    <div v-if="event.contact.contact">
                        <div class="flex items-center mt-8 mb-4">
                            <Icon :icon="mdiHuman" class="w-6 h-6 mr-4 text-gray-400" aria-label="Contact icon"/>
                            <span class="text-lg font-normal tracking-wide text-gray-800 tk-museo">Contact</span>
                        </div>

                        <div class="relative w-full p-6 mx-auto bg-white rounded-lg max-w-7xl lg:p-4">
                            <ResourceContacts :contacts="[event.contact]" />
                        </div>
                    </div>

                    <!-- Resources -->
                    <div v-if="event.resources.length > 0">
                        <div class="flex items-center mt-8 mb-4">
                            <Icon :icon="mdiDownload" class="w-6 h-6 mr-4 text-gray-400" aria-label="Download icon"/>
                            <span class="text-lg font-normal tracking-wide text-gray-800 tk-museo">Info & Resources</span>
                        </div>

                        <div class="relative w-full mx-auto bg-white rounded-lg max-w-7xl">
                            <div class="divider-y divider-y-gray-100">
                                <SetsRenderer :sets="event.resources" class="p-6 lg:p-4" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {Popover, PopoverButton, PopoverGroup, PopoverPanel} from "@headlessui/vue";
import {Link} from '@inertiajs/vue3';
import {
    mdiCalendar,
    mdiCalendarEnd,
    mdiCalendarStart,
    mdiClock,
    mdiDownload,
    mdiHuman,
    mdiMapMarker,
    mdiText
} from "@mdi/js";
import {defineComponent} from 'vue';
import AppHead from "../components/AppHead";
import ContentLocaleSelector from "../components/ContentLocaleSelector";
import EditButton from "../components/EditButton";
import Icon from "../components/Icon";
import LightButton from "../components/LightButton";
import SetsRenderer from "../components/SetsRenderer";
import ResourceContacts from "../sets/ResourceContacts";
import {hydrateEventEntry} from "../utils/eventEntries";

export default defineComponent({
    name: 'EventDatesEntry',
    components: {
        EditButton,
        ResourceContacts,
        Link,
        Icon,
        AppHead,
        Popover,
        LightButton,
        SetsRenderer,
        PopoverGroup,
        PopoverPanel,
        PopoverButton,
        ContentLocaleSelector,
    },
    props: {
        locale: { type: String, required: true },
        breadcrumbs: {type: Array, default: () => []},
        localizations: { type: Array, required: true },
        edit_url: { type: [String, null], default: null },
    },

    setup(props, { attrs }) {
        const event = hydrateEventEntry(attrs);

        return {
            event,
            mdiText,
            mdiClock,
            mdiHuman,
            mdiDownload,
            mdiCalendar,
            mdiMapMarker,
            mdiCalendarEnd,
            mdiCalendarStart,
        }
    },
});
</script>
