<template>
    <div class="p-6 h-full">
        <div v-if="loading" class="flex items-center justify-center h-full">
            <Spinner class="text-primary w-8 h-8"/>
        </div>
        <div v-else class="space-y-4">
            <div v-for="stop in stopsInfo" :key="stop.code" class="px-4 relative">
                <div class="flex justify-between space-x-4">
                    <div>
                        <div class="text-xl text-black font-bold">
                            <h2>{{ stop.name }}</h2>
                        </div>
                        <div class="mt-2 text-base text-gray-700 font-normal">
                            Stop #{{ stop.code }}
                        </div>
                    </div>
                    <div v-if="stop.streetview" class="w-[5rem]">
                        <img class="w-full rounded" :src="stop.streetview" alt="" aria-role="none" />
                    </div>
                </div>
                <template v-for="trip in stop.trips">
                    <div class="mt-8 pb-2 border-b border-gray-500">
                        <h3 class="font-bold text-base text-gray-700">
                            {{ trip.title }}
                    </h3>
                    </div>
                    <div class="my-2">
                        <template v-for="time in trip.times">
                            <div class="flex justify-between space-x-4 py-2" :class="{ 'grayscale opacity-50 line-through': !time.available }">
                                <Tag :label="route.shortName"/>
                                <div class="text-sm flex-1">{{ time.headsign }}</div>
                                <div class="text-right text-sm font-bold w-16">{{ time.value }}</div>
                            </div>
                        </template>
                    </div>
                    <div class="text-right text-sm text-gray-500 font-normal mt-1">
                        Last updated at {{ trip.updatedAt }}
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import {usePage} from '@inertiajs/vue3';
import {mdiChevronLeft} from "@mdi/js";
import {defineComponent, inject, ref, watch} from 'vue';
import {betweenDates, formatAmPmTime, parseFromISO} from "../../utils/date";
import Icon from "../Icon";
import Spinner from "../Spinner";
import Tag from "../Tag";
import {useStore} from "./store";
import { get } from "lodash";

export default defineComponent({
    name: 'MapsSchedulesStopInfo',
    components: {Spinner, Tag, Icon},
    props: {
        route: {type: Object, required: true},
        stops: {type: Array, required: true},
    },

    setup(props) {
        // @todo Refactor so we don't need to use usePage() all over the place.
        const featureFlags = usePage().props.global.feature_flags;

        const api = inject('api');
        const store = useStore();
        const stopsInfo = ref([]);
        const loading = ref(false);
        const googleApiKey = usePage().props.global.google.maps_api_key;

        /**
         * Get information about stops.
         */
        async function getStopsInfo() {
            loading.value = true;
            stopsInfo.value = [];

            const arrivalTimesForMultipleDirections = get(featureFlags, 'route_map_enable_arrival_times_for_multiple_directions');

            // We are iterating over stops because Route Map already provides
            // an array of selected stops, but for now only one is actually selected.
            for (const stop of props.stops) {
                const stopInfoResponse = await api.getStopInfo({
                    stopCode: stop.code,
                });

                if (! arrivalTimesForMultipleDirections) {
                    stopInfoResponse.data = stopInfoResponse.data.splice(0, 1);
                }

                const multiDirectionStopInfo = {};

                for (const stopInfoData of stopInfoResponse.data) {
                    const realtimeInfoResponse = await api.getStopRealtimeInfo({
                        stopId: stopInfoData.id,
                        routeName: props.route.id,
                    });

                    const updatedAt = formatAmPmTime(realtimeInfoResponse.summary.generated_on_server_iso8601);
                    const streetview = (stopInfoData?.extra?.ext_photo?.trim()?.length > 0)
                        ? stopInfoData?.extra.ext_photo
                        : `https://maps.googleapis.com/maps/api/streetview?size=160x120&location=${stopInfoData?.latitude},${stopInfoData?.longitude}&key=${googleApiKey}&source=outdoor`;

                    const directions = (() => {
                        let dirs = stopInfoData.routes.find(
                            (r) => r.routeId.toLowerCase() === props.route.id.toLowerCase()
                        )?.directionIds || [];

                        return (arrivalTimesForMultipleDirections)
                            ? dirs
                            : dirs.slice(0, 1);
                    })();

                    directions.forEach((directionId) => {
                        const directionName = get(props.route, `direction${directionId}Name`);

                        if (! directionName) {
                            console.error(`No direction found for direction "${directionId}" at stop "${stop.code}"`);
                            return;
                        }

                        const alerts = (store.state.alerts?.data || []).filter((alert) => {
                            if (! alert.routes.find(r => r.id.toLowerCase() === props.route.id.toLowerCase())) {
                                return false;
                            }

                            return alert.effect === 'NO_SERVICE' && Boolean(
                                (alert.stops || []).find((alertStop) => alertStop?.id?.toString() === stop?.id?.toString())
                            );
                        });

                        const multiDirectionStopInfoKey = `${stop.id}/${directionId}`;

                        if (! multiDirectionStopInfo.hasOwnProperty(multiDirectionStopInfoKey)) {
                            multiDirectionStopInfo[multiDirectionStopInfoKey] = {
                                alerts,
                                updatedAt,
                                streetview,
                                directionId,
                                title: arrivalTimesForMultipleDirections ? directionName : "Next Ride",
                                times: [],
                            };
                        }

                        // Prepare the trip times
                        const times = realtimeInfoResponse?.data
                            ? Object.values(
                                realtimeInfoResponse.data.reduce((filtered, time) => {
                                    const key = `${time.departureTimeFormatted}-${time.headsign}`;

                                    if (!filtered[key]) {
                                        filtered[key] = {
                                            headsign: time.headsign,
                                            date: time.departureTime,
                                            value: time.departureTimeFormatted,
                                            unixtime: parseFromISO(time.departureTime),
                                            available: alerts.findIndex(a => {
                                                if (alert.effect !== 'NO_SERVICE') {
                                                    return false;
                                                }

                                                const depTime = parseFromISO(time.departureTime);

                                                return alert.activeRanges.some(range => {
                                                    const activeFrom = (range.from) ? parseFromISO(range.from, 'UTC') : null;
                                                    const activeTo = (range.to) ? parseFromISO(range.to, 'UTC') : null;

                                                    return betweenDates(depTime, activeFrom, activeTo);
                                                });
                                            }) === -1,
                                        };
                                    }

                                    return filtered;
                                }, {})
                            )
                            : [];

                        multiDirectionStopInfo[multiDirectionStopInfoKey].times.push(...times);
                    });
                }

                // Sort times of multi direction stop info
                const sortedTrips = Object.values(multiDirectionStopInfo)
                    .sort((a, b) => a.directionId - b.directionId)
                    .map((item) => ({
                        ...item,
                        times: item.times.sort((a, b) => a.unixtime - b.unixtime)
                    }));

                stopsInfo.value.push({
                    code: stop.code,
                    name: stop.name,
                    trips: sortedTrips,
                });
            }

            loading.value = false;
        }

        watch(() => props.stops, () => getStopsInfo());
        getStopsInfo();

        return {
            store,
            loading,
            stopsInfo,
            mdiChevronLeft,
        }
    }
});
</script>
