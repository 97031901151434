<template>
    <div class="flex gap-2" v-if="options.length > 1">
        <Link v-for="option in options" :href="option.permalink" class="px-3 py-1 text-black rounded" :class="{
            'bg-primary-gray': option.key !== locale,
            'bg-green': option.key == locale
        }">
            {{ option.label }}
        </Link>
    </div>
</template>

<script>
import {Link} from '@inertiajs/vue3';

export default {
    name: 'ContentLocaleSelector',
    components: {Link},
    props: {
        options: {
            required: true,
            type: Array,
        },
        locale: {
            required: true,
            type: String
        }
    }
}
</script>
