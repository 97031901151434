<template>
    <div class="border-b-8">
        <SectionTitle link="ridership-trends" color="purple" :title="content.title" :subtitle="content.subtitle" class="mb-8"/>

        <div class="flex flex-col justify-center">

            <div class="flex flex-col p-4 sm:p-12">
                <div class="relative w-fit m-auto">
                    <Image :src="content.rightImage" :alt="content.rightAlt"/>

                    <div class="left-image-container absolute top-0 left-0 bottom-0 overflow-hidden"
                         :style="{ width: 'calc(' + sliderPosition + ' - 4px)' }">
                        <Image class="w-auto h-full max-w-none" :src="content.leftImage" :alt="content.leftAlt"/>
                    </div>

                    <label class="sr-only" for="image-comparison-slider-ridership-trends">Image Comparison
                        Slider</label>
                    <input id="image-comparison-slider-ridership-trends" type="range" min="1" max="100"
                           title="Image Comparison Slider"
                           class="slider border-2 border-gray-500 absolute top-0 left-0 bottom-0 right-0 overflow-hidden"
                           @input="updateValue">
                    <div class="slider-button bg-white border-2 border-gray-500"
                         :style="{ left: 'calc(' + sliderPosition + ' - 6px)', transform: 'translate(-50%)' }"></div>

                </div>

                <p class="text-center p-2">
                    {{ content.sliderLegend }}
                </p>

                <div class="flex flex-col w-full p-4 sm:p-12 m-auto max-w-md">
                    <CovidDisclaimer/>
                </div>

            </div>
        </div>


    </div>
</template>

<script>
import {computed, defineComponent, ref} from 'vue';
import CovidDisclaimer from '../Components/CovidDisclaimer';
import Image from "../Components/Image";

import SectionTitle from '../Components/SectionTitle';
import useTranslations from "../translations";

export default defineComponent({
    components: {
        Image,
        SectionTitle,
        CovidDisclaimer,
    },
    setup() {
        const {translate} = useTranslations();

        const sliderPosition = ref('50%');

        const updateValue = (to) => {
            sliderPosition.value = to.target.value + '%';
        }

        const content = computed(() => ({
            leftImage: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/data-graphs/stop-ridership-2019-map.png',
            leftAlt: translate.value('Heat maps for COVID Ridership Trend Comparison between 2019 and 2021', 'Mapas de calor de la comparación de tendencias de pasajeros durante el COVID entre 2019 y 2021'),
            rightImage: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/data-graphs/stop-ridership-2021-map.png',
            rightAlt: ' ',
            title: translate.value('COVID Ridership Trend Comparison', 'Comparación de Tendencias de Pasajeros Durante el COVID'),
            subtitle: translate.value('Ridership Concentration', 'Concentración de Pasajeros'),
            sliderLegend: translate.value('Move slider side-to-side to compare ridership trends between 2019 and 2021.', 'Mueva el control deslizante de lado a lado para comparar las tendencias de loa pasajeros entre 2019 y 2021.'),
        }));

        return {
            content,
            sliderPosition,
            updateValue
        }
    }
});
</script>

<style scoped lang="scss">

@mixin center() {
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    outline: none;
    margin: 0;
    @include center;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 4px;
        height: 10000px;
        background: #5D5D5D;
        cursor: pointer;
    }

    &::-moz-range-thumb {
        width: 4px;
        height: 10000px;
        background: #5D5D5D;
        cursor: pointer;
    }
}

.slider-button {
    $size: 30px;
    pointer-events: none;
    position: absolute;
    width: $size;
    height: $size;
    border-radius: 50%;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
    @include center;

    @mixin arrow-helper() {
        content: '';
        padding: 3px;
        display: inline-block;
        border: solid #5D5D5D;
        border-width: 0 2px 2px 0;
    }

    &:after {
        @include arrow-helper();
        transform: rotate(-45deg);
    }

    &:before {
        @include arrow-helper();
        transform: rotate(135deg);
    }
}
</style>
