<template>
    <div class="border-b-8">
        <SectionTitle link="walkable-access-in-rail-corridor"
                      :title="content.title"
                      :subtitle="content.subtitle"
                      color="green"
                      imageSize="large"
                      image="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/QOL_information-shape_orange_improvements-infrastructures.svg"/>
        <div class="flex flex-col w-full py-12 px-4">
            <div class="w-full max-w-lg mx-auto">
                <DataTable highlight-first :headings="content.headings" :rows="content.rows"/>
            </div>
            <p class="py-4 flex flex-1 justify-end">{{ content.footer }}</p>
        </div>
    </div>
</template>

<script>

import {computed, defineComponent} from 'vue';
import {useIsSmallScreen} from "../../../utils/helpers";
import DataTable from "../Components/DataTable";

import SectionTitle from '../Components/SectionTitle';
import useTranslations from "../translations";

export default defineComponent({
    components: {
        DataTable,
        SectionTitle,
    },
    setup() {
        const { translate } = useTranslations();
        const isSmallScreen = useIsSmallScreen();

        const content = computed(() => ({
            title: translate.value('Creating More Walkable Communities', 'Creando Comunidades Más Transitables a Pie'),
            subtitle: translate.value('Walkability of Light Rail Stations', 'Acceso a Pie a las Estaciones del Tren Ligero'),
            headings: {
                description: translate.value('Description', 'Descripción'),
                stationCount: {
                    label: translate.value('Number of Stations', 'Número de Estaciones'),
                    classes: 'w-[80px]',
                },
                stationPercentage: {
                    label: translate.value('Percent of Stations', 'Porcentaje de Estaciones'),
                    classes: 'w-[80px]',
                },
            },
            rows: [
                {description: isSmallScreen.value ? translate.value('Walker\'s<br/>Paradise', 'Paraíso<br/>Peatonal') : translate.value('Walker\'s Paradise', 'Paraíso Peatonal'), stationCount: '3', stationPercentage: '7%'},
                {description: isSmallScreen.value ? translate.value('Very<br/>Walkable', 'Muy Transitable<br/>a Pie') : translate.value('Very Walkable', 'Muy Transitable a Pie'), stationCount: '23', stationPercentage: '54%'},
                {description: isSmallScreen.value ? translate.value('Somewhat<br/>Walkable', 'Algo Transitable<br/>a Pie') : translate.value('Somewhat Walkable', 'Algo Transitable a Pie'), stationCount: '7', stationPercentage: '16%'},
                {description: isSmallScreen.value ? translate.value('Car<br/>Dependent', 'Dependiente del Automóvil') : translate.value('Car-Dependent', 'Dependiente del Automóvil'), stationCount: '10', stationPercentage: '23%'},
            ],
            footer: translate.value('Based on information from WalkScore.com', 'Basado en información de WalkScore.com')
        }))

        return {
            content,
        }
    }
});
</script>
