<template>
<div>
    <GoogleMap
        ref="mapRef"
        :api-key="googleApiKey"
        :center="center"
        :zoom="zoom"
        :libraries="['places', 'geometry']"
        style="height: 100%; width: 100%;"
    >
        <!-- User location marker -->

        <!-- Origin/Destination markers -->
        <Marker v-if="originMarker" :options="originMarker" @dragend="locationMarkerDragged('origin', $event)" />
        <Marker v-if="destMarker" :options="destMarker" @dragend="locationMarkerDragged('destination', $event)" />

        <!-- selected trip leg markers -->
        <template v-for="(marker, index) in tripMarkers">
            <Marker v-if="marker.marker" :options="marker.marker" />
            <Polyline v-if="marker.line" :options="marker.line" @click="highlightLeg(index)" />
        </template>
    </GoogleMap>
</div>
</template>

<script>
import {computed, defineComponent, nextTick, ref, watch} from "vue";
import {GoogleMap, Marker, Polyline} from "vue3-google-map";
import {useTripInput} from "./useTripInput";
import {ICONS, LEG_MODE_WALK} from '../../utils/icons';

function buildMarkersAndLines (mapRef, legs, selectedLeg, selectedLegIsLast) {
    const markers = [];
    const highlightColor = '#C44C1C';
    const noneHighlightColor = '#502059';

    legs.forEach((leg, index) => {
        const icon = (index === selectedLeg && selectedLegIsLast)
            ? Object.assign({}, ICONS.mapCircle, ICONS.mapCircleHighlighted)
            : ICONS.mapCircle;

        const mapUtilsIcon = (ICONS[`${leg.mode}MapUtils`]) ? ICONS[`${leg.mode}MapUtils`] : null;
        const iconMode = (mapUtilsIcon) ? mapUtilsIcon : false;

        markers.push({
            marker: {
                icon,
                iconMode,
                title: leg.start_name,
                position: {
                    lat: parseFloat(leg.start_location.lat),
                    lng: parseFloat(leg.start_location.lng),
                },
            },
            line: {
                path: mapRef.value.api.geometry.encoding.decodePath(leg.polyline.shape),
                geodesic: true,
                strokeColor: (index === selectedLeg)
                    ? highlightColor
                    : noneHighlightColor,
                strokeWeight: 4,
                strokeOpacity: leg.mode === LEG_MODE_WALK ? 0 : 1,
                fillOpacity: leg.mode === LEG_MODE_WALK ? 0 : 0.9,
                fillColor: leg.mode === LEG_MODE_WALK ? highlightColor : null,
                icons: leg.mode === LEG_MODE_WALK ? [{
                    icon: {
                        path: 'M 0,-1 0,1',
                        strokeOpacity: 1,
                        scale: index === selectedLeg ? 4 : 3,
                    },
                    offset: '0',
                    repeat: '20px',
                }] : [],
            }
        });

        if (index === legs.length - 1) {
            const icon = (index === selectedLeg && selectedLeg === legs.length - 1)
                ? Object.assign({}, ICONS.mapCircle, ICONS.mapCircleHighlighted)
                : ICONS.mapCircle;

            markers.push({
                marker: {
                    icon,
                    iconMode,
                    title: leg.finish_name,
                    position: {
                        lat: parseFloat(leg.finish_location.lat),
                        lng: parseFloat(leg.finish_location.lng),
                    },
                },
                line: null,
            });
        }
    });

    return markers;
}

export default defineComponent({
    name: "TripPlannerMap",
    components: {
        GoogleMap,
        Polyline,
        Marker,
    },

    props: {
        googleApiKey: {
            type: String,
            required: true,
        },
        center: {
            type: Object,
            default() {
                return {
                    lat: 33.451284,
                    lng: -112.07454,
                }
            },
            validator(value) {
                return value.hasOwnProperty('lat') && value.hasOwnProperty('lng');
            }
        },
        zoom: {
            type: Number,
            default: 10,
        },
        route: {
            type: [Object, null],
            required: false,
            default: null,
        },
        highlightedLeg: {
            type: Number,
            required: false,
            default: -1,
        },
    },

    setup(props, { emit }) {
        const mapRef = ref(null);

        const {locationFrom, locationTo, isMapVisibleOnSmallScreens} = useTripInput();

        // Generate the markers.
        const originMarker = computed(() => {
            if (! locationFrom.value.location) {
                return null;
            }

            return {
                id: 'origin',
                title: 'From',
                icon: ICONS.mapCircle,
                clickable: true,
                draggable: true,
                position: {
                    lat: parseFloat(locationFrom.value.location.lat),
                    lng: parseFloat(locationFrom.value.location.lng),
                },
                label: {
                    text: 'From',
                    color: '#fff',
                    background: '#000',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    className: 'trip-planner__origin-dest-marker'
                },
            };
        });

        const destMarker = computed(() => {
            if (! locationTo.value.location) {
                return null;
            }

            return {
                id: 'destination',
                title: 'To',
                icon: ICONS.mapCircle,
                clickable: true,
                draggable: true,
                position: {
                    lat: parseFloat(locationTo.value.location.lat),
                    lng: parseFloat(locationTo.value.location.lng),
                },
                label: {
                    text: 'To',
                    color: '#fff',
                    background: '#000',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    className: 'trip-planner__origin-dest-marker'
                },
            };
        });

        // Build trip markers and lines.
        const tripMarkers = ref([]);

        function updateMarkersAndLines() {
            tripMarkers.value = buildMarkersAndLines(
                mapRef,
                props.route?.legs || [],
                props.highlightedLeg,
                props.highlightedLeg === (props.route?.legs || []).length - 1
            );

            if (mapRef.value?.ready && props.route) {
                const highlightedLegRoute = (props.highlightedLeg > -1)
                    ? props.route.legs[props.highlightedLeg]
                    : null;

                if (! highlightedLegRoute) {
                    mapRef.value.map.fitBounds(
                        new mapRef.value.api.LatLngBounds(
                            props.route.summary.bounds.southwest,
                            props.route.summary.bounds.northeast
                        )
                    );
                    return;
                }

                const lats = [highlightedLegRoute.start_location.lat, highlightedLegRoute.finish_location.lat];
                const lngs = [highlightedLegRoute.start_location.lng, highlightedLegRoute.finish_location.lng];

                lats.sort((a, b) => {
                    if (a < b) return -1;
                    if (a > b) return 1;
                    return 0;
                });

                lngs.sort((a, b) => {
                    if (a < b) return -1;
                    if (a > b) return 1;
                    return 0;
                });

                mapRef.value.map.fitBounds(
                    new mapRef.value.api.LatLngBounds(
                        { lat: lats[0], lng: lngs[0] },
                        { lat: lats[1], lng: lngs[1] }
                    )
                );
            }
        }

        // Fit the map into the selected route bounds.
        watch(() => props.route, () => updateMarkersAndLines());
        watch(() => props.highlightedLeg, () => updateMarkersAndLines());

        // When map becomes visible we update markers and lines, otherwise the map is not zoomed properly.
        watch(isMapVisibleOnSmallScreens, () => nextTick(() => updateMarkersAndLines()));

        // Emit an event when the Google maps library is ready.
        watch(() => mapRef.value?.ready, () => {
            if (mapRef.value.ready) {
                emit('ready', mapRef);
            }
        });

        function locationMarkerDragged(locationType, event) {
            const newLocation = {
                value: `${event.latLng.lat()},${event.latLng.lng()}`,
                location: {
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng(),
                }
            };

            emit('location-changed', {
                from: locationType === 'origin' ? newLocation : locationFrom.value,
                to: locationType === 'destination' ? newLocation : locationTo.value,
            });
        }

        function highlightLeg(index) {
            emit('highlight-leg', (props.highlightedLeg !== index) ? { index } : { index: null });
        }

        return {
            mapRef,
            originMarker,
            destMarker,
            tripMarkers,
            highlightLeg,
            locationMarkerDragged,
        }
    }
})
</script>

<style>
.trip-planner__origin-dest-marker {
    @apply relative;
    @apply px-2 py-1;
    @apply bg-gray-800;
    width: 3rem;
    margin-left: -4.2rem;
}

.trip-planner__origin-dest-marker::after {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: 100%;
    z-index: 100;
    border-style: solid;
    border-color: transparent theme('colors.gray.800');
    border-width: 12px 0 12px 12px;
    width: 0;
    height: 0;
}
</style>
