<template>
    <div class="statements pb-10 -mt-20 px-14 md:px-16 text-center text-xl leading-8 relative">
        <p class="mt-6" v-for="statement in statements" v-html="statement.content"></p>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'SectionStatements',
    props: {
        statements: {
            required: true,
            type: Array,
        }
    }
})
</script>

<style>
    .main-content-section .statements a {
        text-decoration: underline;
        font-weight: 500;
    }
</style>
