import {
    mdiAlert,
    mdiBike,
    mdiBus,
    mdiChevronRight,
    mdiChevronDown,
    mdiClose,
    mdiDirections,
    mdiMagnify,
    mdiMapMarker,
    mdiPhone,
    mdiStar,
    mdiStarOutline,
    mdiSteering, mdiTicket,
    mdiTram,
    mdiTransitConnection,
    mdiWalk, mdiFacebook, mdiTwitter, mdiEmail, mdiTrain, mdiChevronLeft
} from "@mdi/js";

export const LEG_MODE_WALK = 'walk';
export const LEG_MODE_BUS = 'bus';
export const LEG_MODE_TRAM = 'tram';
export const LEG_MODE_TRANSIT = 'transit';
export const LEG_MODE_DRIVE = 'drive';
export const LEG_MODE_CYCLE = 'cycle';
export const LEG_MODE_RAIL = 'rail';

export const ICONS = {
    [LEG_MODE_RAIL]: mdiTrain,
    [LEG_MODE_WALK]: mdiWalk,
    [LEG_MODE_BUS]: mdiBus,
    [LEG_MODE_TRAM]: mdiTram,
    [LEG_MODE_TRANSIT]: mdiTransitConnection,
    [LEG_MODE_DRIVE]: mdiSteering,
    [LEG_MODE_CYCLE]: mdiBike,
    chevronLeft: mdiChevronLeft,
    chevronRight: mdiChevronRight,
    chevronDown: mdiChevronDown,
    alert: mdiAlert,
    iconStar: mdiStar,
    starOutline: mdiStarOutline,
    iconTicket: mdiTicket,
    close: mdiClose,
    directions: mdiDirections,
    magnify: mdiMagnify,
    mapMarker: mdiMapMarker,
    facebook: mdiFacebook,
    twitter: mdiTwitter,
    email: mdiEmail,
    phone: mdiPhone,
    mapCircle: {
        path: 'M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0',
        fillColor: '#fff',
        fillOpacity: 1,
        scale: 1,
        strokeWeight: 2,
        strokeColor: '#502059',
        text: 'AA',
    },
    mapCircleHighlighted: {
        fillColor: '#502059',
        fillOpacity: 1,
    }
};
