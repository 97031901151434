<template>
    <div class="relative w-full h-full bg-primary max-w-screen-xl mx-auto xl:rounded-xl xl:mb-10">
        <AppHead :title="title">
            <meta head-key="description" name="description" :content="description"/>
        </AppHead>

        <div class="relative w-full max-w-7xl mx-auto p-2 md:p-8">
            <!-- Thumbnails -->
            <nav aria-label="breadcrumb" role="navigation" class="flex items-center space-x-2 mb-6 text-sm uppercase font-light">
                <template v-for="bread in breadcrumbs">
                    <span class="text-primary-gray-400">/</span>
                    <Link :href="bread.permalink" class="text-primary-gray-400 p-1 hover:underline focus:underline focus:ring-white" v-bind="bread.options">
                        {{ bread.title }}
                    </Link>
                </template>
                <span class="text-primary-gray-400">/</span>
                <Link aria-current="page" href="/maps-schedules" class="text-primary-gray-400 p-1 hover:underline focus:underline focus:ring-white" preserve-state>Maps & Schedule</Link>
            </nav>

            <h1 class="text-3xl text-white sm:text-4xl">Maps & Schedules</h1>

            <div class="mt-10">
                <MapsSchedulesSearch id="map-schedule-app-search" light />

                <!-- render the component depending on routeName prop -->
                <div class="mt-8">
                    <component :is="component.comp" v-bind="component.props"/>
                </div>
            </div>
        </div>
        <div class="flex flex-col space-y-4 relative w-full max-w-7xl mx-auto pt-4 pb-6 px-2 md:space-x-4 md:space-y-0 md:flex-row md:px-8 md:pt-4 md:pb-12">
            <a href="/maps-schedules/system-map" class="link link--button justify-between p-4 w-full md:w-1/3">
                Download System Map PDF
                <Icon class="w-4 h-4 text-inherit ml-2" :icon="mdiChevronRight" aria-label="Download icon"/>
            </a>
            <a href="/maps-schedules/my-etransit-book" class="link link--button justify-between p-4 w-full md:w-1/3">
                Create an eTransit Book
                <Icon class="w-4 h-4 text-inherit ml-2" :icon="mdiChevronRight" aria-label="Download icon"/>
            </a>
            <a href="/maps-schedules/service-changes" class="link link--button justify-between p-4 w-full md:w-1/3">
                Service Changes
                <Icon class="w-4 h-4 text-inherit ml-2" :icon="mdiChevronRight" aria-label="Download icon"/>
            </a>
        </div>
    </div>
</template>

<style scoped>
.link--button:hover {
    @apply bg-white text-secondary ring-0;
}

.link--button:focus {
    @apply bg-white text-secondary ring-0 ring-offset-0 outline outline-offset-2 outline-white;
}
</style>

<script>
import {router} from "@inertiajs/vue3";
import {Link} from '@inertiajs/vue3';
import {mdiChevronRight} from "@mdi/js";
import {computed, defineComponent, onMounted, onUnmounted} from 'vue';
import AppHead from "../components/AppHead";
import Icon from "../components/Icon";
import MapsSchedulesList from "../components/MapsSchedules/MapsSchedulesList";
import MapsSchedulesRoute from "../components/MapsSchedules/MapsSchedulesRoute";
import MapsSchedulesSearch from "../components/MapsSchedules/MapsSchedulesSearch";
import {initStore} from "../components/MapsSchedules/store";
import ServiceAlert from "../components/ServiceAlert";
import PagesEntry from "./PagesEntry";

export default defineComponent({
    name: 'MapsSchedulesApp',
    components: {
        Icon,
        Link,
        AppHead,
        PagesEntry,
        ServiceAlert,
        MapsSchedulesList,
        MapsSchedulesSearch,
    },
    props: {
        breadcrumbs: {type: Array, default: () => []},
        routeName: {type: String, required: false},
        props: {type: [Object, null], required: false},
    },

    setup(props) {
        const store = initStore();

        const pageChangeListener = router.on('start', (event) => {
            // If it's not a specific route URL (e.g. /maps-schedules/1) neither a selected tab is provided, then we reset time travel and clear the route.
            if (event.detail.visit.url.pathname === '/maps-schedules' && event.detail.visit.url.searchParams.get('tab') === null) {
                store.setTimeOfTravelValue(-1);
                store.setCurrentRoute(null);
            }
        });

        const title = computed(() => {
            const currentRoute = store.state.routes?.current;

            if (currentRoute) {
                return `${currentRoute.longName} - ${currentRoute.shortName} - Maps & Schedules`;
            }

            return 'Maps & Schedules';
        })

        const description = computed(() => {
            return props.props?.routeId ? `Maps & Schedules - ${props.props?.routeId}` : 'Maps & Schedules';
        })

        const component = computed(() => {
            return (!props.routeName || props.routeName === 'routes-list')
                ? {
                    comp: MapsSchedulesList,
                    props: {},
                }
                : {
                    comp: MapsSchedulesRoute,
                    props: {
                        routeName: props.routeName,
                        routeId: props.props.routeId,
                        stop: props.props.stop,
                        location: props.props.location,
                    }
                };
        });

        onMounted(() => {
            const crumbs  = [];

            crumbs.forEach(crumb => {
                crumbs.push({
                    "@type": "ListItem",
                    "position": crumbs.length+1,
                    "name": crumb.title,
                    "item": crumb.permalink
                });
            });

            crumbs.push({
                "@type": "ListItem",
                "position": crumbs.length+1,
                "name": 'Maps & Schedules',
                "item": '/maps-schedules'
            });

            if(props.props?.routeId) {
                crumbs.push({
                    "@type": "ListItem",
                    "position": crumbs.length+1,
                    "name": `Maps & Schedules | ${props.props?.routeId}`,
                    "item": `/maps-schedules/${props.props?.routeId}`
                });
            }

            let Script = document.createElement("script");
            Script.setAttribute("type", "application/ld+json");
            Script.innerHTML = JSON.stringify(crumbs);
            document.head.appendChild(Script);
        });

        onUnmounted(() => pageChangeListener());

        return {
            component,
            mdiChevronRight,
            title,
            description,
        }
    },
});
</script>
