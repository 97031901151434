<template>
    <div class="flex justify-center">
        <button
            @click="move"
            type="button"
            class="z-[10000] bg-gray-600 text-white text-2xl font-bold py-3 px-4 rounded-xl shadow-xl hover:bg-gray-800 focus:bg-gray-800 focus:ring-2 focus:ring-offset-1 focus:ring-gray-800"
        >
            {{ text }}
        </button>
    </div>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'BackToTop',
    props: {
        text: {
            type: String,
            required: true
        }
    },
    setup() {

        function move() {
            window.parent.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }

        return {
            move,
        }
    },
});
</script>
