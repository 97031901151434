<template>
    <div class="relative w-full bg-white xl:overflow-hidden">
        <SectionTitle :title="title" link="mode-share-by-corridor" color="green" />

        <Section removeBorder>
            <template #graphic>
                <Image class="graphic" alt="" src="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/isolated/static-mode-share-by-corridor-v2.svg" />
            </template>
            <template #info>
                <div class="flex flex-wrap gap-8 justify-center mt-8">
                    <InfoBox width-class="w-[290px] md:w-[500px]" halign="left" color="qol4-full" class="mt-20">
                        <template #header>
                            <div class="flex justify-center -mt-12">
                                <Image src="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/rail.svg" class="h-[5rem]" />
                            </div>
                            <span>{{ infoBoxes[0].title }}</span>
                        </template>
                        <template #content>
                            <div v-html="infoBoxes[0].text" class="stat text-center"></div>
                        </template>
                    </InfoBox>
                    <InfoBox width-class="w-[290px] md:w-[500px]" halign="left" color="qol4-full" class="mt-20">
                        <template #header>
                            <div class="flex justify-center -mt-12">
                                <Image src="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/bus.svg" class="h-[5rem]" />
                            </div>
                            <span>{{ infoBoxes[1].title }}</span>
                        </template>
                        <template #content>
                            <div v-html="infoBoxes[1].text" class="stat text-center"></div>
                        </template>
                    </InfoBox>
                </div>

            </template>
        </Section>
    </div>
</template>

<script>
import {defineComponent, computed} from 'vue';
import Image from "../Components/Image";
import InfoBoxIcon from "../Components/InfoBoxIcon";
import Section from "../Components/Section";
import SectionTitle from "../Components/SectionTitle";
import InfoBox from "../Components/InfoBox";
import useTranslations from "../translations";

export default defineComponent({
    name: 'ModeShareByCorridor',
    components: {
        InfoBoxIcon,
        Image,
        Section,
        SectionTitle,
        InfoBox,
    },
    setup() {
        const { translations } = useTranslations();

        const title = computed(() => translations.value.page2.sections[6].title);
        const infoBoxes = computed(() => translations.value.page2.sections[6].infoBoxes);

        return {
            title,
            infoBoxes,
        };
    }
});
</script>

<style scoped lang="scss">
:deep(.box__header) {
    @apply text-xl h-auto text-center;
}

@media(min-width: theme('screens.md')) {
    :deep(.box__header) {
        @apply h-16;
    }
}

@media(max-width: theme('screens.md')) {
    .stat:deep( strong)::before {
        content: '\a';
        white-space: pre;

    }
}
</style>
