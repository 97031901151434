<template>
    <img :src="icon" class="h-24" alt="" role="img" />
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
    props: {
        icon: {
            type: String,
            required: true
        }
    }
})
</script>
