<template>
    <div class="blog-entry relative w-full h-full max-w-screen-xl mx-auto bg-primary p-3 md:p-10 xl:rounded-xl xl:mb-10">
        <AppHead :title="`${title} - Inside the Ride`">
            <meta head-key="description" name="description" :content="title" />
        </AppHead>

        <div class="w-full max-w-7xl mx-auto flex justify-end mb-2">
            <content-locale-selector :options="localizations" :locale="locale"/>
        </div>

        <EditButton :url="edit_url" />

        <div>
            <nav aria-label="breadcrumb" role="navigation" class="flex items-center space-x-2 mb-6 text-sm uppercase font-light">
                <template v-for="(bread, index) in breadcrumbs">
                    <span class="text-primary-gray">/</span>
                    <Link :aria-current="(index >= breadcrumbs.length - 1) ? 'page' : null" :href="bread.permalink" class="text-primary-gray hover:underline" v-bind="bread.options">{{ bread.title }}</Link>
                </template>
            </nav>
            <h1 class="tk-museo block leading-tight md:leading-snug text-5xl text-left font-extrabold tracking-tight text-white sm:text-5xl">
                {{ title }}
            </h1>
        </div>

        <div class="relative bg-white rounded-lg p-4 mt-10 sm:p-6 lg:p-8">
            <div class="mx-auto prose prose-lg">
                <div class="text-sm uppercase">
                    {{ formattedDate }}
                </div>
            </div>

            <div class="mx-auto prose prose-lg mt-4 text-gray-700">
                <SetsRenderer :sets="entry" />
            </div>
            <div v-if="author" class="mx-auto max-w-prose text-lg mt-16 pt-16 border-t border-primary-gray text-gray-700">
                <div class="flex flex-col items-center space-y-8 text-center lg:flex-row lg:items-start lg:space-y-0 lg:space-x-8 lg:text-left">
                    <div v-if="author.photo" class="flex-shrink-0">
                        <img class="h-24 w-24 rounded-full object-cover" :src="author.photo.permalink" :alt="author.photo.alt || ''" role="img" />
                    </div>
                    <div>
                        <div class="text-lg text-primary-gray-darker font-semibold tracking-wide">
                            {{ author.title }}
                        </div>
                        <div class="text-sm text-gray-400 font-normal tracking-wide uppercase">
                            {{ author.contact_title }}
                        </div>
                        <div class="mt-4 text-sm font-normal">
                            {{ author.introduction || author.full_bio }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent} from 'vue';
import {Link} from '@inertiajs/vue3';
import AppHead from "../components/AppHead";
import ContentLocaleSelector from "../components/ContentLocaleSelector";
import EditButton from "../components/EditButton";
import SetsRenderer from "../components/SetsRenderer";
import {formatFromISO} from "../utils/date";

export default defineComponent({
    name: 'BlogEntry',
    components: {EditButton, AppHead, SetsRenderer, Link, ContentLocaleSelector},

    props: {
        last_modified: { type: String, required: true },
        parent_entry: { type: Object, required: true },
        breadcrumbs: {type: Array, default: () => []},
        permalink: { type: String, required: true },
        author: { type: [Object, null], default: null },
        title: { type: String, required: true },
        entry: { type: Array, required: true },
        date: { type: String, required: true },
        locale: { type: String, required: true },
        localizations: { type: Array, required: true },
        edit_url: { type: [String, null], default: null },
    },

    setup(props) {
        const formattedDate = formatFromISO(props.date, 'MMMM d, yyyy');

        return {
            formattedDate
        };
    },
});
</script>
