<template>
    <div>
        <FieldLabel :field="field"/>

        <div class="flex flex-col items-center space-y-2 md:flex-row md:space-y-0 md:space-x-4">
            <div v-for="dateField in dateFields" :key="field.label" class="w-full md:w-32">
                <select
                    :id="`${field.id}_${dateField.name}`"
                    :name="`__${field.handle}-${dateField.name}`"
                    :required="field.isRequired"
                    class="w-full"
                    @change="e => setDate(dateField, e.target.value)"
                    :value="dateField.value"
                    :aria-label="`${field.label} - ${dateField.name}`"
                >
                    <option value>{{ dateField.name }}</option>
                    <option v-for="option in dateField.options" :value="option.value">{{ option.label }}</option>
                </select>
            </div>

            <!-- Make it invisible for screen readers because browsers' date inputs are not good on accessibility -->
            <div class="flex justify-end items-center" aria-hidden="true">
                <PrimaryButton type="button" class="w-10 h-10 mt-1" inverted @click="showCalendar" aria-label="Click to toggle the calendar widget">
                    <Icon :icon="mdiCalendar" class="w-6 h-6"/>
                </PrimaryButton>
                <input
                    ref="calendarInput"
                    type="date"
                    :name="field.handle"
                    :placeholder="field.placeholder"
                    :title="field.label || field.placeholder"
                    :value="date"
                    :required="field.isRequired"
                    class="field-custom w-0 h-0 opacity-0"
                    @input="e => onDatePicked(e.target.value)"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import {mdiCalendar} from "@mdi/js";
import {getDaysInMonth} from "date-fns";
import {ref} from "vue";
import {format} from "../../../utils/date";
import Icon from "../../Icon.vue";
import PrimaryButton from "../../PrimaryButton.vue";
import FieldLabel from "../FieldLabel";
import {defineField} from "./BaseField.js";

const field = defineField();
const currentYear = (new Date).getFullYear();

const calendarInput = ref();
const date = ref(field.old);

const dateFields = ref({
    month: {
        name: 'Month',
        options: Array.from({length: 12}, (_, m) => ({
            value: m + 1,
            label: new Date(null, m).toLocaleString('default', { month: 'long' }),
        })),
        value: null,
        setValue: setMonth,
    },
    day: {
        name: 'Day',
        options: [],
        value: null,
        setValue: setDay,
    },
    year: {
        name: 'Year',
        options: Array.from({ length: 100 }, (_, y) => ({
            value: currentYear - y,
            label: currentYear - y,
        })),
        value: (new Date).getFullYear(),
        setValue: setYear,
    },
});

function setMonth(value) {
    dateFields.value.month.value = parseInt(value);
    const month = dateFields.value.month.value - 1;

    const length = (dateFields.value.year.value && value)
        ? getDaysInMonth(new Date(dateFields.value.year.value, month))
        : 0;

    if (length > 0) {
        dateFields.value.day.options = Array.from({ length }, (_, d) => {
            const value = format(new Date(dateFields.value.year.value, month).setDate(d + 1), 'dd');

            return ({
                value,
                label: value,
            });
        });

        if (dateFields.value.day.value && parseInt(dateFields.value.day.value) > length) {
            dateFields.value.day.value = null;
        }
    } else {
        dateFields.value.day.options = [];
        dateFields.value.day.value = null;
    }
}

function setDay(value) {
    dateFields.value.day.value = value;
}

function setYear(value) {
    dateFields.value.year.value = value;

    // Update the days if February is selected because we have to take leap years into account.
    if (dateFields.value.month.value === '02') {
        setMonth(dateFields.value.month.value);
    }
}

function setDate(dateField, value) {
    dateField.setValue(value);

    const month = dateFields.value.month.value;
    const day = dateFields.value.day.value;
    const year = dateFields.value.year.value;

    if (month && day && year) {
        date.value = format(new Date(year, parseInt(month) - 1, parseInt(day)), 'yyyy-MM-dd');
    } else {
        date.value = null;
    }
}

function onDatePicked(value) {
    let month = null;
    let day = null;
    let year = null;

    if (value) {
        const parts = value.split('-');
        year = parts[0];
        month = parts[1];
        day = parts[2];
    }

    dateFields.value.month.setValue(month);
    dateFields.value.day.setValue(day);
    dateFields.value.year.setValue(year);
    date.value = value;
}

function showCalendar() {
    calendarInput.value.showPicker();
}
</script>
