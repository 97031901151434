<template>
    <template v-for="set in setsCollection">
        <component :is="set.component" v-bind="{ ...allProps, ...set.props }"></component>
    </template>
</template>

<script>
import {computed, defineAsyncComponent, defineComponent} from "vue";
import {startCase} from "lodash";

export default defineComponent({
    name: "SetsRenderer",
    props: {
        sets: {
            type: Array,
            required: true,
        },
        class: {
            type: String,
            default: '',
        },
        attributes: {
            type: [Object, null],
            default: null,
        },
    },
    setup(props, { attrs }) {
        const setsCollection = computed(() => {
            if (! props.sets) {
                return [];
            }

            return props.sets
                .filter(set => set.enabled !== false)
                .map(set => {
                    const compName = set.vue && set.vue.component
                        ? set.vue.component
                        : startCase(set.type).replaceAll(' ', '');

                    return {
                        component: defineAsyncComponent(() => import(`../sets/${compName}.vue`)),
                        props: set
                    };
                });
        });

        return {
            allProps: {
                class: props.class,
                ...props.attributes,
                ...attrs,
            },
            setsCollection
        };
    }
});
</script>
