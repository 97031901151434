<template>
    <button class="flex-shrink" :class="classes">
        <slot></slot>
    </button>
</template>

<script>
import {computed} from "vue";

export default {
    name: "LightButton",

    props: {
        textStyle: {
            type: String,
            default: 'text-gray-darker',
        },
        bgStyle: {
            type: String,
            default: 'bg-white hover:bg-secondary-100',
        },
        padding: {
            type: String,
            default: 'py-2 px-4',
        }
    },

    setup (props) {
        const classes = computed(
            () => `${props.padding} ${props.bgStyle} ${props.textStyle}`
        );

        return { classes };
    }
}
</script>
