<template>
    <div class="flex flex-col w-fit min-w-[90%] ml-0 mr-12 border-0 border-b-8 border-r rounded-br-3xl bg-gray-150"
         :class="colors">
        <div class="w-0 h-0" :id="linkUrl"></div>
        <div class="flex p-3">
            <a :href="goBackUrl" :aria-label="goBackAriaLabel" target="_top">
                &lt; {{ goBack }}
            </a>
        </div>
        <div class="flex flex-1 flex-row text-left px-3 py-4 md:px-6 lg:px-12">
            <div class="flex flex-1 flex-col">
                <h2 class="text-2xl md:text-5xl">{{ title }}</h2>
                <h3 v-if="subtitle && subtitle.length > 0" class="text-xl md:text-4xl text-gray-800">{{ subtitle }}</h3>
            </div>
            <div v-if="image" class="flex w-0 overflow-visible relative" :class="imageClasses.container">
                <img :src="image" alt="" role="img"
                     class="absolute left-1/2 -translate-x-1/2 bottom-0 translate-y-2/3 w-36 aspect-square max-w-[200px]"
                     :class="imageClasses.image"/>
            </div>
        </div>
    </div>
</template>

<script>
import {computed, defineComponent, inject} from 'vue'
import useTranslations from "../translations";

export default defineComponent({
    name: 'SectionTitle',
    props: {
        title: {
            type: String,
            required: true,
        },
        link: {
            type: String,
            required: true,
        },
        linkName: {
            type: [String, null],
            required: false,
            default: null,
        },
        subtitle: {
            type: String,
            default: ''
        },
        color: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: ''
        },
        imageSize: {
            type: String,
            default: 'normal'
        },
    },
    setup(props) {
        const tx = inject('tx');
        const {translateUrl} = useTranslations();

        const goBack = computed(() => tx('Go Back'))

        const linkUrl = computed(() => {
            const link = props.link.toLowerCase()

            return link.replace(/[^a-z]+/ig, '-')
        })

        const imageClasses = computed(() => {

            if (props.imageSize == 'large') {
                return {container: '', image: 'md:w-48'}
            }

        })

        const colors = computed(() => {

            if (props.color == 'gray-purple') {
                return 'border-gray-400 text-purple-850'
            }

            if (props.color == 'purple') {
                return 'border-purple-850 text-purple-850 bg-purple-150'
            }

            if (props.color == 'gray-green') {
                return 'border-gray-400 text-green-800'
            }

            if (props.color == 'green') {
                return 'border-green-800 text-green-800 bg-green-150'
            }

            if (props.color == 'gray-orange') {
                return 'border-gray-400 text-orange-900'
            }

            if (props.color == 'orange') {
                return 'border-orange-300 text-orange-900 bg-orange-150'
            }

            if (props.color == 'gray-blue') {
                return 'border-gray-400 text-blue-800'
            }

            if (props.color == 'blue') {
                return 'border-blue-800 text-blue-800 bg-blue-150'
            }

            return 'border-gray-400 bg-gray-150'
        })

        const goBackUrl = computed(() => {
            return translateUrl(
                window.qualityOfLifeConfig?.page1Url || (location.origin + '/quality-of-life'),
                linkUrl.value
            );
        })

        const goBackAriaLabel = computed(() => {
            if (props.linkName) {
                return props.linkName;
            }

            return tx('Return to page 1') + ', ' + tx(props.link.replaceAll('-', ' '));
        })

        return {
            linkUrl,
            colors,
            goBack,
            goBackUrl,
            goBackAriaLabel,
            imageClasses
        }
    }
})
</script>
