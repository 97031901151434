import {computed, useAttrs} from "vue";

export function defineField() {
    const { field } = useAttrs();

    const id = `form-field-${field.handle}`;

    return {
        ...field,
        isRequired: (field.validate)
            ? field.validate.includes('required')
            : false,
        isReadOnly: field.visibility === 'readonly',
        id,
    }
}
