<template>
    <button class="flex-shrink inline-flex justify-center items-center space-x-2" :class="classes">
        <slot></slot>
    </button>
</template>

<script>
import {ref} from "vue";
import {useButtonStyle} from "./useButtonStyle";

export default {
    name: "SecondaryButton",

    props: {
        inverted: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'small',
            validator (val) {
                return ['base', 'small'].includes(val);
            }
        },
        customStyle: {
            type: [String, undefined],
            default: undefined,
        },
    },

    setup(props) {
        const { classes } = useButtonStyle(
            'secondary',
            props
        );

        return { classes };
    },
}
</script>
