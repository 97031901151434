import {reactive, ref} from "vue";

const isGeolocationEnabled = ref(false);

const cache = reactive({
    lat: null,
    lng: null,
})

export function useGeolocation() {
    function isGeolocationAllowed() {
        return 'geolocation' in navigator;
    }

    function getCoords(options, ignoreCache) {
        return new Promise((resolve, reject) => {
            if (! isGeolocationAllowed()) {
                return reject('Geolocation not supported');
            }

            if (cache.lat && cache.lng && !ignoreCache) {
                return resolve(cache);
            }

            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { coords: { latitude, longitude } } = position;
                    cache.lat = latitude;
                    cache.lng = longitude;

                    isGeolocationEnabled.value = true;

                    return resolve(cache);
                },

                (error) => {
                    isGeolocationEnabled.value = false;

                    reject(`Geolocation error [${error.code}]: ${error.message}`);
                },

                options,
            )
        })
    }

    return {
        userCoords: cache,
        isGeolocationEnabled,
        getCoords,
    };
}
