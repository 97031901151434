<template>
    <div class="relative">
        <div class="flex flex-col items-center space-y-2 md:space-y-0 md:space-x-2 md:flex-row">
            <label :for="`${id}-site-search-type`" class="w-full text-base font-bold text-left md:w-2/6">
                <span :class="{'text-white': light, 'text-gray-700': !light}">
                    Type of search
                </span>
                <InputSelect
                    :id="`${id}-site-search-type`"
                    class="w-full font-bold py-[12px]"
                    :options="searchTypes"
                    :modelValue="searchType.value"
                    @update:modelValue="updateSearchType"
                    placeholder="Select the type of search"
                    title="Select the type of search"
                />
            </label>
            <label :for="`${id}-site-search-term`" class="w-full text-base font-bold text-left md:w-4/6" :class="{'pt-[4px]': searchType.value === 'location'}">
                <span :class="{'text-white': light, 'text-gray-700': !light}">
                    {{ searchType.placeholder }}
                </span>
                <template v-if="searchType.value === 'location'">
                    <FindByLocationInput
                        :id="`${id}-site-search-term`"
                        aria-controls="maps_schedules_search_listbox"
                        :aria-activedescendant="results?.length > 0 ? `route_${results[currentFocus]?.id}` : ''"
                        :google-api-key="google.maps_api_key"
                        :google-map-bounds="google.bounds"
                        placeholder="Enter Address, Street or ZIP"
                        :modelValue="searchLocation"
                        @update:modelValue="findByLocation"
                        @change="results.value = []"
                        @keydown.esc="focused = false"
                        @keydown.down="focusNext"
                        @keydown.up="focusPrevious"
                        @keydown.enter="selectFocusedOption"
                    />
                </template>

                <template v-if="searchType.value === 'route'">
                    <SearchInput
                        :id="`${id}-site-search-term`"
                        aria-controls="maps_schedules_search_listbox"
                        :aria-activedescendant="results?.length > 0 ? `route_${results[currentFocus]?.id}` : ''"
                        placeholder="Enter Route Name or Number"
                        @keydown.esc="focused = false"
                        @keydown.down="focusNext"
                        @keydown.up="focusPrevious"
                        @keydown.enter="selectFocusedOption"
                        @change="(event) => {
                            updateSearchTerm(event.target.value)
                        }"
                        :value="searchTerm"
                    />
                </template>

                <template v-if="searchType.value === 'stop'">
                    <SearchInput
                        :id="`${id}-site-search-term`"
                        aria-controls="maps_schedules_search_listbox"
                        :aria-activedescendant="results?.length > 0 ? `route_${results[currentFocus]?.id}`: ''"
                        placeholder="Enter Stop Code"
                        @keydown.esc="focused = false"
                        @keydown.down="focusNext"
                        @keydown.up="focusPrevious"
                        @keydown.enter="selectFocusedOption"
                        @change="(event) => updateSearchTerm(event.target.value)"
                        :value="searchTerm"
                    />
                </template>
            </label>
        </div>
        <ul
             role="listbox"
             v-show="results.length > 0"
             id="maps_schedules_search_listbox"
             class="bg-white rounded-b border absolute w-full z-[70]"
        >
            <li
                v-for="(route, index) in results"
                :key="route.id"
                :value="route.id"
                :id="`route_${route.id}`"
                class="w-full"
                role="option"
                :ref="setOptionRef"
                :aria-selected="index === currentFocus"
                :aria-label="`${route.id} - ${route.longName}`"
                @click.prevent="selectOption(index)"
            >
                    <div class="flex items-center justify-between w-full px-3 py-4 space-x-2 cursor-pointer group hover:bg-orange-800 hover:text-white group" :class="{'bg-orange-800 text-white': index === currentFocus}">
                        <span class="flex items-center font-normal flex-0">
                            <Icon v-if="ICONS[route.group.mode]" class="w-6 h-6 mr-1 group-hover:text-white" :class="{'text-white': index === currentFocus, 'text-primary-300': index !== currentFocus}" :icon="ICONS[route.group.mode]" :aria-label="route.group.mode"></Icon>
                            <Icon v-else :icon="mdiCheck" class="w-5 h-5 mr-2 text-green-500 group-hover:text-white" :class="{'text-white': index === currentFocus, 'text-green-500': index !== currentFocus}" aria-label="No rider alerts"/>
                        </span>
                        <span class="flex items-center justify-center w-12 h-6 px-2 text-sm font-normal rounded text-white bg-primary">
                            {{ route.id }}
                        </span>
                        <span class="flex-1 text-left">{{ route.longName }}</span>
                    </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { Combobox, ComboboxOption, ComboboxOptions } from '@headlessui/vue';
import { usePage } from '@inertiajs/vue3';
import { watchDebounced } from "@vueuse/core";
import Fuse from 'fuse.js';
import {defineComponent, inject, nextTick, onMounted, ref, watch} from 'vue';
import { loadGoogleJavascript, setGoogleApi } from "../../utils/google";
import { ICONS } from "../../utils/icons";
import Icon from "../Icon";
import InputLocation from "../InputLocation";
import InputSearchBox from "../InputSearchBox";
import InputSelect from "../InputSelect";
import { useStore } from "./store";
import FindByLocationInput from './SearchInputs/FindByLocationInput.vue';
import SearchInput from './SearchInputs/SearchInput.vue';
import InputText from "../InputText.vue";


export default defineComponent({
    name: 'MapsSchedulesSearch',
    components: {
        InputText,
        SearchInput,
        FindByLocationInput,
        InputLocation,
        InputSelect, InputSearchBox, Combobox, ComboboxOptions, ComboboxOption, Icon
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        light: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        let allRoutes = [];
        let routesSearcher = [];
        const searchTypes = [
            {
                value: 'route',
                label: 'Find by Route',
                placeholder: 'Enter Route Name or Number',
                debounce: false,
                search: async (value) => {
                    return routesSearcher.search(value).map(result => result.item);
                },
            },
            {
                value: 'stop',
                label: 'Find by Stop',
                placeholder: 'Enter Stop Code',
                debounce: false,
                search: async (stopCode) => await fetchStops({stopCode}),
            },
            {
                value: 'location',
                label: 'Find by Location',
                placeholder: 'Enter Address, Street or ZIP',
                debounce: {debounce: 1000, maxWait: 2000},
                search: async (value) => {
                    if (!value?.location?.lat || !value?.location?.lng) {
                        return [];
                    }

                    return await fetchStops({
                        lat: value.location.lat,
                        lng: value.location.lng,
                        location: value.value,
                    });
                },
            },
        ];

        const api = inject('api');
        const store = useStore();
        const google = usePage().props.global.google;
        const searchType = ref(searchTypes[0]);
        const selectedRoute = ref();
        const searchTerm = ref();
        const results = ref([]);
        const optionRefs = [];
        const focused = ref(false);
        const currentFocus = ref(-1);
        const searchLocation = ref({
            value: '',
            location: null,
        });

        const googleJsPromise = loadGoogleJavascript(google.maps_api_key);

        onMounted(() => {
            googleJsPromise.then(() => {
                setGoogleApi(window.google.maps);
            });
        });

        // FUNCTIONS --------------------------------------

        async function fetchStops({stopCode = null, lat = null, lng = null, location = null}) {
            const response = await api.getStopInfo({
                lat,
                lng,
                stopCode,
                radius: '1000',
                includeRoutes: true,
            });

            if (!response || !response.data) {
                return [];
            }

            const foundRoutes = response.data.reduce((result, {routeIds}) => {
                routeIds.forEach(rId => {
                    const route = allRoutes.find(r => r.id === rId);
                    if (!route || result.hasOwnProperty(route.id)) {
                        return;
                    }

                    result[route.id] = {
                        ...route,
                        searchedStopCode: stopCode,
                        searchedLocation: (lat && lng)
                            ? {lat, lng, location}
                            : null,
                    }
                });

                return result;
            }, {});

            return Object.values(foundRoutes);
        };

        const updateSearchTerm = (value) => {
            searchTerm.value = value;
        };

        const updateSearchType = (value) => {
            searchType.value = searchTypes.find(st => st.value === value);
            nextTick(() => {
                searchTerm.value = '';
                searchLocation.value.value = '';
                searchLocation.value.location = null;
            });
        };

        // WATCHERS --------------------------------------

        watch(selectedRoute, (to) => {
            if (searchType.value?.value === 'location') {
                searchLocation.value.value = '';
                searchLocation.value.location = null;
            }

            let href = `/maps-schedules/${to.id}`;

            const queryParams = [];
            if (searchType.value?.value === 'stop' && selectedRoute.value?.searchedStopCode) {
                queryParams.push(`stop=${selectedRoute.value.searchedStopCode}`);
            }
            if (searchType.value?.value === 'location' && selectedRoute.value?.searchedLocation) {
                queryParams.push(`location=${selectedRoute.value.searchedLocation.location}`);
            }

            store.startLoading();
            window.location.href = `${href}?${queryParams.join('&')}`;
        });

        async function findByLocation({value, location}) {
            searchLocation.value.value = value;
            searchLocation.value.location = location;
            results.value = await searchType.value.search(searchLocation.value);
            updateSearchTerm(value);
            if(results.value.length > 0) {
                currentFocus.value = 0;
                optionRefs[currentFocus.value]?.focus();
            }
        }

        function setOptionRef(el) {
            if (el) {
                optionRefs.push(el);
            }
        }

        function focusPrevious(event) {
            event.preventDefault();

            currentFocus.value = (currentFocus.value > 0)
                ? currentFocus.value - 1
                : results.value.length - 1;

            optionRefs[currentFocus.value]?.focus();
        }

        function focusNext(event) {
            event.preventDefault();

            currentFocus.value = (currentFocus.value < results.value.length - 1)
                ? currentFocus.value + 1
                : 0;

            optionRefs[currentFocus.value]?.focus();
        }

        function selectFocusedOption() {
            if (currentFocus.value === -1) {
                return;
            }

            selectedRoute.value = results.value[currentFocus.value];
        }

        function selectOption(refId) {
            selectedRoute.value = results.value[refId];
        }

        watch(() => store.state.routeGroups, (rg) => {
            allRoutes = rg.reduce((routes, group) => routes.concat(group.routes), []);
            routesSearcher = new Fuse(allRoutes, {
                threshold: 0.2,
                keys: ['longName', 'shortName']
            });
        }, {immediate: true});

        watchDebounced([searchTerm], async () => {
            const term = searchTerm.value?.trim();

            if (!searchType.value || !term?.length) {
                results.value = [];
                return;
            }

            if (searchType.value.value === 'location') {
                return;
            }

            results.value = await searchType.value.search(term);

            if(results.value.length > 0) {
                currentFocus.value = 0;
                optionRefs[currentFocus.value]?.focus();
            }
        }, {debounce: 500, maxWait: 1000})

        return {
            ICONS,
            google,
            results,
            searchType,
            searchTypes,
            searchTerm,
            selectedRoute,
            searchLocation,
            updateSearchType,
            updateSearchTerm,
            findByLocation,
            setOptionRef,
            focusPrevious,
            focusNext,
            focused,
            currentFocus,
            selectFocusedOption,
            selectOption
        };
    },
});
</script>
