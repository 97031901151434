<template>
    <div>
        <div v-if="isLoadingRoute" class="flex justify-center w-full py-32">
            <Spinner class="w-8 h-8 text-primary"/>
        </div>
        <div v-else>
            <!-- title -->
            <div class="mt-8">
                <div
                    class="flex flex-row flex-wrap justify-between w-full items-left md:flex-row md:items-center text-primary">
                    <!-- Route name -->
                    <div class="flex mb-4 mr-4 space-x-4 flex-0">
                        <div class="flex items-center h-10 px-2 text-xl font-normal rounded text-primary-50 bg-primary">
                            <Icon v-if="ICONS[currentRoute?.group?.mode]" class="mr-2 text-white w-7 h-7" :icon="ICONS[currentRoute.group.mode]" :aria-label="currentRoute.group.mode"></Icon>
                            <span class="px-1 mt-1 border border-white">
                                {{ currentRoute?.id }}
                            </span>
                        </div>
                        <div class="flex-1">
                            <h2 class="inline-block text-3xl text-left text-white">{{ currentRoute?.longName }}</h2>
                        </div>
                    </div>

                    <!-- Transit Schedule Button -->
                    <div v-if="routeDetails?.pdfs?.length > 0" class="flex mb-4 flex-0">
                        <div class="flex space-x-2">
                            <template v-for="pdf in routeDetails.pdfs">
                                <a :href="pdf.pdf_url" target="_blank" class="w-64 flex flex-col justify-center items-center text-lg font-bold py-3 px-4 border border-white transition-all text-white ring-2 ring-transparent hover:underline focus:underline focus:ring-offset-2 focus:ring-white">
                                    <div class="flex flex-col items-center">
                                        <Icon class="w-8 h-8 -mt-1 -ml-1" :icon="mdiDownload" aria-label="View Printable Schedule" />
                                    </div>
                                    <div class="flex w-full justify-center text-center">
                                        <template v-if="pdf.custom_button_label && pdf.custom_button_label.trim().length > 0">
                                            {{ pdf.custom_button_label }}
                                        </template>
                                        <template v-else>
                                            <span class="text-center">
                                                Click here to view a Printable Schedule
                                                <div v-if="routeDetails.pdfs.length > 1" class="text-white text-sm">
                                                    {{ pdf.expires_at }}
                                                </div>
                                            </span>
                                        </template>
                                    </div>
                                </a>
                            </template>
                        </div>
                    </div>
                </div>

                <!-- route notes -->
                <div v-if="routeDetails?.notes" class="p-3 text-lg text-gray-900 bg-white rounded-xl">
                    <div v-html="routeDetails.notes" class="route-description"></div>
                </div>

                <!-- service alerts -->
                <div class="mt-8 space-y-1">
                    <div v-for="(_, index) in currentRoute?.alert">
                        <ServiceAlert v-if="currentRoute?.alert[index]" :alert="currentRoute?.alert[index]" />
                    </div>
                </div>
            </div>

            <div v-if="isMounted" class="mt-8">
                <TabGroup :default-index="initialTab">
                    <TabList class="flex flex-col py-2 space-y-2 md:flex-row md:space-y-0 md:px-2 md:py-0 md:space-x-2">
                        <Tab as="template" v-slot="{ selected }">
                            <button type="button" :class="getTabClass(selected)">
                                Route Schedule
                            </button>
                        </Tab>
                        <Tab as="template" v-slot="{ selected }">
                            <button type="button" :class="getTabClass(selected)">
                                Route Map
                            </button>
                        </Tab>
                        <Tab as="template" v-slot="{ selected }">
                            <button type="button" :class="getTabClass(selected)">
                                Route Description
                            </button>
                        </Tab>
                    </TabList>
                    <TabPanels class="relative w-full bg-white rounded-lg">
                        <!-- Route Schedule -->
                        <TabPanel class="p-0 shadow bg white rounded-xl ring-0 focus:outline-none focus:ring-0 md:p-3">
                            <MapsSchedulesRouteDetails/>
                        </TabPanel>
                        <!-- Route Map -->
                        <TabPanel class="p-0 shadow bg white rounded-xl ring-0 focus:outline-none focus:ring-0 md:p-3">
                            <MapsSchedulesRouteMap/>
                        </TabPanel>
                        <!-- Route Description -->
                        <TabPanel class="p-2 shadow bg white rounded-xl ring-0 focus:outline-none focus:ring-0 md:p-3">
                            <div v-html="routeDetails?.description" class="route-description"></div>
                        </TabPanel>
                    </TabPanels>
                </TabGroup>
            </div>
        </div>
    </div>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue';
import { mdiBusClock, mdiChevronDown, mdiDownload } from "@mdi/js";
import axios from "axios";
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { ICONS } from "../../utils/icons";
import Icon from "../Icon";
import ServiceAlert from "../ServiceAlert";
import SetsRenderer from "../SetsRenderer";
import Spinner from "../Spinner";
import MapsSchedulesList from "./MapsSchedulesList";
import MapsSchedulesRouteDetails from "./MapsSchedulesRouteDetails";
import MapsSchedulesRouteMap from "./MapsSchedulesRouteMap";
import MapsSchedulesSearch from "./MapsSchedulesSearch";
import { useStore } from "./store";
import Swal from 'sweetalert2';

export default defineComponent({
    name: 'MapsSchedulesRoute',

    components: {
        Tab,
        TabList,
        TabGroup,
        TabPanel,
        TabPanels,
        SetsRenderer,
        Icon,
        Spinner,
        ServiceAlert,
        MapsSchedulesList,
        MapsSchedulesSearch,
        MapsSchedulesRouteMap,
        MapsSchedulesRouteDetails,
    },

    props: {
        routeName: {type: String, required: true},
        routeId: {type: String, required: true},
        stop: {type: [String, null], default: null},
        location: {type: [String, null], default: null},
    },

    setup(props) {
        const store = useStore();
        const routeDetails = ref(null);
        const initialTab = ref(0);
        const isMounted = ref(false);
        const currentRoute = computed(() => store.state.routes.current);
        const currentAlerts = computed(() => store.state.routes.current?.alert);
        const isLoadingRoute = computed(() => {
            return (
                !currentRoute
                || !store.state.selectedSchedule
            )
        });

        async function loadRouteDetails() {
            if (props.routeId && store.state.routes.fetched && !currentRoute.value && (typeof currentRoute.value !== 'undefined')) {
                window.location.href = '/maps-schedules';
                return;
            }

            if (!currentRoute.value) {
                return null;
            }

            const { data } = await axios.get(`/api/routes/${currentRoute.value.id.toLowerCase()}`);

            return data;
        }

        function getInitialTab() {
            if ((props.stop && props.stop.length > 0) || (props.location && props.location.length > 0)) {
                return 1;
            }

            return 0;
        }

        function getTabClass(selected) {
            return [
                'px-6 py-3 text-lg leading-8 font-medium rounded-t-lg rounded-b-lg md:rounded-b-none',
                'focus:bg-white focus:ring-white focus:ring-offset-primary-500 focus:ring-offset-2 focus:ring-2',
                selected
                    ? 'bg-white shadow text-secondary'
                    : 'text-gray-600 bg-primary-gray hover:bg-white hover:text-secondary',
            ];
        }

        function disclaimer() {
            Swal.fire({
                title: '<strong>Notice:</strong>',
                icon: 'info',
                html:
                    '<div class="text-center">' +
                    '<p class="mt-4 font-bold">This route may include stop closures or other important notices. Please review the rider alerts before planning your trip.</p>' +
                    '</div>',
                showConfirmButton: true,
                confirmButtonText:
                    'Agree >',
                focusConfirm: false,
                allowOutsideClick: false,
                allowEscapeKey: false
            });
        }

        if (props.stop && props.stop.length > 0) {
            store.setSelectedStops([
                {code: props.stop}
            ]);
        }

        watch(
            currentRoute,
            () => loadRouteDetails().then((data) => routeDetails.value = data),
            {immediate: true}
        );

        watch(
            () => props.routeId,
            (to) => {
                store.setCurrentRoute(to);
            },
            {immediate: true}
        );

        watch(
            currentAlerts,
            (to) => {
                if (to && to.length) {
                    disclaimer();
                }
            },
            {immediate: true}
        );

        onMounted(() => {
            initialTab.value = getInitialTab();
            isMounted.value = true;
        });

        return {
            store,
            isMounted,
            initialTab,
            routeDetails,
            currentRoute,
            isLoadingRoute,
            getTabClass,

            // icons
            ICONS,
            mdiDownload,
            mdiBusClock,
            mdiChevronDown,
            disclaimer
        }
    },
});
</script>

<style scoped>
.route-description /deep/ {
    @apply prose w-full max-w-none;
}

.route-description li > p {
    margin: 0;
}
</style>
