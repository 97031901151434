<template>
    <div class="border-b-8">
        <SectionTitle :title="content.title" :subtitle="content.subtitle" link="economic-investment" color="orange" />
        <div class="flex flex-col md:flex-row gap-4 p-4 max-w-5xl m-auto">
            <div class="flex-1 text-center">
                <h3 class="text-xl sm:text-3xl xl:text-4xl mb-8">{{ content.graphics[0].title }}</h3>
                <Image class="m-auto" :src="content.graphics[0].url" :alt="content.graphics[0].alt" />
            </div>
            <div class="flex-1 text-center">
                <h3 class="text-xl sm:text-3xl xl:text-4xl mb-8">{{ content.graphics[1].title }}</h3>
                <Image class="m-auto" :src="content.graphics[1].url" :alt="content.graphics[1].alt" />
            </div>
        </div>
        <div class="m-auto mt-4 max-w-xl text-center mb-12">
            <InfoBox halign="left" color="qol2-full" class="mb-8">
                <template #header>
                    <InfoBoxIcon icon="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/assets/qol-buildings.svg" class="-mt-10 -mb-2"/>
                </template>
                <template #content>
                    <p v-html="content.infoBox"></p>
                </template>
            </InfoBox>
        </div>
    </div>
</template>

<script>
import {defineComponent, computed, inject} from 'vue';
import Image from "../Components/Image";
import InfoBox from "../Components/InfoBox";
import InfoBoxIcon from "../Components/InfoBoxIcon";
import SectionTitle from '../Components/SectionTitle'
import useTranslations from "../translations";

export default defineComponent({
    name: 'EconomicInvestment',
    components: {
        InfoBoxIcon,
        Image,
        InfoBox,
        SectionTitle
    },
    setup() {
        const { translate } = useTranslations();

        const content = computed(() => ({
            title: translate.value('Economic Investment','Inversión Económica'),
            subtitle: translate.value('Light rail corridor, 2004-2021', 'Corredor del Tren Ligero, 2004-2021'),
            infoBox: translate.value(
                'Of the $16B in development, $14B has led to an increase in housing, education and business opportunities in the light rail corridor.',
                'De los $16MM en desarrollo, $14MM han resultado en un aumento en oportunidades de vivienda, educación y negocios en el corredor del tren ligero.'
            ),
            graphics: [
                {
                    title: translate.value('Capital Investments', 'Inversiones de Capital'),
                    alt: translate.value(
                        'Capital investments in Billions. Private, $11B. Public, $3.4B. Light Rail, $2.3B.',
                        'Inversiones de capital en miles de millones. Privado, $ 11 mil millones. Público, 3.4 mil millones. Tren ligero, $2.3 mil millones.'
                    ),
                    url: translate.value(
                        'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/economic-investment_capital-investments.svg',
                        'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/capital-investments-spanish.svg'
                    ),
                },
                {
                    title: translate.value('Development Breakdown','Desglose del Desarrollo'),
                    alt: translate.value(
                        'Development Breakdown by category. Residential 56%. Commercial 33%. Education 9%. Public 2%.',
                        'Desarrollo Desglose por categoría. Residencial 56%. Comercial 33%. Educación 9%. Público 2%.'
                    ),
                    url: translate.value(
                        'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/economic-investment_development-breakdown.svg',
                        'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/development-breakdown-spanish.svg'
                    ),
                },
            ]
        }))

        return {
            content,
        };
    }
});
</script>
