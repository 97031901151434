/**
 * Transform the entry that comes from the Statamic to the
 * entity that we require in Vue.
 *
 * @param entry
 * @returns {{dates, locations: *}}
 */
import {format, formatISO, parse} from "date-fns";
import {formatTime} from "../components/MapsSchedules/store";
import { parseFromISO } from "./date";
import {usePage} from '@inertiajs/vue3';

export function hydrateEventEntry(entry) {
    const event = {
        id: entry.id,
        url: entry.url,
        category: entry.event_type?.category,
        locations: entry.locations,
    }

    event.title = entry.custom_name || entry.event_type?.title || entry.title;
    event.description = entry.custom_description?.length ? entry.custom_description : entry.event_type?.default_description;
    event.contact = entry.custom_contact || entry.event_type?.default_contact;

    event.dates = (() => {
        function build(date, time, ampm) {
            if (! date) {
                return null;
            }

            const ampmLabel = (ampm?.label || 'AM').toUpperCase();
            let dateString = date.split('T')[0] + (time ? ` ${time} ${ampmLabel}` : ' 12:00 AM');
            const parsed = parse(dateString, 'yyyy-MM-dd hh:mm aa', parseFromISO(date));

            return buildParsed(parsed);
        }

        function buildParsed(parsed) {
            return {
                dateObj: parsed,
                iso: formatISO(parsed),
                time: formatTime(parsed) || null,
                date: format(parsed, 'eeee, MMMM d, yyyy'),
                date_calendar: format(parsed, "yyyyMMdd'T'HHmm'00'"),
                date_outlook: format(parsed, "yyyy-MM-dd'T'HH:mm:'00'"),
            }
        }

        const start = build(entry.start_date, entry.start_time, entry.start_time_ampm);
        let end = build(entry.end_date || entry.start_date, entry.end_time, entry.end_time_ampm);

        if (start.dateObj.getTime() >= end.dateObj.getTime()) {
            end = buildParsed(new Date(start.dateObj.getTime() + (60 * 60 * 1000)))
        }

        return {
            sameDay: start.date === end.date,
            start,
            end,
        }
    })();

    event.location_description = (() => {
        if (! entry?.locations?.length) {
            return null;
        }

        if (entry.locations.length > 1) {
            return 'Multiple locations';
        }

        return entry.locations[0].short_title;
    })();

    event.links = (() => {
        const startDate = event.dates?.start?.date_calendar;
        const endDate = event.dates?.end?.date_calendar;
        const startDateOutlook = event.dates?.start?.date_outlook;
        const endDateOutlook = event.dates?.end?.date_outlook;

        const googleDates = startDate + (endDate ? `/${endDate}` : `/${startDate}`);

        const platform = window.navigator?.userAgentData?.platform || window.navigator.platform;
        const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'macOS'];
        const iosPlatforms = ['iPhone', 'iPad', 'iPod', 'iOS', 'iPadOS'];

        const supportsWebCal = (macosPlatforms.indexOf(platform) !== -1) || (iosPlatforms.indexOf(platform) !== -1);

        const calendar = supportsWebCal ?
            `webcal://${window.location.host}/event-download/${event.id}.ics` :
            `data:text/calendar;charset=utf8,%0ABEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VEVENT%0ADTSTART:${startDate}%0ADTEND:${endDate}%0ASUMMARY:${event.title}%0ALOCATION:${event.location_description}%0AEND:VEVENT%0AEND:VCALENDAR`;

        const locationDesc = encodeURIComponent(event.location_description);
        const page = usePage();

        console.log(page.props.global.calendar);

        return {
            outlook: encodeURI(`${page.props.global.calendar.outlook}?body=${event.title}&enddt=${endDateOutlook}&location=${locationDesc}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${startDateOutlook}&subject=${event.title}`),
            iCalendar: calendar,
            yahooCalendar: encodeURI(`${page.props.global.calendar.yahoo}?v=60&view=d&type=20&title=${event.title}&st=${startDate}&et=${endDate}&in_loc=${locationDesc}`),
            googleCalendar: encodeURI(`${page.props.global.calendar.google}?action=TEMPLATE&text=${event.title}&dates=${googleDates}&location=${locationDesc}`),
        }
    })();

    event.thumb_image = (() => {
        if (entry.custom_image?.length) {
            return entry.custom_image[0];
        }

        if (entry.augmented_event_type?.thumb_image?.length) {
            return entry.augmented_event_type.thumb_image[0];
        }

        if (entry.event_type?.default_image?.length) {
            return entry.event_type?.default_image[0];
        }

        return null;
    })();

    event.main_image = (() => {
        if (entry.custom_image?.length) {
            return entry.custom_image[0];
        }

        if (entry.event_type?.default_image?.length) {
            return entry.event_type?.default_image[0];
        }

        if (entry.event_type?.thumb_image?.length) {
            return entry.event_type?.thumb_image[0];
        }

        return null;
    })();

    event.resources = (() => {
        const resources = entry.resources || [];

        return (entry.include_default_event_resources)
            ? resources.concat(entry.event_type?.resources)
            : resources;
    })();

    event.contact = (() => {
        return {
            contact: entry.custom_contact || entry.event_type?.default_contact
        }
    })();

    return event;
}
