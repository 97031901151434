<template>
<div
    role="alert"
    class="p-4 bg-yellow-50 rounded-lg transition-all"
    :title="`Rider alert ${alert.title}`"
    :aria-label="`Rider alert ${alert.title}`"
>
    <div class="flex items-center space-x-4 text-yellow-900 font-normal">
        <div>
            <Icon :icon="mdiAlert" class="w-8 h-8 text-yellow-500" :aria-label="`Route Alert: ${alert.title}`" />
        </div>
        <div class="flex-1 space-y-1">
            <h3 class="text-base font-bold">{{ alert.title }}</h3>
            <div class="text-sm">{{ alert.formattedActiveRange }}</div>
        </div>
        <button type="button" class="py-2" @click="toggleOpen" :aria-expanded="open" aria-label="Toggle alert">
            <Icon :icon="mdiChevronDown" class="w-6 h-6 text-yellow-900 transition-all" :class="{'transform rotate-180': open}" role="presentation" aria-label="Arrow down icon"/>
        </button>
    </div>

    <div v-show="open" class="mt-6 text-base text-yellow-800 font-normal service-alert" v-html="alert.htmlDescription"></div>
</div>
</template>

<script>
import Icon from "./Icon";
import {mdiAlert, mdiChevronDown} from "@mdi/js";
import {defineComponent, ref} from 'vue';

export default defineComponent({
    name: 'ServiceAlert',
    components: {Icon},
    props: {
        alert: {type: Object, required: true}
    },

    setup(props) {
        const open = ref(false);

        function toggleOpen () {
            open.value = !open.value;
        }

        return {
            open,

            // Functions
            toggleOpen,

            // ICONS
            mdiAlert,
            mdiChevronDown,
        }
    },
});
</script>

<style>
.service-alert ol {
    display: block !important;
    list-style-type: decimal !important;
    margin-block-start: 1em !important;
    margin-block-end: 1em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    padding-inline-start: 40px !important;
}

.service-alert ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
</style>
