import {useIsSmallScreen} from "../../../utils/helpers";

export const font12 = {size: 12, weight: '500', family: 'museo-sans, Museo-sans, sans-serif, serif'};
export const font14 = {size: 14, weight: '500', family: 'museo-sans, Museo-sans, sans-serif, serif'};
export const font16 = {size: 16, weight: '500', family: 'museo-sans, Museo-sans, sans-serif, serif'};
export const font20 = {size: 20, weight: '500', family: 'museo-sans, Museo-sans, sans-serif, serif'};

const isSmallScreen = useIsSmallScreen();

export function generateTitleSettings({ enabled = true, text = '' }) {
    return {
        display: enabled,
        font: font20,
        text,
    }
}

export function generateTickSettings(options = {}) {
    return {
        font: font16,
        ...options,
    }
}

export function generateDataLabelsSettings(options) {
    return {
        display: !isSmallScreen.value,
        font: font12,
        ...options,
    }
}

export function generateTooltipStettings(options) {
    return {
        enabled: isSmallScreen.value,
        ...options,
    }
}
