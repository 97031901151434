<template>
    <div
        role="button"
        class="w-full rounded overflow-hidden"
        :class="{
            'hover:cursor-pointer focus:ring-0 focus:outline-2 focus:outline-offset-4 focus:outline-white': route.available,
        }"
        @keyup.enter="selectRoute"
        @keyup.space="selectRoute"
        @click="selectRoute"
        :tabindex="route.available ? 0 : null"
    >
        <WithTooltip v-if="!route.available" :id="`tripAlert_${route.startFinishSlug}`" pos="right">
            <template v-slot:element="{ elementAttrs, show, hide }">
                <div class="flex items-center space-x-2 p-2 bg-secondary-100 text-secondary-800" v-bind="elementAttrs" @mouseenter="show" @focus="show" @mouseleave="hide" @blur="hide">
                    <Icon :icon="ICONS.alert" class="w-8 h-8 text-secondary" aria-hidden="true" />
                    <span class="text-secondary-800 text-base">Trip temporarily unavailable</span>
                </div>
            </template>
            <template v-slot:tooltip>
                <div>
                    <ul class="px-4 list-disc">
                        <li v-for="alert in alerts" :key="alert.id" class="pb-3 font-normal text-gray-300">
                            <strong class="font-bold text-white mb-1">{{ alert.title }}</strong><br/>
                            {{ alert.description }}
                        </li>
                    </ul>
                </div>
            </template>
        </WithTooltip>
        <div :class="{'opacity-75 pointer-events-none grayscale': !route.available}">
            <div class="bg-gray-100 p-2">
                <div class="text-base text-gray-900 font-bold" :aria-label="`Trip duration ${route.summary.duration.total.text}`">
                    {{ route.summary.duration.total.text }}
                </div>
                <div class="flex justify-between text-sm text-gray-700 font-normal">
                    <div v-if="route.summary.depart && route.summary.arrive" class="mt-2" :aria-label="`Departs at ${route.summary.depart.text} and arrives at ${route.summary.arrive.text}`">
                        {{ departTime }} - {{ arriveTime }}
                    </div>
                    <div class="flex-1 flex justify-end items-center mt-2 ml-2 text-sm text-gray-700" :aria-description="`${route.summary.duration.by_walk_vehicle.walk.text} and ${route.summary.calories.text} walk`">
                        <Icon class="mr-1 w-5 h-5 text-gray-600" :icon="ICONS[LEG_MODE_WALK]" aria-hidden="true"></Icon>
                        {{ route.summary.duration.by_walk_vehicle.walk.text }}, {{ route.summary.calories.text }}
                    </div>
                </div>
            </div>
            <div class="bg-white px-2 py-1">
                <!-- Legs -->
                <div class="flex flex-wrap items-center">
                    <template v-for="(leg, legIndex) in noWalkLegs">
                        <div class="flex items-center mr-2 py-1">
                            <div class="leg" :class="{'leg--first': legIndex === 0, 'leg--last': legIndex === noWalkLegs.length - 1}">
                                <!-- service icon -->
                                <template v-if="ICONS[leg.mode]">
                                    <Icon class="w-6 h-6 px-1 text-primary-900" :icon="ICONS[leg.mode]" :aria-label="leg.mode"></Icon>
                                </template>
                                <template v-else>
                                    {{ leg.mode }}
                                </template>

                                <!-- service line -->
                                <span v-if="leg.line !== '-'" class="flex-1 text-center">{{ leg.line }}</span>

                                <!-- service alerts integration -->

                                <!-- leg alert -->
                                <Icon v-if="leg.alert" :icon="ICONS.alert" :title="`Has service alert ${alert.title}`" :aria-label="`Has service alert ${alert.title}`"></Icon>
                            </div>
                        </div>
                    </template>
                </div>
                <!-- show when/where first transit departure -->
                <div v-if="firstTransitLeg" class="pt-2 pb-1 text-sm text-gray-600 font-light">
                    {{ firstTransitLeg.time }} from {{ firstTransitLeg.name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {computed} from 'vue';
import {format} from "date-fns";
import {parseFromISO} from "../../utils/date";
import {useTripInput} from "./useTripInput";
import {ICONS, LEG_MODE_BUS, LEG_MODE_TRAM, LEG_MODE_WALK} from "../../utils/icons";
import Icon from "../Icon";
import WithTooltip from '../WithTooltip.vue';
import { slugify } from '../../utils/helpers';

export default {
    name: "TripPlannerRouteSummary",
    components: { Icon, WithTooltip },

    props: {
        route: {
            type: Object,
            required: true,
        },
    },

    setup(props, { emit }) {
        const {
            date,
        } = useTripInput();

        const departTime = computed(() => {
            const theDate = parseFromISO(props.route.summary.depart.iso8601);

            return (date.value !== '-' && date.value !== format(theDate, 'yyyy-MM-dd'))
                ? `${format(theDate, 'h:mm aaaa')} (${format(theDate, 'iiii')})`
                : `${format(theDate, 'h:mm aaaa')}`;
        });

        const arriveTime = computed(() => {
            const theDate = parseFromISO(props.route.summary.arrive.iso8601);

            return (date.value !== '-' && date.value !== format(theDate, 'yyyy-MM-dd'))
                ? `${format(theDate, 'h:mm aaaa')} (${format(theDate, 'iiii')})`
                : `${format(theDate, 'h:mm aaaa')}`;
        });

        const noWalkLegs = computed(() => {
            if (! props.route.summary.legs) {
                return [];
            }

            return props.route.summary.legs.filter(l => l.mode !== LEG_MODE_WALK);
        });

        const firstTransitLeg = computed(() => {
            const leg = props.route.legs.find(l => [LEG_MODE_BUS, LEG_MODE_TRAM].includes(l.mode));

            if (! leg) {
                return null;
            }

            return {
                time: format(parseFromISO(leg.depart.iso8601), 'h:mm aaaa'),
                name: leg.start_name,
            };
        });

        const startFinishSlug = computed(() => {
            `${slugify(props.route.summary.start_name)}-${slugify(props.route.summary.finish_name)}`;
        });

        const alerts = computed(() => {
            return props.route.legs.reduce((acc, leg) => ([
                ...acc,
                ...leg.alerts,
            ]), []);
        });

        function selectRoute() {
            if (props.route.available) {
                emit('selected');
            }
        }

        return {
            LEG_MODE_WALK,
            ICONS,
            alerts,
            departTime,
            arriveTime,
            noWalkLegs,
            firstTransitLeg,
            startFinishSlug,
            selectRoute,
        }
    }
}
</script>

<style scoped>
.leg {
    @apply relative flex items-center;
    @apply text-sm text-primary-900 font-normal;
    @apply bg-primary-50;
    @apply border border-primary rounded rounded-r-md;
    padding-right: 0.4rem;
    min-width: 4rem;
}

.leg:not(.leg--last)::after {
    content: "";
    display: block;
    position: absolute;
    top: 2px;
    left: 100%;
    z-index: 40;
    border-style: solid;
    border-color: transparent theme('colors.primary.DEFAULT');
    border-width: 10px 0 10px 8px;
    width: 0;
    height: 0;
}
</style>
