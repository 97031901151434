<template>
<div>
    <AppHead title="Trip Planner"></AppHead>

    <TripPlannerComp
        :base-url="TripPlanner.url"
        :location-from="TripPlanner.location_from"
        :location-to="TripPlanner.location_to"
        :mode="TripPlanner.mode"
        :time="TripPlanner.time"
        :date="TripPlanner.date"
        :google-api-key="maps_api_key"
        :map-bounds="bounds"></TripPlannerComp>
</div>
</template>

<script>
import AppHead from "../components/AppHead";
import TripPlannerComp from "../components/TripPlanner/TripPlanner";
import {usePage} from '@inertiajs/vue3';

export default {
    name: "TripPlanner",
    components: {AppHead, TripPlannerComp},

    props: {
        TripPlanner: {
            /**
             * @see App\Fieldtypes\Sets\VueApp class.
             * {
             *     location_from,
             *     location_to,
             *     mode,
             *     time,
             *     date,
             * }
             */
            type: Object,
            required: true,
        },
    },

    setup() {
        const page = usePage();

        const { google: { maps_api_key, bounds }} = page.props.global;

        return {
            bounds,
            maps_api_key,
        }
    }
}
</script>
