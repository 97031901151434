<template>
    <a :name="href" :href="qolPage2Url + '#' + href" target="_top" :class="[color,{'cursor-pointer': Boolean(href)}]"
       class="button flex flex-col mx-10 my-10 z-[11] w-[250px] md:w-[325px] lg:w-[400px]">
        <div class="flex flex-row justify-center mb-10">
            <div
                class="button-image flex items-center justify-center w-40 lg:w-52 background-gray-400 shadow-2xl border-[10px] border-gray-400 rounded-full overflow-hidden aspect-square">
                <img :src="icon" class="object-fill" :class="iconClasses" alt="" role="img">
            </div>
        </div>
        <div class="button-text flex flex-col flex-1 justify-center text-center border-2 rounded-2xl bg-gray-200">
            <div class="text-xl md:text-2xl font-bold p-5 md:p-6">{{ text }}</div>
        </div>
    </a>
</template>

<script>
import {computed, defineComponent} from 'vue'
import useTranslations from "../translations";

export default defineComponent({
    name: 'CircleButton',

    props: {
        icon: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
        href: {
            type: String,
            required: false,
        },
        iconClasses: {
            type: [Array, null],
            required: false,
        }
    },

    setup() {
        const {translateUrl} = useTranslations()

        const qolPage2Url = computed(() => {
            return translateUrl(window.qualityOfLifeConfig?.page2Url || (location.origin + '/quality-of-life-2'));
        })

        return {
            qolPage2Url,
        }
    },
});
</script>

<style lang="scss" scoped>
.button {
    .button-text, .button-image {
        @apply transition-all duration-200;
    }

    &.purple, &.gray-purple {
        .button-text {
            @apply border-[#5a1769] shadow-[5px_5px_#5a1769];
        }
    }

    &.orange, &.gray-orange {
        .button-text {
            @apply border-[#7c470f] shadow-[5px_5px_#7c470f];
        }
    }

    &.blue, &.gray-blue {
        .button-text {
            @apply border-[#144767] shadow-[5px_5px_#144767];
        }
    }

    &.green, &.gray-green {
        .button-text {
            @apply border-[#556628] shadow-[5px_5px_#556628];
        }
    }

    &:hover {
        &.purple, &.gray-purple {
            .button-text {
                @apply bg-[#ded0e0] text-[#5a1769] shadow-[8px_8px_#5a1769] mt-[-2px];
            }

            .button-image {
                @apply border-[#5a1769] mt-[-2px];
            }
        }

        &.orange, &.gray-orange {
            .button-text {
                @apply bg-[#fce8d3] border-[#f9ba78] shadow-[8px_8px_#f9ba78] text-[#7c470f] ml-[-2px] mt-[-2px];
            }

            .button-image {
                @apply border-[#f9ba78] mt-[-2px];
            }
        }

        &.blue, &.gray-blue {
            .button-text {
                @apply bg-[#d0e5f4] border-[#226b98] shadow-[8px_8px_#226b98] text-[#144767] ml-[-2px] mt-[-2px];
            }

            .button-image {
                @apply border-[#144767] mt-[-2px];
            }
        }

        &.green, &.gray-green {
            .button-text {
                @apply bg-[#ebf4d9] border-[#556628] shadow-[8px_8px_#556628] text-[#556628] ml-[-2px] mt-[-2px];
            }

            .button-image {
                @apply border-[#556628] mt-[-2px];
            }
        }

    }

    &:focus {
        @apply outline-none ring-0;

        .button-text {
            @apply outline-none ring-2 ring-offset-4 shadow-none;
        }

        &.purple .button-text, &.gray-purple .button-text {
            @apply ring-[#5a1769];
        }

        &.orange .button-text, &.gray-orange .button-text {
            @apply ring-[#f9ba78];
        }

        &.blue .button-text, &.gray-blue .button-text {
            @apply ring-[#226b98];
        }

        &.green .button-text, &.gray-green .button-text {
            @apply ring-[#556628];
        }
    }
}
</style>
