<template>
    <div class="border-b-8">
        <SectionTitle link="rider-survey-results" color="blue"
            :title="$tx('Rider Survey Results')"
            :subtitle="$tx('Rider Safety and Security Satisfaction 2020 vs. 2021')" />
        <div class="flex flex-col lg:flex-row p-6 md:p-12">
            <ChartTableSwitch page-title="Rider Survey Results: Safety and Security Satisfaction">
                <template v-slot:chart>
                    <div class="flex lg:flex-1 lg:pr-4 h-[350px] md:h-[450px] lg:h-[600px]">
                        <ChartRenderer :chart="BarChart" chartId="rider-survey-results-chart" :chartData="chartData" :options="options" class="w-full lg:mr-0 lg:ml-auto lg:origin-left" label="Rider Survey Results: Safety and Security Satisfaction Chart/Graph. Refer to data table for details." />
                    </div>
                </template>

                <template v-slot:table>
                    <div class="flex justify-center min-w-full mt-4">
                        <DataTable highlight-first v-bind="dataTable" />
                    </div>
                </template>
            </ChartTableSwitch>
            <div class="flex m-auto lg:mr-auto lg:ml-0">
                <div class="max-w-sm text-center">
                    <InfoBox halign="left" color="qol3-full" class="mb-8 mt-20"
                             icon="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/QOL_information-shape_green_CNG-powered.svg"
                             icon-classes="mb-[32px] h-[5rem] lg:mb-[58px] lg:h-[8rem]"
                    >
                        <template #content>
                            <p v-html="$tx(`In 2021, <strong>75%</strong> of respondents provided a satisfaction rating of either <strong>4 or 5</strong>,<br/> (5 being excellent) an improvement compared to 2020's 52% satisfaction rate.`)">
                            </p>
                        </template>
                    </InfoBox>

                    <CovidDisclaimer />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {isNumber} from "lodash/lang";
import {computed, defineComponent, inject} from 'vue';
import ChartRenderer from "../../../components/ChartRenderer";
import ChartTableSwitch from "../Components/ChartTableSwitch";
import DataTable from "../Components/DataTable";

import SectionTitle from '../Components/SectionTitle';
import InfoBox from "../Components/InfoBox";
import CovidDisclaimer from '../Components/CovidDisclaimer';

import { BarChart } from 'vue-chart-3';
// See link for registering chart types to allow for tree-shaking if desired
// https://vue-chart-3.netlify.app/guide/#chart-js-v3
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

import { useIsSmallScreen } from "../../../utils/helpers";
import {
    font12,
    font14,
    font20,
    generateDataLabelsSettings,
    generateTickSettings,
    generateTitleSettings, generateTooltipStettings
} from "./chartsUtils";

export default defineComponent({
    components: {
        ChartRenderer,
        DataTable,
        ChartTableSwitch,
        SectionTitle,
        InfoBox,
        BarChart,
        CovidDisclaimer,
    },
    setup() {

        const tx = inject('tx')
        const isSmallScreen = useIsSmallScreen()

        const chartData = computed(() => ({
            labels: [
                `${tx('Excellent')} = 5`,
                '4',
                '3',
                '2',
                `${tx('Poor')} = 1`,
                tx("Don't know")
            ],
            datasets: [
                {
                    label: '2021',
                    backgroundColor: 'rgb(170, 205, 79)',
                    data: [38,37,17,4,3,0],
                    datalabels: generateDataLabelsSettings({
                        color: '#000000',
                        labels: {
                            value: {
                                formatter: (value) => value + '%',
                            }
                        }
                    }),
                },
                {
                    label: '2020',
                    backgroundColor: 'rgb(89, 23, 105)',
                    data: [24,28,18,8,6,18],
                    datalabels: generateDataLabelsSettings({
                        color: '#ffffff',
                        labels: {
                            value: {
                                formatter: (value) => value + '%',
                            }
                        }
                    })
                },
            ],
        }));

        const scoreAxis = computed(() => ({
            title: generateTitleSettings({
                text: tx('Rating'),
            }),
            ticks: generateTickSettings({
                font: isSmallScreen.value ? font12 : font14,
            }),
        }))

        const valueAxis = computed(() => ({
            ticks: generateTickSettings({
                min: 0,
                max: 100,
                callback: (value) => isNumber(value) ? `${value}%` : '',
            }),
            title: generateTitleSettings({
                text: tx('Response'),
            }),
        }))

        const options = computed(() => ({
            hover: { mode: null },
            indexAxis: isSmallScreen.value ? 'x' : 'y',
            // Elements options apply to all of the options unless overridden in a dataset
            // In this case, we are setting the border of each horizontal bar to be 2px wide
            elements: {
                bar: {
                    borderWidth: 0,
                    inflateAmount: 1,
                    borderRadius: 5,
                }
            },
            layout: {
                padding: 4,
            },
            plugins: {
                tooltip: generateTooltipStettings({
                    callbacks: {
                        label: (ctx) => `${ctx.formattedValue}%`
                    }
                }),
                legend: {
                    position: 'bottom',
                    font: font20,
                    reverse: true,
                    labels: {
                        boxWidth: 25,
                        boxHeight: 25,
                        font: font20,
                        padding: 25,
                    },
                },
                title: { display: false },
                datalabels: generateDataLabelsSettings({
                    font: isSmallScreen.value ? font12 : font14,
                }),
            },
            responsive: true,
            scales: {
                x: isSmallScreen.value ? scoreAxis : valueAxis,
                y: isSmallScreen.value ? valueAxis : scoreAxis,
            }
        }));

        const dataTable = computed(() => {
            const headings = {
                rating: tx('Rating'),
            };

            chartData.value.datasets.forEach((dataset) => {
               headings[`year${dataset.label}`] = dataset.label;
            });

            const rows = chartData.value.labels.map((rating, idx) => {
                const row = { rating };
                chartData.value.datasets.forEach((dataset) => row[`year${dataset.label}`] = dataset.data[idx] + '%');
                return row;
            })

            return { headings, rows };
        });

        return { BarChart, chartData, options, dataTable };
    }
});
</script>
