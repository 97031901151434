<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img">
        <path fill="currentColor" :d="icon"></path>
    </svg>
</template>

<script>
export default {
    name: "Icon",
    props: {
        icon: {
            type: String,
            required: true,
        }
    }
}
</script>
