<template>
    <div class="border-b-8">
        <SectionTitle :title="title" :subtitle="subtitle" link="access-to-jobs" color="orange" class="mb-8" />
        <div class="flex flex-col m-auto lg:flex-row">
            <div class="flex-1 p-4 w-full max-w-4xl m-auto lg:mr-4 lg:ml-auto">
                <Image :src="image" :alt="imageAlt" class="block" />
            </div>
            <div class="flex flex-col p-4 m-auto lg:ml-4 lg:mr-auto">
                <InfoBox halign="left" color="qol2-full" class="mb-8"
                         icon="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/QOL_information-shape_green_CNG-powered.svg"
                         icon-classes="mb-[32px] h-[5rem] lg:mb-[58px] lg:h-[8rem]"
                >
                    <template #content>
                        <p>{{ infoBoxText }}</p>
                    </template>
                </InfoBox>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, computed} from 'vue';
import Image from "../Components/Image";

import SectionTitle from '../Components/SectionTitle'
import InfoBox from "../Components/InfoBox";

import useTranslations from "../translations";

export default defineComponent({
    name: 'AccessToJobs',
    components: {
        Image,
        SectionTitle,
        InfoBox,
    },
    setup() {
        const { translate } = useTranslations();

        const title = computed(() => translate.value(
          'Access to Jobs',
          'Acceso a Empleos'
        ));

        const subtitle = computed(() => translate.value(
          'Job Concentration in the Frequent Service Network',
          'Concentración de Empleos en la Red de Servicio Frecuente'
        ));

        const image = computed(() => translate.value(
          'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/access-to-jobs-english-1651801676.svg',
          'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/access-to-jobs-spanish-1651801672.svg'
        ))

        const imageAlt = computed(() => translate.value(
            'Heat map showing job concentration within half-mile on the frequent service network',
            'Mapa de calor que muestra la concentración de trabajo dentro de media milla en la red de servicio frecuente'
        ))

        const infoBoxText = computed(() => translate.value(
            '28% of all jobs in Maricopa County are within 1/2 mile of the frequent service network.',
            '28% de todos los empleos en el Condado Maricopa están dentro de 1/2 milla de la red de servicio frecuente.'
        ))

        return {
            title,
            subtitle,
            image,
            imageAlt,
            infoBoxText,
        };
    }
});
</script>
