<template>
    <div class="border-b-8">
        <SectionTitle link="regional-housing" color="orange" class="mb-12"
            :title="$tx('Regional Housing Growth')"
            :subtitle="`${$tx('Maricopa County')}, 2010-2019`"/>
        <div class="flex flex-col w-full lg:flex-row p-6 md:p-12">
            <ChartTableSwitch page-title="Regional Housing Growth" view-table="View Tables" view-chart="View Charts">
                <template v-slot:chart>
                    <div class="flex flex-col space-y-8 w-full lg:space-y-0 lg:space-x-8 lg:flex-row">
                        <div class="flex flex-col lg:flex-1">
                            <h3 class="text-2xl">{{ $tx('Single Family Housing') }}</h3>
                            <h4 class="text-xl lg:h-14">{{ $tx('1-unit Detached') }}</h4>
                            <ChartRenderer :chart="BarChart" chartId="regional-housing-single-family-housing" :chartData="chartData1" :options="options1" class="w-full h-[350px] md:h-450px] lg:h-[500px]"
                                label="Single-family units constructed Chart/Graph. Refer to data table for details." />
                        </div>
                        <div class="flex flex-col lg:flex-1">
                            <h3 class="text-2xl">{{ $tx('Multi-Family Housing') }}</h3>
                            <h4 class="text-xl lg:h-14">{{ $tx('2+ Units, excluding mobile, boat, RV & van homes') }}</h4>
                            <ChartRenderer :chart="BarChart" chartId="regional-housing-multi-family-housing" :chartData="chartData2" :options="options2" class="w-full h-[350px] md:h-450px] lg:h-[500px]"
                                label="Multi-family units constructed Chart/Graph. Refer to data table for details." />
                        </div>
                    </div>
                </template>

                <template v-slot:table>
                    <div class="flex flex-col space-y-8 w-full lg:space-y-0 lg:space-x-8 lg:flex-row">
                        <div class="flex flex-col lg:flex-1">
                            <h3 class="text-2xl">{{ $tx('Single Family Housing') }}</h3>
                            <h4 class="text-xl lg:h-14">{{ $tx('1-unit Detached') }}</h4>
                            <DataTable highlight-first v-bind="dataTable1" class="mt-4" />
                        </div>
                        <div class="flex flex-col lg:flex-1">
                            <h3 class="text-2xl">{{ $tx('Multi-Family Housing') }}</h3>
                            <h4 class="text-xl lg:h-14">{{ $tx('2+ Units, excluding mobile, boat, RV & van homes') }}</h4>
                            <DataTable highlight-first v-bind="dataTable2" class="mt-4" />
                        </div>
                    </div>
                </template>
            </ChartTableSwitch>
        </div>
        <div class="m-auto mt-4 max-w-xl text-center mb-12">
            <InfoBox halign="left" color="qol2-full" class="mb-8 mt-20" icon="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/QOL_information-shape_orange_housing-growth.svg">
                <template #content>
                    <p>
                        {{ $tx('From 2009 to 2019, Maricopa County population grew 15%.') }}
                    </p>
                    <p>
                        {{ $tx('New housing grew by 9%.') }}
                    </p>
                </template>
            </InfoBox>
        </div>
    </div>
</template>

<script>
import {computed, defineComponent, inject} from 'vue';
import ChartRenderer from "../../../components/ChartRenderer";
import ChartTableSwitch from "../Components/ChartTableSwitch";
import DataTable from "../Components/DataTable";

import SectionTitle from '../Components/SectionTitle';
import InfoBox from "../Components/InfoBox";

import { BarChart } from 'vue-chart-3';

// See link for registering chart types to allow for tree-shaking if desired
// https://vue-chart-3.netlify.app/guide/#chart-js-v3
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
Chart.register(ChartDataLabels);

import { useIsSmallScreen } from "../../../utils/helpers";

import numeral from 'numeral';
import {generateDataLabelsSettings, generateTickSettings, generateTitleSettings} from "./chartsUtils";

export default defineComponent({
    components: {
        ChartRenderer,
        DataTable,
        ChartTableSwitch,
        SectionTitle,
        InfoBox,
        BarChart,
    },
    setup() {
        const tx = inject('tx')
        const isSmallScreen = useIsSmallScreen()

        const labelformat = (context) => { return context.formattedValue }
        const titleformat = (context) => { return context[0].label }

        const chartData1 = computed(() => ({
            labels: ['2010','2011','2012','2013','2014','2015','2016','2017','2018','2019'],
            datasets: [
                {
                label: tx('Single-Family'),
                backgroundColor: 'rgb(89, 23, 105)',
                borderColor: 'rgb(132, 132, 132)',
                data: [1040239,1051197,1073063,1077858,1080086,1079567,1102688,1130349,1136579,1129661],
                }
            ],
        }));

        const options1 = computed(() => ({
            hover: { mode: null },
            plugins: {
                tooltip: { enabled: isSmallScreen.value },
                legend: false,
                datalabels: generateDataLabelsSettings({
                    anchor: 'end',
                    align: 'end',
                    font: {size: 12, weight: '500', family: 'museo-sans, Museo-sans, sans-serif, serif'},
                    labels: {
                        value: {
                            formatter: (value) => {
                                if(typeof value == 'object') {
                                    return
                                }

                                return numeral(value).format('0.000a').toUpperCase()
                            }
                        }
                    }
                })
            },
            responsive: true,
            scales: {
                x: {
                    title: generateTitleSettings({
                        text: tx('Years'),
                    }),
                    ticks: generateTickSettings(),
                },
                y: {
                    title: generateTitleSettings({
                        text: tx('Units constructed'),
                    }),
                    ticks: generateTickSettings({
                        callback: (value, index, values) => {
                            return numeral(value).format('0.00a').toUpperCase()
                        }
                    }),
                    min: 1020000,
                    max: 1160000,
                }
            }
        }));

        const chartData2 = computed(() => ({
            labels: ['2010','2011','2012','2013','2014','2015','2016','2017','2018','2019'],
            datasets: [
                {
                label: tx('Multi-Family'),
                backgroundColor: 'rgb(170, 205, 79)',
                borderColor: 'rgb(132, 132, 132)',
                data: [511573,513730,500328,502834,511891,526250,520142,518452,531361,558112],
                }
            ],
        }));

        const options2 = computed(() => ({
            hover: { mode: null },
            plugins: {
                legend: false,
                tooltip: {
                    enabled: isSmallScreen.value,
                    callbacks: {
                        label: labelformat,
                        title: titleformat,
                    },
                },
                datalabels: generateDataLabelsSettings({
                    anchor: 'end',
                    align: 'end',
                    labels: {
                        value: {
                            formatter: (value) => {
                                if(typeof value == 'object') {
                                    return
                                }

                                return numeral(value).format('0.0a').toUpperCase();
                            }
                        }
                    }
                })
            },
            responsive: true,
            scales: {
                x: {
                    title: generateTitleSettings({
                        text: tx('Years'),
                    }),
                    ticks: generateTickSettings(),
                },
                y: {
                    position: isSmallScreen.value ? 'left' : 'right',
                    title: generateTitleSettings({
                        text: tx('Units constructed'),
                    }),
                    ticks: generateTickSettings({
                        callback: (value) => {
                            return numeral(value).format('0.0a').replace('.0', '').toUpperCase()
                        }
                    }),
                    min: 490000,
                    max: 570000,
                }
            }
        }));

        function buildDataTable(source) {
            const headings = {
                year: tx('Year'),
                units: tx('Units constructed'),
            };

            const rows = source.labels.map((year, idx) => ({
                year,
                units: Number(source.datasets[0].data[idx]).toLocaleString(),
            }));

            return { headings, rows };
        }

        const dataTable1 = computed(() => buildDataTable(chartData1.value));
        const dataTable2 = computed(() => buildDataTable(chartData2.value));

        return { BarChart, chartData1, options1, chartData2, options2, dataTable1, dataTable2 };
    }
});
</script>
