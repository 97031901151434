<template>
    <div>
        <div v-if="store.state.isLoading" class="w-full flex justify-center py-8">
            <Spinner class="text-primary w-8 h-8"/>
        </div>

        <transition name="fade">
            <div v-if="currentRoute">
                <div class="border border-gray-100 rounded-lg p-2">
                    <div class="flex flex-col-reverse lg:flex-row w-full border-t-2 border-b-2 border-primary">
                        <div class="relative flex w-full lg:w-[23rem] h-[51vh] overflow-hidden">
                            <div class="overflow-y-scroll lg:w-full">
                                <MapsSchedulesStopInfo v-if="selectedStops.length > 0" :stops="selectedStops"
                                                       :route="currentRoute"/>
                                <div v-else class="p-6 text-center text-base text-gray-700">
                                    Select a stop for details.
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow h-[51vh] min-h-[300px]">
                            <div class="w-full h-full overflow-hidden">
                                <RouteMap
                                    v-if="currentRoute && selectedSchedule?.shape"
                                    ref="map"
                                    class="w-full h-full"
                                    :route="currentRoute"
                                    :shape="mapShape"
                                    :zoom="mapZoom"
                                    :center-on-bounds="selectedStops.length === 0"
                                    :highlighted-stops="selectedStops"
                                    @ready="onMapReady"
                                    @onMarkerClicked="onMarkerClicked"
                                    hide-from-and-to
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { setGoogleApi } from "../../utils/google";
import RouteMap from "../RouteMap";
import Spinner from "../Spinner";
import MapsSchedulesStopInfo from "./MapsSchedulesStopInfo";
import { useStore } from "./store";

export default defineComponent({
    name: 'MapsSchedulesRouteMap',
    components: {Spinner, RouteMap, MapsSchedulesStopInfo},
    setup() {
        const store = useStore();
        const selectedSchedule = computed(() => store.state.selectedSchedule);
        const selectedStops = computed(() => store.state.selectedStops);
        const currentRoute = computed(() => store.state.routes.current);
        const map = ref(null);
        const mapZoom = ref(11);

        const mapShape = computed(() => {
            if (!selectedSchedule.value?.shape) {
                return null;
            }

            return {
                ...selectedSchedule.value.shape,
                stops: selectedSchedule.value.shape.allStops,
                originStop: null,
                destStop: null,
            };
        });

        function onMapReady(mapRef) {
            setGoogleApi(mapRef.value.api);

            if (selectedStops && selectedStops.value.length > 0) {
                const stop = selectedSchedule.value.shape.allStops.find((s) => s.code === selectedStops.value[0].code);
                if (stop) {
                    mapZoom.value = 16;
                    map.value.setMapCenter({
                        lat: parseFloat(stop.location.lat),
                        lng: parseFloat(stop.location.lng),
                    });
                }
            }
        }

        function onMarkerClicked(marker) {
            store.setSelectedStops([marker.stop]);
        }

        return {
            map,
            store,
            mapZoom,
            mapShape,
            currentRoute,
            selectedStops,
            selectedSchedule,
            onMapReady,
            onMarkerClicked,
        }
    },
});
</script>
