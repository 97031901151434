<template>
    <div class="border-b-8">
        <SectionTitle link="regional-emissions" color="green" class="mb-8"
            :title="$tx('Regional Emissions')"
            :subtitle="$tx('Maricopa County Transportation Sector GHG Emissions')" />
        <div class="flex flex-col max-w-6xl m-auto lg:pb-24">
            <ChartTableSwitch page-title="Regional Emissions">
                <template v-slot:chart>
                    <div class="flex-1 p-4">
                        <ChartRenderer :chart="BarChart" chartId="regional-emissions-chart" :chartData="chartData" :plugins="[ChartDataLabels]" :options="options" class="flex flex-1 h-[350px] md:h-450px] lg:h-[500px]" label="Regional Emissions Chart/Graph. Refer to data table for details." />
                    </div>
                </template>

                <template v-slot:table>
                    <div class="flex justify-center min-w-full mt-4">
                        <DataTable highlight-first v-bind="dataTable" />
                    </div>
                </template>
            </ChartTableSwitch>

            <div class="flex m-auto mt-12">
                <div class="max-w-lg text-center">
                    <InfoBox halign="left" color="qol4-full" class="mb-8 mt-4 md:mt-8"
                             icon="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/QOL_information-shape_green_CNG-powered.svg"
                             icon-classes="mb-[32px] h-[5rem] lg:mb-[58px] lg:h-[8rem]"
                    >
                        <template #content>
                            <span v-html="$tx('Transit ridership can continue to increase without creating more emissions. Valley drivers can help lower emission levels by reducing days spent in their personal vehicle and taking public transit.')"></span>
                        </template>
                    </InfoBox>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {isObject} from "lodash";
import {defineComponent, computed, inject} from 'vue';
import ChartRenderer from "../../../components/ChartRenderer";
import {useIsSmallScreen} from "../../../utils/helpers";
import ChartTableSwitch from "../Components/ChartTableSwitch";
import DataTable from "../Components/DataTable";
import InfoBox from "../Components/InfoBox";

import SectionTitle from '../Components/SectionTitle'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import { BarChart } from 'vue-chart-3';
import {generateDataLabelsSettings, generateTickSettings, generateTitleSettings} from "./chartsUtils";

export default defineComponent({
    name: 'RegionalEmissions',
    components: {
        ChartRenderer,
        InfoBox,
        DataTable,
        ChartTableSwitch,
        SectionTitle,
        ChartDataLabels,
        BarChart,
    },
    setup() {
        const tx = inject('tx')
        const isSmall = useIsSmallScreen()

        const chartData = computed(() => ({
            labels: [
                tx('Valley\nMetro'),
                tx('Locomotives'),
                tx('Airport'),
                tx('Nonroad\nVehicles'),
                tx('Trucks'),
                tx('Personal\nVehicles'),
            ],
            datasets: [
                {
                    data: [ // width = data, length = %
                        0.4,
                        0.5,
                        4.0,
                        12.1,
                        22.5,
                        60.4,
                    ],
                    dataFigures: [
                        '79,985',
                        '113,142',
                        '840,372',
                        '2,527,974',
                        '4,692,436',
                        '12,603,272',
                    ],
                    backgroundColor: [
                        'rgb(111, 65, 127)',
                        'rgb(92, 158, 213)',
                        'rgb(248, 199, 60)',
                        'rgb(247, 141, 30)',
                        'rgb(241, 91, 87)',
                        'rgb(124, 126, 134)',
                    ],
                },

            ],
        }));

        const options = computed(() => ({
            hover: { mode: null },
            plugins: {
                datalabels: generateDataLabelsSettings({
                    anchor: 'end',
                    align: 'end',
                    labels: {
                        value: {
                            formatter: (value) => {
                                if (isObject(value)) {
                                    return '';
                                }

                                return `${value}%`
                            }
                        }
                    },
                }),
                legend: false,
                tooltip: {
                    enabled: isSmall.value,
                    callbacks: {
                        label(context) {
                            return`${context.formattedValue}%`;
                        }
                    }
                },
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    title: generateTitleSettings({
                        text: tx('Transportation Mode'),
                    }),
                    ticks: generateTickSettings()
                },
                y: {
                    title: generateTitleSettings({
                        text: tx('Percentage'),
                    }),
                    ticks: generateTickSettings({
                        callback(value) {
                            return value + '%';
                        }
                    })
                }
            }
        }));

        const dataTable = computed(() => {
            const headings = {
                transport: tx('Transportation Mode'),
                percent: tx('Percent') + ' (%)',
            }

            const rows = chartData.value.labels.map((transport, idx) => ({
                transport,
                percent: chartData.value.datasets[0].data[idx] + '%',
            }));

            return { headings, rows };
        });

        return {
            BarChart,
            options,
            chartData,
            dataTable,
            ChartDataLabels,
         };

    }
});
</script>
