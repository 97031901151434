<template>
    <div class="relative w-full h-full max-w-screen-xl p-3 mx-auto news-entry bg-primary md:p-8 lg:p-16 xl:rounded-xl xl:mb-10">
        <AppHead :title="title">
            <meta head-key="description" name="description" :content="`${title} at ${formattedDate}`" />
        </AppHead>

        <EditButton :url="edit_url" />

        <div class="flex justify-end w-full mx-auto mb-2 max-w-7xl">
            <content-locale-selector :options="localizations" :locale="locale"/>
        </div>
        <div class="prose-2xl max-w-prose">
            <nav aria-label="breadcrumb" role="navigation" v-if="breadcrumbs" class="flex items-center mb-6 space-x-2 text-sm font-light uppercase">
                <template v-for="(bread, index) in breadcrumbs">
                    <span class="text-primary-gray">/</span>
                    <Link :aria-current="(index >= breadcrumbs.length - 1) ? 'page' : null" :href="bread.permalink" class="text-primary-gray hover:underline" v-bind="bread.options">{{ bread.title }}</Link>
                </template>
            </nav>
            <h1 class="block text-4xl leading-relaxed tracking-tight text-left text-white tk-museo sm:text-5xl">
                {{ title }}
            </h1>
        </div>
        <div class="relative w-full p-4 mx-auto mt-10 bg-white rounded-lg max-w-7xl sm:p-6 lg:p-8">
            <div class="mx-auto prose prose-lg">
                <div class="text-sm uppercase">
                    {{ formattedDate }}
                </div>
            </div>
            <div class="pt-4 mx-auto prose prose-lg text-gray-700">
                <SetsRenderer :sets="entry" />
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent} from "vue";
import AppHead from "../components/AppHead";
import ContentLocaleSelector from "../components/ContentLocaleSelector";
import EditButton from "../components/EditButton";
import {formatFromISO} from "../utils/date";
import {Link} from '@inertiajs/vue3';
import SetsRenderer from "../components/SetsRenderer";

export default defineComponent({
    name: "NewsEntry",
    components: {EditButton, AppHead, SetsRenderer, Link, ContentLocaleSelector},
    props: {
        title: {type: String, required: true},
        entry: {type: Array, required: true},
        date: {type: String, required: true},
        locale: {type: String, required: true},
        breadcrumbs: {type: Array, default: () => []},
        localizations: {type: Array, required: true},
        edit_url: {type: [String, null], default: null},
    },
    setup(props) {
        const formattedDate = formatFromISO(props.date, 'MMMM d, yyyy');

        return {
            formattedDate
        };
    }
});
</script>

<style scoped>

</style>
