<template>
    <div class="b-8">
        <SectionTitle link="emissions-saved" color="green" class="mb-12"
                      :title="$tx('Emissions Saved')"
                      :subtitle="$tx('Emission Impacts of Valley Metro\'s Transit Services')"/>
        <div class="flex flex-col w-full lg:flex-row p-4 md:p-12">
            <ChartTableSwitch page-title="Emissions Saved" view-table="View Tables" view-chart="View Charts">
                <template v-slot:chart>
                    <div class="flex flex-col space-y-8 w-full lg:space-y-0 lg:space-x-8 lg:flex-row">
                        <div class="flex flex-col lg:flex-1">
                            <h3 class="text-xl md:text-2xl">{{
                                    $tx('Criteria Pollutant Emissions & Savings (in tons)')
                                }}</h3>
                            <ChartRenderer :chart="BarChart" chartId="emissions-saved-criteria-pollutant"
                                           :chartData="chartData1" :options="options1"
                                           class="w-full h-[350px] md:h-450px]"
                                           label="Criteria Pollutants Emissions saved Chart/Graph. Refer to data table for details."/>
                        </div>
                        <div class="flex flex-col lg:flex-1">
                            <h3 class="text-xl md:text-2xl">{{
                                    $tx('Greenhouse Gas Emissions & Savings (in tons)')
                                }}</h3>
                            <ChartRenderer :chart="BarChart" chartId="emissions-saved-greenhouse-gas"
                                           :chartData="chartData2" :options="options2"
                                           class="w-full h-[350px] md:h-450px]"
                                           label="Greenhouse Gas Emissions saved Chart/Graph. Refer to data table for details."/>
                        </div>
                    </div>
                </template>

                <template v-slot:table>
                    <div class="flex flex-col space-y-8 w-full lg:space-y-0 lg:space-x-8 lg:flex-row">
                        <div class="flex flex-col lg:flex-1">
                            <h3 class="text-2xl">{{ $tx('Criteria Pollutant Emissions & Savings (in tons)') }}</h3>
                            <DataTable highlight-first v-bind="dataTable1" class="mt-4"/>
                        </div>
                        <div class="flex flex-col lg:flex-1">
                            <h3 class="text-2xl">{{ $tx('Greenhouse Gas Emissions & Savings (in tons)') }}</h3>
                            <DataTable highlight-first v-bind="dataTable2" class="mt-4"/>
                        </div>
                    </div>
                </template>
            </ChartTableSwitch>
        </div>
        <div class="m-auto mt-4 max-w-xl text-center mb-12">
            <InfoBox halign="left" color="qol4-full" class="mb-8 mt-8 md:mt-20"
                     icon="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/QOL_information-shape_green_emissions.svg">
                <template #content>
                    <div
                        v-html="$tx('By providing shared modes of transportation, Valley Metro <strong>displaces more emissions than we create.</strong>')"></div>
                </template>
            </InfoBox>
        </div>
    </div>
</template>

<script>
import {isNumber} from "lodash";
import {computed, defineComponent, inject} from 'vue';
import ChartRenderer from "../../../components/ChartRenderer";
import {useIsSmallScreen} from "../../../utils/helpers";
import ChartTableSwitch from "../Components/ChartTableSwitch";
import DataTable from "../Components/DataTable";

import SectionTitle from '../Components/SectionTitle';
import InfoBox from "../Components/InfoBox";

import {BarChart} from 'vue-chart-3';

// See link for registering chart types to allow for tree-shaking if desired
// https://vue-chart-3.netlify.app/guide/#chart-js-v3
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {Chart, registerables} from "chart.js";

Chart.register(...registerables);
Chart.register(ChartDataLabels);

import numeral from 'numeral';
import {generateDataLabelsSettings, generateTickSettings, generateTitleSettings} from "./chartsUtils";

export default defineComponent({
    components: {
        ChartRenderer,
        DataTable,
        ChartTableSwitch,
        SectionTitle,
        InfoBox,
        BarChart,
    },
    setup() {
        const tx = inject('tx')
        const isSmall = useIsSmallScreen()

        const chartData1 = computed(() => ({
            labels: [tx('VM Emissions'), tx('Regional Savings')],
            datasets: [
                {
                    label: tx('Criteria Pollutant Emissions & Savings (in tons)'),
                    backgroundColor: ['rgba(248, 199, 60, 0.85)', 'rgba(170, 205, 79, 0.85)'],
                    borderColor: 'rgb(132, 132, 132)',
                    data: [289, 7800],
                }
            ],
        }));

        const options1 = computed(() => ({
            hover: {mode: null},
            plugins: {
                legend: false,
                tooltip: {
                    enabled: isSmall.value,
                },
                datalabels: generateDataLabelsSettings({
                    anchor: 'end',
                    align: 'end',
                    color: '#000000',
                    labels: {
                        value: {
                            formatter: (value) => isNumber(value) ? Number(value).toLocaleString() : '',
                        }
                    }
                })
            },
            scales: {
                x: {
                    title: false,
                    ticks: generateTickSettings(),
                },
                y: {
                    title: generateTitleSettings({
                        text: tx('Tons'),
                    }),
                    ticks: generateTickSettings(),
                    stacked: true,
                    min: 0,
                    max: 9000,
                }
            },
            barThickness: 80,
            responsive: true,
        }));

        const chartData2 = computed(() => ({
            labels: [tx('VM Emissions'), tx('Regional Savings')],
            datasets: [
                {
                    label: tx('Greenhouse Gas Emissions & Savings (in tons)'),
                    backgroundColor: ['rgba(248, 199, 60, 0.85)', 'rgba(170, 205, 79, 0.85)'],
                    borderColor: 'rgb(132, 132, 132)',
                    data: [48000, 210000],
                }
            ],
        }));

        const options2 = computed(() => ({
            hover: {mode: null},
            plugins: {
                legend: false,
                tooltip: {
                    enabled: isSmall.value,
                },
                datalabels: generateDataLabelsSettings({
                    anchor: 'end',
                    align: 'end',
                    color: '#000000',
                    labels: {
                        value: {
                            formatter: (value) => isNumber(value) ? Number(value).toLocaleString() : '',
                        }
                    }
                }),
            },
            scales: {
                x: {
                    title: false,
                    ticks: generateTickSettings(),
                },
                y: {
                    title: generateTitleSettings({
                        text: tx('Tons'),
                    }),
                    ticks: generateTickSettings(),
                    stacked: true,
                    min: 0,
                    max: 230000,
                },
            },
            barThickness: 80,
            responsive: true,
        }));

        function buildDataTable(source) {
            const headings = {
                year: tx('Year'),
                tons: tx('Tons'),
            };

            const rows = source.labels.map((year, idx) => ({
                year,
                tons: isNumber(source.datasets[0].data[idx]) ? Number(source.datasets[0].data[idx]).toLocaleString() : null,
            }));

            return {headings, rows};
        }

        const dataTable1 = computed(() => buildDataTable(chartData1.value));
        const dataTable2 = computed(() => buildDataTable(chartData2.value));

        return {
            BarChart,
            chartData1,
            options1,
            chartData2,
            options2,
            dataTable1,
            dataTable2
        };
    }
});
</script>
