<template>
    <div class="mt-8 shadow-lg max-w-[320px] m-auto">
        <div class="flex items-center p-4 border border-gray-500">
            <img class="inline-block h-6 w-6 mr-4" alt="COVID" src="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/covid-icon.svg" role="img" />
            <p v-html="text"></p>
        </div>
    </div>
</template>

<script>
import {computed, defineComponent} from 'vue';
import useTranslations from "../translations";

export default defineComponent({
    name: 'CovidDisclaimer',

    setup() {
        const { translate } = useTranslations();

        const text = computed(() => translate.value('Indicates data impacted by<br/>the COVID-19 pandemic.', 'Indica los datos impactados <br/>por la pandemia del COVID-19'));

        return {
            text
        }
    }
})
</script>
