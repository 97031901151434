<template>
    <div class="main-content-title-container flex flex-col max-w-6xl mr-4 sm:mr-20" :class="containerColorClass">
        <div class="flex flex-row">
            <div class="flex h-[150px]">
                <div class="self-end text-right w-full px-2 sm:pl-0">
                    <div class="ml-0 w-[120px] border-2 border-gray-400 bg-gray-100 rounded-2xl rounded-b-none border-b-0 overflow-hidden sm:w-[250px] sm:ml-16">
                        <img class="-mb-[31px] object-cover sm:-mb-[68px]" :src="icon" alt="" role="img" />
                    </div>
                </div>
            </div>
            <div class="flex flex-1 self-end">
                <div class="self-end w-full">
                    <h2 class="p-1 sm:p-5 text-2xl sm:text-3xl" :class="{'pb-0 sm:pb-0': subheading}">{{ heading }}</h2>
                    <h3 class="pl-1 pb-1 text-xl sm:pl-5 sm:pb-5" v-if="subheading">{{ subheading }}</h3>
                </div>
            </div>
        </div>
        <div class="flex flex-row mb-5">
            <div class="title-container flex content-start border border-l-0 w-full rounded-br-3xl">
                 <h3 class="p-6 pl-5 font-bold text-2xl sm:pl-10 sm:text-3xl lg:text-5xl">{{ title }}</h3>
            </div>
        </div>
    </div>

</template>

<script>
import {computed, defineComponent} from 'vue';

export default defineComponent({
    name: 'SectionHeader',

    props: {
        icon: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        heading: {
            type: String,
            required: true,
        },
        subheading: {
            type: [String, null],
            default: null,
        },
        color: {
            type: String,
            required: true
        }
    },

    setup(props) {
        const containerColorClass = computed(() => {
            return `${props.color}`
        });

        return {
            containerColorClass,
        }
    },
});
</script>

<style lang="scss" scoped>
    .gray-purple {
        .title-container{

            @apply border-[#5a1769] bg-[#e6e6e6] drop-shadow-down;

            h3 {
                @apply text-[#5a1769];
            }
        }
    }

    .purple {
        .title-container{

            @apply border-[#5a1769] bg-[#ded0e0] drop-shadow-[0_20px_0_#5a1769];

            h3 {
                @apply text-[#5a1769];
            }
        }
    }

    .gray-orange {
        .title-container{

            @apply border-[#d7a77c] bg-[#e6e6e6] drop-shadow-down;

            h3 {
                @apply text-[#b96a16];
            }
        }
    }

    .orange {
        .title-container{

            @apply border-[#d7a77c] bg-[#fce8d3] drop-shadow-[0_20px_0_#f9ba78];

            h3 {
                @apply text-[#b96a16];
            }
        }
    }

    .gray-blue {
        .title-container{

            @apply border-[#33617a] bg-[#e6e6e6] drop-shadow-down;

            h3 {
                @apply text-[#186b98];
            }
        }
    }

    .blue {
        .title-container{

            @apply border-[#0e4767] bg-[#d0e5f4] drop-shadow-[0_20px_0_#186b98];

            h3 {
                @apply text-[#186b98];
            }
        }
    }

    .gray-green {
        .title-container{

            @apply border-[#556628] bg-[#e6e6e6] drop-shadow-down;

            h3 {
                @apply text-[#556628];
            }
        }
    }

    .green {
        .title-container{

            @apply border-[#556628] bg-[#ebf4d9] drop-shadow-[0_20px_0_#556628];

            h3 {
                @apply text-[#556628];
            }
        }
    }

</style>
