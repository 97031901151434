function getTripPlannerUrl (baseUrl, locationFrom, locationTo, mode, date, time) {
    if (!locationFrom.value.location || !locationTo.value.location) {
        return null;
    }

    const oname = locationFrom.value.value.replace('&', '%26');
    const olat = locationFrom.value.location.lat;
    const olng = locationFrom.value.location.lng;
    const dname = locationTo.value.value.replace('&', '%26');
    const dlat = locationTo.value.location.lat;
    const dlng = locationTo.value.location.lng;

    const dt = (date.value && date.value !== '-') ? date.value : '-';
    const tm = (time.value && time.value !== '-') ? time.value : '-';

    return `/${baseUrl}?oname=${oname}&olat=${olat}&olng=${olng}&dname=${dname}&dlat=${dlat}&dlng=${dlng}&mode=${mode.value}&dt=${dt}&tm=${tm}`;
}

export {
    getTripPlannerUrl,
}
