import Swal from 'sweetalert2';

export function usePagePopups() {
    function showPagePopup(heading, content, buttonLabel) {
        Swal.fire({
            title: `<strong>${heading || ''}</strong>`,
            icon: 'info',
            html:`<div class="prose prose-sm text-left">${content}</div>`,
            showConfirmButton: true,
            confirmButtonText: buttonLabel || '',
            focusConfirm: false,
            allowOutsideClick: false,
            allowEscapeKey: false
        });
    }

    async function queuePagePopups(popups) {
        for (let popup of popups) {
            await showPagePopup(popup?.heading, popup?.content, popup?.button_label);
        }
    }

    return { showPagePopup, queuePagePopups };
}
