<template>
    <div class="border-b-8">
        <SectionTitle link="rider-survey-results" color="blue"
            :title="$tx('Rider Survey Results')"
            :subtitle="$tx('Top Reasons for Using Transit Less Often 2020 vs. 2021')" />
        <div class="flex flex-col lg:flex-row p-6 md:p-12">
            <ChartTableSwitch page-title="Rider Survey Results: Reasons For Using Transit">
                <template v-slot:chart>
                    <div class="flex lg:flex-1 lg:pr-4 h-[350px] md:h-[450px] lg:h-[600px]">
                        <ChartRenderer :chart="BarChart" chartId="rider-survey-results-top-reasons-chart" :chartData="chartData" :options="options" class="w-full lg:mr-0 lg:ml-auto lg:origin-left" label="Rider Survey Results: Reasons for using transit less Chart/Graph. Refer to data table for details." />
                    </div>
                </template>

                <template v-slot:table>
                    <div class="flex justify-center min-w-full mt-4">
                        <DataTable highlight-first v-bind="dataTable" />
                    </div>
                </template>
            </ChartTableSwitch>
            <div class="flex m-auto lg:mr-auto lg:ml-0">
                <div class="max-w-sm text-center">
                    <InfoBox halign="left" color="qol3-full" class="mb-8 mt-20" textBoxClass="text-left">
                        <template #header>
                            <div class="w-full flex justify-center items-center pb-1 lg:pb-0 -mb-[2px] lg:mb-0">
                                <img alt="COVID" src="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/covid-icon.svg" class="relative h-8 lg:h-12" role="img"/>
                                <span class="ml-4">{{ $tx('Addressing Rider Concerns') }}</span>
                            </div>
                        </template>
                        <template #content>
                            <ul class="list-disc ml-8">
                                <li>
                                    {{ $tx('Implemented passenger limits and installed driver barrier.') }}
                                </li>
                                <li>
                                    {{ $tx('Shifted to rear-door boarding. Returned to front door boarding October 2021.') }}
                                </li>
                                <li>
                                    {{ $tx('Federal mask mandate extended to March 2022.') }}
                                </li>
                                <li>
                                    {{ $tx('Increased cleaning of high-touch surfaces. Implemented fogging of buses and light rail cars.') }}
                                </li>
                            </ul>
                        </template>
                    </InfoBox>

                    <CovidDisclaimer />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {isNumber} from "lodash";
import {computed, defineComponent, inject} from 'vue';
import ChartRenderer from "../../../components/ChartRenderer";
import ChartTableSwitch from "../Components/ChartTableSwitch";
import DataTable from "../Components/DataTable";
import InfoBoxIcon from "../Components/InfoBoxIcon";

import SectionTitle from '../Components/SectionTitle';
import InfoBox from "../Components/InfoBox";
import CovidDisclaimer from '../Components/CovidDisclaimer';

import { BarChart } from 'vue-chart-3';
// See link for registering chart types to allow for tree-shaking if desired
// https://vue-chart-3.netlify.app/guide/#chart-js-v3
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

import { useIsSmallScreen } from "../../../utils/helpers";
import {font12, font14, generateDataLabelsSettings, generateTickSettings, generateTitleSettings} from "./chartsUtils";

export default defineComponent({
    components: {
        ChartRenderer,
        InfoBoxIcon,
        DataTable,
        ChartTableSwitch,
        SectionTitle,
        InfoBox,
        BarChart,
        CovidDisclaimer,
    },
    setup() {

        const tx = inject('tx')
        const isSmallScreen = useIsSmallScreen()

        const labelformat = (context) => {
            return context.formattedValue + tx('% of responses')
        }

        const titleformat = (context) => {
            return context[0].label.replace(/[,\s]+/g, ' ')
        }

        const chartData = computed(() => ({
            labels: [
                [tx('Concerns about'), tx('social distancing')],
                [tx('Concerns regarding riders'), tx('wearing face coverings')],
                [tx('Cleanliness of buses, stops,'), tx('trains and stations')],
                [tx('Reductions or changes in'), tx('transit service'), tx('due to pandemic')],
            ],
            datasets: [
                {
                    label: '2020',
                    backgroundColor: 'rgb(89, 23, 105)',
                    data: [47,36,31,29],
                    datalabels: generateDataLabelsSettings({
                        color: '#ffffff',
                        labels: {
                            value: {
                                formatter: (value) => value + '%',
                            }
                        }
                    }),
                },
                {
                    label: '2021',
                    backgroundColor: 'rgb(170, 205, 79)',
                    data: [37,36,29,26],
                    datalabels: generateDataLabelsSettings({
                        color: '#000000',
                        labels: {
                            value: {
                                formatter: (value) => value + '%',
                            }
                        }
                    }),
                }
            ],
        }));

        const categoryAxis = computed(() => ({
            title: false,
            ticks: generateTickSettings({
                font: isSmallScreen.value ? font12 : font14,
            },)
        }))

        const valueAxis = computed(() => ({
            ticks: generateTickSettings({
                min: 0,
                max: 100,
                callback: (value) => isNumber(value) ? `${value}%` : '',
            }),
            title: generateTitleSettings({
                text: tx('Response'),
            }),
        }))

        const options = computed(() => ({
            hover: { mode: null },
            indexAxis: isSmallScreen.value ? 'x' : 'y',
            // Elements options apply to all of the options unless overridden in a dataset
            // In this case, we are setting the border of each horizontal bar to be 2px wide
            elements: {
                bar: {
                    borderWidth: 4,
                    borderColor: 'rgba(0,0,0,0)',
                    borderRadius: 10,
                }
            },
            plugins: {
                tooltip: {
                    enabled: isSmallScreen.value,
                    callbacks: {
                        label: labelformat,
                        title: titleformat,
                    },
                },
                legend: {
                    position: 'bottom',
                    labels: {
                        padding: 10,
                        boxWidth: 25,
                        boxHeight: 25,
                        font: {size: 20, weight: '500', family: 'museo-sans, Museo-sans, sans-serif, serif'},
                    },
                },
                datalabels: generateDataLabelsSettings({
                    font: isSmallScreen.value ? font12 : font14
                })
            },
            responsive: true,
            scales: {
                x: isSmallScreen.value ? categoryAxis : valueAxis,
                y: isSmallScreen.value ? valueAxis : categoryAxis,
            }
        }));

        const dataTable = computed(() => {
            const headings = {
                reason: tx('Reason'),
            };

            chartData.value.datasets.forEach((dataset) => {
                headings[dataset.label] = dataset.label;
            });

            const rows = chartData.value.labels.map((reason, idx) => {
                const row = { reason: reason.join(' ') };
                chartData.value.datasets.forEach((dataset) => row[dataset.label] = dataset.data[idx] + '%');
                return row;
            })

            return { headings, rows };
        });

        return { BarChart, chartData, options, dataTable };
    }
});
</script>
