import axios from "axios";
import {format} from "date-fns";
import {get, isInteger} from "lodash";
import {getNow} from "../utils/date.js";

/**
 * Format a url for trip planner.
 *
 * @param locationFrom
 * @param locationTo
 * @param mode
 * @param date
 * @param time
 * @returns {string|null}
 */
export function formatParamsToTripPlannerUrl(locationFrom, locationTo, date, time) {
    if (!locationFrom.value.location || !locationTo.value.location) {
        return null;
    }

    const from = encodeURIComponent(locationFrom.value.value);
    const to = encodeURIComponent(locationTo.value.value);
    const originLat = locationFrom.value.location.lat;
    const originLng = locationFrom.value.location.lng;
    const destLat = locationTo.value.location.lat;
    const destLng = locationTo.value.location.lng;

    const dt = (!date.value?.length || date.value === '-') ? format(getNow(), 'yyyy-MM-dd') : date.value;
    const tm = (!time.value?.length || time.value === '-') ? format(getNow(), 'HH:mm') : time.value;

    return `${from}/${originLat}/${originLng}/${to}/${destLat}/${destLng}/${dt}/${tm}`;
}

/**
 * Create a new API instance.
 */
export class Api {
    constructor(baseUrl, baseApiUrl, baseLaravelApiUrl, featureFlags) {
        this.baseUrl = baseUrl;
        this.baseApiUrl = baseApiUrl;
        this.baseLaravelApiUrl = baseLaravelApiUrl;
        this.featureFlags = featureFlags;
    }

    /**
     * Fetch the list of available trips given origin, destination and date.
     *
     * @param locationFrom
     * @param locationTo
     * @param mode
     * @param date
     * @param time
     * @returns {Promise<{routes, error: (*|null), fetched: boolean}|{routes: null, error: null, fetched: boolean}>}
     */
    async fetchTrips(locationFrom, locationTo, mode, date, time, transitRoutingPreference) {
        const params = formatParamsToTripPlannerUrl(locationFrom, locationTo, date, time);

        if (!params) {
            return {
                error: null,
                routes: null,
                fetched: false,
            };
        }

        const url = `${this.baseApiUrl}/trip-planner/${params}/${mode}?alternatives=true&time_zone=America%2FPhoenix&units=imperial&transit_routing_preference=${transitRoutingPreference}`;

        const {data: {errors, data: {routes}}} = await axios.get(url);

        return {
            error: (errors?.message) ? errors.message : null,
            fetched: true,
            routes,
        }
    }

    /**
     * Get routes as configured in the Control Panel.
     *
     * @returns {Promise<void>}
     */
    async getRoutesFromCP() {
        return await axios.get('/api/transit-book/routes')
            .then(response => response.data);
    }

    /**
     * Fetch routes.
     *
     * @returns {Promise<{data: any}>}
     */
    async getRoutes() {
        const {data: {data, refs, errors}} = await axios.get(`${this.baseApiUrl}/routes`);

        return {
            data,
            refs,
            fetched: true,
            error: (errors?.message)
                ? errors.message
                : null,
        }
    }

    async getRouteSchedules(routeSlug) {
        const {data: {data, refs, summary, errors}} = await axios.get(`${this.baseApiUrl}/schedules`, {
            params: {
                routeSlug
            }
        });

        return {
            data,
            refs,
            summary,
            fetched: true,
            error: (errors?.message)
                ? errors.message
                : null,
        }
    }

    async getRouteShapes(routeId, from, to) {
        const params = {
            routeId
        };

        if (from) {
            params.from = from;
        }

        if (to) {
            params.to = to;
        }

        const {data: {data, refs, summary, errors}} = await axios.get(`${this.baseApiUrl}/shapes`, {
            params
        });

        return {
            data,
            refs,
            summary,
            fetched: true,
            error: (errors?.message)
                ? errors.message
                : null,
        }
    }

    /**
     * Fetch service alerts.
     *
     * @returns {Promise<{data: [], refs: { routes: {id: string, shortName: string, longName: string, alertIndex: int}[] }, error: (*|null), fetched: boolean}>}
     */
    async getServiceAlerts() {
        const {data: {data, refs, errors}} = await axios.get(`${this.baseUrl}/api/alerts`);

        return {
            data,
            refs,
            fetched: true,
            error: (errors?.message)
                ? errors.message
                : null,
        }
    }

    async getTicketOutlets({searchTerm, lat, lng}) {
        const params = {};

        if (searchTerm) {
            params.qry = searchTerm;
        }

        if (lat && lng) {
            params.lat = lat;
            params.lng = lng;
        }

        return await axios.get(`${this.baseApiUrl}/ticket-outlets`, {params})
    }

    async getTransitCenters({searchTerm, lat, lng, name, address}) {
        const params = {};

        if (searchTerm) {
            params.qry = searchTerm;
        }

        if (name && address) {
            params.name = name;
            params.address = address;
        }

        if (lat && lng) {
            params.lat = lat;
            params.lng = lng;
        }

        return await axios.get(`${this.baseApiUrl}/transit-centers`, {params})
    }

    async getParkRides({searchTerm, lat, lng, name, address}) {
        const params = {};

        if (searchTerm) {
            params.qry = searchTerm;
        }

        if (name && address) {
            params.name = name;
            params.address = address;
        }

        if (lat && lng) {
            params.lat = lat;
            params.lng = lng;
        }

        // Show all results
        params.showall = true;

        // Skip empty results
        params.skipempty = true;

        return await axios.get(`${this.baseApiUrl}/parkride`, {params})
    }

    async getRideTextGroups() {
        const {data} = await axios.get(`${this.baseLaravelApiUrl}/ride-text/groups`);

        return {
            data,
        }
    }

    async subscribeToRideText({groupId, phoneNumber}) {
        const {data} = await axios.post(`${this.baseLaravelApiUrl}/ride-text/subscriptions`, {
            groupId,
            phoneNumber,
        });

        return {
            data,
        }
    }

    async getStopInfo({stopCode = null, lat = null, lng = null, includeRoutes = true, radius = null}) {
        if ((!lat && !lng) && (!stopCode || stopCode.length !== 5)) {
            return [];
        }

        const params = {
            includeRoutes,
        }

        if (stopCode) {
            params.codeQuery = stopCode;
        }

        if (lat) {
            params.lat = lat;
        }

        if (lng) {
            params.lng = lng;
        }

        if (radius?.length) {
            params.radius = radius;
        }

        const {data: {data}} = await axios.get(`${this.baseApiUrl}/stops`, {
            params,
        });

        return {
            data,
        }
    }

    async getStopRealtimeInfo({stopId, routeName}) {
        const {data: {data, summary}} = await axios.get(`${this.baseApiUrl}/realtime/stop/${stopId}`, {
            params: {
                type: 'stop',
                routeName,
                stopId,
            },
        });

        return {
            data,
            summary,
        }
    }

    async getEventsInCalendar({year, month}) {
        if (!isInteger(year)) {
            throw new Error('year parameter must be a number');
        }

        if (!isInteger(month) || month < 1 || month > 12) {
            throw new Error('month parameter must be a number between 1 and 12');
        }

        const {data} = await axios.get(`${this.baseUrl}/api/events-calendar`, {
            params: {year, month}
        });

        return {
            data,
        }
    }
}

export function installApiPlugin(options) {
    const api = new Api(options.baseUrl, options.endpoint, options.laravelEndpoint, options.featureFlags);

    return {
        api,
        install: (app) => {
            app.config.globalProperties.$api = api;
            app.provide('api', api);
        }
    }
}
