<template>
    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <div v-if="label" class="px-4 py-2 bg-white border-b border-gray-200 text-lg text-gray-800 font-bold">
            {{ label }}
        </div>
        <div class="overflow-x-auto">
            <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                <tr v-for="(hRow, hIndex) in headingRows" :key="hIndex" class="tableRow" :class="{highlightFirst}">
                    <th v-for="col in hRow"
                        :key="col.key"
                        v-bind="col.attrs"
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        v-html="col.label"
                    ></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(row, rIndex) in rows" :key="rIndex" class="tableRow" :class="{highlightFirst}">
                    <td v-for="(heading, hIndex) in headingRows[headingRows.length-1]" :key="heading.key"
                        class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-left"
                        :class="{'font-medium text-gray-900 text-left': hIndex === 0, 'text-gray-500': hIndex > 0}"
                        v-html="row[heading.key]"
                    ></td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<style scoped>
.tableRow.highlightFirst th:first-child,
.tableRow.highlightFirst td:first-child {
    @apply font-bold;
}
.tableRow:nth-child(even) {
    @apply bg-white;
}
.tableRow:nth-child(odd) {
    @apply bg-gray-50;
}
</style>

<script>
import {isObject} from "lodash/lang";
import {computed, defineComponent} from 'vue';

export default defineComponent({
    name: 'DataTable',
    props: {
        highlightFirst: {type: Boolean, default: false},
        /**
         * This should be:
         *
         * {
         *     theProperty: 'the label', // For a single heading
         *     theProperty: {            // For a grouped heading
         *          label: 'The label',
         *          classes: '...',
         *          children: {
         *              theProperty: 'The label',
         *          }
         *     }
         * }
         */
        headings: {type: Object, required: true},

        /**
         * @todo add description...
         */
        rows: {type: Array, required: true},

        label: {type: [String, null, undefined], default: undefined},
    },

    setup(props) {
        const headingRows = computed(() => {
            let rows = [[], []];

            for (let key in props.headings) {
                const heading = props.headings[key];
                const hasChildren = isObject(heading) && heading.hasOwnProperty('children');

                rows[0].push({
                    key,
                    label: isObject(heading) ? heading.label : heading,
                    attrs: {
                        colspan: hasChildren ? Object.keys(heading.children).length : 1,
                        class: (hasChildren ? 'text-center bg-gray-200' : '') + (isObject(heading) && heading.classes ? heading.classes : ''),
                    }
                });

                if (hasChildren) {
                    for (let childKey in heading.children) {
                        const childHeading = heading.children[childKey];
                        const classes = [(childKey === Object.keys(heading.children).pop()) ? 'border-r border-gray-200' : ''];

                        if (isObject(childHeading) && childHeading.classes) {
                            classes.push(childHeading.classes);
                        }

                        rows[1].push({
                            key: childKey,
                            label: isObject(childHeading) ? childHeading.label : childHeading,
                            attrs: {
                                class: classes.join(' ').trim(),
                            },
                        });
                    }
                }
            }

            return rows.filter((r) => r.length > 0);
        });

        return {
            headingRows,
        }
    },
});
</script>
