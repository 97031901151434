<template>
    <div class="border-b-8">
        <SectionTitle :title="content.title" :subtitle="content.subtitle" link="frequent-service-network" color="purple"
                      class="mb-8"/>
        <div class="flex flex-col m-auto lg:flex-row">
            <div class="flex-1 p-4 w-full max-w-4xl m-auto lg:ml-auto">
                <Image :alt="content.imageAlt" :src="content.imageSrc" class="block"/>
            </div>
            <div class="flex flex-col p-4 m-auto">
                <InfoBox halign="left" color="qol1-full" class="mb-8"
                         icon="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/QOL_information-shape_green_CNG-powered.svg"
                >
                    <template #content>
                        <p>{{ content.textBox1 }}</p>
                        <br>
                        <p>{{ content.textBox2 }}</p>
                    </template>
                </InfoBox>
            </div>
        </div>
    </div>
</template>

<script>
import {computed, defineComponent} from 'vue';
import Image from "../Components/Image";
import InfoBox from "../Components/InfoBox";
import SectionTitle from '../Components/SectionTitle'
import useTranslations from "../translations";

export default defineComponent({
    name: 'FrequentServiceNetwork',
    components: {
        Image,
        SectionTitle,
        InfoBox,
    },
    setup() {
        const {translate} = useTranslations();

        const content = computed(() => ({
            title: translate.value('Frequent Service Network', 'Red de Servicio Frecuente'),
            subtitle: translate.value('April 2021', 'Abril de 2021'),
            imageAlt: translate.value('Frequent Service Map', 'Mapa de Servicio Frecuente'),
            imageSrc: translate.value(
                'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/frequent-service-english.svg',
                'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/frequent-service-english.svg'
            ),
            textBox1: translate.value('This map highlights the frequent service routes throughout the Valley.', 'Este mapa destaca las rutas de servicio frecuente a través de todo el Valle.'),
            textBox2: translate.value('Frequent service is when transit vehicles run every 15 minutes or better based on the Valley Metro Transit Standards and Performance Measures.', 'El servicio frecuente es cuando los vehículos de transporte público circulan cada 15 minutos ó más frecuentemente o de acuerdo a los Estándares de Transporte y las Medidas de Desempeño de Valley Metro.'),
        }));

        return {content};
    }
});
</script>
