<template>
    <footer class="bg-gray-100" aria-labelledby="footer-heading">
        <div class="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
            <div class="md:grid md:grid-cols-2 md:gap-8">
                <div class="flex flex-col items-center space-y-8 md:col-span-1 md:items-start">
                    <img class="h-8"
                         src="https://drupal-space.nyc3.cdn.digitaloceanspaces.com/s3fs-public/vm-logorgb_horz-525-2300-c_163x40.png"
                         alt="Valley Metro">
                    <div class="my-8">
                        <div class="flex-1 mt-2">
                            <h2 class="text-lg font-normal leading-normal text-gray-800">Contact Us</h2>
                        </div><br>
                        <a href="mailto:csr@valleymetro.org" class="link link--button">
                            Email Us
                            <Icon class="w-4 h-4 ml-2 text-white" :icon="mdiChevronRight" aria-label="Arrow right icon"/>
                        </a>
                        <p class="mt-2 text-base font-normal tracking-wide text-gray-600">
	                        <a href="tel:16022535000" alt="call Valley Metro customer service at 6 0 2 2 5 3 5 0 0 0">
                                <strong>(602) 253-5000</strong>
                            </a>
                            <strong> or </strong>
                            <a href="tel:711" aria-label="call AZ Relay 711">
                                <strong>AZ Relay 711</strong>
                            </a>
                        </p>
                    </div>

                    <div class="flex space-x-6">
                        <a href="https://www.facebook.com/valleymetro" alt="follow Valley Metro on Facebook" target="_blank" class="text-gray-600 hover:text-gray-700">
                            <span class="sr-only">Facebook</span>
                            <Icon class="w-6 h-6" :icon="mdiFacebook" aria-hidden="true" aria-label="Facebook icon"/>
                        </a>
                        <a href="https://twitter.com/valleymetro" alt="follow Valley Metro on twitter" target="_blank" class="text-gray-600 hover:text-gray-700">
                            <span class="sr-only">Twitter</span>
                            <Icon class="w-6 h-6" :icon="mdiTwitter" aria-hidden="true" aria-label="Twitter icon"/>
                        </a>
                        <a href="https://www.instagram.com/valleymetro/" alt="follow Valley Metro on instagram" target="_blank" class="text-gray-600 hover:text-gray-700">
                            <span class="sr-only">Instagram</span>
                            <Icon class="w-6 h-6" :icon="mdiInstagram" aria-hidden="true" aria-label="Instagram icon" />
                        </a>
                        <a href="https://www.youtube.com/ValleyMetro" alt="follow Valley Metro on YouTube"  target="_blank" class="text-gray-600 hover:text-gray-700">
                            <span class="sr-only">YouTube</span>
                            <Icon class="w-6 h-6" :icon="mdiYoutube" aria-hidden="true" aria-label="Youtube icon" />
                        </a>
                        <a href="/webforms/valley_metro_email_notices" alt="Sign up for Valley Metro email notices" target="_blank" class="text-gray-600 hover:text-gray-700">
                            <span class="sr-only">Valley Metro email notices</span>
                            <Icon class="w-6 h-6" :icon="mdiMail" aria-hidden="true" aria-label="Mail icon" />
                        </a>
                        <a href="https://www.flickr.com/photos/valleymetro/albums" alt="follow Valley Metro on flicker" target="_blank" class="text-gray-600 hover:text-gray-700">
                            <span class="sr-only">Flicker</span>
                            <Icon class="w-6 h-6" :icon="mdiCameraBurst" aria-hidden="true" aria-label="Flicker icon" />
                        </a>
                    </div>
                </div>
                <div class="grid grid-cols-2 gap-8 mt-12 md:mt-0 md:col-span-1">
                    <div>
                        <ul role="list" class="space-y-4">
                            <li>
                                <a href="/" class="text-base text-gray-600 hover:text-gray-900">
                                    Home
                                </a>
                            </li>

                            <li>
                                <a href="/trip-planner" class="text-base text-gray-600 hover:text-gray-900">
                                    Trip Planner
                                </a>
                            </li>

                            <li>
                                <a href="/maps-schedules" class="text-base text-gray-600 hover:text-gray-900">
                                    Maps & Schedules
                                </a>
                            </li>

                            <li>
                                <a href="/fares" class="text-base text-gray-600 hover:text-gray-900">
                                    Fares
                                </a>
                            </li>

                            <li>
                                <a href="/rider-alerts" class="text-base text-gray-600 hover:text-gray-900">
                                    Rider Alerts
                                </a>
                            </li>

                            <li>
                                <a href="/how-to-ride" class="text-base text-gray-600 hover:text-gray-900">
                                    How to Ride
                                </a>
                            </li>

                            <li>
                                <a href="/news-events" class="text-base text-gray-600 hover:text-gray-900">
                                    News & Events
                                </a>
                            </li>

                            <li>
                                <a href="https://reservations.valleymetro.org" target="_blank" class="text-base text-gray-600 hover:text-gray-900">
                                    ADA Paratransit Trip Reservation
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul role="list" class="space-y-4">
                            <li>
                                <a href="/about" class="text-base text-gray-600 hover:text-gray-900">
                                    About Valley Metro
                                </a>
                            </li>

                            <li>
                                <a href="/blog" class="text-base text-gray-600 hover:text-gray-900">
                                    Inside the Ride Blog
                                </a>
                            </li>

                            <li>
                                <a href="/contact" class="text-base text-gray-600 hover:text-gray-900">
                                    Contact Us
                                </a>
                            </li>

                            <li>
                                <a href="/careers" class="text-base text-gray-600 hover:text-gray-900">
                                    Careers
                                </a>
                            </li>

                            <li>
                                <a href="/transit-education" class="text-base text-gray-600 hover:text-gray-900">
                                    Transit Education
                                </a>
                            </li>

                            <li>
                                <a href="/commute-solutions" class="text-base text-gray-600 hover:text-gray-900">
                                    Commute Solutions
                                </a>
                            </li>

                            <li>
                                <a href="/accessibility" class="text-base text-gray-600 hover:text-gray-900">
                                    Accessibility
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="mt-12 space-y-4 text-sm text-gray-600">
                <h3 class="text-base font-bold">Civil Rights Protections</h3>

                <p>Valley Metro is committed to operating its programs and services without regard to disability, race,
                    color or national origin, and encourage diversity on our committees and advisory boards, in
                    accordance with applicable federal statutes:</p>
                <p>Title II of the Americans with Disabilities Act | <a class="link"
                                                                        href="/accessibility/ada-policy-statement">ADA&nbsp;Public
                    Notice/Policy Statement </a> | <a class="link" href="/webforms/ada_complaint">ADA Complaint
                    Process</a></p>
                <p>Title VI of the Civil Rights Act | <a class="link" href="/about/civil-rights"
                                                         rel="noopener noreferrer" target="blank">Title VI Public
                    Notice/Policy Statement</a> | <a class="link" href="/webforms/title_vi_complaint">Title VI
                    Complaint Process</a></p>
            </div>
            <div class="pt-8 mt-12 border-t border-gray-200">
                <div class="mt-8 text-sm text-gray-600 xl:text-center">
                    &copy; Copyright {{ year }} Valley Metro. All Rights Reserved. | <a href="/privacy-notice" class="link">Privacy Notice</a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import {mdiChevronRight, mdiFacebook, mdiInstagram, mdiTwitter, mdiYoutube, mdiMail, mdiCameraBurst} from '@mdi/js';
import Icon from "./Icon";

export default {
    name: "SiteFooter",
    components: {Icon},

    setup() {
        const year = (new Date).getFullYear();

        return {
            year,
            mdiChevronRight,
            mdiTwitter,
            mdiFacebook,
            mdiInstagram,
            mdiYoutube,
            mdiMail,
            mdiCameraBurst
        }
    }
}
</script>
