<template>
    <div class="flex gap-2" v-if="options.length > 1" role="list">
        <Link
            v-for="option in options"
            :href="option.permalink"
            :aria-current="option.key === locale"
            role="listitem"
            class="px-3 py-1 text-black rounded focus:ring-0 focus:outline-2 border-transparent hover:border-white focus:outline-white"
            :class="{
            'bg-transparent text-white': option.key !== locale,
            'bg-white text-primary': option.key === locale
        }">
            {{ option.label }}
        </Link>
    </div>
</template>

<script>
import {Link} from '@inertiajs/vue3';

export default {
    name: 'ContentLocaleSelector',
    components: {Link},
    props: {
        options: {
            required: true,
            type: Array,
        },
        locale: {
            required: true,
            type: String
        }
    }
}
</script>
