<template>
    <select @change="onChange" v-bind="$attrs" class="form-select" :class="{'form-input shadow rounded border-gray-500 hover:border-gray-700 focus:ring-2 focus:ring-offset-2 focus:border-gray-700 focus:ring-gray-700': !unstyled}">
        <template v-for="option in options">
            <option :value="option.value" :selected="modelValue === option.value" :disabled="option.disabled">
                {{ option.label }}
            </option>
        </template>
    </select>
</template>

<script>
export default {
    name: "InputSelect",

    props: {
        modelValue: {
            type: String,
            default: '',
            required: false,
        },
        options: {
            type: Array,
            required: true,
        },
        unstyled: {
            type: Boolean,
            required: false,
            default: false,
        }
    },

    setup(props, { emit }) {
        function onChange(ev) {
            emit('update:modelValue', ev.target.value);
        }

        return {
            onChange,
        }
    },
}
</script>

<style scoped>

</style>
