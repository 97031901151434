<template>
    <div class="bg-gray-100 text-black text-base font-medium p-4 my-6 rounded">
        <div v-if="field.instructions_position === 'above' && field.instructions && field.instructions.length > 0" class="text-gray-700 text-sm font-normal">
            {{ field.instructions }}
        </div>
        <div class="text-black text-lg font-bold">
            {{ field.display }}
        </div>
        <div v-if="field.instructions_position === 'below' && field.instructions && field.instructions.length > 0" class="text-gray-700 text-sm font-normal">
            {{ field.instructions }}
        </div>
    </div>
</template>

<script setup>
import {defineField} from "./BaseField";

const field = defineField();
</script>
