<template>
    <img v-if="src && src.length > 0" :src="src" :alt="alt || ''" role="img" />
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'Image',
    props: {
        src: { type: [String, null], default: '' },
        alt: { type: [String, null], default: '' },
    },

    setup() {
        return {}
    },
});
</script>
