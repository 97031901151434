import { Loader } from '@googlemaps/js-api-loader';
import {ref, watch} from 'vue';

/**
 * Load the Google Map's Javascript file.
 *
 * @param apiKey
 * @returns {Promise<unknown>}
 */
function loadGoogleJavascript(apiKey) {
    const loader = new Loader({
        apiKey,
        version: 'weekly',
        libraries: ['places', 'geometry'],
    });

    return loader.load();
}

// --------------------------------------------------------------------------
// GOOGLE API
// --------------------------------------------------------------------------

const googleApi = ref(null);

/**
 * Set the Google API instance.
 *
 * @param api
 */
function setGoogleApi(api) {
    googleApi.value = api;
}

/**
 * Add a hook to be executed when Google API is set.
 *
 * @param hook
 */
function onGoogleApiSet() {
    return new Promise((resolve) => {
        if (googleApi.value) {
            resolve(googleApi.value);
            return;
        }

        const watcher = watch(googleApi, () => {
            resolve(googleApi.value);

            watcher();
        });
    });
}

/**
 * @returns {{googleApi: null}}
 */
function useGoogleApi () {
    return {
        googleApi,
        onGoogleApiSet,
    }
}

// --------------------------------------------------------------------------
// AUTOCOMPLETE SERVICE
// --------------------------------------------------------------------------

let autocompleteService = null;

/**
 * Create a new Autocomplete session token.
 *
 * @returns {*}
 */
function createAutocompleteSessionToken() {
    if (!googleApi.value?.places) {
        return;
    }

    return new googleApi.value.places.AutocompleteSessionToken();
}

/**
 * Get the autocomplete service.
 * @returns {null}
 */
function getAutocompleteService() {
    return new Promise((resolve, reject) => {
        if (autocompleteService) {
            resolve(autocompleteService);
            return;
        }

        if (! googleApi.value?.places) {
            reject('No Places API not set');
        }

        if (! autocompleteService) {
            autocompleteService = new googleApi.value.places.AutocompleteService();
        }

        resolve(autocompleteService);
    });
}

/**
 * @returns {{autocompleteService: *}}
 */
function useAutocompleteService () {
    return {
        createAutocompleteSessionToken,
        getAutocompleteService,
    }
}

// --------------------------------------------------------------------------
// PLACES SERVICE
// --------------------------------------------------------------------------

const placesServices = {};

/**
 * Get a places service instance.
 *
 * @param key
 * @param element
 * @returns {*}
 */
function getPlacesService(key, element) {
    return new Promise((resolve, reject) => {
        if (placesServices.hasOwnProperty(key)) {
            resolve(placesServices[key]);
            return;
        }

        if (!googleApi.value?.places) {
            reject('No Places API available');
        }

        if (! placesServices.hasOwnProperty(key)) {
            placesServices[key] = new googleApi.value.places.PlacesService(element);
        }

        resolve(placesServices[key]);
    });
}

/**
 * @param key
 * @param elementRef
 * @returns {{placesService: *}}
 */
function usePlacesService (key, elementRef) {
    return {
        getPlacesService: () => getPlacesService(key, elementRef.value)
    }
}

// --------------------------------------------------------------------------
// HELPER METHODS
// --------------------------------------------------------------------------

function isPlaceInsidePolygonArea(place) {
    if (! googleApi.value) {
        throw new Error('googleApi not set');
    }

    return googleApi.value.geometry.poly.containsLocation(
        place.geometry.location,
        new googleApi.value.Polygon({ paths: AREA_POLYGON_DATA })
    );
}

async function getPlacePredictions(sessionToken, bounds, input) {
    if (! googleApi.value) {
        throw new Error('googleApi not set');
    }

    if (! autocompleteService) {
        throw new Error('AutocompleteService not available');
    }

    return new Promise((resolve) => {
        autocompleteService.getPlacePredictions({
            input,
            sessionToken,
            bounds,
            strictBounds: true,
            componentRestrictions: {
                country: 'us',
            },
        }, (predictions, status) => {
            if (status !== googleApi.value.places.PlacesServiceStatus.OK || !predictions) {
                resolve([]);
                console.error(`Unable to get place predictions: ${status}`);
            }

            resolve(predictions);
        });
    });
}

async function getPlaceDetails(sessionToken, placesService, placeId) {
    if (! googleApi.value) {
        throw new Error('googleApi not set');
    }

    return new Promise((resolve, reject) => {
        placesService.getDetails({
            placeId,
            sessionToken,
            fields: ['name', 'geometry.location', 'place_id', 'formatted_address'],
        }, (place, status) => {
            if (status !== googleApi.value.places.PlacesServiceStatus.OK || !place) {
                reject(status);
            }

            resolve(place);
        });
    });
}

async function searchPlace({ query, bounds, placesService, sessionToken }) {
    sessionToken = sessionToken || createAutocompleteSessionToken();
    const predictions = await getPlacePredictions(sessionToken, bounds, query)

    return predictions.map((prediction) => ({
            title: prediction.description,
            place_id: prediction.place_id,
            main_text: prediction.structured_formatting.main_text,
            secondary_text: prediction.structured_formatting.secondary_text,
    }))
}

// ---------------------------------------------------------------------------------

const AREA_POLYGON_DATA = [
    {lat: 33.7836397, lng: -112.1470237},
    {lat: 33.7950529, lng: -112.4134421},
    {lat: 33.4932336, lng: -112.460134},
    {lat: 33.4634518, lng: -112.6386618},
    {lat: 33.0638502, lng: -112.729299},
    {lat: 32.9187169, lng: -112.8034568},
    {lat: 32.5536842, lng: -112.9627585},
    {lat: 32.32188, lng: -112.9682517},
    {lat: 32.3474076, lng: -112.8007102},
    {lat: 32.6439261, lng: -112.8062033},
    {lat: 33.1236769, lng: -112.5315451},
    {lat: 33.2340191, lng: -112.2816062},
    {lat: 33.1903586, lng: -111.842153},
    {lat: 33.2500991, lng: -111.605947},
    {lat: 33.4955241, lng: -111.6196799},
    {lat: 33.7242662, lng: -111.8668723},
    {lat: 33.7836397, lng: -112.1470237},
];

export {
    AREA_POLYGON_DATA,

    loadGoogleJavascript,
    setGoogleApi,

    useGoogleApi,
    usePlacesService,
    useAutocompleteService,

    searchPlace,
    getPlaceDetails,
    getPlacePredictions,
    isPlaceInsidePolygonArea,
}
