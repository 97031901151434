<template>
    <div class="pb-16 border-b-8">
        <SectionTitle :title="$tx('Affordability of Transportation')" :subtitle="$tx('January-December, 2021')" link="affordability-of-transportation" color="blue" class="mb-8" />
        <div class="max-w-6xl m-auto">
            <ChartTableSwitch page-title="Affordability of Transportation">
                <template v-slot:chart>
                    <div class="pb-4 px-4">
                        <h3 class="text-2xl text-center">{{ $tx('Income Spent on Transportation') }}</h3>
                        <ChartRenderer :chart="BarChart" chartId="affordability-of-transportation-chart" :chartData="chartData" :plugins="[ChartDataLabels]" :options="options" class="sm:m-auto w-full h-[350px] md:h-450px] lg:h-[500px]"
                            label="Income spent on transportation Chart/Graph. Refer to data table for details." />
                    </div>
                </template>

                <template v-slot:table>
                    <div class="flex justify-center min-w-full mt-4 px-6">
                        <DataTable highlight-first v-bind="dataTable" class="w-full" />
                    </div>
                </template>
            </ChartTableSwitch>
        </div>
        <div class="m-auto mt-0 max-w-xl text-center md:mb-12 md:mt-20">
            <InfoBox halign="left" color="qol3-full" class="md:mb-8"
                     icon="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/cost_of_congestion_3.svg"
                     icon-classes="mb-[32px] h-[6rem] lg:mb-[58px] lg:h-[8rem]"
            >
                <template #content>
                    {{ $tx('On average, Americans spend $10,742 per year on transportation.') }}
                </template>
            </InfoBox>
        </div>
    </div>
</template>

<script>
import {computed, defineComponent, inject} from 'vue';
import ChartRenderer from "../../../components/ChartRenderer";
import {useIsSmallScreen} from "../../../utils/helpers";
import ChartTableSwitch from "../Components/ChartTableSwitch";
import DataTable from "../Components/DataTable";

import SectionTitle from '../Components/SectionTitle';
import InfoBox from "../Components/InfoBox";
// import InfoBoxIcon from "../Components/InfoBoxIcon";
import CovidDisclaimer from '../Components/CovidDisclaimer';

import { BarChart } from 'vue-chart-3';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart, registerables } from "chart.js";
import {font12, generateDataLabelsSettings, generateTickSettings, generateTitleSettings} from "./chartsUtils";
Chart.register(...registerables);

export default defineComponent({
    components: {
        ChartRenderer,
        DataTable,
        ChartTableSwitch,
        SectionTitle,
        CovidDisclaimer,
        BarChart,
        ChartDataLabels,
        InfoBox,
    },
    setup() {
        const isSmall = useIsSmallScreen();
        const tx = inject('tx');

        const responsiveTitleFont = (context, valueToSubtract = 0) => {
            let size = 20

            if (context.chart.width < 768) {
                size = 16
            }

            return { size: size - valueToSubtract, weight: '500', family: 'museo-sans, Museo-sans, sans-serif, serif' }
        }

        const chartData = computed(() => ({
            labels: [
                tx('Phoenix'),
                tx('Dallas'),
                tx('Portland'),
                tx('San Diego'),
                tx('Salt Lake City'),
                tx('Denver'),
            ],
            datasets: [
                {
                    backgroundColor: [
                        'rgba(111, 65, 127, 0.85)', // Phoenix
                        'rgba(247, 141, 30, 0.85)', // Dallas
                        'rgba(241, 91, 87, 0.85)', // Portland
                        'rgba(248, 199, 60, 0.85)', // San Diego
                        'rgba(92, 158, 213, 0.85)', // Salt Lake City
                        'rgba(170, 205, 79, 0.85)', // Denver
                    ],
                    dataFigures: [ // width = %, length = data
                        '$12,776', // Phoenix
                        '$10,146', // Dallas
                        '$9,696', // Portland
                        '$11,310', // San Diego
                        '$10,146', // Salt Lake City
                        '$12,749', // Denver
                    ],
                    data: [ // width = data, length = %
                        20.5, // % Phoenix
                        16.5, // % Dallas
                        15.5, // % Portland
                        14.5, // % San Diego
                        15.5, // % Salt Lake City
                        17.5, // % Denver
                    ],
                    datalabels: generateDataLabelsSettings({
                        labels: {
                            name: {
                                color: 'rgb(17, 24, 39)',
                                font: (context) => responsiveTitleFont(context, 0),
                                formatter: ((name, context) => context.chart.data.labels[context.dataIndex] + (context.chart.width > 500 ? '\n' : '')),
                            },
                            value: {
                                color: 'rgb(107, 114, 128)',
                                font: (context) => responsiveTitleFont(context, 2),
                                formatter: (value, context) => {
                                    const amount = context.dataset.dataFigures[context.dataIndex];

                                    return `\n${amount} (${value}%)`;
                                },
                            },
                        },
                    }),
                },
            ],
        }));

        const options = computed(() => ({
            hover: {mode: null},
            layout: {
                padding: 20,
            },
            borderWidth: 0,
            plugins: {
                datalabels: generateDataLabelsSettings({
                    labels: {
                        name: {
                            anchor: 'end',
                            align: 'end',
                        },
                        value: {
                            anchor: 'end',
                            align: 'end',
                        },
                    },
                    offset: 10,
                }),
                legend: false,
                tooltip: {
                    enabled: isSmall.value,
                    callbacks: {
                        label(context) {
                            const amount = context.dataset.dataFigures[context.dataIndex];

                            return `\n${amount} (${context.formattedValue}%)`;
                        }
                    }
                },
            },
            responsive: true,
            height: 300,
            scales: {
                x: {
                    title: generateTitleSettings({
                        text: tx('Cities'),
                    }),
                    ticks: generateTickSettings({
                        display: isSmall.value,
                        font: font12,
                    }),
                },
                y: {
                    min: 10,
                    max: 24,
                    title: generateTitleSettings({
                        text: tx('Percentage'),
                    }),
                    ticks: generateTickSettings({
                        callback: (value) => {
                            return value + '%';
                        }
                    }),
                }
            },
        }));

        const dataTable = computed(() => {
            const headings = {
                city: tx('City'),
                amount: tx('Amount'),
                percent: tx('Percent'),
            };

            const rows = chartData.value.labels.map((city, idx) => ({
                city,
                amount: chartData.value.datasets[0].dataFigures[idx],
                percent: Number(chartData.value.datasets[0].data[idx]).toLocaleString() + '%',
            })).filter((r) => !!r.city);

            return { headings, rows };
        });

        return { BarChart, chartData, options, ChartDataLabels, dataTable };
    }
});
</script>
