<template>
    <div class="flex flex-wrap w-full p-1 overflow-hidden items-center shadow rounded bg-white border border-gray-500 ring-0 hover:border-gray-700">
        <div v-if="$slots?.label || label" class="w-full py-2 px-4 text-gray-800 text-lg font-bold lg:w-auto lg:flex-0">
            <template v-if="$slots?.label">
                <slot name="label"/>
            </template>
            <template v-else>
                {{ label }}
            </template>
        </div>
        <div class="flex-1 flex flex-col items-center space-y-1 md:space-y-0 md:space-x-1 md:flex-row">
            <MapInputText
                ref="inputEl"
                @input="onInput"
                @keydown="onKeyDown"
                :value="value"
                :name="name"
                :placeholder="placeholder"
                :title="title || label || placeholder"
                :role="role"
                :aria-controls="ariaControls"
                :aria-label="placeholder"
                :aria-autocomplete="ariaAutocomplete"
                :aria-activedescendant="ariaActivedescendant"
                autocomplete="off"
                unstyled class="w-full flex-1 py-2 px-4 rounded border-0 shadow-0 ring-0 text-black hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
            ></MapInputText>
            <PrimaryButton v-if="!hideButton" type="submit" class="w-full md:w-auto">{{ button }}</PrimaryButton>
        </div>
    </div>
</template>

<script>
import {ref} from "vue";
import MapInputText from "./MapInputText";
import PrimaryButton from "./PrimaryButton";
import {ComboboxInput} from "@headlessui/vue";

export default {
    name: "MapSearchBox",
    components: {ComboboxInput, PrimaryButton, MapInputText},
    props: {
        value: {
            type: String,
            required: false,
            default: '',
        },
        name: {
            required: true,
            type: String,
        },
        title: {
            required: false,
            type: [String, null],
            default: null,
        },
        label: {
            required: false,
            type: [String, null],
            default: null,
        },
        placeholder: {
            required: false,
            type: [String, null],
            default: 'Search...',
        },
        hideButton: {
            type: Boolean,
            default: false,
        },
        button: {
            required: false,
            type: String,
            default: 'Search',
        },
        role: {
            required: false,
            type: String,
            default: '',
        },
        ariaControls: {
            type: String,
            required: false,
            default: '',
        },
        ariaActivedescendant: {
            type: String,
            required: false,
            default: '',
        },
        ariaAutocomplete: {
            type: String,
            required: false,
            default: 'none',
        },
    },

    setup(props, { emit }) {
        const inputEl = ref();

        function onInput (value) {
            emit('input', value);
        }

        function focusOnInput () {
            inputEl.value.$el.focus();
        }

        function onKeyDown(ev) {
            ev.stopPropagation();
            emit('keydown', ev);
        }

        return { inputEl, onInput, onKeyDown, focusOnInput };
    }
}
</script>
