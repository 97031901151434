<template>
    <div class="border-b-8">
        <SectionTitle link="alternative-transit-modes" color="purple" :title="content.title" :subtitle="content.subtitle" class="mb-8" />

        <div class="flex flex-col m-auto lg:flex-row">

          <div class="flex flex-col p-4 lg:p-12 lg:ml-auto lg:mr-4">
            <div class="relative w-fit m-auto">

              <Image :src="content.rightImage" :alt="content.rightAlt" />

              <div class="left-image-container absolute top-0 left-0 bottom-0 overflow-hidden" :style="{ width: 'calc(' + sliderPosition + ' - 4px)' }">
                <Image class="w-auto h-full max-w-none" :src="content.leftImage" :alt="content.leftAlt" />
              </div>

              <label class="sr-only" for="image-comparison-slider-alternative-transit-modes">Image Comparison Slider</label>
              <input id="image-comparison-slider-alternative-transit-modes" title="Image Comparison Slider" type="range" min="1" max="100" value="75" class="slider border-2 border-gray-500 absolute top-0 left-0 bottom-0 right-0 overflow-hidden" @input="updateValue">
              <div class="slider-button bg-white border-2 border-gray-500" :style="{ left: 'calc(' + sliderPosition + ' - 6px)', transform: 'translate(-50%)' }"></div>

            </div>

            <p class="text-center p-2">
              {{ content.sliderLegend }}
            </p>
          </div>

          <div class="flex flex-col p-4 sm:p-12 m-auto lg:ml-4 lg:mr-auto">
            <InfoBox halign="left" color="qol1-full" class="mb-8"  icon="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/van.svg">
              <template #content>
                  {{ content.infoBox1 }}
              </template>
            </InfoBox>
            <InfoBox halign="left" color="qol1-full" class="mb-8 lg:mt-20"  icon="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/Buildings_Icon.svg">
              <template #content>
                {{ content.infoBox2 }}
              </template>
            </InfoBox>
          </div>

        </div>

    </div>
</template>

<script>
import {computed, defineComponent, inject, ref} from 'vue';
import Image from "../Components/Image";

import InfoBox from '../Components/InfoBox';

import SectionTitle from '../Components/SectionTitle';
import useTranslations from "../translations";

export default defineComponent({
    components: {
        Image,
        SectionTitle,
        InfoBox,
    },
    setup() {
        const { translate } = useTranslations();

      const sliderPosition = ref('75%')

      const updateValue = (to) => {
        sliderPosition.value = to.target.value + '%'
      }

      const content = computed(() => ({
          leftImage: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/data-graphs/july-2021-vanpool-home-end-heat-map.png',
          leftAlt: translate.value('Heat maps showing vanpool destinations for work and home.', 'Mapas de calor que muestran los destinos de los viajes compartidos en camioneta al trabajo y el hogar.'),
          rightImage: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/data-graphs/july-2021-vanpool-work-end-heat-map.png',
          rightAlt: ' ',
          title: translate.value('Vanpool Origins and Destinations', 'Orígenes y Destinos de Viajes Compartidos en Camioneta'),
          subtitle: translate.value('Vanpool Concentrations July 2021', 'Concentraciones de los Viajes Compartidos en Camioneta, Julio de 2021'),
          infoBox1: translate.value('Vanpool retained the highest ridership during the pandemic compared to other Valley Metro service modes.', 'Los viajes compartidos en camioneta retuvieron el mayor número de pasajeros durante la pandemia en comparación con otros modos de servicio de Valley Metro.'),
          infoBox2: translate.value('The vanpool program provides alternative transit options for high employment areas, including destinations outside of Maricopa County.', 'El programa de viajes compartidos en camioneta provee opciones alternativas de transporte para áreas con alta cantidad de empleos, incluyendo a destinos fuera del Condado Maricopa.'),
          sliderLegend: translate.value('Move slider side-to-side to compare vanpool home and work maps.', 'Mueva el control deslizante de lado a lado para comparar los mapas de los viajes compartidos en camioneta en el hogar y el trabajo.'),
      }))

      return { content, sliderPosition, updateValue }
    }
});
</script>

<style scoped lang="scss">

@mixin center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  background: none;
  outline: none;
  margin: 0;
  @include center;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 4px;
    height: 10000px;
    background: #5D5D5D;
    cursor: pointer;
  }
  &::-moz-range-thumb {
    width: 4px;
    height: 10000px;
    background: #5D5D5D;
    cursor: pointer;
  }
}

.slider-button {
  $size: 30px;
  pointer-events: none;
  position: absolute;
  width: $size;
  height: $size;
  border-radius: 50%;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  @include center;

  @mixin arrow-helper() {
    content: '';
    padding: 3px;
    display: inline-block;
    border: solid #5D5D5D;
    border-width: 0 2px 2px 0;
  }
  &:after {
    @include arrow-helper();
    transform: rotate(-45deg);
  }
  &:before {
    @include arrow-helper();
    transform: rotate(135deg);
  }
}
</style>
