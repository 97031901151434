<template>
    <div class="relative w-full mx-auto tabs max-w-7xl">
        <!-- Content -->
        <div :class="{
            'card bg-white rounded-xl p-3': tabsStyle === 'card',
            'grid-of-cards': tabsStyle === 'grid_of_cards',
            'grid-of-cards-with-light-bg bg-white rounded-xl p-3': tabsStyle === 'grid_of_cards_with_light_bg',
            'default': tabsStyle === 'default'
        }">
            <div v-if="shouldRenderTabs" class="px-2 pb-2 space-x-1 md:hidden">
                <v-select class="bg-white rounded" :options="items" :clearable="false" label="title" :value="selectedItem" @option:selected="setSelectedItem"></v-select>
            </div>

            <TabGroup v-if="shouldRenderTabs" :selectedIndex="selectedIndex" @change="setSelectedIndex">
                <TabList class="hidden px-2 space-x-2 overflow-auto md:flex">
                    <Tab v-for="(item, index) in items" :key="index" as="template" v-slot="{ selected }">
                        <button type="button" :class="[
                          'w-full flex items-center justify-center px-6 py-3 rounded-t-lg md:w-auto',
                          'text-lg leading-8 font-medium',
                          'focus:bg-white focus:ring-white focus:ring-offset-primary-500 focus:ring-offset-2 focus:ring-2',
                          selected ? 'bg-white shadow border border-primary-gray text-secondary' : 'text-gray-600 bg-primary-gray hover:bg-white hover:text-secondary',
                        ]">
                            {{ item.title }}
                        </button>
                    </Tab>
                </TabList>

                <TabPanels class="relative w-full bg-white rounded-lg" :class="{'with-tab-list border border-primary-gray': shouldRenderTabs, 'bg-white rounded-xl': !shouldRenderTabs}">
                    <TabPanel
                        v-for="(item, index) in items"
                        :key="index"
                        :class="[
                            'bg-white rounded-xl p-3',
                            'focus:outline-none focus:ring-0 ring-0',
                        ]">
                        <TabChild v-bind="item" />
                    </TabPanel>
                </TabPanels>
            </TabGroup>

            <div v-else-if="items.length > 0">
                <div class="p-3 section-bg ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60 focus:outline-none focus:ring-2 xl:rounded-xl">
                    <TabChild v-bind="items[0]" with-subheading />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {computed, defineComponent, ref} from 'vue';
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
import SetsRenderer from "./SetsRenderer";
import TabChild from "./Tab";
import vSelect from 'vue-select'

export default defineComponent({
    name: 'Tabs',
    components: {
        SetsRenderer,
        TabChild,
        TabGroup,
        TabList,
        TabPanels,
        TabPanel,
        Tab,
        vSelect
    },
    props: {
        items: { type: Array, required: true },
        tabs_style: { type: Object, default: {value: 'default', key: 'default', label: 'Default'} },
    },

    setup(props) {
        const selectedIndex = ref(0)
        const shouldRenderTabs = computed(() => props.items.length > 1);
        const tabsStyle = computed(() => props.tabs_style.value)
        const selectedItem = computed(() => props.items[selectedIndex.value]);

        function setSelectedIndex(index) {
            selectedIndex.value = index;
        }

        function setSelectedItem(selectedItem) {
            selectedIndex.value = props.items.findIndex(item => item.title === selectedItem.title)
        }

        return {
            shouldRenderTabs,
            tabsStyle,
            selectedIndex,
            setSelectedIndex,
            selectedItem,
            setSelectedItem,
        }
    }
});
</script>

<style scoped>
:deep(.v-select) {
    --vs-controls-color: #B76912;
    --vs-border-color: transparent;

    --vs-dropdown-bg: #ffffff;
    --vs-dropdown-color: #111827;
    --vs-dropdown-option-color: #111827;

    --vs-selected-color: #111827;

    --vs-dropdown-option--active-bg: #CB7517;
    --vs-dropdown-option--active-color: #eeeeee;
}
</style>
