import {computed, watch, watchEffect} from 'vue';

export function useButtonStyle(color, config) {
    const SIZES = {
        base: 'py-2.5 px-4 text-base',
        small: 'py-2.5 px-3 text-sm',
    }

    const COLORS = {
        primary:  {
            defaults: 'text-white bg-primary hover:bg-primary-darker focus:bg-primary-darker focus:ring-white focus:ring-inset focus:ring-offset-4 focus:ring-offset-primary-darker focus:ring-2',
            inverted: 'text-primary bg-white hover:bg-primary-100 focus:bg-primary-100 focus:ring-primary focus:ring-inset focus:ring-offset-4 focus:ring-offset-primary-100 focus:ring-2',
        },

        secondary: {
            defaults: 'text-white bg-secondary hover:bg-secondary-darker focus:bg-secondary-darker focus:ring-white focus:ring-inset focus:ring-offset-4 focus:ring-offset-secondary-darker focus:ring-2',
            inverted: 'text-secondary-900 bg-white hover:bg-secondary-100 focus:bg-secondary-100 focus:ring-secondary-900 focus:ring-inset focus:ring-offset-4 focus:ring-offset-secondary-100 focus:ring-2',
        },

        light: {
            defaults: '',
        },
    };

    const classes = computed(() => {
        if (config.customStyle) {
            return config.customStyle;
        }

        const sizeValue = SIZES[config.size];
        const colorsValue = COLORS[color][config.inverted ? 'inverted' : 'defaults'];

        return `rounded font-normal focus:ring-2 focus:ring-offset-1 focus:outline-none ${colorsValue} ${sizeValue}`;
    });

    return {
        classes,
    }
}
