<template>
    <div class="border-b-8">
        <SectionTitle link="regional-air-quality" color="green" class="mb-8"
            :title="$tx('Regional Air Quality')"
            :subtitle="$tx('Days of Poor Air Quality in Maricopa County')" />
        <div class="flex flex-col max-w-6xl m-auto gap-12 lg:pb-24">
            <ChartTableSwitch page-title="Regional Air Quality">
                <template v-slot:chart>
                    <div class="flex-1 p-4">
                        <ChartRenderer :chart="BarChart" chartId="regional-air-quality-chart" :chartData="chartData" :plugins="[ChartDataLabels]" :options="chartOptions" class="flex flex-1 h-[350px] md:h-450px] lg:h-[600px]" label="Regional Air Quality Chart/Graph. Refer to data table for details." />
                    </div>
                </template>

                <template v-slot:table>
                    <div class="flex justify-center min-w-full mt-4">
                        <DataTable highlight-first v-bind="dataTable" />
                    </div>
                </template>
            </ChartTableSwitch>
        </div>
    </div>
</template>

<script>
import {defineComponent, computed, inject} from 'vue';
import ChartRenderer from "../../../components/ChartRenderer";
import {useIsSmallScreen} from "../../../utils/helpers";
import DataTable from "../Components/DataTable";
import ChartDataLabels from 'chartjs-plugin-datalabels'
import ChartTableSwitch from "../Components/ChartTableSwitch";
import Section from "../Components/Section";
import SectionTitle from "../Components/SectionTitle";
import InfoBox from "../Components/InfoBox";
import InfoTable from "../Components/InfoTable";
import CovidDisclaimer from '../Components/CovidDisclaimer';
import { BarChart } from 'vue-chart-3';
import {generateDataLabelsSettings, generateTickSettings, generateTitleSettings} from "./chartsUtils";

export default defineComponent({
    name: 'RegionalAirQuality',
    components: {
        ChartRenderer,
        DataTable,
        BarChart,
        ChartTableSwitch,
        Section,
        SectionTitle,
        InfoBox,
        InfoTable,
        CovidDisclaimer,
    },
    setup() {
        const tx = inject('tx')
        const isSmall = useIsSmallScreen()

        const chartData = {
            labels: [ 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021 ],
            datasets: [
                {
                    data: [21, 21, 10, 35, 52, 61, 37, 47, 43 ],
                    backgroundColor: '#aacd4e',
                    borderColor: 'rgb(132, 132, 132)',
                }
            ],
        };

        const chartOptions = computed(() => ({
            hover: { mode: null },
            plugins: {
                legend: false,
                tooltip: {
                    enabled: isSmall.value,
                },
                datalabels: generateDataLabelsSettings({
                    anchor: 'end',
                    align: 'end',
                })
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    title: generateTitleSettings({
                        text: tx('Years'),
                    }),
                    ticks: generateTickSettings(),
                },
                y: {
                    title: generateTitleSettings({
                        text: tx('Days'),
                    }),
                    ticks: generateTickSettings(),
                }
            }
        }));

        const dataTable = computed(() => {
            const headings = {
                year: tx('Year'),
                value: tx('Days'),
            };

            const rows = chartData.labels.map((year, idx) => ({
                year,
                value: chartData.datasets[0].data[idx],
            }));

            return {
                headings,
                rows,
            }
        })

        return {
            BarChart,
            dataTable,
            chartData,
            chartOptions,
            ChartDataLabels,
        };
    }
});
</script>
