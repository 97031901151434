<template>
    <Popover v-slot="{ open, close }">
        <div>
            <PopoverButton class="relative py-4 px-4 text-gray-600 group bg-white border border-t-4 w-full flex justify-center items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-0 lg:w-auto lg:py-8 lg:px-4 lg:inline-flex" :class="{'border-gray-200 border-t-red': open, 'border-transparent': !open}" >
                <span v-if="open" class="hidden absolute inset-x-0 bottom-0 -mb-1 z-40 w-full h-1 bg-white lg:block"></span>
                <span>
                    <slot name="label"></slot>
                </span>
                <!-- Icon -->
                <slot name="icon" class="text-gray-400 ml-2 h-5 w-5 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"></slot>
            </PopoverButton>

            <PopoverPanel v-show="open" static class="absolute z-10 left-0 right-0 mt-0 transform w-screen px-0 md:px-4 lg:px-8">
                <div class="relative w-full shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden bg-white p-2 md:px-5 md:py-6">
                    <slot name="menu"></slot>
                </div>
            </PopoverPanel>
        </div>
    </Popover>
</template>

<script>
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/vue';
import {defineComponent} from "vue";

export default defineComponent({
    name: "FlyoutItem",
    components: { Popover, PopoverButton, PopoverPanel }
});
</script>
