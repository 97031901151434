<template>
    <component
        :is="as"
        v-bind="$attrs"
        class="flex-shrink inline-flex justify-center items-center space-x-2"
        :class="classes"
        @click="onClick"
    >
        <slot></slot>
    </component>
</template>

<script>
import {useButtonStyle} from "./useButtonStyle";

export default {
    name: "PrimaryButton",

    props: {
        inverted: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'small',
            validator (val) {
                return ['base', 'small'].includes(val);
            }
        },
        customStyle: {
            type: [String, undefined],
            default: undefined,
        },
        as: {
            type: String,
            default: 'button',
        },
    },

    emits: ['click'],

    setup(props, { emit }) {
        const { classes } = useButtonStyle(
            'primary',
            props
        );

        function onClick() {
            emit('click');
        }

        return { classes, onClick };
    },
}
</script>
