<template>
    <header class="mx-auto site-header max-w-7xl">
        <div class="flex flex-wrap items-center justify-between md:flex-nowrap">
            <div class="flex items-center justify-between w-full top-container md:w-auto">
                <div class="flex items-center py-3 pl-3 logo-container">
                    <a href="/">
                        <img src="/assets/valley-metro.svg" class="h-8 sm:h-10 md:h-20" alt="Valley Metro" />
                    </a>
                </div>
                <div class="pr-2 ml-auto md:hidden">
                    <button
                        @click="toggleMainContent"
                        type="button"
                        class="inline-flex items-center justify-center p-2 text-gray-600 bg-white rounded-md hover:text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        aria-expanded="false"
                    >
                        <span class="sr-only">Open menu</span>
                        <!-- Heroicon name: outline/menu -->
                        <svg
                            class="w-6 h-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    </button>
                </div>
            </div>
            <div class="flex items-center w-full expandable-container md:w-auto" v-show="showMainContent" @click.stop>
                <div class="w-full main-container">
                    <div class="px-3 py-2 search-container md:p-0 md:hidden">
                        <SiteSearchInput name="mobile-search" />
                    </div>
                    <div v-show="showDesktopSearch" class="px-3 py-2 search-container md:p-0">
                        <SiteSearchInput name="desktop-search" ref="searchHeaderDesktop"/>
                    </div>
                    <nav v-show="!showDesktopSearch" role="menu" class="flex flex-wrap menu-container md:flex-nowrap">
                        <template v-for="topLevelItem in mainNav">
                            <keep-alive>
                                <component :is="navItemComponent(topLevelItem)" :label="topLevelItem.title" class="w-full md:w-auto" role="menuitem">
                                    <template
                                        v-if="topLevelItem.left && topLevelItem.left.length"
                                        v-slot:left
                                    >
                                        <SetsRenderer :sets="topLevelItem.left" />
                                    </template>

                                    <template v-slot:center>
                                        <div class="flex flex-row flex-wrap w-full mt-2" role="menu">
                                            <template v-for="child in topLevelItem.children">
                                                <Link
                                                    :href="child.url"
                                                    role="menuitem"
                                                    @success="hideDropdown"
                                                    class="w-full p-1 text-secondary hover:text-secondary-darker hover:underline"
                                                >
                                                    {{ child.title }}
                                                </Link>
                                            </template>
                                        </div>
                                    </template>

                                    <template v-slot:right>
                                        <div class="w-full">
                                            <SetsRenderer
                                                v-if="topLevelItem.right && topLevelItem.right.length"
                                                :sets="topLevelItem.right"
                                            />
                                        </div>

                                        <div v-if="hasRightSlotMenu(topLevelItem)" role="menu" class="grid w-full mt-2 lg:grid-flow-col" :class="`lg:grid-rows-${topLevelItem.row_count || 8}`">
                                            <template v-for="child in topLevelItem.children">
                                                <Link
                                                    :href="child.url"
                                                    role="menuitem"
                                                    @success="hideDropdown"
                                                    class="p-1 text-secondary hover:text-secondary-darker hover:underline"
                                                >
                                                    {{ child.title }}
                                                </Link>
                                            </template>
                                        </div>
                                    </template>
                                </component>
                            </keep-alive>
                        </template>
                    </nav>
                </div>
                <div class="hidden md:block">
                    <button @click="toggleSearch" class="flex items-center p-4 py-8 border-t-4 border-transparent group focus:ring-0 focus:bg-gray-100 focus:border-t-red" :aria-label="toggleSearchButtonTitle" :title="toggleSearchButtonTitle">
                        <transition name="fade" mode="out-in">
                            <Icon :icon="mdiMagnify" v-if="!showDesktopSearch" class="w-[1.75rem] h-[1.75rem] text-gray-800 group-hover:text-secondary group-focus:text-secondary" aria-label="Magnifier icon"/>
                            <Icon :icon="mdiClose" v-else class="w-[1.75rem] h-[1.75rem] text-gray-800 group-hover:text-secondary group-focus:text-secondary" aria-label="Close icon"/>
                        </transition>
                    </button>
                </div>
            </div>
        </div>
        <div class="flex flex-wrap items-center justify-between md:flex-nowrap">
            <div class="flex items-center py-3 pl-3">
                <a href="/app" class="p-2 text-lg font-bold text-white transition-all rounded-full bg-secondary hover:scale-110 focus:ring-2 focus:ring-offset-2 focus:ring-secondary">
                    Download the Valley Metro app
                </a>
            </div>
            <div class="flex items-center py-6 pl-6">
                <div id="google_translate_element" class="google-translate-element"></div>
            </div>
        </div>
    </header>
</template>

<script>
import {computed, nextTick, ref, watch} from 'vue';
import FlyoutMenu from './FlyoutMenu';
import MainNavItem from './MainNavItem';
import CenterNavItem from './CenterNavItem';
import SetsRenderer from './SetsRenderer';
import SiteSearchInput from './SiteSearchInput';
import InputSearchBox from './InputSearchBox';
import FaresTable from '../components/FaresTable';
import { Link, usePage } from '@inertiajs/vue3';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import {mdiMagnify, mdiClose} from '@mdi/js';
import Icon from "./Icon";
import { useSiteMainNav } from "../utils/siteMainNav";

export default {
    name: 'SiteHeader',
    components: {
        FaresTable,
        SetsRenderer,
        SiteSearchInput,
        InputSearchBox,
        MainNavItem,
        CenterNavItem,
        FlyoutMenu,
        Link,
        Icon,
    },

    setup() {
        const siteNav = useSiteMainNav();
        const page = usePage();
        const mainNav = computed(() => page.props.global.main_nav);

        const breakpoints = useBreakpoints(breakpointsTailwind);
        const isSmallScreen = breakpoints.smaller('md');

        const hideDropdown = () => {
            siteNav.hideDropdown();
            showMainContent.value = !isSmallScreen.value;
        }

        // Desktop search.
        const showDesktopSearch = ref(false);
        const toggleSearchButtonTitle = computed(() => {
            return showDesktopSearch.value
                ? 'Hide the site search field'
                : 'Show the site search field';
        });

        const toggleSearch = () => {
            showDesktopSearch.value = !showDesktopSearch.value;

            if (showDesktopSearch.value) {
                nextTick(() => searchHeaderDesktop.value?.focusOnInput());
            }
        };

        // Hide the desktop search when user is on the small screen.
        watch(isSmallScreen, () => {
            showDesktopSearch.value = false;
        });

        // @deprecated
        // Focus the search input when the search flyout is opened.
        const OnDesktopSearchTransition = () => {
            if (showDesktopSearch.value) {
                nextTick(() => searchHeaderDesktop.value?.focusOnInput());
            }
        }

        // Main content.
        const showMainContent = ref(!isSmallScreen.value)
        watch(isSmallScreen, (to) => {
            showMainContent.value = !to;
        });

        const toggleMainContent = () => {
            showMainContent.value = !showMainContent.value;
        };

        const showMenu = computed(() => {
            return isSmallScreen || !showDesktopSearch;
        });

        const navItemComponent = (topLevelItem) => {
            return topLevelItem.center ? 'CenterNavItem' : 'MainNavItem';
        };

        const hasRightSlotMenu = (topLevelItem) => {
            return !topLevelItem.center;
        };

        const searchHeaderDesktop = ref();

        const clickedLayout = () => {
            if (!searchHeaderDesktop.value?.form.q) {
                showDesktopSearch.value = false;
            }
        };

        return {
            mainNav,
            navItemComponent,
            hasRightSlotMenu,

            showDesktopSearch,
            toggleSearchButtonTitle,
            toggleSearch,
            OnDesktopSearchTransition,

            showMainContent,
            toggleMainContent,

            mdiMagnify,
            mdiClose,

            searchHeaderDesktop,

            clickedLayout,
            hideDropdown
        };
    },
    mounted() {
        let googleTranslateScript = document.createElement('script');
        googleTranslateScript.setAttribute('src', 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
        document.head.appendChild(googleTranslateScript);

        let googleTranslateInit = document.createElement('script');
        googleTranslateInit.appendChild(document.createTextNode(`
            function googleTranslateElementInit() {
                new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element');
                var googleCombo = document.querySelector('.goog-te-combo');

                var translateInterval = setInterval(() => {
                    var firstOpt = googleCombo.options[0];
                    if (firstOpt) {
                        clearInterval(translateInterval);
                        googleCombo.setAttribute('aria-label', googleCombo.options[0].text);
                    }
                }, 10);
            }
        `));
        document.head.appendChild(googleTranslateInit);
    }
};
</script>

<style scoped>
    @media(min-width: theme('screens.md')) {
        .logo-container {
            height: 93px;
        }
    }
</style>
