import {compareAsc, format, parse, startOfDay} from "date-fns";
import {utcToZonedTime} from "date-fns-tz";

function formatFromISO(iso8601, formatString) {
    return format(parseFromISO(iso8601), formatString);
}

function parseFromISO(isoDate, originalTimezone = null) {
    if (! isoDate) {
        return null;
    }

    return utcToZonedTime(isoDate, 'America/Phoenix', {
        timeZone: originalTimezone,
    });
}

function formatAmPmTime(iso8601) {
    return formatFromISO(iso8601,'h:mm aaaa');
}

function transformAmPmFrom24(time) {
    const [ampmTime, ampmPeriod] = format(parse(time, 'H:mm:ss', new Date()), 'h:mm a').split(' ');

    return {
        time: ampmTime,
        ampm: ampmPeriod.toUpperCase(),
    }
}

function getToday() {
    return startOfDay(new Date());
}

function getNow() {
    return new Date();
}

function parseDate(dateString) {
    const [year, month, day] = dateString.split('/');

    return startOfDay(new Date(year, month - 1, day));
}

function betweenDates(date, start, end) {
    const compareStart = (start) ? compareAsc(date, start) > -1 : true;
    const compareEnd = (end) ? compareAsc(end, date) > -1 : true;

    return compareStart && compareEnd;
}

export {
    parse,
    format,
    getNow,
    getToday,
    parseDate,
    betweenDates,
    parseFromISO,
    formatFromISO,
    formatAmPmTime,
    transformAmPmFrom24,
}
