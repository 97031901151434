<template>
    <component :is="chart" v-bind="$attrs" ref="chartRef"></component>
</template>

<script>
import {defineComponent, inject, ref, watch} from 'vue';
import useTranslations from "../pages/QOL/translations";

export default defineComponent({
    name: 'ChartRenderer',

    props: {
        chart: {
            required: true,
        },
        label: {
            type: String,
            required: true,
        }
    },

    setup(props) {
        const tx = inject('tx')
        const { locale } = useTranslations();
        const chartRef = ref()

        watch([chartRef, locale ], () => {
            if (chartRef.value) {
                const canvas = chartRef.value.canvasRef
                canvas.setAttribute('role', 'img')
                canvas.ariaLabel = tx(props.label)
            }
        });

        return {
            chartRef,
        }
    },
});
</script>
