<template>
    <div class="element" ref="elementRef">
        <slot name="element" v-bind="{ elementAttrs, visible, show, hide }"></slot>
    </div>
    <div
        :id="tooltipId"
        ref="tooltipRef"
        role="tooltip"
        class="tooltip max-w-[320px] bg-gray-800 text-white px-2 py-3 text-md rounded-lg z-[30] shadow-lg md:max-w-[500px] lg:max-w-[800px]"
        v-bind="tooltipAttrs"
        v-show="visible"
    >
        <slot></slot>
        <slot name="tooltip" v-bind="{ tooltipAttrs }"></slot>
        <div class="arrow shadow-lg" data-popper-arrow></div>
    </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue';
import { createPopper } from '@popperjs/core';

export default defineComponent({
    name: "WithTooltip",
    props: {
        id: {
            type: String,
            required: true,
        },
        pos: {
            type: String,
            default: 'top',
        },
    },
    setup(props) {
        let popperInstance = null;
        const visible = ref(false);
        const elementRef = ref();
        const tooltipRef = ref();

        const tooltipId = computed(() => `${props.id}_tooltip`)

        const tooltipAttrs = computed(() => {
            if (visible.value) {
                return {

                }
            }

            return {};
        })

        const elementAttrs = computed(() => {
            return {
                'aria-describedby': tooltipId.value,
            }
        })

        function show() {
            visible.value = true;
            // We need to tell Popper to update the tooltip position after we show the tooltip
            popperInstance.update();
        }

        function hide() {
            visible.value = false;
        }

        onMounted(() => {
            popperInstance = createPopper(elementRef.value, tooltipRef.value, {
                placement: props.pos,
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 8],
                        }
                    }
                ]
            });
        })

        return {
            show,
            hide,
            visible,
            tooltipId,
            elementRef,
            tooltipRef,
            tooltipAttrs,
            elementAttrs,
        };
    },
    components: {}
})
</script>

<style scoped>
.arrow,
.arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
}

.arrow {
    visibility: hidden;
}

.arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
}

.tooltip[data-popper-placement^='top']>.arrow {
    bottom: -4px;
}

.tooltip[data-popper-placement^='bottom']>.arrow {
    top: -4px;
}

.tooltip[data-popper-placement^='left']>.arrow {
    right: -4px;
}

.tooltip[data-popper-placement^='right']>.arrow {
    left: -4px;
}
</style>
