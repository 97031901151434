<template>
    <FlyoutHoverItem v-bind="$attrs" :id="label">
        <template role="menuitem" v-slot:label>{{ label }}</template>

        <template role="menu" v-slot:menu>
            <div class="flex flex-col-reverse w-full max-w-6xl mx-auto md:space-x-6 md:flex-row lg:space-x-8">
                <div class="w-auto mt-8 md:mt-0 md:w-1/3 lg:w-1/3">
                    <slot name="left"></slot>
                </div>
                <div class="w-auto md:w-1/3 lg:w-1/3">
                    <slot name="center"></slot>
                </div>
                <div class="w-auto md:w-1/3 lg:w-1/3">
                    <slot name="right"></slot>
                </div>
            </div>
        </template>
    </FlyoutHoverItem>
</template>

<script>
import {defineComponent} from "vue";
import FlyoutItem from "./FlyoutItem";
import FlyoutHoverItem from "./FlyoutHoverItem";

export default defineComponent({
    name: "CenterNavItem",
    components: {
        FlyoutItem,
        FlyoutHoverItem,
    },
    props: {
        label: {
            required: true,
            type: String,
        }
    }
});
</script>
