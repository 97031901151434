<template>
<div class="h-full w-full bg-white" @click="clickedLayout">
    <div v-if="alerts && alerts.length > 0" class="banner">
        <template v-for="alert in alerts">
            <div class="message" v-html="alert.content"></div>
        </template>
    </div>
    <SiteHeader ref="header"/>
    <main class="w-full max-w-screen">
        <slot />
    </main>
    <SiteFooter />
</div>
</template>

<style scoped>
.banner {
    background: #FBBF24;
    color: #443904;
    font-size: 1rem;
    text-align: left;
    padding: 12px;
    border-bottom: 2px solid #D3981F;
}

.message:after {
    content: '';
    display: block;
    width: 32px;
    border-bottom: 2px solid #443904;
    padding-bottom: 8px;
    margin-bottom: 8px;
}

.message:last-child:after {
    display: none;
}
</style>

<script>
import {usePage} from "@inertiajs/vue3";
import {defineComponent, onMounted, ref} from "vue";
import {usePagePopups} from "../hooks/usePagePopups";
import SiteFooter from "./SiteFooter";
import SiteHeader from "./SiteHeader";

export default defineComponent({
    name: "DefaultLayout",
    components: {SiteHeader, SiteFooter},
    setup() {
        const { queuePagePopups } = usePagePopups();

        const header = ref()
        const alerts = usePage().props.global.alerts
        const popups = usePage().props.page_popups

        const clickedLayout = () => {
            header.value.clickedLayout()
        }

        onMounted(() => {
            if (popups && popups.length) {
                queuePagePopups(popups);
            }
        });

        return {
            alerts,
            header,
            clickedLayout,
        }
    }
});
</script>
