<template>
    <fieldset class="w-full">
        <legend class="font-bold">
            {{ field.display }} <span v-if="field.isRequired" class="font-normal italic text-xs text-gray-500 ml-1">required</span>
        </legend>
        <template v-for="(optionLabel, optionValue) in field.options">
            <label :class="{'block w-full mb-1': !field.inline}">
                <input :title="optionLabel || field.placeholder" type="radio" :name="field.handle" :value="optionValue" :required="field.isRequired" :checked="isChecked(field, optionValue)">
                {{ optionLabel || optionValue }}
            </label>
        </template>
    </fieldset>
</template>

<script setup>
import {defineField} from "./BaseField.js";

const field = defineField();

const isChecked = (field, value) => {
    if (!field.old) {
        return false;
    }

    return field.old === value;
}
</script>
