<template>
    <div class="relative w-full bg-white xl:overflow-hidden">
        <SectionTitle :title="content.title" link="collisions-involving-cyclists-and-pedestrians" color="blue" class="mb-8" />

        <Section class="">
            <template #graphic>
                <Image class="graphic" alt="" src="https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/static-graphics/isolated/static-vehicle-collisions.svg" />
            </template>

            <template #info>
                <div class="flex flex-wrap gap-8 justify-center lg:pb-24">
                    <InfoBox v-for="(infoBox, index) in content.infoBoxes" :key="index" halign="left" color="qol3-full" width-class="w-[290px] md:w-[420px]">
                        <template #header>
                            <InfoBoxIcon :icon="infoBox.icon" class="-mt-10 -mb-2"/>
                        </template>
                        <template #content>
                            <div v-html="infoBox.text" class="text-center"></div>
                        </template>
                    </InfoBox>
                </div>
            </template>
        </Section>
    </div>
</template>

<script>
import {defineComponent, computed, inject} from 'vue';
import Image from "../Components/Image";
import Section from "../Components/Section";
import SectionTitle from "../Components/SectionTitle";
import InfoBox from "../Components/InfoBox";
import InfoBoxIcon from "../Components/InfoBoxIcon";
import useTranslations from "../translations";

export default defineComponent({
    name: 'BikeAndPedestrianCollisions',
    components: {
        Image,
        Section,
        SectionTitle,
        InfoBox,
        InfoBoxIcon,
    },
    setup() {
        const { translate } = useTranslations();

        const content = computed(() => ({
            title: translate.value('Vehicle Collisions with Bicyclists and Pedestrians', 'Colisiones Vehiculares con Ciclistas y Peatones'),
            infoBoxes: [
                {
                    icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/vehicle_collisions_1.svg',
                    text: translate.value(
                        'Arizona has the <strong>5th</strong> highest pedestrian fatality rate in the nation.',
                        'Arizona tiene la <strong>5ª</strong> tasa de mortalidad peatonal más alta de la nación.'
                    )
                },
                {
                    icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/vehicle_collisions_2.svg',
                    text: translate.value(
                        'There were over <strong>1,600</strong> vehicle collisions with bicyclists and pedestrians in Maricopa County in 2020.',
                        'Hubo más de <strong>1,600</strong> colisiones de vehículos con ciclistas y peatones en el Condado Maricopa en 2020.'
                    )
                },
                {
                    icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/vehicle_collisions_3.svg',
                    text: translate.value(
                        'Maricopa County accounts for over <strong>80%</strong><br class="hidden md:inline" /> all pedestrian fatalities in Arizona, with only <br class="hidden md:inline" /><strong>62%</strong> of the of the population.',
                        'Más del <strong>80%</strong> de todas las muertes peatonales en Arizona ocurrieron en el Condado Maricopa, el cual cuenta con sólo el <strong>62%</strong> de la población',
                    )
                },
                {
                    icon: 'https://vulcan-production.nyc3.cdn.digitaloceanspaces.com/misc-assets/quality-of-life/information-boxes/second-page/vehicle_collisions_4.svg',
                    text: translate.value(
                        'Pedestrian deaths are up <strong>193%</strong><br/> in Arizona since 2009.',
                        'Las muertes peatonales han aumentado <strong>193%</strong> en Arizona desde 2009.'
                    )
                }
            ],
        }))

        return {
            content
        };
    }
});
</script>
