<template>
    <FieldContainer v-slot="{ field, fieldClasses }" disable-prepend disable-append>
        <select
            :id="field.id"
            :name="field.multiple ? `${field.handle}[]` : field.handle"
            :required="field.isRequired"
            :multiple="field.multiple"
            class="w-full field-custom field-in-group"
            :title="field.label || field.placeholder"
            :class="fieldClasses"
        >
            <option v-if="! field.multiple" value>{{ field.placeholder || 'Please select...' }}</option>

            <template v-for="(label, value) in field.options">
                <option :value="value" :selected="isSelected(field, value)">
                    {{ label || value }}
                </option>
            </template>
        </select>
    </FieldContainer>
</template>

<script setup>
import FieldContainer from "../FieldContainer";

function isSelected(field, value) {
    if (! field.value || !field.old) {
        return false;
    }

    if (field.multiple) {
        return field.value.includes(value);
    }

    return field.old;
}
</script>
