<template>
    <div class="mx-auto grid gap-5" :class="classes">
        <slot />
    </div>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'CardGrid',

    props: {
        gridClass: {
            type: String,
            default: 'md:grid-cols-3 grid-cols-2',
            validator(value) {
                return value.includes('grid-cols');
            }
        },
    },

    setup(props) {
        const classes = [
            props.gridClass,
        ];

        return { classes }
    },
});
</script>
