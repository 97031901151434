<template>
    <MapSearchBox
        :hide-button="true"
        @input="onInput"
        @keydown="onKeyDown"
        :id="`${id}-site-search-term`"
        name="maps_schedules_search_term"
        role="searchbox"
        :value="value"
        :aria-controls="ariaControls"
        :aria-activedescendant="ariaActivedescendant"
        :placeholder="placeholder"
        aria-autocomplete="list"
        tabindex="-1"
    />
</template>

<script>
import MapSearchBox from '../../MapSearchBox.vue';

export default {
    name: "SearchInput",
    components: {MapSearchBox},
    emits: ['change', 'keydown'],
    props: {
        id: {
            type: String,
            required: true
        },
        value: {
            type: String,
            required: false,
            default: '',
        },
        placeholder: {
            type: String,
            required: true
        },
        ariaControls: {
            type: String,
            required: true
        },
        ariaActivedescendant: {
            type: String,
            required: true
        },
    },

    setup(props, { emit }) {
        function onKeyDown(ev) {
            ev.stopPropagation();
            emit('keydown', ev);
        }

        function onInput(ev) {
            ev.stopPropagation();
            emit('change', ev);
        }

        return { onKeyDown, onInput };
    }
}
</script>
