<template>
    <div class="relative w-full h-full max-w-screen-xl mx-auto project-entry bg-primary xl:rounded-xl xl:mb-10">
        <AppHead :title="title">
            <meta head-key="description" name="description" :content="`${title} - ${location} - ${description}`" />
        </AppHead>

        <div class="flex justify-end w-full px-2 pt-4 mx-auto mb-2 max-w-7xl md:px-8">
            <content-locale-selector :options="localizations" :locale="locale"/>
        </div>

        <EditButton :url="edit_url" />

        <div class="relative w-full p-2 mx-auto max-w-7xl md:p-8">
            <!-- Thumbnails -->
            <nav class="flex items-center mb-6 space-x-2 text-sm font-light uppercase">
                <template v-for="bread in breadcrumbs">
                    <span class="text-gray-300">/</span>
                    <Link :href="bread.permalink" class="text-primary-gray hover:underline" v-bind="bread.options">{{ bread.title }}</Link>
                </template>
            </nav>

            <!-- Title -->
            <div class="relative w-full mx-auto rounded-lg max-w-7xl">
                <h1 v-if="title" class="block pb-4 text-4xl font-extrabold leading-relaxed tracking-tight text-left text-white border-b border-gray-200 tk-museo sm:leading-loose sm:text-5xl">
                    {{ title }}
                </h1>
            </div>

            <div class="p-3 mt-10 bg-white rounded-xl">
                <!-- Picture & Description -->
                <div v-if="topImage && location" class="flex flex-col w-full mb-4 overflow-hidden rounded-xl bg-primary-gray md:flex-row">
                    <!-- Entry picture -->
                    <div v-if="topImage" class="flex-1 bg-gray-100" style="min-height: 320px">
                        <div class="flex-shrink-0 h-full">
                            <img class="object-cover w-full h-full" :src="topImage.url" :alt="topImage.alt || ''" role="img" />
                        </div>
                    </div>

                    <!-- Entry title and date -->
                    <div  v-if="location" class="p-8 space-y-4 w-96">
                        <div class="flex items-center text-2xl font-normal leading-normal text-gray-800">
                            <Icon :icon="mdiMapMarker" class="w-5 h-6 mr-4" aria-label="Map marker icon"/>
                            {{ location }}
                        </div>

                        <div v-if="description" class="mt-6 text-base font-normal tracking-wide text-gray-600">
                            <SetsRenderer :sets="description" />
                        </div>
                    </div>

                </div>

                <!-- Content -->
                <div class="relative w-full lg:grid lg:grid-cols-6 lg:gap-5">
                    <div :class="classes.content">
                        <div class="space-y-16">
                            <Tabs :items="tabs" />
                        </div>
                    </div>
                    <div v-if="resources && resources.length > 0" class="lg:col-span-2">
                        <div class="relative w-full p-2 mx-auto bg-white rounded-lg max-w-7xl">
                            <div class="space-y-2">
                                <SetsRenderer :sets="resources" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Link} from '@inertiajs/vue3';
import {mdiMapMarker} from '@mdi/js';
import {computed, defineComponent} from 'vue';
import AppHead from "../components/AppHead";
import ContentLocaleSelector from "../components/ContentLocaleSelector";
import EditButton from "../components/EditButton";
import Icon from "../components/Icon";
import SetsRenderer from "../components/SetsRenderer";
import Tabs from "../components/Tabs";

export default defineComponent({
    name: 'ProjectsEntry',
    components: {
        EditButton,
        Icon,
        Link,
        Tabs,
        AppHead,
        SetsRenderer,
        ContentLocaleSelector
    },

    props: {
        tabs: {type: Array, required: true},
        title: {type: String, required: true},
        resources: {type: Array, default: () => []},
        date: {type: [String, null], default: null},
        media: {type: [Array, null], default: null}, // []
        breadcrumbs: {type: Array, default: () => []},
        parent: {type: [Object, null], default: null}, // {}
        location: {type: [String, null], default: null},
        description: {type: [Array, null], default: null}, // Object[]
        thumb_image: {type: [Object, null], default: null}, // { url, alt }
        project_status: {type: [Object, null], default: null},
        showThumbnails: {type: [Boolean, null], default: true},
        locale: {type: String, required: true},
        localizations: {type: Array, required: true},
        image_url: {type: [String, null], default: null},
        is_url: {type: [Boolean, null], default: null},
        edit_url: { type: [String, null], default: null },
    },

    setup(props) {
        const classes = props.resources.length
            ? { content: 'lg:col-span-4' }
            : { content: 'lg:col-span-6' };

        const topImage = computed(() => {
            if (props.is_url) {
                return {
                    url: props.image_url,
                    alt: '',
                }
            }

            return props.thumb_image;
        });

        return {
            classes,
            topImage,
            mdiMapMarker,
        }
    },
});
</script>
